.loading-translate-key-item {
  border-radius: 10px !important;
  color: #e5e5e5 !important;
  background: #e5e5e5 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px!important;
  -webkit-line-clamp: 1;
  display: inline-block;
  mix-blend-mode: hard-light;
}