.modal {
  z-index: 2147483500;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.modal__mask {
  background-color: #00000073;
  position: fixed;
  z-index: 1000;
  height: 100%;
  inset: 0;
}

.modal__title-text {
  margin: 0;
  color: #000000d9;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ofa {
  overflow: unset !important;
}

.modal__content-wrapper {
  z-index: 1000;
  position: fixed;
  inset: 0;
  overflow: auto;
  outline: 0;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}

.modal__content {
  z-index: 2147483501;
  min-width: 320px;
  box-sizing: border-box;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  top: 100px;
  position: relative;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
}

.modal__title {
  padding: 16px 24px;
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.modal__cross {
  position: absolute;
  padding: 20px;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.modal__cross-img {
  filter: invert(58%) sepia(0%) saturate(1495%) hue-rotate(134deg) brightness(82%) contrast(79%);
}
