@font-face {
  font-family: 'LANGCO';
  src: url('/fonts/LANGCO.ttf?kh2zoe') format('truetype'),
  url('/fonts/LANGCO.woff?kh2zoe') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.json {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'LANGCO' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
  content: "\e900";
}
.icon-plus:before {
  content: "\e901";
}
.icon-delete:before {
  content: "\e902";
}
.icon-blog1:before {
  content: "\e91c";
}
.icon-currencies1:before {
  content: "\e91d";
}
.icon-lessons1:before {
  content: "\e91e";
}
.icon-levels1:before {
  content: "\e91f";
}
.icon-news1:before {
  content: "\e920";
}
.icon-products1:before {
  content: "\e921";
}
.icon-promocode1:before {
  content: "\e922";
}
.icon-reviews1:before {
  content: "\e923";
}
.icon-role1:before {
  content: "\e924";
}
.icon-rules1:before {
  content: "\e925";
}
.icon-target1:before {
  content: "\e926";
}
.icon-translates1:before {
  content: "\e927";
}
.icon-tree1:before {
  content: "\e928";
}
.icon-users-group1:before {
  content: "\e929";
}
.icon-users1:before {
  content: "\e92a";
}
.icon-menu-ico-1:before {
  content: "\e912";
}
.icon-menu-ico-2:before {
  content: "\e913";
}
.icon-menu-ico-3:before {
  content: "\e914";
}
.icon-menu-ico-4:before {
  content: "\e915";
}
.icon-menu-ico-5:before {
  content: "\e916";
}
.icon-menu-ico-6:before {
  content: "\e917";
}
.icon-menu-ico-7:before {
  content: "\e918";
}
.icon-menu-ico-8:before {
  content: "\e919";
}
.icon-menu-ico-9:before {
  content: "\e91a";
}
.icon-menu-ico-10:before {
  content: "\e91b";
}
.icon-blog:before {
  content: "\e903";
}
.icon-currencies:before {
  content: "\e904";
}
.icon-lessons:before {
  content: "\e905";
}
.icon-levels:before {
  content: "\e906";
}
.icon-news:before {
  content: "\e907";
}
.icon-products:before {
  content: "\e908";
}
.icon-promocode:before {
  content: "\e909";
}
.icon-reviews:before {
  content: "\e90a";
}
.icon-role:before {
  content: "\e90b";
}
.icon-rules:before {
  content: "\e90c";
}
.icon-target:before {
  content: "\e90d";
}
.icon-translates:before {
  content: "\e90e";
}
.icon-tree:before {
  content: "\e90f";
}
.icon-users:before {
  content: "\e910";
}
.icon-users-group:before {
  content: "\e911";
}
.icon-arrow_for_select:before {
  content: "\e92b";
}
.icon-break:before {
  content: "\e92c";
}
