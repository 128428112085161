@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Thin.woff2') format('woff2'),
        url('Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.woff2') format('woff2'),
        url('Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Light.woff2') format('woff2'),
        url('Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-BoldOblique.woff2') format('woff2'),
        url('Evolventa-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-Bold.woff2') format('woff2'),
        url('Evolventa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-Oblique.woff2') format('woff2'),
        url('Evolventa-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-Regular.woff2') format('woff2'),
        url('Evolventa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-Bold.woff2') format('woff2'),
        url('Evolventa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-Regular.woff2') format('woff2'),
        url('Evolventa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-BoldOblique.woff2') format('woff2'),
        url('Evolventa-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Evolventa';
    src: url('Evolventa-Oblique.woff2') format('woff2'),
        url('Evolventa-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

