.language-selector__button {

}

.language-selector__select {
  transform: translateX(-25%);
  border: 2px solid #e4ebef;
  border-radius: 10px;
  visibility: hidden;
  background: #FFFFFF;
  position: absolute;
  padding: 10px;
  top: 30px;
}

.language-selector__mask {
  visibility: hidden;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.language-selector__select_show {
  visibility: visible;
}

.language-selector__item {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-transform: uppercase;
  text-align: center;
  padding: 7px 5px;
}

.language-selector__item:hover {
  opacity: .6;
}

.language-selector__item:not(:last-child) {
  border-bottom: 2px solid #e4ebef;
}
