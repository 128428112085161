@charset "UTF-8";
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0 !important;
}

html {
  overflow-x: hidden;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
}

#__next-build-watcher {
  z-index: 10 !important;
}

.section_courses-slider h4 {
  margin: 50px 0 30px!important;
}

body {
  overflow: visible;
  overflow-y: visible!important;
  width: 100%!important;
}

html {
  overflow: visible;
}

body,
html {
  width: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Evolventa", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #0A0A0B;
  position: relative;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-display: swap;
  margin: 0;
  counter-reset: step;
}

.cookie-butons {
  display: flex;
}

.landing .wrapper_btn {
  border-radius: 12px !important;
  overflow: hidden !important;
}

.landing .wrapper_btn:hover {
  opacity: 1 !important;
}

.mt-30 {
  margin-top: 30px;
}

@media (max-width: 1799px) {
  body {
    font-size: 16px;
  }
}

@media (max-width: 1279px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 1023px) {
  body {
    font-size: 16px;
  }
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #050425;
}

.ant-switch-checked {
  background: lawngreen !important;
}

.hidden-text__gradient {
  max-height: 40px;
  overflow: hidden;
  position: relative;
/*{ maxHeight: 40, overflow: "hidden" }*/
}

.hidden-text__gradient:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 20px;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.ta-c {
  text-align: center;
}

.df {
  display: flex;
}

.flx1 {
  flex: 1;
}

.fdc {
  flex-direction: column;
}

@media (max-width: 1023px) {
  .m-fdc {
    flex-direction: column!important;
  }

  .m-gap16 {
    gap: 16px;
  }
}

.df.ai-b {
  align-items: baseline;
}

.df.ai-c {
  align-items: center;
}

.df.jc-e {
  justify-content: end;
}

.df.jc-c {
  justify-content: center;
}

.df.jc-str {
  justify-content: stretch;
}

.df.jc-spaceb {
  justify-content: space-between;
}

.df.gap20 {
  gap: 20px;
}

.main-color {
  background: #EFF3F7;
}

.price-list h4 {
  margin: 0!important;
}

@media (min-width: 767px) {
  .price-list .select-box {
    width: 40%;
  }
}

hr {
  border: none;
  background: rgba(160, 176, 205, 0.15);
  height: 2px !important;
}

.mt-16 {
  margin-top: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-16 {
  margin-bottom: 16px;
}

.w100p {
  width: 100%!important;
}

.h100p {
  height: 100%!important;
}

.header_with_search_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

:-webkit-full-screen-ancestor:not(iframe) {
  z-index: 99999999 !important;
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-width: 0px !important;
  max-width: none !important;
  min-height: 0px !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-transform: none !important;
  transform: none !important;
  margin: 0px !important;
}

:not(:root):-webkit-full-screen {
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-width: initial !important;
  max-width: none !important;
  min-height: initial !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-transform: none !important;
  transform: none !important;
  margin: 0px !important;
}

:not(:root):-moz-full-screen {
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: initial !important;
  max-width: none !important;
  min-height: initial !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  transform: none !important;
  margin: 0px !important;
}

:not(:root):-ms-fullscreen {
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  box-sizing: border-box !important;
  min-width: initial !important;
  max-width: none !important;
  min-height: initial !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -ms-transform: none !important;
  transform: none !important;
  margin: 0px !important;
}

:not(:root):fullscreen {
  -o-object-fit: contain;
  object-fit: contain;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-width: initial !important;
  max-width: none !important;
  min-height: initial !important;
  max-height: none !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  margin: 0px !important;
}

.general-wrapper {
  width: 100%;
  min-width: 335px;
  max-width: 100vw;
}

::-moz-selection {
  background: rgba(22, 217, 194, 0.6);
}

::selection {
  background: rgba(22, 217, 194, 0.6);
}

::-moz-selection {
  background: rgba(22, 217, 194, 0.6);
}

input,
textarea {
  -webkit-box-shadow: inset 0 0 0 100px white;
  box-shadow: inset 0 0 0 100px white;
}

input[type="submit"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[readonly] {
  pointer-events: none;
}

img,
picture,
video {
  max-width: 100%;
}

ol,
ul {
  padding: 0;
  margin: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-style: inherit;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

a {
  text-decoration: none;
  outline: 0 !important;
  color: inherit;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  will-change: opacity;
}

a:hover {
  text-decoration: none;
  opacity: .5;
}

a:active {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  opacity: .5;
}

a:active {
  text-decoration: none;
  color: inherit;
}

button {
  outline: 0 !important;
  border: none;
  background-color: unset;
  padding: 0;
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 43px;
}

@media (max-width: 1799px) {
  h1,
  .h1 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1430px) {
  h1,
  .h1 {
    font-size: 22px;
    line-height: 29px;
  }
}

@media (max-width: 1279px) {
  h1,
  .h1 {
    font-size: 20px;
    line-height: 26px;
  }
}

h2,
.h2 {
  font-size: 28px;
  line-height: 37px;
}

@media (max-width: 1799px) {
  h2,
  .h2 {
    font-size: 22px;
    line-height: 29px;
  }
}

@media (max-width: 1430px) {
  h2,
  .h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 1279px) {
  h2,
  .h2 {
    font-size: 18px;
    line-height: 23px;
  }
}

@media (max-width: 1023px) {
  h2,
  .h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

h3,
.h3 {
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 1799px) {
  h3,
  .h3 {
    font-size: 19px;
    line-height: 25px;
  }
}

@media (max-width: 1430px) {
  h3,
  .h3 {
    font-size: 18px;
    line-height: 23px;
  }
}

@media (max-width: 1279px) {
  h3,
  .h3 {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 1023px) {
  h3,
  .h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

h4,
.h4 {
  font-size: 20px;
  line-height: 27px;
}

@media (max-width: 1799px) {
  h4,
  .h4 {
    font-size: 18px;
    line-height: 23px;
  }
}

@media (max-width: 1430px) {
  h4,
  .h4 {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 1279px) {
  h4,
  .h4 {
    font-size: 14px;
    line-height: 18px;
  }
}

h5,
.h5 {
  font-size: 18px;
  line-height: 24px;
  color: #4987FF;
}

@media (max-width: 1799px) {
  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1279px) {
  h5,
  .h5 {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 1023px) {
  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }
}

p {
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 1em;
  line-height: 160%;
  margin: 0;
}

p.small {
  font-size: 0.888888em;
  line-height: 28px;
}

@media (max-width: 1799px) {
  p.small {
    font-size: 0.875em;
    line-height: 20px;
  }
}

@media (max-width: 1430px) {
  p.small {
    font-size: 0.8125em;
  }
}

@media (max-width: 1279px) {
  p.small {
    font-size: 0.857em;
    line-height: 18px;
  }
}

@media (max-width: 1023px) {
  p.small {
    font-size: 0.75em;
  }
}

@media (max-width: 1023px) {
  p.small {
    font-size: 0.875em;
  }
}

.button {
  cursor: pointer;
  font-family: "Evolventa";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 486.23px;
  color: white;
  height: 60px;
  min-width: 259px;
  padding: 0 40px;
  background-color: #4987FF;
  -webkit-transition: .3s ease-out;
  -o-transition: .3s ease-out;
  transition: .3s ease-out;
  position: relative;
}

.button.square-button {
  border-radius: 17px;
}

@media (max-width: 1799px) {
  .button {
    padding: 0 25px;
  }

  .button.square-button {
    border-radius: 12px;
  }
}

@media (max-width: 1430px) {
  .button {
    padding: 0 19px;
  }

  .button.square-button {
    border-radius: 11px;
  }
}

@media (max-width: 1279px) {
  .button {
    padding: 0 25px;
    height: 50px;
    font-size: 14px;
  }

  .button.square-button {
    border-radius: 10px;
  }
}

@media (max-width: 1023px) {
  .button {
    padding: 0 13px;
    min-width: 150px;
  }

  .button.square-button {
    border-radius: 8px;
  }

  .sing-form .button {
    padding: 0 25px;
  }
}

@media (max-width: 767px) {
  .button {
    font-size: 13px;
  }

  .button.square-button {
    border-radius: 11px;
  }
}

.button.medium {
  height: 50px;
  font-size: 13px;
  min-width: 122px;
}

@media (max-width: 1799px) {
  .button.medium {
    height: 40px;
    font-size: 12px;
  }
}

@media (max-width: 1430px) {
  .button.medium {
    height: 38px;
    font-size: 11px;
  }
}

@media (max-width: 1279px) {
  .button.medium {
    height: 36px;
  }
}

@media (max-width: 1023px) {
  .button.medium {
    height: 34px;
    font-size: 9px;
  }
}

@media (max-width: 767px) {
  .button.medium {
    font-size: 13px;
  }
}

.button:hover {
  background-color: #1B5DDC;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  opacity: 1;
}

.button.arrow {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.button.arrow::after {
  content: "";
  width: 42px;
  height: 12px;
  position: relative;
  background-image: url(/svg/button-next-arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 20px;
}

.button.plus::before {
  content: "";
  width: 14px;
  height: 14px;
  margin-right: 10px;
  position: relative;
  background-image: url(/svg/button-white-plus.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button.load {
  pointer-events: none;
}

.button.load::before {
  content: "";
  background-image: url(/svg/loader_white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 30px;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}

.button.load > *, .button.load::after {
  opacity: 0;
  visibility: hidden;
}

.button.black {
  background-color: #0A0A0B;
}

.button.black:hover {
  background-color: #333335;
}

.button.gray {
  background-color: #EFF3F7;
  color: #0A0A0B;
}

.button.gray:hover {
  background-color: #D2DCEF;
}

.button.gray.load::before {
  background-image: url(/svg/loader.svg);
}

.button.green {
  background-color: #16D9C2;
}

.button.green:hover {
  background-color: #00B49F;
  color: white;
}

.button.red {
  background-color: #FF3366;
}

.button.red:hover {
  background-color: #e60039;
}

.button.border {
  background-color: transparent;
  border: 2px solid #0A0A0B;
  color: #0A0A0B;
}

.button.border:hover {
  background-color: #0A0A0B;
  color: white;
}

.button.border.load::before {
  background-image: url(/svg/loader.svg);
}

@media (max-width: 1799px) {
  .button.border {
    border-width: 1px;
  }
}

.greeter-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 1023px) {
  .greeter-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.auth-container {
  width: 44.2708333%;
  padding: 40px 50px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1799px) {
  .auth-container {
    width: 50%;
    padding: 62px 53px 49px;
  }
}

@media (max-width: 1430px) {
  .auth-container {
    width: 47.65%;
    padding: 45px 35px 13px;
  }
}

@media (max-width: 1279px) {
  .auth-container {
    width: 47.45%;
    padding: 48px 27px 39px;
  }
}

@media (max-width: 1023px) {
  .auth-container {
    width: 100%;
    padding: 36px 22px 29px;
  }
}

@media (max-width: 767px) {
  .auth-container {
    padding: 25px 20px 38px;
  }
}

.auth-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 80px;
  width: 100%;
}

@media (max-width: 1799px) {
  .auth-header {
    margin-bottom: 60px;
  }
}

@media (max-width: 1279px) {
  .auth-header {
    margin-bottom: 80px;
  }
}

@media (max-width: 1023px) {
  .auth-header {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .auth-header {
    margin-bottom: 40px;
  }
}

.auth-body {
  text-align: center;
  margin: auto 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

@media (max-width: 767px) {
  .auth-body {
    width: 100%;
  }
}

.auth-header__logo {
  width: 231px;
  height: 45px;
  -webkit-animation: auth-header__logo 1s ease 0s 1;
  animation: auth-header__logo 1s ease 0s 1;
}

@media (max-width: 1799px) {
  .auth-header__logo {
    width: 193px;
    height: 37px;
  }
}

@media (max-width: 1430px) {
  .auth-header__logo {
    width: 146px;
    height: 28px;
  }
}

@media (max-width: 1279px) {
  .auth-header__logo {
    width: 138px;
    height: 26px;
  }
}

@media (max-width: 767px) {
  .auth-header__logo {
    width: 144px;
    height: 28px;
  }
}

@-webkit-keyframes auth-header__logo {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes auth-header__logo {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.auth-header__steps {
  font-family: "Evolventa";
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #4987FF;
  -webkit-animation: hideElementShow .3s ease 0s 1;
  animation: hideElementShow .3s ease 0s 1;
}

@media (max-width: 1430px) {
  .auth-header__steps {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 1279px) {
  .auth-header__steps {
    font-size: 18px;
    line-height: 23px;
  }
}

.sing-forms {
  position: relative;
  width: 400px;
}

@media (max-width: 1430px) {
  .sing-forms {
    width: 370px;
  }
}

@media (max-width: 767px) {
  .sing-forms {
    width: 100%;
  }

  .sing-forms .button {
    width: 100%;
  }
}

.sing-title {
  margin-bottom: 30px;
  width: 75%;
  display: inline-block;
}

.sing-subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 50px;
}

.regform-step .sing-subtitle {
  font-size: 16px;
}

@media (max-width: 1799px) {
  .sing-subtitle {
    margin-bottom: 43px;
  }
}

@media (max-width: 1430px) {
  .sing-subtitle {
    margin-bottom: 16px;
  }
}

@media (max-width: 1279px) {
  .sing-subtitle {
    margin-bottom: 24px;
  }

  .regform-step .sing-subtitle {
    font-size: 12px;
  }
}

@media (max-width: 1023px) {
  .sing-subtitle {
    margin-bottom: 13px;
  }
}

.sing-subtitle.small {
  font-size: 12px;
  line-height: 18px;
}

.sing-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 1430px) {
  .sing-switcher {
    margin-bottom: 75px;
  }
}

@media (max-width: 767px) {
  .sing-switcher {
    margin-bottom: 40px;
  }
}

.sing-title,
.sing-switcher {
  font-weight: bold;
  font-size: 30px;
  line-height: 1;
}

@media (max-width: 1799px) {
  .sing-title,
  .sing-switcher {
    font-size: 30px;
    line-height: 39px;
  }
}

@media (max-width: 1430px) {
  .sing-title,
  .sing-switcher {
    font-size: 28px;
    line-height: 37px;
  }
}

@media (max-width: 767px) {
  .sing-title,
  .sing-switcher {
    font-size: 22px;
    line-height: 29px;
  }
}

.sing-switcher__element {
  padding: 0 29px 20px;
  border-bottom: 3px solid rgba(137, 149, 170, 0.7);
  color: #8995AA;
  opacity: 0.7;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  cursor: pointer;
  position: relative;
}

.sing-switcher__element::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0%;
  bottom: -3px;
  border-bottom: 3px solid #0A0A0B;
  -webkit-transition: .3s ease-out;
  -o-transition: .3s ease-out;
  transition: .3s ease-out;
}

.sing-switcher__element.active {
  color: #0A0A0B;
  border-bottom-color: transparent;
  opacity: 1;
  cursor: default;
}

.sing-switcher__element.active::before {
  width: 100%;
}

.sing-switcher__element:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 1799px) {
  .sing-switcher__element {
    padding: 0 19px 18px;
  }
}

@media (max-width: 1430px) {
  .sing-switcher__element {
    padding: 0 18px 17px;
  }
}

@media (max-width: 767px) {
  .sing-switcher__element {
    padding: 0 13px 14px;
  }
}

.sing-form {
  display: none;
}

.sing-form.show {
  display: block;
  z-index: 1;
  opacity: 1;
  visibility: visible;
  -webkit-animation: hideElementShow .3s ease 0s 1;
  animation: hideElementShow .3s ease 0s 1;
}

@-webkit-keyframes hideElementShow {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes hideElementShow {
  from {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.input-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 52px;
  position: relative;
  margin-bottom: 20px;
}

.input-box:last-child {
  margin-bottom: 0;
}

@media (max-width: 1430px) {
  .input-box {
    height: 47px;
  }
}

@media (max-width: 1279px) {
  .input-box {
    height: 46px;
  }
}

@media (max-width: 767px) {
  .input-box {
    height: 48px;
  }

  .input-box.radio__btns {
    height: auto;
  }
}

.regform-step.regform-step-3 {
  margin-top: -100px;
}

@media (max-width: 1799px) {
  .regform-step.regform-step-3 {
    margin-top: -135px;
  }
}

@media (max-width: 1430px) {
  .regform-step.regform-step-3 {
    margin-top: -100px;
  }
}

@media (max-width: 1279px) {
  .regform-step.regform-step-3 {
    margin-top: -95px;
  }
}

@media (max-width: 1023px) {
  .regform-step.regform-step-3 {
    margin-top: -133px;
  }
}

@media (max-width: 767px) {
  .regform-step.regform-step-3 {
    margin-top: -110px;
  }
}

.regform-step.regform-step-3 .user__data-fields {
  width: 100%;
}

@media (max-width: 767px) {
  .regform-step.regform-step-3 .user__data-fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .regform-step.regform-step-3 .user__data-fields > * {
    width: 48%;
  }
}

.input-box__input,
.input-box__textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid rgba(210, 220, 239, 0.4);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  outline: none;
  -webkit-box-shadow: inset 0 0 0 30px white;
  box-shadow: inset 0 0 0 30px white;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 0;
}

@media (max-width: 1430px) {
  .input-box__input,
  .input-box__textarea {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .input-box__input,
  .input-box__textarea {
    font-size: 15px;
  }
}

.input-box__input[type="password"],
.input-box.show .input-box__input {
  padding-right: 55px;
}

.select__single-value {
  text-transform: capitalize;
}

.regform-step .select__single-value,
.regform-step .select__input {
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  color: black;
}

@media (max-width: 1430px) {
  .regform-step .select__single-value,
  .regform-step .select__input {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .regform-step .select__single-value,
  .regform-step .select__input {
    font-size: 15px;
  }
}

.input-box__input:hover,
.input-box__input:focus {
  border-bottom-color: #0A0A0B;
}

.input-box__label {
  position: absolute;
  left: 0;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #8995AA;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: .3s ease-out;
  -o-transition: .3s ease-out;
  transition: .3s ease-out;
}

.input-box__input:focus ~ .input-box__label,
.input-box__input:not(:placeholder-shown) ~ .input-box__label {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  top: 0;
  font-size: 12px;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.input-box__showhide {
  width: 50px;
  height: inherit;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 1799px) {
  .input-box__showhide img {
    width: 22px;
    height: 18px;
  }
}

@media (max-width: 1430px) {
  .input-box__showhide img {
    width: 20px;
    height: 17px;
  }
}

.input-box__showhide > svg {
  width: 100%;
  max-height: 100%;
  height: auto;
}

.input-box__showline {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.input-box.show .input-box__showline {
  opacity: 0;
}

input:focus.error,
input.error {
  border-bottom-color: #FF3366;
}

span.error {
  position: absolute;
  top: 100%;
  font-size: 13px;
  color: #FF3366;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-animation: showError .3s ease 0s 1;
  animation: showError .3s ease 0s 1;
  max-width: 100%;
}

label.error {
  color: #FF3366;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

@-webkit-keyframes showError {
  from {
    -webkit-clip-path: inset(0 10% 0 0);
    clip-path: inset(0 10% 0 0);
  }
  to {
    -webkit-clip-path: inset(0%);
    clip-path: inset(0%);
  }
}

@keyframes showError {
  from {
    -webkit-clip-path: inset(0 10% 0 0);
    clip-path: inset(0 10% 0 0);
  }
  to {
    -webkit-clip-path: inset(0%);
    clip-path: inset(0%);
  }
}

.form-bottom {
  margin-top: 60px;
}

@media (max-width: 1799px) {
  .form-bottom {
    margin-top: 50px;
  }
}

@media (max-width: 1430px) {
  .form-bottom {
    margin-top: 45px;
  }
}

@media (max-width: 767px) {
  .form-bottom {
    margin-top: 40px;
  }
}

.form-bottom__button:not(:last-child) {
  margin-bottom: 35px;
}

.form-bottom__text {
  font-size: 12px;
}

.form-bottom__text a,
.form-bottom__text .a {
  text-decoration: underline;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
}

.form-bottom__text a:hover,
.form-bottom__text .a:hover {
  -webkit-text-decoration-color: transparent;
  text-decoration-color: transparent;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none !important;
}

.input-code-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.input-code__input {
  width: 47px;
  height: 47px;
  -webkit-box-shadow: inset 0 0 0 100px rgba(137, 149, 170, 0.08);
  box-shadow: inset 0 0 0 100px rgba(137, 149, 170, 0.08);
  border-radius: 6px;
  border: 1px solid transparent;
  margin-right: 8px;
  font-family: "Evolventa";
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
  color: #0A0A0B;
  padding: 0;
  text-align: center;
}

.input-code__input:last-child {
  margin-right: 0;
}

.input-code__input:focus,
.input-code__input:hover {
  border-color: #4987FF;
}

.input-code__input.error,
.input-code__input.error:hover,
.input-code__input.error:focus {
  border-color: #FF3366;
}

.input-code-hint {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #4987FF;
}

.input-code-hint.error {
  color: #FF3366;
}

.reg-form-back {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4987FF;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  margin-top: 25px;
}

.reg-form-back:hover {
  opacity: 0.7;
}

.input-group__box:not(:last-child) {
  margin-bottom: 40px;
}

.input-box.textarea {
  display: block;
  text-align: left;
  height: auto;
  margin-top: 30px;
}

.input-box.textarea .input-box__label {
  display: block;
  margin-bottom: 12px;
  top: initial;
  -webkit-transform: initial;
  -ms-transform: initial;
  transform: initial;
  position: relative;
}

.input-box__textarea {
  border: 1px solid rgba(210, 220, 239, 0.4);
  height: 70px;
  resize: none;
  width: 100%;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-right: 0;
  border-radius: 0;
  padding: 10px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.input-box__textarea:hover,
.input-box__textarea:focus {
  border-color: #0A0A0B;
}

.input-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-row.double {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.input-row.double > * {
  width: 48%;
  margin-bottom: 0;
}

.select-box {
  width: 100%;
  height: 100%;
}

.select-box-200-px {
  width: 200px !important;
}

.select-box .select__single-value {
  padding-left: 0;
}

.select-box .select__value-container {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.select-box .select__value-container > * {
  padding: 0;
}

.select-box .select__indicator-separator {
  display: none;
}

.select-box .select__control {
  height: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid rgba(210, 220, 239, 0.4);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-radius: 0;
}

@media (max-width: 1023px) {
  .select-box .select__control {
    min-height: 30px;
  }
}

.select-box .select__control:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
  border-bottom-color: #0A0A0B;
}

.select-box .select__dropdown-indicator {
  padding: 0;
}

.select-box .select__dropdown-indicator svg {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.select-box .select__control--menu-is-open .select__dropdown-indicator svg {
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}

.select-box .select__placeholder {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #8995AA;
  margin-left: 0;
}

@-webkit-keyframes show-drop {
  from {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes show-drop {
  from {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}

.select-box .select__menu {
  -webkit-animation: show-drop .3s ease 0;
  animation: show-drop .3s ease 0;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.select-box .select__menu-list {
  padding: 0;
  max-height: 270px;
  overflow-x: hidden;
}

.select-box .select__menu-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.select-box .select__menu-list::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.select-box .select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #8995AA;
}

.select-box .select__option {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #0A0A0B;
  margin-left: 0;
  text-align: left;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  padding: 18px 24px;
}

.regform-step .select-box .select__option {
  font-size: 14px;
}

.select-box .select__option--is-focused {
  background-color: #EFF3F7;
}

.select-box .select__option--is-selected {
  background-color: transparent;
  color: #4987FF;
}

.recovery-form {
  display: none;
  width: 395px;
}

@media (max-width: 767px) {
  .recovery-form {
    width: auto;
  }
}

.recovery-form.show {
  display: block;
  -webkit-animation: hideElementShow .3s ease 0s 1;
  animation: hideElementShow .3s ease 0s 1;
}

.recovery-form .sing-title {
  width: auto;
}

.auth-footer {
  margin-top: auto;
  font-family: "Montserrat";
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #8995AA;
}

@media (max-width: 1023px) {
  .auth-footer {
    display: none;
  }
}

@media (max-width: 767px) {
  .auth-footer {
    display: block;
    margin: 25px auto 0;
  }
}

.welcome-container {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: white;
  background-color: #050425;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-animation: welcome-show 1s ease 0s 1 forwards alternate;
  animation: welcome-show 1s ease 0s 1 forwards alternate;
}

@media (max-width: 1023px) {
  .welcome-container {
    margin-top: auto;
    padding-top: 63px;
    padding-bottom: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}

@media (max-width: 767px) {
  .welcome-container {
    display: none;
  }
}

@-webkit-keyframes welcome-show {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes welcome-show {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.welcome-container__head {
  margin-top: 105px;
  margin-bottom: 50px;
  margin-left: 110px;
}

@media (max-width: 1799px) {
  .welcome-container__head {
    margin-top: 88px;
    margin-left: 83px;
  }
}

@media (max-width: 1430px) {
  .welcome-container__head {
    margin-top: 70px;
    margin-left: 66px;
  }
}

@media (max-width: 1279px) {
  .welcome-container__head {
    margin-top: 60px;
    margin-left: 36px;
  }
}

@media (max-width: 1023px) {
  .welcome-container__head {
    width: 42.7%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 22px;
  }
}

.welcome-container__title {
  font-weight: bold;
  font-size: 53px;
  max-width: 93%;
  line-height: 109.2%;
  color: #FFF;
  margin-bottom: 50px;
  -webkit-animation: welcome-container__title 2s ease 0s 1;
  animation: welcome-container__title 2s ease 0s 1;
}

@media (max-width: 1799px) {
  .welcome-container__title {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 33px;
  }
}

@media (max-width: 1430px) {
  .welcome-container__title {
    font-size: 40px;
    margin-bottom: 25px;
  }
}

@media (max-width: 1023px) {
  .welcome-container__title {
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 35px;
  }
}

@-webkit-keyframes welcome-container__title {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes welcome-container__title {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.welcome-container__subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;
  color: #16D9C2;
  -webkit-animation: welcome-container__subtitle 2.1s ease 0s 1;
  animation: welcome-container__subtitle 2.1s ease 0s 1;
}

@media (max-width: 1799px) {
  .welcome-container__subtitle {
    font-size: 20px;
    line-height: 27px;
  }
}

@media (max-width: 1430px) {
  .welcome-container__subtitle {
    font-size: 16px;
    line-height: 22px;
  }
}

@-webkit-keyframes welcome-container__subtitle {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes welcome-container__subtitle {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.welcome-container__bg {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 25px;
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 433px;
}

.welcome-container__bg img {
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 1799px) {
  .welcome-container__bg {
    height: 317px;
  }
}

@media (max-width: 1430px) {
  .welcome-container__bg {
    height: 254px;
  }
}

@media (max-width: 1279px) {
  .welcome-container__bg {
    height: 267px;
  }
}

@media (max-width: 1023px) {
  .welcome-container__bg {
    height: 194px;
    width: 384px;
    margin-left: auto;
  }
}

.welcome-bg-1 {
  -webkit-animation: welcome-bg-1 2s ease 0s 1;
  animation: welcome-bg-1 2s ease 0s 1;
}

@-webkit-keyframes welcome-bg-1 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}

@keyframes welcome-bg-1 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}

.welcome-bg-2 {
  -webkit-animation: welcome-bg-2 1.6s ease 0s 1;
  animation: welcome-bg-2 1.6s ease 0s 1;
}

@-webkit-keyframes welcome-bg-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}

@keyframes welcome-bg-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}

.welcome-bg-3 {
  -webkit-animation: welcome-bg-3 1.3s ease 0s 1;
  animation: welcome-bg-3 1.3s ease 0s 1;
}

@-webkit-keyframes welcome-bg-3 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}

@keyframes welcome-bg-3 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%) rotate(360deg);
    transform: translateX(200%) rotate(360deg);
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}

.welcome-bg-4 {
  -webkit-animation: welcome-bg-4 1.3s ease 0s 1;
  animation: welcome-bg-4 1.3s ease 0s 1;
}

@-webkit-keyframes welcome-bg-4 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes welcome-bg-4 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.welcome-container__copyright {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #8995AA;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 55px;
  margin-top: auto;
  margin-right: 60px;
}

@media (max-width: 1799px) {
  .welcome-container__copyright {
    margin-right: 25px;
    margin-bottom: 33px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 1430px) {
  .welcome-container__copyright {
    margin-right: 40px;
    margin-bottom: 25px;
  }
}

@media (max-width: 1279px) {
  .welcome-container__copyright {
    margin-right: 35px;
    margin-bottom: 32px;
  }
}

@media (max-width: 1023px) {
  .welcome-container__copyright {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 45px;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: 0;
  }
}

.profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.aside-container {
  background-color: white;
  position: fixed;
  height: 100vh;
  width: 280px;
}

@-webkit-keyframes asideShow {
  0% {
    -webkit-clip-path: inset(0% 100% 0% 0%);
    clip-path: inset(0% 100% 0% 0%);
  }
  50% {
    -webkit-clip-path: inset(0% 100% 0% 0%);
    clip-path: inset(0% 100% 0% 0%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-clip-path: inset(0%);
    clip-path: inset(0%);
  }
}

@keyframes asideShow {
  0% {
    -webkit-clip-path: inset(0% 100% 0% 0%);
    clip-path: inset(0% 100% 0% 0%);
  }
  50% {
    -webkit-clip-path: inset(0% 100% 0% 0%);
    clip-path: inset(0% 100% 0% 0%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-clip-path: inset(0%);
    clip-path: inset(0%);
  }
}

.general-body {
  margin-left: 280px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #EFF3F7;
  position: relative;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-animation: bodyShow 2s ease-out 0s 1;
  animation: bodyShow 2s ease-out 0s 1;
  max-width: calc(100% - 280px);
}

.general-body.premier {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.26%, #050425), color-stop(68.48%, #121132));
  background: -webkit-linear-gradient(top, #050425 0.26%, #121132 68.48%);
  background: -o-linear-gradient(top, #050425 0.26%, #121132 68.48%);
  background: linear-gradient(180deg, #050425 0.26%, #121132 68.48%);
}

.general-body.premier .section-title,
.general-body.premier .section-title .select-box .select__single-value,
.general-body.premier .header-menu__item.active,
.general-body.premier .tarif-list__label,
.general-body.premier .payments-box,
.general-body.premier .tarif-list__header,
.general-body.premier .schedule__empty,
.general-body.premier .schedule__head,
.general-body.premier .schedule__head-val,
.general-body.premier .button.border,
.general-body.premier .select-box .select__single-value {
  color: white;
}

.general-body.premier .button.border {
  border-color: white;
}

.general-body.premier .teaches-select .select__placeholder,
.general-body.premier .teaches-select .select__single-value {
  color: inherit;
}

.general-body.premier .teaches-select .select__indicator.select__dropdown-indicator path,
.general-body.premier .select-box.header-button .select__dropdown-indicator svg {
  fill: white;
}

.general-body.premier .teaches-select .select__control {
  border: none;
  background-color: #121333;
}

@media (min-width: 768px) {
  .general-body.premier .header-logo {
    background-image: url(/svg/logo-header_white.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .general-body.premier .header-logo img {
    opacity: 0;
    visibility: hidden;
  }
}

.general-body.premier .header-button_logout::after {
  background-image: url(/svg/logout-arrow_white.svg);
}

.general-body.premier .menu-item__list-items {
  -webkit-box-shadow: 0px 4px 30px rgba(5, 4, 37, 0.06);
  box-shadow: 0px 4px 30px rgba(5, 4, 37, 0.06);
  background-color: white;
}

.general-body.premier .menu-item__list-items {
  color: #0A0A0B;
}

.general-body.premier .header-button:hover {
  background-color: transparent;
  border-color: white;
  color: #8995AA;
  opacity: 1;
}

.general-body.premier .header-button_away:hover::after {
  background-image: url(/svg/away-arrow.svg);
}

.general-body.premier .header-menu__item.active::after,
.general-body.premier .preloader-progress::before {
  background-color: white;
}

.general-body.premier .section-title__label {
  background-color: #121333;
  color: #8995AA;
}

.general-body.premier .tarif-list__nav-button::before {
  background-color: #21224A;
}

.general-body.premier .tarif-list__nav-button.slider-btn-prev::after {
  background-image: url(/svg/swiper-white-arrow_left.svg);
}

.general-body.premier .tarif-list__nav-button.slider-btn-next::after {
  background-image: url(/svg/swiper-white-arrow_right.svg);
}

.general-body.premier .user-card__headline-btn {
  background-image: url(/svg/arrow-left-white.svg);
}

.general-body.noanimate {
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
}

@-webkit-keyframes bodyShow {
  0% {
    -webkit-clip-path: inset(0 50% 0% 50%);
    clip-path: inset(0 50% 0% 50%);
  }
  50% {
    -webkit-clip-path: inset(0 50% 0% 50%);
    clip-path: inset(0 50% 0% 50%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-clip-path: inset(0%);
    clip-path: inset(0%);
  }
}

@keyframes bodyShow {
  0% {
    -webkit-clip-path: inset(0 50% 0% 50%);
    clip-path: inset(0 50% 0% 50%);
  }
  50% {
    -webkit-clip-path: inset(0 50% 0% 50%);
    clip-path: inset(0 50% 0% 50%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-clip-path: inset(0%);
    clip-path: inset(0%);
  }
}

@media (max-width: 1799px) {
  .aside-container {
    width: 240px;
  }

  @-webkit-keyframes asideShow {
    0% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes asideShow {
    0% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  .general-body {
    max-width: calc(100% - 240px);
  }

  @-webkit-keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    50% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    50% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
}

@media (max-width: 1430px) {
  .aside-container {
    width: 220px;
  }

  @-webkit-keyframes asideShow {
    0% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes asideShow {
    0% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  .general-body {
    max-width: calc(100% - 220px);
  }

  @-webkit-keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    50% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    50% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
}

@media (max-width: 1279px) {
  .aside-container {
    width: 62px;
  }

  @-webkit-keyframes asideShow {
    0% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes asideShow {
    0% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  .general-body {
    margin-left: 62px;
    max-width: calc(100% - 62px);
  }

  @-webkit-keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    50% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    50% {
      -webkit-clip-path: inset(0 50% 0% 50%);
      clip-path: inset(0 50% 0% 50%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
}

@media (max-width: 767px) {
  .aside-container {
    position: fixed;
    z-index: 5;
    left: 0;
    bottom: 0;
    width: 100%;
    height: initial;
  }

  .aside-sticky {
    height: initial !important;
    overflow: visible !important;
  }

  .general-body {
    margin-left: 0;
  }

  .aside-container .aside-header {
    display: none;
  }

  .aside-container .aside-part {
    padding: 0 35px;
    height: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
    background-color: #0A0A0B;
  }

  @-webkit-keyframes asideShow {
    0% {
      -webkit-clip-path: inset(100% 0% 0% 0%);
      clip-path: inset(100% 0% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(100% 0% 0% 0%);
      clip-path: inset(100% 0% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes asideShow {
    0% {
      -webkit-clip-path: inset(100% 0% 0% 0%);
      clip-path: inset(100% 0% 0% 0%);
    }
    50% {
      -webkit-clip-path: inset(100% 0% 0% 0%);
      clip-path: inset(100% 0% 0% 0%);
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  .general-body {
    max-width: calc(100% - 0px);
  }

  @-webkit-keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(50% 0% 0 0%);
      clip-path: inset(50% 0% 0 0%);
      opacity: 0;
      visibility: hidden;
    }
    50% {
      -webkit-clip-path: inset(50% 0% 0 0%);
      clip-path: inset(50% 0% 0 0%);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
  @keyframes bodyShow {
    0% {
      -webkit-clip-path: inset(50% 0% 0 0%);
      clip-path: inset(50% 0% 0 0%);
      opacity: 0;
      visibility: hidden;
    }
    50% {
      -webkit-clip-path: inset(50% 0% 0 0%);
      clip-path: inset(50% 0% 0 0%);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
      -webkit-clip-path: inset(0%);
      clip-path: inset(0%);
    }
  }
}

.aside-sticky {
  height: 100vh;
  overflow-y: scroll;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.aside-header {
  padding: 20px 0;
  text-align: center;
  border-bottom: 2px solid #EFF3F7;
}

@media (max-width: 1799px) {
  .aside-header {
    padding: 15px 0;
    margin: 0 25px;
  }
}

@media (max-width: 1430px) {
  .aside-header {
    padding: 19px 0;
    margin: 0 23px;
  }
}

@media (max-width: 1279px) {
  .aside-header {
    padding: 10px 0 13px;
    margin: 0;
  }
}

@media (max-width: 1023px) {
  .aside-header {
    padding: 11px 0 17px;
  }
}

.aside-header__profile {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 10px;
}

@media (max-width: 1799px) {
  .aside-header__profile {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }
}

@media (max-width: 1430px) {
  .aside-header__profile {
    width: 60px;
    height: 60px;
    margin-bottom: 6px;
  }
}

@media (max-width: 1279px) {
  .aside-header__profile {
    width: 48px;
    height: 48px;
    margin-bottom: 0;
  }
}

@media (max-width: 1023px) {
  .aside-header__profile {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767px) {
  .aside-header__profile {
    width: 38px;
    height: 38px;
  }
}

.aside-photo {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
}

.aside-photo img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.aside-settings {
  position: absolute;
  right: -4px;
  bottom: 4px;
  width: 36px;
  height: 36px;
  background-color: #050425;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
}

.aside-settings:hover {
  opacity: .5;
}

.aside-settings img {
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: 55.56%;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.aside-settings:hover img {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 1799px) {
  .aside-settings {
    right: -13px;
    bottom: 0;
  }
}

@media (max-width: 1430px) {
  .aside-settings {
    right: -9px;
    bottom: 4px;
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 1279px) {
  .aside-settings {
    width: 19px;
    height: 19px;
    right: -2px;
    bottom: -2px;
  }

  .aside-settings:hover {
    opacity: 1;
  }

  .aside-settings:hover img {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .aside-body {
    height: 78vh;
    overflow-y: auto;
  }
}

.aside-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 8px;
  color: #050425;
  padding: 0 15px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1430px) {
  .aside-name {
    margin-bottom: 4px;
    font-size: 17px;
    line-height: 22px;
  }
}

@media (max-width: 1279px) {
  .aside-name {
    display: none;
  }
}

.aside-label-profile {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #A0B0CD;
}

@media (max-width: 1430px) {
  .aside-label-profile {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 1279px) {
  .aside-label-profile {
    display: none;
  }
}

.aside-part {
  padding-top: 33px;
}

.aside-part:last-child {
  padding-top: 0;
}

.aside-part .aside-part__others {
  border-top: 2px solid #EFF3F7;
  margin-top: 20px;
  padding-top: 33px;
}

.aside-part .aside-part__others.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 1799px) {
  .aside-part {
    padding-top: 15px;
  }

  .aside-part .aside-part__others {
    margin-top: 15px;
    padding-top: 15px;
  }
}

@media (max-width: 1430px) {
  .aside-part {
    padding-top: 13px;
  }

  .aside-part .aside-part__others {
    margin-top: 13px;
    padding-top: 13px;
  }
}

@media (max-width: 1279px) {
  .aside-part {
    padding-top: 25px;
  }

  .aside-part .aside-part__others {
    margin-top: 25px;
    padding-top: 25px;
  }
}

@media (max-width: 1023px) {
  .aside-part {
    padding-top: 15px;
  }

  .aside-part .aside-part__others {
    margin-top: 15px;
    padding-top: 15px;
  }
}

@media (max-width: 767px) {
  .aside-part.aside-part__mobile {
    opacity: 0;
    pointer-events: none;
    padding: 0 20px;
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 5;
    bottom: 100%;
    right: 0;
    border-radius: 20px 20px 0 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .aside-part {
    margin-bottom: 0!important;
  }

  .aside-part .anticon {
    color: #fff;
  }

  .aside-part.aside-part__mobile.shown {
    opacity: 1;
    pointer-events: auto;
  }

  .aside-part .aside-part__additional,
  .aside-part .aside-part__others {
    margin: 0;
    padding: 7px 0;
    display: block;
  }

  .aside-part .aside-part__additional .aside-item,
  .aside-part .aside-part__others .aside-item {
    padding: 15px 0;
    height: auto;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .aside-part .aside-part__additional .aside-item .aside-item__name,
  .aside-part .aside-part__others .aside-item .aside-item__name {
    margin-left: 23px;
    display: block;
    font-size: 16px;
    color: #fff;
  }

  .aside-part .aside-part__others {
    border-bottom: 2px solid #EFF3F7;
  }
}

.admin-aside-part span.aside-item__ico {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.admin-aside-part .aside-item__name {
  margin-left: 15px;
}

.aside-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 16px 30px 16px 35px;
  position: relative;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer!important;
}

@media (max-width: 767px) {
  .aside-item {
    padding: 16px 150px 16px 35px;
  }
}

.ant-transfer-list {
  width: 50%!important;
}

.aside-item__select > .aside-item > picture {
  margin-left: 40px;
}

.aside-item__select > .aside-item:hover::before {
  width: 100%;
}

.aside-item__picture {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: center center;
  transform: rotate(-90deg);
}

.aside-item__picture_open {
  transform: rotate(0deg);
}

.aside-item__select {
  overflow: hidden;
  height: 0;
}

.aside-item__select_open {
  height: auto;
}

.aside-item picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.aside-item:hover {
  opacity: 1;
}

.aside-item-active::after {
  border-left-width: 4px !important;
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
}


.messages__nav-item__unread {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: -webkit-linear-gradient(180deg, rgba(73, 135, 255, 0.25) -4.9%, rgba(73, 135, 255, 0) 136.08%);
  background: -o-linear-gradient(180deg, rgba(73, 135, 255, 0.25) -4.9%, rgba(73, 135, 255, 0) 136.08%);
  background: linear-gradient(180deg, rgba(73, 135, 255, 0.25) -4.9%, rgba(73, 135, 255, 0) 136.08%);
}

.messages__nav-item__counter_msg {
  width: 9px;
  height: 9px;
  background-color: #4987FF;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.aside-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  border-radius: 0 30px 30px 0;
  background: -webkit-linear-gradient(0.39deg, rgba(73, 135, 255, 0.15) -4.9%, rgba(73, 135, 255, 0) 136.08%);
  background: -o-linear-gradient(0.39deg, rgba(73, 135, 255, 0.15) -4.9%, rgba(73, 135, 255, 0) 136.08%);
  background: linear-gradient(89.61deg, rgba(73, 135, 255, 0.15) -4.9%, rgba(73, 135, 255, 0) 136.08%);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.aside-item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-left: 0px solid #4987FF;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.aside-item:hover::before {
  width: 85%;
}

.aside-item:hover::after {
  border-left-width: 4px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.aside-item.disable {
  opacity: 0.2;
  pointer-events: none;
}

.schedule__teacher-autofill-button_mobile {
  display: none !important;
}

.schedule__autofill_styled-select .ant-select-disabled .ant-select-selector > span > input {
  background-color: #f5f5f5 !important;
}

.schedule__autofill__form__input_center {
  width: 30px;
  max-width: 30px;
  border-left: 0;
  border-right: 0;
  pointer-events: none;
}

.schedule__autofill__form__input {
  width: 100% !important;
  max-width: 100px;
  text-align: center;
}

.schedule__autofill__form__input_right > div {
  border-left: 0 !important;
}

.schedule__autofill__form__input_left > div {
  border-right: 0 !important;
}


@media (max-width: 1799px) {
  .aside-item {
    padding: 18px 10px 18px 26px;
  }
}

@media (max-width: 1430px) {
  .aside-item {
    padding: 15px 10px 15px 30px;
  }
}

@media (max-width: 1279px) {
  .aside-item {
    padding: 0;
    height: 63px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .aside-item__select > .aside-item > picture {
    margin-left: 0;
  }

  .aside-item::before {
    border-radius: 0;
    background: -webkit-linear-gradient(355.04deg, rgba(73, 135, 255, 0.15) -30.59%, rgba(73, 135, 255, 0) 77.13%);
    background: -o-linear-gradient(355.04deg, rgba(73, 135, 255, 0.15) -30.59%, rgba(73, 135, 255, 0) 77.13%);
    background: linear-gradient(94.96deg, rgba(73, 135, 255, 0.15) -30.59%, rgba(73, 135, 255, 0) 77.13%);
  }
}

@media (max-width: 767px) {
  .aside-item {
    height: 24px;
  }

  .aside-item:before, .aside-item:after {
    display: none;
  }

  .aside-item img,
  .aside-item picture {
    display: block;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.aside-part__mobile-btn {
  display: none;
}

@media (max-width: 767px) {
  .aside-part__mobile-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 24px;
  }

  .aside-part__mobile-btn img,
  .aside-part__mobile-btn picture {
    display: block;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.aside-item__ico {
  font-size: 22px;
  width: 16px;
  height: 22px;
  -o-object-fit: contain;
  object-fit: contain;
  color: #0A0A0B;
}

@media (max-width: 1430px) {
  .aside-item__name {
    margin-left: 15px;
  }
}

@media (max-width: 1279px) {
  .aside-item__ico {
    width: 16px;
    height: 23px;
  }

  .aside-item__name {
    margin-right: 0;
  }
}

@media (max-width: 1023px) {
  .aside-item__ico {
    width: 16px;
    height: 24px;
  }
}

@media (max-width: 768px) {
  .aside-item__ico {
    color: #FFFFFF;
  }

  .schedule__teacher-autofill-button {
    display: none !important;
  }
  .schedule__teacher-autofill-button_mobile {
    display: block !important;
  }

  .aside-item img {
    max-width: none;
  }

  .aside-item {
    margin-right: 30px;
  }

  .aside-item__picture {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: center center;
    transform: rotate(0deg);
  }

  .aside-item__picture_open {
    transform: rotate(-90deg);
  }

  .aside-item__select {
    display: none;
  }

  .aside-item__select_open {
    display: block;
  }
}

.aside-item__name {
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  color: #0A0A0B;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 23px;
}

@media (max-width: 1799px) {
  .aside-item__name {
    font-size: 14px;
  }
}

@media (max-width: 1430px) {
  .aside-item__name {
    font-size: 13px;
  }
}

@media (max-width: 1279px) {
  .aside-item__name {
    display: none;
  }
}

.aside-item.active {
  cursor: default;
  pointer-events: none;
}

.aside-item.active:hover {
  opacity: 1;
}

.aside-item.active::before {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  width: 85%;
}

.aside-item.active::after {
  border-left-width: 4px;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.aside-item__select .aside-item.active::before {
  width: 100%;
}

@media (max-width: 1279px) {
  .aside-item.active::before {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .aside-item.active {
    opacity: .3;
  }

  .aside-item.active:hover {
    opacity: .3;
  }
}

.aside-item__counter {
  margin-left: 12px;
  width: 22px;
  height: 22px;
  color: white;
  background-color: #4987FF;
  border-radius: 50%;
  overflow: hidden;
  font-weight: bold;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: showCount .3s ease 0s 1;
  animation: showCount .3s ease 0s 1;
}

.aside-item__counter_msg {
  margin-left: 12px;
  width: 11px;
  height: 11px;
  color: white;
  background-color: #4987FF;
  border-radius: 50%;
  overflow: hidden;
  font-weight: bold;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: showCount .3s ease 0s 1;
  animation: showCount .3s ease 0s 1;
}

@media (max-width: 1799px) {
  .aside-item__counter,
  .aside-item__counter_msg {
    margin-left: 10px;
  }
}

@media (max-width: 1430px) {
  .aside-item__counter{
    width: 19px;
    height: 19px;
    margin-left: 15px;
  }

  .aside-item__counter_msg {
    width: 11px;
    height: 11px;
    margin-left: 15px;
  }
}

@media (max-width: 1279px) {
  .aside-item__counter,
  .aside-item__counter_msg  {
    margin-left: 0;
    position: absolute;
    top: 50%;
    left: 55%;
  }
}

@media (max-width: 767px) {
  .aside-item__counter,
  .aside-item__counter_msg  {
    width: 14px;
    height: 14px;
    font-size: 9px;
  }
}

@-webkit-keyframes showCount {
  from {
    -webkit-clip-path: circle(0);
    clip-path: circle(0);
  }
  to {
    -webkit-clip-path: circle(100%);
    clip-path: circle(100%);
  }
}

@keyframes showCount {
  from {
    -webkit-clip-path: circle(0);
    clip-path: circle(0);
  }
  to {
    -webkit-clip-path: circle(100%);
    clip-path: circle(100%);
  }
}

.general-wrapper {
  width: 86.2913097%;
  margin: 0 auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1799px) {
  .general-wrapper {
    width: 86.7671692%;
    min-width: 1036px;
  }
}

@media (max-width: 1430px) {
  .general-wrapper {
    width: 88.8994307%;
    min-width: 937px;
  }
}

@media (max-width: 1279px) {
  .general-wrapper {
    width: 91.37%;
    min-width: 880px;
  }
}

@media (max-width: 1023px) {
  .general-wrapper {
    width: 88.68%;
    min-width: initial;
  }
}

@media (max-width: 767px) {
  .general-wrapper {
    width: 89.33%;
    padding-top: 80px;
  }
}

.header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 22px 0;
  border-bottom: 2px solid rgba(160, 176, 205, 0.15);
  margin-bottom: 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 3;
}

@media (max-width: 1799px) {
  .header {
    padding: 13px 0;
    margin-bottom: 45px;
  }
}

@media (max-width: 1430px) {
  .header {
    padding: 15px 0;
  }
}

@media (max-width: 1279px) {
  .header {
    padding: 23px 0;
    margin-bottom: 25px;
  }
}

@media (max-width: 1023px) {
  .header {
    padding: 11px 0;
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  .header {
    margin-bottom: 0;
    height: 55px;
    padding: 8px 15px;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 0;
    -webkit-box-shadow: 0px 4px 30px rgba(5, 4, 37, 0.06);
    box-shadow: 0px 4px 30px rgba(5, 4, 37, 0.06);
  }
}

.header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
}

@media (max-width: 1799px) {
  .header-menu {
    margin-right: 22px;
  }
}

@media (max-width: 1430px) {
  .header-menu {
    margin-right: 33px;
  }
}

@media (max-width: 1279px) {
  .header-menu {
    margin-right: 35px;
  }
}

@media (max-width: 1023px) {
  .header-menu {
    margin-right: 23px;
  }
}

@media (max-width: 767px) {
  .header-menu {
    margin-right: 19px;
  }
}

.header-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 1799px) {
  .header-logo {
    width: 134px;
    height: 26px;
  }
}

@media (max-width: 1430px) {
  .header-logo {
    width: 149px;
    height: 29px;
  }
}

@media (max-width: 1279px) {
  .header-logo {
    width: 118px;
    height: 23px;
  }
}

@media (max-width: 1023px) {
  .header-logo {
    width: 98px;
    height: 19px;
  }
}

@media (max-width: 767px) {
  .header-logo {
    width: 144px;
    height: 28px;
  }
}

.burger {
  position: relative;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 22px;
  height: 18px;
}

@media (max-width: 767px) {
  .burger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.burger__line {
  top: 50%;
  width: 22px;
  height: 2px;
  border-radius: 2px;
  background-color: #0A0A0B;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.burger.active .burger__line {
  position: absolute;
}

.burger.active .burger__line:first-child {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.burger.active .burger__line:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger.active .burger__line:last-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header-menu__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767px) {
  .header-menu__wrapper {
    padding: 30px 20px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    -webkit-box-shadow: 0px 34px 30px -30px rgba(5, 4, 37, 0.2);
    box-shadow: 0px 34px 30px -30px rgba(5, 4, 37, 0.2);
  }

  .header-menu__wrapper.shown {
    opacity: 1;
    pointer-events: auto;
  }
}

.header-menu__part .sn-box,
.header-menu__part_link {
  display: none;
}

@media (max-width: 767px) {
  .header-menu__part .sn-box,
  .header-menu__part_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
  }
}

.header-menu__part_link {
  margin-top: 22px;
  font-weight: 500;
  color: #4987FF;
}

.header-menu__part_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
}

@media (max-width: 767px) {
  .header-menu__part_menu {
    margin-right: 0;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .header-menu__part_row {
    margin-top: 35px;
    padding: 24px 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 2px solid rgba(160, 176, 205, 0.15);
    border-bottom: 2px solid rgba(160, 176, 205, 0.15);
  }
}

.header-menu__item {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  color: #8995AA;
  margin-right: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.header-menu__item:hover {
  color: rgba(137, 149, 170, 0.8);
}

.header-menu__item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.header-menu__item:not(.button):after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -24px;
  width: 0%;
  height: 2px;
  background-color: #0A0A0B;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

@media (max-width: 1799px) {
  .header-menu__item:not(.button):after {
    bottom: -15px;
  }
}

@media (max-width: 1430px) {
  .header-menu__item:not(.button):after {
    bottom: -17px;
  }
}

@media (max-width: 1279px) {
  .header-menu__item:not(.button):after {
    bottom: -25px;
  }
}

@media (max-width: 1023px) {
  .header-menu__item:not(.button):after {
    bottom: -13px;
  }
}

@media (max-width: 767px) {
  .header-menu__item:not(.button):after {
    bottom: -10px;
  }
}

.header-menu__item.active {
  color: #0A0A0B;
  pointer-events: none;
}

.header-menu__item.active::after {
  width: 100%;
}

@media (max-width: 1799px) {
  .header-menu__item {
    margin-right: 35px;
    font-size: 13px;
  }
}

@media (max-width: 1430px) {
  .header-menu__item {
    margin-right: 40px;
  }
}

@media (max-width: 1279px) {
  .header-menu__item {
    margin-right: 34px;
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .header-menu__item {
    margin-right: 21px;
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .header-menu__item {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.header-menu__item-list:hover {
  opacity: 1;
}

.header-menu__item-list:hover .menu-item__list {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  pointer-events: initial;
  display: block;
  -webkit-animation: menu-list-show .3s ease 0s 1;
  animation: menu-list-show .3s ease 0s 1;
}

@media (max-width: 767px) {
  .header-menu__item-list {
    display: block;
    text-align: center;
  }
}

.menu-item__list {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 0;
  width: auto;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 195px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  pointer-events: none;
  display: none;
}

@media (max-width: 767px) {
  .menu-item__list {
    position: relative;
    padding: 20px 0 0;
  }
}

@-webkit-keyframes menu-list-show {
  from {
    -webkit-transform: translateX(-50%) translateY(10%);
    transform: translateX(-50%) translateY(10%);
  }
  to {
    -webkit-transform: translateX(-50%) translateY(0%);
    transform: translateX(-50%) translateY(0%);
  }
}

@keyframes menu-list-show {
  from {
    -webkit-transform: translateX(-50%) translateY(10%);
    transform: translateX(-50%) translateY(10%);
  }
  to {
    -webkit-transform: translateX(-50%) translateY(0%);
    transform: translateX(-50%) translateY(0%);
  }
}

.menu-item__list-items {
  background-color: #0A0A0B;
  border-radius: 10px;
  color: white;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  z-index: 102;
  position: relative;
  text-transform: none;
}

.menu-list__item:hover {
  color: rgba(255, 255, 255, .8);
}

.menu-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 20px;
  pointer-events: initial;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  color: #FFFFFF;
}

.menu-list__item img {
  width: 96px;
  height: 27px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 10px;
  position: relative;
}

.menu-list__item:first-child {
  padding-top: 20px;
}

.menu-list__item:last-child {
  padding-bottom: 20px;
}

.header-button {
  background-color: transparent;
  border: 2px solid rgba(160, 176, 205, 0.15);
  height: 50px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 30px;
  min-width: initial;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.header-button:hover {
  background-color: transparent;
  border-color: #0A0A0B;
  color: #0A0A0B;
  opacity: 1;
}

.header-button_away::after {
  content: "";
  display: block;
  position: relative;
  width: 13px;
  height: 13px;
  margin-left: 15px;
  background-image: url(/svg/away-arrow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.header-button_away:hover::after {
  background-image: url(/svg/away-arrow_hover.svg);
}

.header-button_logout {
  padding: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.impersonate-button {
  margin-left: 20px;
}

.red-button {
  border-color: red;
  color: red;
}


.header-button_logout span {
  display: none;
}

.header-button_logout::after {
  content: "";
  display: block;
  position: relative;
  width: 61%;
  height: 61%;
  background-image: url(/svg/logout-arrow.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: -10%;
}

@media (max-width: 1799px) {
  .header-button {
    padding: 0 18px;
    height: 42px;
  }

  .header-button_away::after {
    width: 11px;
    height: 11px;
    margin-left: 28px;
  }

  .header-button_logout {
    padding: 0;
    width: 42px;
    height: 42px;
  }

  .header-button_logout::after {
    width: 47%;
    height: 47%;
  }
}

@media (max-width: 1430px) {
  .header-button {
    padding: 0 15px;
    height: 44px;
  }

  .header-button_away::after {
    margin-left: 18px;
  }

  .header-button_logout {
    padding: 0;
    width: 44px;
    height: 44px;
  }

  .header-button_logout::after {
    width: 43%;
    height: 43%;
  }
}

@media (max-width: 1279px) {
  .header-button {
    padding: 0 17px;
    height: 36px;
  }

  .header-button_away::after {
    width: 10px;
    height: 10px;
    margin-left: 25px;
  }

  .header-button_logout {
    padding: 0;
    width: 39px;
    height: 36px;
    border-radius: 43px;
  }

  .header-button_logout::after {
    width: 56%;
    height: 58%;
  }
}

@media (max-width: 1023px) {
  .header-button {
    padding: 0 15px;
    height: 34px;
  }

  .header-button_away::after {
    width: 9px;
    height: 9px;
    margin-left: 11px;
  }

  .header-button_logout {
    padding: 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .header-button_logout::after {
    width: 56%;
    height: 56%;
  }
}

@media (max-width: 767px) {
  .header-button {
    padding: 0 30px;
    height: 50px;
  }

  .header-button_away::after {
    width: 12px;
    height: 12px;
    margin-left: 38px;
  }

  .header-button_logout {
    padding: 0 30px;
    width: 220px;
    height: 50px;
    border-radius: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-button_logout span {
    display: block;
  }

  .header-button_logout.header-menu__item {
    margin: 35px auto 0;
  }

  .header-button_logout::after {
    width: 28px;
    height: 27px;
  }
}

.header-profile__avatar,
.header-profile__list {
  display: none;
}

@media (max-width: 767px) {
  .header-profile__avatar,
  .header-profile__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.header-profile__avatar {
  overflow: hidden;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #F2F6F8;
}

@media (max-width: 767px) {
  .header-profile__wrapper {
    padding: 30px 20px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    -webkit-box-shadow: 0px 34px 30px -30px rgba(5, 4, 37, 0.2);
    box-shadow: 0px 34px 30px -30px rgba(5, 4, 37, 0.2);
  }

  .header-profile__wrapper.shown {
    opacity: 1;
    pointer-events: auto;
  }
}

.header-profile__list {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .header-profile__list .header-profile__item {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  }

  .header-profile__list .header-profile__item:last-child {
    margin-bottom: 0;
  }
}

.select-box.header-button {
  padding: 0 20px;
  min-width: 115px;
  border-radius: 55px;
  cursor: pointer;
}

.select-box.black {
  background: #0A0A0B;
}

.select-box.header-button .select__single-value {
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
  overflow: visible;
}

.select-box.black .select__single-value {
  color: #fff;
}

.select-box.header-button .select__control {
  background-color: transparent;
  border-bottom: none;
  cursor: pointer;
}

.select-box.header-button .select__input {
  opacity: 0;
  margin: 0;
}

.select-box.header-button .select__dropdown-indicator svg {
  fill: #0A0A0B;
}

.select-box.black .select__dropdown-indicator svg {
  fill: #fff;
}

@media (max-width: 1279px) {
  .select-box.header-button .select__dropdown-indicator svg {
    width: 15px;
    height: 13px;
  }
}

.select-box.header-button .select__menu {
  width: 105%;
  left: 0;
  z-index: 2;
  -webkit-animation: show-drop .3s ease 0s;
  animation: show-drop .3s ease 0s;
}

.select-box.header-button .select__menu-list {
  padding: 0;
}

.select-box.header-button .select__option {
  font-weight: 600;
  padding: 18px 24px;
  cursor: pointer;
  text-transform: uppercase;
}

@media (max-width: 1799px) {
  .select-box.header-button {
    padding: 0 18px;
    min-width: 84px;
    border-radius: 48px;
  }

  .select-box.header-button .select__single-value {
    font-size: 13px;
  }
}

@media (max-width: 1430px) {
  .select-box.header-button {
    padding: 0 20px;
    min-width: 103px;
  }
}

@media (max-width: 1279px) {
  .select-box.header-button {
    padding: 0 16px;
   min-width: 78px;
    border-radius: 43px;
  }

  .select-box.header-button .select__single-value {
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .select-box.header-button {
    padding: 0 12px;
    min-width: 73px;
    border-radius: 38px;
  }
}

@media (max-width: 767px) {
  .select-box.header-button {
    padding: 0 22px;
    min-width: 115px;
    border-radius: 55px;
  }

  .select-box.header-button .select__single-value {
    font-size: 15px;
  }
}

.main {
  margin-bottom: 100px;
}

@media (max-width: 1279px) {
  .main {
    margin-bottom: 50px;
  }
}

@media (max-width: 1023px) {
  .main {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .main {
    margin-bottom: 30px;
  }
}

.footer {
  margin-top: auto;
  margin-bottom: 15px;
}

@media (max-width: 1799px) {
  .footer {
    margin-bottom: 20px;
  }
}

@media (max-width: 1279px) {
  .footer {
    margin-bottom: 25px;
  }
}

@media (max-width: 1023px) {
  .footer {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .footer {
    margin-bottom: 110px;
  }
}

.footer__part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px 0;
  border-top: 2px solid rgba(137, 149, 170, 0.15);
}

.footer__part.footer__part-bottom {
  display: block;
}

.footer__part:last-child {
  padding-bottom: 0;
}

@media (max-width: 1799px) {
  .footer__part {
    padding: 15px 0;
  }
}

/*@media (max-width: 1430px) {*/
/*  .footer__part {*/
/*    padding: 18px 0;*/
/*  }*/
/*}*/

@media (max-width: 1279px) {
  .footer__part {
    padding: 12px 0;
  }
}

@media (max-width: 1023px) {
  .footer__part {
    padding: 11px 0;
  }
}

@media (max-width: 767px) {
  .footer__part {
    padding: 21px 0;
  }

  .footer__part.footer__part-contacts {
    display: none;
  }
}

.sn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sn-box__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  position: relative;
}

.sn-box__item::before {
  content: "";
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  background-color: rgba(160, 176, 205, 0.2);
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.sn-box__item:hover::before {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sn-box__item:last-child {
  margin-right: 0;
}

.sn-box__item:hover {
  opacity: 1;
}

@media (max-width: 1799px) {
  .sn-box__item {
    width: 34px;
    height: 34px;
    margin-right: 12px;
  }

  .sn-box__item img {
    width: 41%;
    height: 41%;
  }

  .sn-box__item.tiktok img {
    width: 65%;
    height: 65%;
  }
}

@media (max-width: 1023px) {
  .sn-box__item {
    width: 32px;
    height: 32px;
    margin-right: 11px;
  }
}

@media (max-width: 767px) {
  .sn-box__item {
    width: 44px;
    height: 44px;
    margin-right: 16px;
  }
}

.footer-support {
  font-weight: bold;
  font-size: 20px;
  color: #8995AA;
}

@media (max-width: 1799px) {
  .footer-support {
    font-size: 14px;
    line-height: 18px;
  }
}

/*@media (max-width: 1430px) {*/
/*  .footer-support {*/
/*    font-size: 16px;*/
/*    line-height: 22px;*/
/*  }*/
/*}*/

@media (max-width: 1279px) {
  .footer-support {
    font-size: 14px;
    line-height: 18px;
  }
}

.footer-pays.footer__part {
  padding: 19px 0;
}

.footer-pays__cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-pays__item {
  margin-right: 44px;
  height: 29px;
}

.footer-pays__item img {
  display: block;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer-pays__item:last-child {
  margin-right: 0;
}

@media (max-width: 1799px) {
  .footer-pays.footer__part {
    padding: 22px 0;
  }

  .footer-pays__item {
    height: 26px;
    margin-right: 40px;
  }
}

@media (max-width: 1430px) {
  .footer-pays__item {
    height: 24px;
    margin-right: 35px;
  }
}

@media (max-width: 1023px) {
  .footer-pays.footer__part {
    padding: 20px 0;
  }

  .footer-pays__item {
    height: 22px;
    margin-right: 32px;
  }
}

@media (max-width: 767px) {
  .footer-pays.footer__part {
    padding: 25px 0;
  }

  .footer-pays__item {
    margin-right: 26px;
  }
}

.footer__copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .footer__copyright {
    display: block;
  }
}

.copyright {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: rgba(137, 149, 170, 0.5);
}

.made_by {
  text-align: right;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: rgba(137, 149, 170, 0.5);
}

.made_by a {
  color: #8995AA;
}

@media (max-width: 1799px) {
  .copyright {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1430px) {
  .copyright {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 767px) {
  .copyright {
    width: 100%;
    font-size: 14px;
    line-height: 17px;
  }
}

.our-logo {
  display: block;
  width: 133px;
}

.our-logo img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 1799px) {
  .our-logo {
    width: 122px;
  }
}

@media (max-width: 1279px) {
  .our-logo {
    width: 114px;
  }
}

@media (max-width: 1023px) {
  .our-logo {
    width: 122px;
  }
}

@media (max-width: 767px) {
  .our-logo {
    margin-top: 11px;
    width: 133px;
  }
}

.footer__policy {
  margin-bottom: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__policy-item {
  margin-right: 34px;
  font-style: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8995AA;
}

.footer__policy-item:last-child {
  margin-right: 0;
}

@media (max-width: 1799px) {
  .footer__policy {
    margin-bottom: 25px;
  }

  .footer__policy-item {
    margin-right: 25px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1430px) {
  .footer__policy {
    margin-bottom: 30px;
  }

  .footer__policy-item {
    margin-right: 30px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 1279px) {
  .footer__policy {
    margin-bottom: 23px;
  }
}

@media (max-width: 1023px) {
  .footer__policy {
    margin-bottom: 17px;
  }

  .footer__policy-item {
    margin-right: 26px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .footer__policy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 28px;
  }

  .footer__policy-item {
    margin-right: 0;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 17px;
  }
}

.color-red {
  color: #FF3366;
}

.color-green {
  color: #16D9C2;
}

.color-blue {
  color: #4987FF;
}

.card {
  background-color: white;
  border-radius: 20px;
  -webkit-animation: showCard .3s ease-out 0s 1;
  animation: showCard .3s ease-out 0s 1;
}

@-webkit-keyframes showCard {
  from {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes showCard {
  from {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}

.wellcome-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 70px;
  overflow: hidden;
  width: 100%;
}

.mb-0-i {
  margin-bottom: 0 !important;
}

@media (max-width: 1799px) {
  .wellcome-steps {
    margin-bottom: 47px;
  }
}

@media (max-width: 1430px) {
  .wellcome-steps {
    margin-bottom: 53px;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps {
    margin-bottom: 50px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .wellcome-steps {
    padding: 0 24px;
    display: block;
    margin-bottom: 40px;
    max-height: initial;
  }
}

.wellcome-steps img {
  width: 100%;
}

.wellcome-steps-pic {
  max-height: 350px;
  padding: 0;
  overflow: initial;
  background-color: transparent;
}

@media (max-width: 767px) {
  .wellcome-steps-pic {
    max-height: initial;
  }
}

.wellcome-steps__img {
  margin-right: 5.10638298%;
  margin-left: 6.38297872%;
  width: 17.5744681%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 1799px) {
  .wellcome-steps__img {
    width: 34.68%;
    margin: 0;
    padding: 0 5.285%;
  }
}

@media (max-width: 1430px) {
  .wellcome-steps__img {
    width: 34.37%;
    padding: 0 3.285%;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__img {
    width: 31.75%;
    padding: 0 3.285%;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__img {
    width: 42.34%;
    padding: 0 3.285%;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__img {
    margin: 0 auto;
    padding: 20px 0 0;
    width: 77%;
  }
}

.wellcome-steps__content {
  max-width: 59.5744681%;
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
}

@media (max-width: 1799px) {
  .wellcome-steps__content {
    padding: 24px 0;
    max-width: 58.88%;
  }
}

@media (max-width: 1430px) {
  .wellcome-steps__content {
    max-width: 61.63%;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__content {
    max-width: 63.55%;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__content {
    max-width: 53.6%;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__content {
    max-width: 100%;
    padding: 28px 0;
  }
}

.wellcome-steps__bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #EFF3F7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
  color: #8995AA;
  overflow: hidden;
  margin-bottom: 25px;
}

@media (max-width: 1799px) {
  .wellcome-steps__bar {
    margin-bottom: 19px;
    font-size: 9px;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__bar {
    margin-bottom: 25px;
    font-size: 10px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__bar {
    margin-bottom: 15px;
    font-size: 8px;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__bar {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 27px;
    font-size: 11px;
    width: 100%;
  }
}

.wellcome-steps__part {
  padding: 0 15px;
  height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 2px solid #EFF3F7;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.wellcome-steps__part:last-child {
  border-right: none;
}

.wellcome-steps__part.active {
  background-color: #EFF3F7;
}

@media (max-width: 1799px) {
  .wellcome-steps__part {
    height: 26px;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__part {
    height: 32px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__part {
    height: 23px;
    padding: 0 13px;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__part {
    padding: 0 15px;
    height: 40px;
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 14px;
  }

  .wellcome-steps__part span {
    display: none;
  }
}

.wellcome-steps__title {
  margin-bottom: 25px;
}

.purchase__case.wellcome-steps__title {
  margin-bottom: 6px;
}

@media (max-width: 1799px) {
  .wellcome-steps__title {
    margin-bottom: 15px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__title {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__title {
    margin-bottom: 18px;
  }
}

.wellcome-steps__info {
  margin-top: 40px;
}

.wellcome-steps__info .button {
  margin-right: 55px;
}

@media (max-width: 1799px) {
  .wellcome-steps__info .button {
    margin-right: 33px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__info .button {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 1799px) {
  .wellcome-steps__info {
    margin-top: 37px;
  }
}

@media (max-width: 1430px) {
  .wellcome-steps__info {
    margin-top: 18px;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__info {
    margin-top: 27px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__info {
    margin-top: 33px;
  }

  .wellcome-steps__info a:not(.button) {
    display: block;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__info {
    margin-top: 15px;
  }
}

.wellcome-steps__lesson {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1023px) {
  .wellcome-steps__lesson {
    display: block;
  }
}

.wellcome-steps__lesson-info {
  width: 50%;
  padding-right: 60px;
  margin-right: 60px;
  border-right: 2px solid #EFF3F7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 1799px) {
  .wellcome-steps__lesson-info {
    width: 64%;
    padding-right: 27px;
    margin-right: 34px;
  }
}

@media (max-width: 1430px) {
  .wellcome-steps__lesson-info {
    padding-right: 35px;
    margin-right: 27px;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__lesson-info {
    padding-right: 30px;
    margin-right: 35px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__lesson-info {
    width: 100%;
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 20px;
    margin-bottom: 11px;
    border-right: none;
    border-bottom: 2px solid #EFF3F7;
  }
}

.wellcome-steps__lesson-user {
  padding-left: 60px;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.wellcome-steps .wellcome-steps__lesson-label,
.wellcome-steps .wellcome-steps__user-label {
  margin-bottom: 10px;
  line-height: 24px;
}

@media (max-width: 1799px) {
  .wellcome-steps .wellcome-steps__lesson-label,
  .wellcome-steps .wellcome-steps__user-label {
    margin-bottom: 9px;
    line-height: 20px;
  }
}

.wellcome-steps__lesson-date {
  font-weight: bold;
  word-break: break-all;
  margin-bottom: 20px;
  color: #0A0A0B;
}

.wellcome-steps__lesson-teacher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.wellcome-steps__lesson-teacher .button {
  margin-top: auto;
}

.wellcome-steps__teacher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.wellcome-steps__lesson-button,
.wellcome-steps__teacher-button {
  margin-top: auto;
}

.wellcome-steps__teacher-photo {
  width: 80px;
  height: 80px;
  margin-right: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.wellcome-steps__teacher-photo img {
  -o-object-fit: cover;
  object-fit: cover;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

@media (max-width: 1799px) {
  .wellcome-steps__teacher-photo {
    margin-right: 22px;
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 1279px) {
  .wellcome-steps__teacher-photo {
    margin-right: 11px;
    width: 47px;
    height: 47px;
  }
}

@media (max-width: 1023px) {
  .wellcome-steps__teacher-photo {
    margin-right: 24px;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767px) {
  .wellcome-steps__teacher-photo {
    margin-right: 26px;
    width: 80px;
    height: 80px;
  }
}

.wellcome-steps__teacher-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.wellcome-steps__teacher-name {
  font-weight: bold;
  color: #0A0A0B;
}

.widget-block:not(:last-child) {
  margin-bottom: 45px;
}

.widget-title {
  margin-bottom: 25px;
}

@media (max-width: 1430px) {
  .widget-title {
    margin-bottom: 22px;
  }
}

@media (max-width: 1279px) {
  .widget-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .widget-title {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .widget-title {
    margin-bottom: 27px;
  }
}

.widget-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (max-width: 1023px) {
  .widget-content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .widget-content {
    display: block;
  }
}

.widget-item {
  overflow: hidden;
  margin-right: 2.12765957%;
  padding: 33px 60px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  gap: 20px;
}

.widget-item:nth-child(1) {
  width: 29.787234%;
}

.widget-item:nth-child(2) {
  width: 36.0992908%;
}

.widget-item:nth-child(3) {
  width: 29.787234%;
}

.widget-item:last-child {
  margin-right: 0;
}

.widget-item.large {
  width: 100%;
  margin-bottom: 5em;
}

.widget-item.large .widget-picture {
  width: auto;
}

@media (max-width: 767px) {
  .widget-item.large .widget-picture {
    width: 30%;
  }
}

@media (max-width: 1023px) {
  .widget-item.large .widget-name {
    -webkit-box-flex: initial;
    -ms-flex: initial;
    flex: initial;
  }
}

@media (max-width: 1279px) {
  .widget-item.large {
    margin-bottom: 50px;
  }
}

@media (max-width: 1023px) {
  .widget-item.large {
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 20px 40px;
  }
}

@media (max-width: 767px) {
  .widget-item.large {
    display: block;
    padding: 20px 26px;
  }
}

@media (max-width: 1799px) {
  .widget-item {
    padding: 18px 40px 28px;
  }
}

@media (max-width: 1430px) {
  .widget-item {
    padding: 20px 38px 28px;
  }
}

@media (max-width: 1023px) {
  .widget-item {
    padding: 20px 25px 35px;
    margin-right: 0;
    margin-bottom: 17px;
  }

  .widget-item:nth-child(1), .widget-item:nth-child(2), .widget-item:nth-child(3) {
    width: calc((100% - 12px) / 2);
  }
}

@media (max-width: 767px) {
  .widget-item {
    padding: 20px 25px 28px;
    margin-bottom: 15px;
  }

  .widget-item:nth-child(1), .widget-item:nth-child(2), .widget-item:nth-child(3) {
    width: 100%;
  }

  .widget-item .button {
    width: 100%;
  }
}

.widget-info__title {
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border: 2px solid #EFF3F7;
  border-radius: 50%;
  margin-right: 17px;
}

@media (max-width: 1799px) {
  .widget-icon {
    width: 36px;
    height: 36px;
    margin-right: 13px;
  }
}

@media (max-width: 1430px) {
  .widget-icon {
    width: 32px;
    height: 32px;
    margin-right: 11px;
  }
}

@media (max-width: 1279px) {
  .widget-icon {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1023px) {
  .widget-icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
}

@media (max-width: 767px) {
  .widget-icon {
    width: 34px;
    height: 34px;
  }
}

.widget-icon__ico {
  max-height: 45%;
}

.widget-name {
  font-weight: bold;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 18px;
  line-height: 23px;
  margin: 0;
}

@media (max-width: 1799px) {
  .widget-name {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1023px) {
  .widget-name {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 767px) {
  .widget-name {
    font-size: 14px;
    line-height: 18px;
  }
}

.widget-info-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.widget-info-row .widget-info {
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .widget-info-row {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-left: 70px;
  }
}

@media (max-width: 767px) {
  .widget-info-row {
    margin-left: 0px;
  }
}

.widget-text {
  border-left: 2px solid #EFF3F7;
  padding-left: 60px;
  margin-left: 15%;
  width: 60%;
  color: #8995AA;
}

@media (max-width: 1023px) {
  .widget-text {
    border-left: none;
    padding-left: 0px;
    margin-left: 0%;
    margin-top: 20px;
    width: auto;
    font-size: 12px;
  }
}

@media (max-width: 1023px) {
  .widget-text {
    display: none;
  }
}

.widget-info__title {
  margin-bottom: 15px;
}

@media (max-width: 1799px) {
  .balance-box .widget-info__title {
    margin-bottom: 0;
  }
}

@media (max-width: 1023px) {
  .widget-info__title {
    margin-bottom: 0px;
    font-size: 16px;
  }
}

.widget-info__title:last-child:not(:only-child) {
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .widget-info__title:last-child:not(:only-child) {
    font-size: 12px;
  }
}

.widget-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  text-align: center;
}

@media (max-width: 1799px) {
  .widget-buttons {
    display: block;
  }

  .widget-buttons .button {
    width: 100%;
  }
}

.widget-buttons .button:not(:only-child) {
  padding: 0px 26px;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .widget-buttons .button:not(:only-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.widget-buttons .button:not(:only-child):first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.widget-buttons .button:not(:only-child):last-child {
  margin-right: 0;
}

@media (max-width: 1799px) {
  .widget-buttons .button:not(:only-child):last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1799px) {
  .widget-buttons .button:not(:only-child) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.widget-picture {
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 767px) {
  .widget-picture {
    width: 140px;
  }
}

.widget-info__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.widget-info__time {
  margin-right: 25px;
  margin-bottom: 0;
  font-size: 1em;
}

.widget-info__duration {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #8995AA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
}

.widget-info__duration::before {
  content: "";
  background-image: url(/svg/clock.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 18px;
  height: 18px;
  display: block;
  position: relative;
  margin-right: 5px;
}

.widget-teacher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.widget-teacher__photo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  margin-right: 35px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #EFF3F7;
}

.widget-teacher__photo > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.widget-teacher__info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.widget-teacher__envelop {
  position: absolute;
  right: -20%;
  bottom: 0;
  width: 36px;
  height: 36px;
}

.widget-teacher__name {
  font-weight: bold;
  word-break: break-all;
}

.widget-teacher__country {
  color: #8995AA;
}

.widget-teacher__status {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #8995AA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 8px;
}

.widget-teacher__status::before {
  content: "";
  position: relative;
  margin-right: 10px;
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  background-color: #8995AA;
  margin-top: 1px;
}

.widget-teacher__status.online {
  color: #16D9C2;
}

.widget-teacher__status.online::before {
  background-color: #16D9C2;
}

.widget-content.smaller .widget-item {
  width: 23.4042553%;
  margin-right: 2.12765957%;
  padding: 33px 40px;
}

.widget-content.smaller .widget-item:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .widget-content.smaller .widget-item {
    width: 100%;
    margin-right: 0;
  }
}


.widget-content.smaller .widget-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.widget-content.smaller .widget-icon__ico {
  max-height: 65%;
}

.widget-content.smaller .widget-info {
  margin-top: auto;
  margin-bottom: 0;
}

.widget-content.smaller .widget-info__title {
  margin-bottom: 0;
}

.widget-content.smaller .widget-picture {
  top: initial;
  bottom: 0;
}

@media (max-width: 1799px) {
  .widget-content.smaller .widget-item {
    width: 23.689%;
    margin-right: 1.94%;
    padding: 19px 33px;
  }

  .widget-content.smaller .widget-label {
    margin-bottom: 18px;
  }

  .widget-content.smaller .widget-icon {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 1430px) {
  .widget-content.smaller .widget-item {
    width: 23.63%;
    margin-right: 2.14%;
    padding: 10px 27px 27px 27px;
  }

  .widget-content.smaller .widget-label {
    margin-bottom: 14px;
  }

  .widget-content.smaller .widget-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
}

@media (max-width: 1279px) {
  .widget-content.smaller .widget-item {
    width: 23.825%;
    margin-right: 1.832%;
    padding: 17px 20px 23px 25px;
  }
}

@media (max-width: 1023px) {
  .widget-content.smaller .widget-item {
    width: 32%;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .widget-content.smaller .widget-item {
    width: 100%;
    padding: 20px 25px 28px;
  }

  .widget-content.smaller .widget-picture {
    width: 150px;
  }

  .widget-content.smaller .widget-label {
    margin-bottom: 20px;
  }

  .widget-content.smaller .widget-icon {
    width: 34px;
    height: 34px;
    margin-right: 14px;
  }
}

.hint-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  margin-left: 10px;
  line-height: 1;
  background-color: #EFF3F7;
  border-radius: 50%;
  background-image: url(/svg/hint-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
  cursor: pointer;
}

.preloader {
  text-align: center;
  margin: auto;
  padding: 20px;
  display: block;
  -ms-flex-item-align: center;
  align-self: center;
  justify-self: center;
  place-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.preloader.screen-mode {
  height: 100%;
  position: fixed;
  z-index: 1000000000;
  background: #eff3f7;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.preloader.fly-mode {
  position: absolute;
}

.preloader-ico {
  margin-bottom: 33px;
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden;
}

.preloader-progress {
  width: 160px;
  height: 8px;
  background-color: gray;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.preloader-progress::before {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  border-radius: 5px;
  -webkit-animation: preloader-bar 3s ease-out 0s infinite;
  animation: preloader-bar 3s ease-out 0s infinite;
}

@-webkit-keyframes preloader-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes preloader-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.noscroll {
  overflow: hidden !important;
}

.pp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  will-change: opacity, visibility;
}

.pp::-webkit-scrollbar {
  width: 6px;
}

.pp::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 5px;
  color: var(--lightgray);
}

.pp::-webkit-scrollbar-thumb {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-radius: 5px;
  background-color: var(--black);
}

.pp__bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 12, 12, 0.45);
  z-index: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}

.pp__content {
  position: absolute;
  z-index: 1;
  width: 900px;
  border-radius: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin-bottom: 60px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.07);
  will-change: transform;
  padding: 35px 142.5px 50px;
}

@media (max-width: 1279px) {
  .pp__content {
    top: 60px;
  }
}

@media (max-width: 767px) {
  .pp__content {
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0;
    overflow-y: auto;
  }
}

.pp__content-head {
  margin-bottom: 35px;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  color: #0A0A0B;
  text-align: center;
}

@media (max-width: 1430px) {
  .pp__content-head {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1279px) {
  .pp__content-head {
    margin-bottom: 28px;
  }
}

@media (max-width: 1023px) {
  .pp__content-head {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .pp__content-head {
    margin-bottom: 23px;
  }
}

.show {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.pp-notes-single.show {
  z-index: 102;
}

.show .pp__content {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-clip-path: inset(0%);
  clip-path: inset(0%);
}

.pp__close {
  top: 35px;
  right: 40px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: end;
  align-self: flex-end;
  will-change: transform;
  cursor: pointer;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  z-index: 10;
  will-change: transform;
}

.pp__close:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .pp__close {
    top: 25px;
    right: 25px;
    width: 20px;
    height: 20px;
  }
}

.close__lane {
  background-color: #0A0A0B;
  height: 2px;
  width: 100%;
  will-change: transform;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  will-change: transform;
}

.close__lane:nth-child(2) {
  will-change: transform;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: -4px;
  margin-top: -2px;
}

@media (max-width: 767px) {
  .pp-interests .pp__content {
    padding-left: 5.33500%;
    padding-right: 5.33500%;
  }
}

.pp-interests__head h1 {
  margin-bottom: 11px;
}

.pp-interests__head {
  margin-bottom: 40px;
  text-align: center;
}

.pp-interests__body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [ 5 ];
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
}

.interests__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 110px;
  height: 130px;
  position: relative;
  padding: 24px 0;
}

.interests__input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.interests__border {
  border: 2px solid #EFF3F7;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.interests__input:checked ~ .interests__border {
  border-color: #0A0A0B;
}

.button.pp-interests__button {
  grid-column: 1 / -1;
  width: auto;
  margin: 40px auto 0;
}

.section-title {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 45px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

@media (max-width: 1799px) {
  .section-title {
    margin-bottom: 50px;
  }

  .notifications-section .section-title {
    margin-bottom: 58px;
  }
}

@media (max-width: 1430px) {
  .section-title {
    margin-bottom: 30px;
  }

  .notifications-section .section-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 1279px) {
  .section-title {
    margin-bottom: 27px;
  }

  .notifications-section .section-title {
    margin-bottom: 68px;
  }
}

@media (max-width: 1023px) {
  .section-title {
    margin-bottom: 35px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .teachers-section .section-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .teachers-section .section-title .section-title__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 767px) {
  .section-title {
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .notifications-section .section-title {
    display: block;
    margin-bottom: 50px;
  }

  .notifications-section .section-title .section-title__right {
    margin-top: 30px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .teachers-section .section-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.section-title__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .section-title__left {
    display: block;
  }

  .section-title__left .section-title__back {
    float: left;
    margin-top: 4px;
  }

  .tarifs-section .section-title__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .user-card__wrapper .section-title__left,
  .teacher-card__wrapper .section-title__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.section-title__label {
  margin-left: 34px;
  background: rgba(210, 220, 239, 0.4);
  border-radius: 7px;
  padding: 10px 21px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: #8995AA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1799px) {
  .section-title__label {
    margin-left: 40px;
    padding: 7px 16px;
  }
}

@media (max-width: 1430px) {
  .section-title__label {
    margin-left: 32px;
    padding: 7px 15px 8px;
    font-size: 14px;
  }
}

@media (max-width: 1279px) {
  .section-title__label {
    margin-left: 30px;
    padding: 7px 13px 8px;
    font-size: 12px;
  }
}

@media (max-width: 1023px) {
  .section-title__label {
    padding: 6px 14px 7px;
  }
}

@media (max-width: 767px) {
  .section-title__label {
    display: none;
  }
}

.section-title__right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 1799px) {
  .section-title__right .button.medium {
    padding: 0 35px;
    height: 50px;
    font-size: 13px;
  }
}

@media (max-width: 1279px) {
  .section-title__right .button.medium {
    padding: 0 33px;
    height: 46px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .section-title__right .button.medium {
    padding: 0;
    width: 38px;
    height: 38px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 0;
    min-width: 38px;
    border-radius: 50%;
  }

  .section-title__right .button.medium::before {
    margin-right: 0;
    width: 16px;
    height: 16px;
  }
}

.section-title__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: .4em;
}

span.section-title__time:before {
  content: '';
  background-image: url(/svg/clock.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: .4em;
}

.tarifs-box {
  margin-bottom: 90px;
}

.tarifs-box:last-child {
  margin-bottom: 0;
}

@media (max-width: 1799px) {
  .tarifs-box {
    margin-bottom: 55px;
  }
}

@media (max-width: 1430px) {
  .tarifs-box {
    margin-bottom: 40px;
  }
}

@media (max-width: 1279px) {
  .tarifs-box {
    margin-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .tarifs-box {
    margin-bottom: 40px;
    display: block;
  }
}

.tarifs-item {
  width: 30.4964539%;
  margin-right: 3.12056738%;
  padding: 30px 55px 55px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tarifs-item:last-child {
  margin-right: 0;
}

@media (max-width: 1799px) {
  .tarifs-item {
    padding: 28px 30px 34px;
    width: calc((100% - 72px) / 3);
    margin-right: 36px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item {
    padding: 30px 40px 17px;
    width: calc((100% - 44px) / 3);
    margin-right: 22px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item {
    padding: 25px 35px 30px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item {
    padding: 20px 22px 30px;
    width: calc((100% - 24px) / 3);
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .tarifs-item {
    width: 100%;
    padding: 23px 32px 40px;
    margin-right: 0;
    margin-bottom: 18px;
  }

  .tarifs-item:last-child {
    margin-bottom: 0;
  }
}

.tarif-list {
  width: 100%;
  margin-bottom: 70px;
}

.tarif-list:last-child {
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .tarif-list {
    margin-bottom: 40px;
  }
}

.tarif-list__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
}

.tarif-list__header h2 {
  margin-bottom: 0;
}

@media (max-width: 1279px) {
  .tarif-list__header {
    margin-bottom: 30px;
  }
}

.tarif-list__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tarif-list__nav-button.slider-btn {
  width: 57px;
  height: 57px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
}

@media (max-width: 1023px) {
  .tarif-list__nav-button.slider-btn {
    width: 30px;
    height: 30px;
  }
}

.tarif-list__nav-button.slider-btn:hover {
  opacity: 1;
}

.tarif-list__nav-button::before, .tarif-list__nav-button::after {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.tarif-list__nav-button::before {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
}

.tarif-list__nav-button:hover:before {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.tarif-list__nav-button::after {
  background-image: url(/svg/swiper-black-arrow_left.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 22px;
  height: 15px;
}

@media (max-width: 1023px) {
  .tarif-list__nav-button::after {
    width: 12px;
    height: 12px;
  }
}

.tarif-list__nav-button.slider-btn-next::after {
  background-image: url(/svg/swiper-black-arrow_right.svg);
}

.swiper-button-disabled {
  opacity: .5;
  pointer-events: none;
}

.tarif-list__body .tarifs-item {
  width: 31.6312057%;
  margin-right: 2.41134752%;
  padding: 60px 70px 45px 50px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tarif-list__body .tarifs-item:last-child {
  margin-right: 0;
}

@media (max-width: 1799px) {
  .tarif-list__body .tarifs-item {
    width: 31.6602317%;
    margin-right: 2.41312741%;
    padding: 45px 35px 30px;
  }
}

@media (max-width: 1430px) {
  .tarif-list__body .tarifs-item {
    width: 31.8036286%;
    margin-right: 2.45464248%;
    padding: 35px 35px 30px;
  }
}

@media (max-width: 1279px) {
  .tarif-list__body .tarifs-item {
    width: 31.5909091%;
    margin-right: 2.44318182%;
    padding: 30px 32px 25px;
  }
}

@media (max-width: 1023px) {
  .tarif-list__body .tarifs-item {
    width: 31.629393%;
    margin-right: 2.55591054%;
    padding: 20px 22px;
  }
}

@media (max-width: 767px) {
  .tarif-list__body .tarifs-item {
    width: 290px;
    margin-right: 12px;
    padding: 30px;
  }
}

.tarifs-item__sale {
  position: absolute;
  width: 71px;
  height: 27px;
  z-index: 1;
  right: -40px;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tarifs-item__sale::before {
  content: "";
  background-image: url(/svg/sale-tail.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 32px;
  height: 10px;
  position: absolute;
  left: -16%;
  top: 17%;
}

@media (max-width: 1799px) {
  .tarifs-item__sale {
    width: 52px;
    height: 20px;
    top: 20px;
    right: -28px;
  }

  .tarifs-item__sale::before {
    width: 22px;
    height: 8px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item__sale {
    width: 47px;
    height: 18px;
    top: 14px;
    right: -23px;
  }

  .tarifs-item__sale::before {
    width: 20px;
    height: 7px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__sale {
    width: 44px;
    height: 17px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__sale {
    width: 32px;
    height: 12px;
    top: 10px;
    right: -18px;
  }

  .tarifs-item__sale::before {
    width: 14px;
    height: 5px;
  }
}

@media (max-width: 767px) {
  .tarifs-item__sale {
    width: 47px;
    height: 18px;
    top: 13px;
    right: -22px;
  }

  .tarifs-item__sale::before {
    width: 21px;
    height: 7px;
  }
}

.tarifs-item__sale-svg {
  width: 100%;
  height: 100%;
}

.tarifs-item__sale-val {
  font-weight: bold;
  font-size: 13px;
  line-height: 1.4;
  text-transform: uppercase;
  position: absolute;
  margin-left: 7%;
  margin-bottom: 1px;
}

@media (max-width: 1799px) {
  .tarifs-item__sale-val {
    font-size: 10px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item__sale-val {
    font-size: 9px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__sale-val {
    font-size: 8px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__sale-val {
    font-size: 6px;
  }
}

@media (max-width: 767px) {
  .tarifs-item__sale-val {
    font-size: 9px;
  }
}

.tarifs-item__title {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4987FF;
}

@media (max-width: 1799px) {
  .tarifs-item__title.title-w-label {
    max-width: 80%;
  }
}

.tarifs-item__description {
  margin-bottom: 35px;
}

@media (max-width: 1799px) {
  .tarifs-item__description {
    margin-bottom: 30px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item__description {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__description {
    margin-bottom: 20px;
  }

  .tarifs-item__description p.small {
    font-size: 12px;
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .tarifs-item__description {
    margin-bottom: 27px;
  }
}

.tarifs-item__descr-title {
  margin-bottom: 25px;
  font-size: 0.833333em;
}

.tarifs-item__descr-title:last-child {
  margin-bottom: 0px;
}

@media (max-width: 1799px) {
  .tarifs-item__descr-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__descr-title {
    margin-bottom: 18px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__descr-title {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .tarifs-item__descr-title {
    margin-bottom: 25px;
  }
}

.tarifs-item__price {
  margin-bottom: 30px;
  line-height: 1;
  margin-top: auto;
}

@media (max-width: 1799px) {
  .tarifs-item__price {
    margin-bottom: 35px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__price {
    margin-bottom: 0px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__price {
    margin-bottom: 10px;
  }
}

.tarifs-item__price-row {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #EFF3F7;
  cursor: pointer;
  position: relative;
}

.sale .tarifs-item__price-row {
  padding-right: 48px;
}

.tarifs-item__price-row:last-child {
  border-bottom: 0;
}

@media (max-width: 1799px) {
  .sale .tarifs-item__price-row {
    padding: 13px 0;
    padding-right: 35px;
  }

  .sale .tarifs-item__price-row .tarifs-item__count {
    margin-left: 20px;
    font-size: 14px;
  }
}

@media (max-width: 1430px) {
  .sale .tarifs-item__price-row {
    padding: 10px 0;
    padding-right: 30px;
  }

  .sale .tarifs-item__price-row .tarifs-item__count {
    font-size: 13px;
  }
}

@media (max-width: 1279px) {
  .sale .tarifs-item__price-row {
    padding-right: 26px;
  }

  .sale .tarifs-item__price-row .tarifs-item__count {
    margin-left: 18px;
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__price-row {
    padding: 12px 0;
  }

  .sale .tarifs-item__price-row {
    padding: 8px 0;
    padding-right: 20px;
  }

  .sale .tarifs-item__price-row .tarifs-item__count {
    margin-left: 5px;
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .sale .tarifs-item__price-row {
    padding: 14px 0;
    padding-right: 30px;
  }

  .sale .tarifs-item__price-row .tarifs-item__count {
    margin-left: 19px;
    font-size: 13px;
  }
}

.tarifs-item__slash {
  color: #A0B0CD;
  opacity: .12;
  margin: 0 5px;
}

.tarifs-item__button {
  width: 170px;
}

.tarifs-item__button:hover {
  opacity: 1;
}

@media (max-width: 1799px) {
  .tarifs-item__button {
    width: 135px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item__button {
    width: 117px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__button {
    width: 150px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__button {
    width: 95px;
  }
}

@media (max-width: 767px) {
  .tarifs-item__button {
    display: block;
    padding: 0 40px;
    min-width: initial;
    width: auto;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}

.tarifs-item__checkbox {
  position: relative;
  border: 2px solid #EFF3F7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.tarifs-item__checkbox::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 22px;
  top: -9px;
  left: -4px;
  background-image: url(/svg/check-icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.tarifs-item__checkbox.active::after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1279px) {
  .tarifs-item__checkbox.active::after {
    width: 25px;
    height: 18px;
    top: -7px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__checkbox.active::after {
    width: 16px;
    height: 12px;
    top: -6px;
    left: -2px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__checkbox {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__checkbox {
    width: 12px;
    height: 12px;
  }
}

.tarifs-item__count {
  margin-left: 15px;
  margin-bottom: 0;
  font-family: "Evolventa";
  font-weight: normal;
  line-height: 1;
}

@media (max-width: 1799px) {
  .tarifs-item__count {
    font-size: 13px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item__count {
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__count {
    margin-left: 10px;
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .tarifs-item__count {
    font-size: 12px;
    margin-left: 20px;
  }
}

.tarifs-item__price-v1 {
  margin-left: auto;
}

@media (max-width: 1279px) {
  .tarifs-item__price-v1 {
    font-size: 20px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__price-v1 {
    font-size: 14px;
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .tarifs-item__price-v1 {
    font-size: 18px;
  }
}

.tarifs-item__label {
  position: absolute;
  right: -20px;
  top: -15px;
  width: 133px;
  height: 75px;
}

@media (max-width: 1279px) {
  .tarifs-item__label {
    right: -10px;
    width: 100px;
    height: auto;
  }
}

.tarifs-item__price-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
}

.tarifs-item__price-double {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 1279px) {
  .tarifs-item__price-double {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

@media (max-width: 767px) {
  .tarifs-item__price-double {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.tarifs-item__price-old {
  position: relative;
  margin-right: 10px;
  font-size: 0.643em;
  line-height: 1;
}

@media (max-width: 1799px) {
  .tarifs-item__price-old {
    font-size: 16px;
    margin-right: 5px;
  }
}

@media (max-width: 1430px) {
  .tarifs-item__price-old {
    font-size: 14px;
  }
}

@media (max-width: 1279px) {
  .tarifs-item__price-old {
    margin-right: 0;
    font-size: 15px;
    margin-bottom: 6px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__price-old {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .tarifs-item__price-old {
    font-size: 15px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.tarifs-item__price-old::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #0a0a0b;
  margin: auto;
}

@media (max-width: 1279px) {
  .tarifs-item__price-old::before {
    height: 1px;
  }
}

.tarifs-item__price-new {
  color: #f36;
  line-height: 1;
}

.tarifs-item__price-lesson {
  margin-top: 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  color: #f36;
}

@media (max-width: 1023px) {
  .tarifs-item__price-lesson {
    font-size: 8px;
  }
}

.tarifs-item__price-lesson span {
  font-size: 12px;
  font-weight: 600;
}

@media (max-width: 1279px) {
  .tarifs-item__price-lesson span {
    font-size: 10px;
  }
}

@media (max-width: 1023px) {
  .tarifs-item__price-lesson span {
    font-size: 8px;
  }
}

.tarif-list__label {
  margin-top: 60px;
}

@media (max-width: 1279px) {
  .tarif-list__label {
    margin-top: 30px;
  }
}

@media (max-width: 1023px) {
  .tarif-list__label {
    margin-top: 20px;
    font-size: 10px;
  }
}

.payments-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .payments-box {
    display: block;
  }
}

.payments-box__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 40px;
}

@media (max-width: 1799px) {
  .payments-box__list {
    margin-left: 25px;
  }
}

@media (max-width: 1279px) {
  .payments-box__list {
    margin-left: 22px;
  }
}

@media (max-width: 1023px) {
  .payments-box__list {
    margin-left: 38px;
  }
}

@media (max-width: 767px) {
  .payments-box__list {
    margin-left: 0;
    margin-top: 15px;
  }
}

.payments-box__item {
  -webkit-filter: grayscale(0.9);
  filter: grayscale(0.9);
  margin-right: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
}

.payments-box__item:last-child {
  margin-right: 0;
}

.payments-box__item:hover {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.payments-box__item > img {
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 1799px) {
  .payments-box__item {
    margin-right: 28px;
  }
}

@media (max-width: 1430px) {
  .payments-box__item {
    height: 35px;
    margin-right: 38px;
  }
}

@media (max-width: 1279px) {
  .payments-box__item {
    height: 30px;
    margin-right: 23px;
  }
}

@media (max-width: 1023px) {
  .payments-box__item {
    height: 32px;
    margin-right: 35px;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
}

@media (max-width: 767px) {
  .payments-box__item {
    height: 40px;
    margin-right: 30px;
  }
}

.section.payment-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 10.43%;
}

@media (max-width: 1799px) {
  .section.payment-section {
    padding: 35px 9.36%;
  }
}

@media (max-width: 1430px) {
  .section.payment-section {
    padding: 35px 10.46%;
  }
}

@media (max-width: 1279px) {
  .section.payment-section {
    padding: 35px 10.78%;
  }
}

@media (max-width: 1023px) {
  .section.payment-section {
    padding: 30px 6.4% 40px 8.45%;
  }
}

@media (max-width: 767px) {
  .section.payment-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 37px 25px 48px;
  }
}

.payment-section__content {
  width: 50%;
}

@media (max-width: 767px) {
  .payment-section__content {
    width: 100%;
  }
}

.payment-section__title {
  margin-bottom: 30px;
}

@media (max-width: 1799px) {
  .payment-section__title {
    margin-bottom: 25px;
  }
}

@media (max-width: 1430px) {
  .payment-section__title {
    margin-bottom: 15px;
  }
}

@media (max-width: 1279px) {
  .payment-section__title {
    margin-bottom: 25px;
  }
}

@media (max-width: 1023px) {
  .payment-section__title {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .payment-section__title {
    margin-bottom: 24px;
  }
}

.payment-section__text {
  margin-bottom: 40px;
}

@media (max-width: 1799px) {
  .payment-section__text {
    margin-bottom: 55px;
  }
}

@media (max-width: 1430px) {
  .payment-section__text {
    margin-bottom: 60px;
  }
}

@media (max-width: 1023px) {
  .payment-section__text {
    margin-bottom: 45px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .payment-section__text {
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .payment-section__button.button.medium {
    padding: 0 40px;
    height: 50px;
    font-size: 13px;
  }
}

.payment-section__picture {
  width: 360px;
}

@media (max-width: 1799px) {
  .payment-section__picture {
    width: 262px;
  }
}

@media (max-width: 1023px) {
  .payment-section__picture {
    width: 198px;
  }
}

@media (max-width: 767px) {
  .payment-section__picture {
    width: 67.5%;
  }
}

.read-all-notify {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  color: #8995AA;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.read-all-notify:hover {
  opacity: 0.5;
}

.notifications-box {
  width: 82.8368794%;
  margin-left: auto;
  position: relative;
}

@media (max-width: 1799px) {
  .notifications-box {
    width: 80.96%;
  }
}

@media (max-width: 1430px) {
  .notifications-box {
    width: 82.9%;
  }
}

@media (max-width: 1279px) {
  .notifications-box {
    width: 100%;
  }
}

.notifications-item {
  padding: 28px 13% 28px 30px;
  margin-bottom: 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.notifications-item::before {
  content: "";
  position: absolute;
  left: -6.48464%;
  top: 0;
  bottom: -20px;
  border-right: 2px solid rgba(160, 176, 205, 0.15);
  z-index: 1;
}

.notifications-item:first-child::before {
  top: 50%;
}

.notifications-item:last-child::before {
  bottom: calc(100% - 50%);
}

.notifications-item__mark {
  position: absolute;
  left: calc(-6.48464% + 1px - 7px);
  width: 14px;
  height: 14px;
  background-color: #4987FF;
  border-radius: 50%;
  z-index: 2;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.notifications-item__mark.warning {
  background-color: #FF3366;
}

.card.notifications-item.read .notifications-item__mark {
  background-color: #D2DCEF;
}

.card.notifications-item.read .notifications-item__mark.warning {
  background-color: #A0B0CD;
}

@media (max-width: 1799px) {
  .notifications-item {
    padding: 25px 15% 25px 29px;
    margin-bottom: 33px;
  }

  .notifications-item::before {
    left: -6.56%;
    bottom: -33px;
    border-right: 2px solid rgba(160, 176, 205, 0.15);
  }

  .notifications-item__mark {
    left: calc(-6.56% + 1px - 7px);
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 1430px) {
  .notifications-item {
    padding: 22px 16.23% 22px 25px;
    margin-bottom: 23px;
  }

  .notifications-item::before {
    left: -5%;
    bottom: -23px;
    border-right: 2px solid rgba(160, 176, 205, 0.15);
  }

  .notifications-item__mark {
    left: calc(-5% + 1px - 6px);
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 1279px) {
  .notifications-item {
    padding: 18px 14.25% 18px 20px;
    margin-bottom: 55px;
  }

  .notifications-item::before {
    display: none;
  }

  .notifications-item__mark {
    display: none;
  }
}

@media (max-width: 1023px) {
  .notifications-item {
    padding: 18px 12.25% 18px 20px;
    margin-bottom: 55px;
  }
}

@media (max-width: 767px) {
  .notifications-item {
    padding: 13px 14.57% 20px 15px;
    margin-bottom: 40px;
  }
}

.notifications-item__date {
  position: absolute;
  left: -20.5%;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #A0B0CD;
  max-width: 10%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@media (max-width: 1799px) {
  .notifications-item__date {
    left: -23.5%;
    max-width: 15%;
  }
}

@media (max-width: 1430px) {
  .notifications-item__date {
    left: -20.5%;
    font-size: 14px;
  }
}

@media (max-width: 1279px) {
  .notifications-item__date {
    top: -22px;
    left: 0;
  }
}

@media (max-width: 767px) {
  .notifications-item__date {
    max-width: 100%;
    font-size: 12px;
    top: -20px;
  }
}

.notifications-item__ico {
  border: 2px solid #EFF3F7;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
  background-image: url("/svg/n-info.svg");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: background .3s;
  -o-transition: background .3s;
  transition: background .3s;
}

.notifications-item__ico.warning {
  background-image: url("/svg/n-warning.svg");
}

.notifications-item__ico.system_update {
  background-image: url("/svg/renewal_blue.svg");
}

.notifications-item__ico.info {
  background-image: url("/svg/info_blue.svg");
}

.notifications-item__ico.event {
  background-image: url("/svg/event_blue.svg");
}

.card.notifications-item.read .notifications-item__ico {
  background-image: url("/svg/n-info_gray.svg");
}

.card.notifications-item.read .notifications-item__ico.warning {
  background-image: url("/svg/n-warning_gray.svg");
}

.card.notifications-item.read .notifications-item__ico.system_update {
  background-image: url("/svg/renewal_grey.svg");
}

.card.notifications-item.read .notifications-item__ico.info {
  background-image: url("/svg/info_grey.svg");
}

.card.notifications-item.read .notifications-item__ico.event {
  background-image: url("/svg/event_grey.svg");
}

@media (max-width: 1430px) {
  .notifications-item__ico {
    width: 54px;
    height: 54px;
    margin-right: 27px;
    background-size: 50%;
  }
}

@media (max-width: 1279px) {
  .notifications-item__ico {
    width: 56px;
    height: 56px;
  }
}

@media (max-width: 767px) {
  .notifications-item__ico {
    width: 34px;
    height: 34px;
    margin-right: 18px;
    background-size: 55%;
  }
}

.notifications-item__content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.notifications-item__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 14px;
}

.notifications-item__title:only-child {
  margin-bottom: 0;
}

@media (max-width: 1430px) {
  .notifications-item__title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .notifications-item__title {
    margin-bottom: 6px;
  }
}

.notifications-item__text {
  font-size: 16px;
  line-height: 19px;
}

.notifications-item__text a {
  font-weight: 600;
  color: #4987FF;
}

@media (max-width: 1430px) {
  .notifications-item__text {
    font-size: 13px;
    line-height: 20px;
  }
}

@media (max-width: 1279px) {
  .notifications-item__text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .notifications-item__text {
    line-height: 19px;
  }
}

.notifications-item__button {
  top: 16px;
  right: 30px;
  width: 24px;
  height: 24px;
  z-index: 1;
  position: absolute;
  background-image: url("/svg/n-eye.svg");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.notifications-item__button.true {
  background-image: url("/svg/n-eye-close.svg");
}

@media (max-width: 1799px) {
  .notifications-item__button {
    top: 20px;
    right: 20px;
  }
}

@media (max-width: 1430px) {
  .notifications-item__button {
    width: 22px;
    height: 22px;
    top: 23px;
    right: 23px;
  }
}

@media (max-width: 1279px) {
  .notifications-item__button {
    width: 24px;
    height: 24px;
    top: 25px;
    right: 52px;
  }
}

@media (max-width: 1023px) {
  .notifications-item__button {
    top: 25px;
    right: 25px;
  }
}

@media (max-width: 767px) {
  .notifications-item__button {
    top: 13px;
    right: 13px;
  }
}

.custom-notify .pnotify-container {
  background-color: black;
  border-radius: 10px;
  padding: 20px 25px;
  color: white;
}

.custom-notify .pnotify-container .pnotify-title {
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .custom-notify .pnotify-container {
    width: 300px;
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 14px;
    width: 300px !important;
  }
}

.custom-notify .pnotify-closer {
  width: 14px;
  height: 14px;
  background-image: url("/svg/n-close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.custom-notify .pnotify-title {
  color: white;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.faq-item {
  padding: 14px 25px 14px 60px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.faq-item:last-child {
  margin-bottom: 0;
}

.faq-item::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: #4987FF;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  will-change: transform;
}

.faq-item.active::after {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.faq-item a {
  color: #4987FF;
}

@media (max-width: 1799px) {
  .faq-item {
    padding: 13px 22px 13px 45px;
    margin-bottom: 22px;
  }
}

@media (max-width: 1430px) {
  .faq-item {
    padding: 12px 18px 12px 60px;
    margin-bottom: 23px;
  }

  .faq-item::after {
    width: 8px;
  }
}

@media (max-width: 1279px) {
  .faq-item {
    padding: 12px 15px 12px 60px;
    margin-bottom: 20px;
  }

  .faq-item.card {
    border-radius: 18px;
  }
}

@media (max-width: 1023px) {
  .faq-item {
    padding: 12px 10px 12px 25px;
  }
}

@media (max-width: 767px) {
  .faq-item {
    padding: 20px 15px 20px 25px;
    margin-bottom: 10px;
  }

  .faq-item::after {
    display: none;
  }

  .faq-item.card {
    border-radius: 10px;
  }
}

.faq-item__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.faq-item__title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 5%;
}

.faq-item__button {
  height: 52px;
  border-left: 2px solid #EFF3F7;
  width: 98px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.faq-item__button::before, .faq-item__button::after {
  content: "";
  position: absolute;
  width: 2.5px;
  height: 18px;
  border-radius: 2px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  background-color: #0A0A0B;
}

.faq-item__button::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.faq-item.active .faq-item__button::before {
  height: 0;
}

@media (max-width: 1799px) {
  .faq-item__button {
    height: 55px;
    width: 112px;
  }
}

@media (max-width: 1430px) {
  .faq-item__button {
    height: 50px;
    width: 100px;
  }

  .faq-item__button::before, .faq-item__button::after {
    height: 16px;
  }
}

@media (max-width: 1279px) {
  .faq-item__button {
    width: 72px;
  }
}

@media (max-width: 1023px) {
  .faq-item__button {
    width: 70px;
  }
}

@media (max-width: 767px) {
  .faq-item__button {
    width: 34px;
    height: 34px;
    border-left: 0;
  }

  .faq-item__button::before, .faq-item__button::after {
    height: 14px;
  }
}

.faq-item__body {
  height: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  -webkit-clip-path: inset(0% 0% 100% 0%);
  clip-path: inset(0% 0% 100% 0%);
  font-size: 16px;
  line-height: 25px;
}

.faq-item.active .faq-item__body {
  display: block;
  padding-top: 25px;
  height: auto;
  opacity: 1;
  visibility: visible;
  -webkit-clip-path: inset(0%);
  clip-path: inset(0%);
}

@media (max-width: 1799px) {
  .faq-item.active .faq-item__body {
    margin-top: 13px;
  }
}

@media (max-width: 1430px) {
  .faq-item.active .faq-item__body {
    margin-top: 7px;
  }
}

@media (max-width: 1279px) {
  .faq-item__body {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .faq-item.active .faq-item__body {
    margin-top: 24px;
  }
}

.faq-info-box {
  margin-top: 50px;
  min-height: 380px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 1799px) {
  .faq-info-box {
    min-height: 296px;
  }
}

@media (max-width: 1430px) {
  .faq-info-box {
    margin-top: 20px;
    min-height: 255px;
  }
}

@media (max-width: 1279px) {
  .faq-info-box {
    margin-top: 43px;
    min-height: 202px;
  }
}

@media (max-width: 1023px) {
  .faq-info-box {
    display: block;
    margin-top: 60px;
    min-height: auto;
  }
}

@media (max-width: 767px) {
  .faq-info-box {
    margin: 60px -5.335%;
    padding: 0 5.335%;
    margin-top: 45px;
    min-height: 94vw;
  }
}

.faq-info-box__bg {
  position: absolute;
  right: -8%;
  z-index: 0;
  height: 100%;
}

@media (max-width: 1279px) {
  .faq-info-box__bg {
    right: -5%;
  }
}

@media (max-width: 1023px) {
  .faq-info-box__bg {
    width: 70%;
    height: auto;
    right: -6.5%;
    bottom: -30px;
  }
}

@media (max-width: 767px) {
  .faq-info-box__bg {
    width: auto;
    height: 100%;
    top: 0;
    bottom: auto;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.faq-info-box__left {
  width: 44.5%;
}

@media (max-width: 1799px) {
  .faq-info-box__left {
    width: 40.4%;
  }
}

@media (max-width: 1430px) {
  .faq-info-box__left {
    width: 44%;
  }
}

@media (max-width: 1279px) {
  .faq-info-box__left {
    width: 45%;
  }
}

@media (max-width: 1023px) {
  .faq-info-box__left {
    width: 74.5%;
  }
}

@media (max-width: 767px) {
  .faq-info-box__left {
    width: 100%;
  }
}

.faq-info-box__title {
  margin-bottom: 25px;
}

@media (max-width: 1799px) {
  .faq-info-box__title {
    margin-bottom: 30px;
  }
}

@media (max-width: 1279px) {
  .faq-info-box__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .faq-info-box__title {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .faq-info-box__title {
    width: 60%;
    margin-bottom: 20px;
  }
}

.faq-info-box__text {
  font-weight: 400;
  line-height: 35px;
}

@media (max-width: 1799px) {
  .faq-info-box__text {
    line-height: 25px;
  }
}

@media (max-width: 1279px) {
  .faq-info-box__text {
    line-height: 22px;
  }
}

.faq-info-box__contacts {
  position: relative;
  z-index: 1;
  width: 44.5%;
}

@media (max-width: 1799px) {
  .faq-info-box__contacts {
    width: 49.3%;
  }
}

@media (max-width: 1430px) {
  .faq-info-box__contacts {
    width: 43.2%;
  }
}

@media (max-width: 1279px) {
  .faq-info-box__contacts {
    width: 38.68%;
  }
}

@media (max-width: 1023px) {
  .faq-info-box__contacts {
    margin-top: 25px;
    width: 51%;
  }
}

@media (max-width: 767px) {
  .faq-info-box__contacts {
    width: 100%;
  }
}

.contact-item {
  margin-bottom: 40px;
}

.contact-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 1430px) {
  .contact-item {
    margin-bottom: 25px;
  }
}

@media (max-width: 1279px) {
  .contact-item {
    margin-bottom: 22px;
  }
}

@media (max-width: 1023px) {
  .contact-item {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .contact-item {
    margin-bottom: 30px;
  }
}

.contact-item__link {
  font-weight: bold;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}

@media (max-width: 1799px) {
  .contact-item__link {
    font-size: 32px;
  }
}

@media (max-width: 1430px) {
  .contact-item__link {
    margin-bottom: 13px;
    font-size: 28px;
  }
}

@media (max-width: 1279px) {
  .contact-item__link {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .contact-item__link {
    margin-bottom: 5px;
    font-size: 24px;
  }
}

.contact-item__descr {
  color: #4987FF;
  font-weight: bold;
  line-height: 1;
}

.news-box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 34px;
  grid-column-gap: 1.7%;
}

@media (max-width: 1799px) {
  .news-box {
    grid-row-gap: 28px;
    grid-column-gap: 1.9%;
  }
}

@media (max-width: 1430px) {
  .news-box {
    grid-row-gap: 23px;
    grid-column-gap: 1.6%;
  }
}

@media (max-width: 1023px) {
  .news-box {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 14px;
    grid-column-gap: 2.2%;
  }
}

@media (max-width: 767px) {
  .news-box {
    display: block;
  }

  .news-box > * + * {
    margin-top: 15px;
  }
}

.card.news-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 50px 50px 35px;
  position: relative;
  overflow: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.card.news-item:hover {
  opacity: 1;
}

.card.news-item::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 218px;
  height: 272px;
  background-image: url(/svg/news-bg-1.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  will-change: transform;
  z-index: 0;
}

.card.news-item:hover::after {
  -webkit-transform: translate(5%, 5%);
  -ms-transform: translate(5%, 5%);
  transform: translate(5%, 5%);
}

.card.news-item:nth-child(6n + 2):after {
  background-image: url(/svg/news-bg-2.svg);
}

.card.news-item:nth-child(6n + 3):after {
  background-image: url(/svg/news-bg-3.svg);
}

.card.news-item:nth-child(6n + 4):after {
  background-image: url(/svg/news-bg-3.svg);
}

.card.news-item:nth-child(6n + 5):after {
  background-image: url(/svg/news-bg-1.svg);
}

.card.news-item:nth-child(6n + 6):after {
  background-image: url(/svg/news-bg-2.svg);
}

@media (max-width: 1799px) {
  .card.news-item {
    padding: 25px 35px 21px;
  }

  .card.news-item::after {
    width: 177px;
    height: 222px;
  }
}

@media (max-width: 1430px) {
  .card.news-item {
    padding: 25px 30px;
  }

  .card.news-item::after {
    width: 161px;
    height: 202px;
  }
}

@media (max-width: 1279px) {
  .card.news-item {
    padding: 25px;
  }

  .card.news-item::after {
    width: 150px;
    height: 188px;
  }
}

@media (max-width: 767px) {
  .card.news-item {
    padding: 24px 25px 30px;
  }

  .card.news-item::after {
    width: 242px;
    height: 303px;
  }
}

.news-item__title {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 28px;
}

@media (max-width: 1799px) {
  .news-item__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1430px) {
  .news-item__title {
    margin-bottom: 15px;
  }
}

@media (max-width: 1279px) {
  .news-item__title {
    margin-bottom: 13px;
  }
}

@media (max-width: 1023px) {
  .news-item__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .news-item__title {
    margin-bottom: 15px;
  }
}

.news-item__text {
  margin-bottom: 85px;
  max-height: 30px;
  overflow: hidden;
}

@media (max-width: 1799px) {
  .news-item__text {
    margin-bottom: 40px;
  }
}

@media (max-width: 1430px) {
  .news-item__text {
    margin-bottom: 35px;
  }
}

@media (max-width: 1279px) {
  .news-item__text {
    margin-bottom: 50px;
  }
}

@media (max-width: 1023px) {
  .news-item__text {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .news-item__text {
    margin-bottom: 35px;
  }
}

.news-item__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: auto;
}

.news-item__date {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #A0B0CD;
}

.news-item__button {
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  color: #4987FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.news-item__button::after {
  content: "";
  position: relative;
  display: block;
  background-image: url(/svg/more-arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 19px;
  height: 12px;
  margin-left: 15px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.news-item:hover .news-item__button::after {
  -webkit-transform: translateX(10%);
  -ms-transform: translateX(10%);
  transform: translateX(10%);
}

@media (max-width: 1799px) {
  .news-item__button {
    font-size: 14px;
  }

  .news-item__button::after {
    width: 14px;
    height: 8px;
    margin-left: 9px;
  }
}

@media (max-width: 1430px) {
  .news-item__button {
    font-size: 12px;
  }

  .news-item__button::after {
    width: 13px;
    height: 7px;
    margin-left: 13px;
  }
}

@media (max-width: 1279px) {
  .news-item__button::after {
    margin-left: 9px;
  }
}

@media (max-width: 767px) {
  .news-item__button {
    font-size: 15px;
  }

  .news-item__button::after {
    width: 20px;
    height: 17px;
    margin-left: 9px;
  }
}

.news-more {
  margin: 50px 0 0;
  text-align: center;
}

.section:not(:last-of-type) {
  margin-bottom: 90px;
}

@media (max-width: 1799px) {
  .section:not(:last-of-type) {
    margin-bottom: 85px;
  }
}

@media (max-width: 1430px) {
  .section:not(:last-of-type) {
    margin-bottom: 50px;
  }
}

@media (max-width: 1279px) {
  .section:not(:last-of-type) {
    margin-bottom: 60px;
  }
}

@media (max-width: 1023px) {
  .section:not(:last-of-type) {
    margin-bottom: 55px;
  }
}

@media (max-width: 767px) {
  .section:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .other-arcticles-section {
    padding-bottom: 80px;
    position: relative;
  }

  .other-arcticles-section .section-title .button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.arcticle-head {
  padding: 42px 0 25px;
  margin-bottom: 22px;
  background-image: url("/svg/a-head-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 1799px) {
  .arcticle-head {
    padding: 31px 0 28px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .arcticle-head {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .arcticle-head {
    padding: 22px 0 33px;
    margin-bottom: 18px;
  }
}

.arcticle-grid {
  max-width: 933px;
  margin: 0 auto;
  padding: 0 20px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

@media (max-width: 1799px) {
  .arcticle-grid {
    max-width: 771px;
  }
}

@media (max-width: 1430px) {
  .arcticle-grid {
    max-width: 700px;
  }
}

@media (max-width: 1279px) {
  .arcticle-grid {
    max-width: 657px;
  }
}

@media (max-width: 1023px) {
  .arcticle-grid {
    max-width: 546px;
  }
}

@media (max-width: 767px) {
  .arcticle-grid {
    padding: 0 15px;
    max-width: 284px;
  }
}

.arcticle-head__back {
  color: #4987FF;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  margin-bottom: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.arcticle-head__back::before {
  content: "";
  position: relative;
  display: block;
  width: 26px;
  height: 14px;
  margin-right: 14px;
  background-image: url(/svg/article-back.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1799px) {
  .arcticle-head__back {
    margin-bottom: 46px;
  }
}

@media (max-width: 1430px) {
  .arcticle-head__back {
    margin-bottom: 40px;
  }
}

@media (max-width: 1279px) {
  .arcticle-head__back {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1023px) {
  .arcticle-head__back {
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  .arcticle-head__back {
    margin-bottom: 17px;
    font-size: 15px;
    line-height: 19px;
  }
}

.arcticle-head__title {
  font-size: 38px;
  line-height: 51px;
  margin-bottom: 58px;
}

@media (max-width: 1799px) {
  .arcticle-head__title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 37px;
  }
}

@media (max-width: 1430px) {
  .arcticle-head__title {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 39px;
  }
}

@media (max-width: 1279px) {
  .arcticle-head__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 32px;
  }
}

@media (max-width: 1023px) {
  .arcticle-head__title {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .arcticle-head__title {
    margin-bottom: 100px;
  }
}

.arcticle-head__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  color: #A0B0CD;
}

@media (max-width: 1279px) {
  .arcticle-head__info {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .arcticle-head__info {
    font-size: 16px;
  }
}

.arcticle-head__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  color: #A0B0CD;
  margin-right: 25px;
}

@media (max-width: 1799px) {
  .arcticle-head__date {
    margin-right: 22px;
  }
}

@media (max-width: 1279px) {
  .arcticle-head__date {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .arcticle-head__date {
    margin-right: 23px;
  }
}

.arcticle-head__date::before {
  content: "";
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background-image: url(/svg/a-date.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1799px) {
  .arcticle-head__date::before {
    margin-right: 12px;
  }
}

@media (max-width: 1279px) {
  .arcticle-head__date::before {
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .arcticle-head__date::before {
    width: 19px;
    height: 19px;
    margin-right: 12px;
  }
}

.arcticle-head__views {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.arcticle-head__views::before {
  content: "";
  position: relative;
  display: block;
  width: 27px;
  height: 16px;
  margin-right: 15px;
  background-image: url(/svg/views.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1279px) {
  .arcticle-head__views::before {
    width: 24px;
    height: 14px;
    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  .arcticle-head__views::before {
    width: 27px;
    height: 16px;
    margin-right: 15px;
  }
}

.arcticle-body {
  padding: 65px 0 40px;
}

@media (max-width: 1799px) {
  .arcticle-body {
    padding: 45px 0 20px;
  }
}

@media (max-width: 1430px) {
  .arcticle-body {
    padding: 35px 0 25px;
  }
}

@media (max-width: 1023px) {
  .arcticle-body {
    padding: 35px 0;
  }
}

@media (max-width: 767px) {
  .arcticle-body {
    padding: 27px 0;
  }
}

.arcticle-body__article {
  padding-bottom: 45px;
  margin-bottom: 20px;
  border-bottom: 2px solid #EFF3F7;
}

@media (max-width: 1279px) {
  .arcticle-body__article {
    padding-bottom: 25px;
  }
}

.arcticle-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.arcticle-author__photo {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 25px;
}

.arcticle-author__photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.arcticle-author__name {
  margin-bottom: 5px;
  font-weight: bold;
  line-height: 1;
}

.arcticle-author__team {
  color: #A0B0CD;
}

.arcticle-body__article h1,
.arcticle-body__article h2,
.arcticle-body__article h3,
.arcticle-body__article h4,
.arcticle-body__article h5 {
  margin: 0 0 1.5em;
}

.arcticle-body__article h5,
.arcticle-body__article .h5 {
  color: #0A0A0B;
}

.arcticle-body__article p {
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
}

.arcticle-body__article a {
  color: #4987FF;
  font-weight: bold;
}

.arcticle-body__article img {
  margin: 50px auto;
  border-radius: 10px;
  display: block;
}

@media (max-width: 1799px) {
  .arcticle-body__article img {
    margin: 40px auto 45px;
  }
}

@media (max-width: 1430px) {
  .arcticle-body__article img {
    margin: 30px auto 35px;
  }
}

@media (max-width: 1279px) {
  .arcticle-body__article img {
    margin: 35px auto 40px;
  }
}

@media (max-width: 1023px) {
  .arcticle-body__article img {
    margin: 30px auto 50px;
  }
}

@media (max-width: 767px) {
  .arcticle-body__article img {
    margin: 25px auto;
  }
}

.arcticle-body__article ul,
.arcticle-body__article ol {
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
  -webkit-margin-after: 1em;
  margin-block-end: 1em;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  -webkit-padding-start: 40px;
  padding-inline-start: 40px;
}

@media (max-width: 1023px) {
  .arcticle-body__article ul,
  .arcticle-body__article ol {
    -webkit-padding-start: 30px;
    padding-inline-start: 30px;
  }
}

.section-title__search {
  margin-left: 50px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1799px) {
  .section-title__search {
    margin-left: 48px;
  }
}

@media (max-width: 1430px) {
  .section-title__search {
    margin-left: 28px;
  }
}

@media (max-width: 1279px) {
  .section-title__search {
    margin-left: 38px;
  }
}

@media (max-width: 1023px) {
  .section-title__search {
    margin-top: 25px;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .section-title__search {
    display: none;
  }
}

.section-title__find-butoon {
  position: absolute;
  left: 20px;
  width: 20px;
  height: 20px;
  background-image: url(/svg/magnifying-glass.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 1430px) {
  .section-title__find-butoon {
    width: 18px;
    height: 18px;
    left: 18px;
  }
}

@media (max-width: 1279px) {
  .section-title__find-butoon {
    width: 17px;
    height: 17px;
    left: 17px;
  }
}

.overflow_scroll .ant-table {
  overflow-x: scroll;
}

.section-title__input {
  position: relative;
  background: white;
  border-radius: 55px;
  border: none;
  height: 50px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  width: 500px;
  padding: 0 55px;
}

.section-title__input.small {
  height: 40px;
}

@media (max-width: 1430px) {
  .section-title__input {
    width: 468px;
    height: 46px;
    font-size: 14px;
  }
}

@media (max-width: 1279px) {
  .section-title__input {
    width: 432px;
    padding: 0 50px;
    height: 43px;
    font-size: 13px;
  }
}

@media (max-width: 1023px) {
  .section-title__input {
    width: 385px;
    height: 46px;
  }
}

.notes-box:not(:last-child) {
  margin-bottom: 50px;
}

@media (max-width: 1799px) {
  .notes-box:not(:last-child) {
    margin-bottom: 55px;
  }
}

@media (max-width: 1430px) {
  .notes-box:not(:last-child) {
    margin-bottom: 60px;
  }
}

@media (max-width: 1279px) {
  .notes-box:not(:last-child) {
    margin-bottom: 55px;
  }
}

@media (max-width: 1023px) {
  .notes-box:not(:last-child) {
    margin-bottom: 40px;
  }
}

.notes-box__title {
  margin-bottom: 20px;
  color: #A0B0CD;
}

@media (max-width: 1799px) {
  .notes-box__title {
    margin-bottom: 17px;
  }
}

@media (max-width: 1279px) {
  .notes-box__title {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .notes-box__title {
    margin-bottom: 10px;
  }
}

.notes-box__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 34px;
  grid-column-gap: 2.2%;
  -webkit-perspective: 100px;
  perspective: 100px;
}

@media (max-width: 1799px) {
  .notes-box__list {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 2.3%;
  }
}

@media (max-width: 1279px) {
  .notes-box__list {
    grid-column-gap: 2.2%;
  }
}

@media (max-width: 1023px) {
  .notes-box__list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 16px;
  }
}

@media (max-width: 767px) {
  .notes-box__list {
    display: block;
  }

  .notes-box__list .notes-item + .notes-item {
    margin-top: 12px;
  }
}

.notes-item {
  padding: 30px 35px 15px;
  cursor: pointer;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  word-break: break-word;
}

.notes-item:hover {
  -webkit-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateZ(2px);
  transform: translateZ(2px);
}

@media (max-width: 1430px) {
  .notes-item {
    padding: 30px 32px 15px;
  }
}

@media (max-width: 1279px) {
  .notes-item {
    padding: 28px 29px 15px;
  }
}

@media (max-width: 1023px) {
  .notes-item {
    padding: 28px 27px 15px;
  }
}

@media (max-width: 767px) {
  .notes-item {
    padding: 27px 35px 15px;
  }
}

.notes-item__body {
  height: 278px;
  overflow: hidden;
  margin-bottom: 30px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, black), color-stop(95%, transparent));
  background: -webkit-linear-gradient(top, black 70%, transparent 95%);
  background: -o-linear-gradient(top, black 70%, transparent 95%);
  background: linear-gradient(180deg, black 70%, transparent 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.notes-item__body * + * {
  margin-top: 12px;
}

@media (max-width: 1430px) {
  .notes-item__body {
    height: 252px;
    margin-bottom: 27px;
  }
}

@media (max-width: 1279px) {
  .notes-item__body {
    height: 226px;
    margin-bottom: 7px;
  }
}

@media (max-width: 1023px) {
  .notes-item__body {
    height: 216px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .notes-item__body {
    height: 278px;
    margin-bottom: 30px;
  }
}

.notes-item__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.notes-item__date {
  color: #A0B0CD;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
}

.notes-item__tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.notes-item__button {
  width: 46px;
  height: 46px;
  margin-right: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.notes-item__button:last-child {
  margin-right: 0;
}

.notes-item__button::before {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #4987FF;
  z-index: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.notes-item__button:hover::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.notes-item__button_edit::after, .notes-item__button_delete::after {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  -webkit-transition: background .3s;
  -o-transition: background .3s;
  transition: background .3s;
}

.notes-item__button_edit::after {
  background-image: url(/svg/note-edit.svg);
}

.notes-item__button_edit:hover::after {
  background-image: url(/svg/note-edit_white.svg);
}

.notes-item__button_delete::after {
  background-image: url(/svg/note-delete.svg);
}

.notes-item__button_delete:hover::after {
  background-image: url(/svg/note-delete_white.svg);
}

@media (max-width: 1430px) {
  .notes-item__button {
    width: 42px;
    height: 42px;
  }
}

@media (max-width: 1279px) {
  .notes-item__button {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 767px) {
  .notes-item__button {
    width: 46px;
    height: 46px;
  }
}

.pp-notes-delete .pp__content {
  width: 400px;
  padding: 60px 40px;
}

.pp-notes-delete .pp__close {
  width: 17px;
  height: 17px;
  top: 23px;
  right: 32px;
}

.pp-notes-delete__body {
  text-align: center;
}

.pp-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 45px;
}

.pp-buttons.start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pp-buttons .button:not(:last-child) {
  margin-right: 10px;
}

.pp-notes-single .pp__content {
  padding: 45px 80px;
  width: 992px;
  top: 13vh;
}

@media (max-width: 767px) {
  .pp-notes-single .pp__content {
    top: 0;
    padding: 17px 20px;
  }
}

.pp-notes-single .pp__close {
  right: 80px;
  top: 42px;
}

@media (max-width: 767px) {
  .pp-notes-single .pp__close {
    top: 17px;
    right: 20px;
  }
}

.pp-notes-single__date {
  font-weight: 600;
  color: #A0B0CD;
  margin-bottom: 40px;
}

.pp-notes-single__content {
  resize: vertical;
  width: 100%;
  height: 400px;
  margin: 0;
  border: none;
  padding: 0;
}

.pp-notes-single__content::-webkit-scrollbar-track {
  background-color: transparent;
}

.pp-notes-single__content::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.pp-notes-single__content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #050425;
}

.pp-notes-single .pp-buttons {
  margin-top: 7em;
}

/* Start 404 */
.not-found__inner {
  width: 58%;
  margin: 0 auto;
  padding: 60px 0;
  text-align: center;
}

@media (max-width: 1799px) {
  .not-found__inner {
    width: 60%;
    margin: 0 auto;
    padding: 70px 0 60px;
  }
}

@media (max-width: 1430px) {
  .not-found__inner {
    width: 70.4%;
    margin: 0 auto;
    padding: 60px 0 60px;
  }
}

@media (max-width: 1279px) {
  .not-found__inner {
    width: 61.7%;
    margin: 0 auto;
    padding: 90px 0 60px;
  }
}

@media (max-width: 1023px) {
  .not-found__inner {
    width: 87%;
    margin: 0 auto;
    padding: 145px 0 60px;
  }
}

@media (max-width: 1430px) {
  .not-found__img {
    width: 89%;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 1279px) {
  .not-found__img {
    width: 81%;
  }
}

.not-found__img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.not-found__text {
  margin-top: 25px;
}

.not-found__link {
  margin-top: 25px;
}

/* End 404 */
/* Start 403 */
.not-show__text {
  margin-top: 35px;
}

.not-show__link {
  margin-top: 65px;
}

/* End 403 */
/* Start messages */
.messages__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 20px;
  background: white;
  overflow: hidden;
  /* высота экрана - отступ хедера - высота header - отступ main - высота footer - отступ footer */
  min-height: 740px;
  max-height: 81vh;
}

@media (max-width: 767px) {
  .messages__wrapper {
    min-height: 500px;
    height: calc(100vh - 110px);
    max-height: 80vh;
  }
}

.messages__nav-cont {
  width: 29.8%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-shadow: 0px 4px 100px rgba(70, 85, 113, 0.08);
  box-shadow: 0px 4px 100px rgba(70, 85, 113, 0.08);
  overflow-y: auto;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.messages__nav-cont::-webkit-scrollbar-track {
  background-color: transparent;
}

.messages__nav-cont::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.messages__nav-cont::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #D2DCEF;
}

@media (max-width: 1799px) {
  .messages__nav-cont {
    width: 30.2%;
  }
}

@media (max-width: 1430px) {
  .messages__nav-cont {
    width: 33%;
  }
}

@media (max-width: 1279px) {
  .messages__nav-cont {
    width: 32.2%;
  }
}

@media (max-width: 1017px) {
  .messages__nav-cont {
    width: 100%;
  }
}

.messages__nav-cont.hide {
  width: 0;
}

.messages__nav-head,
.messages-list__head {
  height: 86px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #EFF3F7;
}

@media (max-width: 1430px) {
  .messages__nav-head,
  .messages-list__head {
    height: 70px;
  }
}

@media (max-width: 1023px) {
  .messages__nav-head,
  .messages-list__head {
    height: 77px;
  }
}

.messages__nav-head {
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 1799px) {
  .messages__nav-head {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 1430px) {
  .messages__nav-head {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1023px) {
  .messages__nav-head {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.messages__nav-item {
  cursor: pointer;
}

.messages__user {
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: showMessagesUser .3s ease 0s 1;
  animation: showMessagesUser .3s ease 0s 1;
}

@-webkit-keyframes showMessagesUser {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes showMessagesUser {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.messages-back {
  display: none;
  width: 9px;
  min-width: 9px;
  height: 17px;
  margin-right: 27px;
  background-image: url(/svg/messages-back.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1023px) {
  .messages-back {
    margin-right: 20px;
    display: block;
  }
}

.messages-user__logo {
  overflow: hidden;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-right: 18px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 1;
}

.messages-user__logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 1799px) {
  .messages-user__logo {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}

@media (max-width: 1430px) {
  .messages-user__logo {
    width: 40px;
    height: 40px;
    margin-right: 18px;
  }
}

@media (max-width: 1023px) {
  .messages-user__logo {
    width: 54px;
    height: 54px;
  }
}

.messages__user-info {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messages__user-name {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #0A0A0B;
  z-index: 1;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1430px) {
  .messages__user-name {
    font-size: 14px;
  }
}

@media (max-width: 1023px) {
  .messages__user-name {
    font-size: 16px;
  }
}

.messages__user-status {
  margin-top: 5px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #A0B0CD;
  text-transform: uppercase;
  z-index: 1;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

@media (max-width: 1430px) {
  .messages__user-status {
    font-size: 9px;
    margin-top: 3px;
  }
}

@media (max-width: 1279px) {
  .messages__user-status {
    font-size: 8px;
  }
}

@media (max-width: 1023px) {
  .messages__user-status {
    font-size: 10px;
    margin-top: 8px;
  }
}

.messages__user-status--on {
  padding-left: 14px;
  position: relative;
  color: #16D9C2;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messages__user-status--on::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #16D9C2;
}

.messages__user-status--off {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messages__nav-item.messages__user {
  padding: 15px 60px;
  position: relative;
  border-bottom: 2px solid #EFF3F7;
}

.messages__nav-item.messages__user.aside-item.active::before, .messages__nav-item.messages__user.aside-item:hover::before {
  width: 100%;
  border-radius: 0;
}

@media (max-width: 1799px) {
  .messages__nav-item.messages__user {
    padding: 15px 25px;
  }
}

@media (max-width: 1430px) {
  .messages__nav-item.messages__user {
    padding: 15px 40px;
  }
}

@media (max-width: 1279px) {
  .messages__nav-item.messages__user {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 1023px) {
  .messages__nav-item.messages__user {
    padding: 12px 30px;
    height: auto;
  }
}

.messages-list__empty {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .messages-list__empty {
    display: none;
  }
}

.messages-list__empty-logo {
  width: 11%;
  margin: 0 auto 50px;
}

.messages-list__empty-logo img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.messages-list__empty-text {
  margin-top: 30px;
  text-align: center;
}

.messages-list__wrapper {
  width: 71.2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1799px) {
  .messages-list__wrapper {
    width: calc(100% - 30.2%);
  }
}

@media (max-width: 1430px) {
  .messages-list__wrapper {
    width: calc(100% - 33%);
  }
}

@media (max-width: 1279px) {
  .messages-list__wrapper {
    width: calc(100% - 32.2%);
  }
}

@media (max-width: 1023px) {
  .messages-list__wrapper {
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 100%;
  }
}

.messages-list__head {
  padding-left: 50px;
  padding-right: 55px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 1799px) {
  .messages-list__head {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.messages-list__head-btn {
  padding: 5px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #A0B0CD;
  border-radius: 55px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.messages-list__head-btn.active {
  border-color: #050425;
  pointer-events: none;
}

.messages-list__head-btn:hover {
  opacity: 0.6;
}

.messages-list__head-btn + .messages-list__head-btn {
  margin-left: 14px;
}

@media (max-width: 1430px) {
  .messages-list__head-btn {
    padding: 10px 20px;
  }
}

@media (max-width: 1279px) {
  .messages-list__head-btn {
    padding: 8px 18px;
  }
}

@media (max-width: 767px) {
  .messages-list__head-btn {
    padding: 0;
    border: none;
  }

  .messages-list__head-btn + .messages-list__head-btn {
    margin-left: 12px;
  }
}

.messages-list__head-btn-icon {
  margin-right: 5px;
  width: 21px;
  height: 21px;
}

.messages-list__head-btn-icon svg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.messages-list__head-btn-icon svg path {
  fill: #A0B0CD;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 1430px) {
  .messages-list__head-btn-icon {
    width: 19px;
    height: 17px;
  }
}

@media (max-width: 767px) {
  .messages-list__head-btn-icon {
    width: 22px;
    height: 22px;
    margin-right: 0;
  }
}

.messages-list__head-btn.active svg path {
  fill: #050425;
}

.messages-list__head-btn-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 29px;
  color: #A0B0CD;
  text-transform: uppercase;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 1430px) {
  .messages-list__head-btn-text {
    font-size: 12px;
    line-height: 17px;
  }
}

@media (max-width: 767px) {
  .messages-list__head-btn-text {
    display: none;
  }
}

.messages-list__head-btn.active .messages-list__head-btn-text {
  color: #050425;
}

.messages-list__head-user {
  max-width: 75%;
}

.messages-list__body {
  margin-right: 8px;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.messages-list__body::-webkit-scrollbar-track {
  background-color: transparent;
}

.messages-list__body::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.messages-list__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #D2DCEF;
}

@media (max-width: 1799px) {
  .messages-list__body {
    margin-right: 5px;
  }
}

.messages-list {
  padding: 0 50px;
}

.messages-list.files-list {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .messages-list.files-list {
    padding: 0 10px 0 20px;
  }
}

@media (max-width: 1799px) {
  .messages-list {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .messages-list {
    padding: 0 5px 0 20px;
  }
}

.messages-list__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 10px;
}

.message {
  max-width: 555px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 25px;
  -webkit-animation: message-show .3s ease-in 0s 1;
  animation: message-show .3s ease-in 0s 1;
  margin-bottom: 6px;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  white-space: pre-line;
}

.message.my-message {
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-right: 7px;
  -webkit-animation: my-message-show .3s ease-in 0s 1;
  animation: my-message-show .3s ease-in 0s 1;
}

.message:last-child {
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .message {
    max-width: 70%;
  }
}

@media (max-width: 767px) {
  .message {
    max-width: 100%;
  }
}

@-webkit-keyframes message-show {
  from {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes message-show {
  from {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes my-message-show {
  from {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes my-message-show {
  from {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.message-logo.messages-user__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

@media (max-width: 1023px) {
  .message-logo.messages-user__logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.message-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  color: #8995AA;
  white-space: nowrap;
}

.my-message .message-footer {
  left: auto;
  right: 7px;
  padding-left: 0;
}

.message-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  max-width: 100%;
}

@media (max-width: 767px) {
  .message-cont {
    max-width: calc(100% - 40px);
  }
}

.my-message .message-cont {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media (max-width: 767px) {
  .my-message .message-cont {
    max-width: 100%;
  }
}

.message-error {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #FF3366;
  color: white;
  right: -25px;
  top: calc(50% - 8px);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 9px;
  line-height: 1;
  font-weight: bold;
  cursor: pointer;
}

.my-message .message-error {
  right: initial;
  left: -25px;
}

.message-cont__item {
  display: inline-block;
  position: relative;
  padding: 15px 25px;
  border-radius: 8px;
  background-color: #4987FF;
  color: white;
  max-width: 100%;
}

@media (max-width: 1430px) {
  .message-cont__item {
    padding: 10px 20px;
  }
}

@media (max-width: 1023px) {
  .message-cont__item {
    padding: 15px 25px;
  }
}

@media (max-width: 767px) {
  .message-cont__item {
    padding: 13px 25px;
  }
}

.my-message .message-cont__item {
  color: #050425;
  background-color: #EFF3F7;
}

.message-cont__item + .message-cont__item {
  margin-top: 6px;
}

.message-cont__item-tail {
  width: 15px;
  height: 9px;
  position: absolute;
  left: -7px;
  bottom: 0;
}

.message-cont__item-tail svg {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.message-cont__item-tail svg path {
  fill: #4987FF;
}

.my-message .message-cont__item-tail {
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  left: auto;
  right: -7px;
}

.my-message .message-cont__item-tail svg path {
  fill: #EFF3F7;
}

.message-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.message-text + .message-file {
  margin-top: 8px;
}

.message-text a {
  color: #56d7ec;
  display: block;
  word-break: break-all;
}

.my-message .message-text a {
  color: #4987FF;
}

.message-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.message-file__logo {
  margin-right: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.message-file__logo:hover {
  opacity: 0.6;
}

.message-file__logo svg {
  display: block;
  width: 15px;
  height: 20px;
}

.message-file__logo svg path {
  stroke: #4987FF;
}

@media (max-width: 1430px) {
  .message-file__logo {
    width: 46px;
    height: 46px;
  }
}

.message-file__info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.message-file__info-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@media (max-width: 1430px) {
  .message-file__info-title {
    font-size: 13px;
  }
}

.message-file__info-text {
  opacity: 0.7;
  margin-top: 2px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 1430px) {
  .message-file__info-text {
    line-height: 14px;
  }
}

.my-message .message-file__logo {
  background-color: #4987FF;
}

.my-message .message-file__logo svg path {
  stroke: white;
}

.messages-list__footer {
  padding: 18px 78px 18px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 2px solid #EFF3F7;
  position: relative;
}

.messages-list__footer.file-mode {
  padding-top: 28px;
}

.messages-list__footer.loading {
  pointer-events: none;
  opacity: 0.5;
}

.messages-list__footer .preloader {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.messages-list__footer .preloader-ico {
  display: none;
}

@media (max-width: 1799px) {
  .messages-list__footer {
    padding: 18px 20px;
  }
}

@media (max-width: 1430px) {
  .messages-list__footer {
    padding: 13px 20px;
  }
}

.messages-list__footer-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.messages-list__footer-btn:hover {
  opacity: 0.6;
}

.messages-list__footer-btn svg path {
  fill: white;
}

.messages-list__footer-btn.add-file__btn {
  background-color: #050425;
}

.messages-list__footer-btn.add-file__btn input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.messages-list__footer-btn.add-file__btn .messages-list__footer-btn-label {
  display: block;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.messages-list__footer-btn.add-file__btn .messages-list__footer-btn-label svg {
  width: 17px;
  height: 17px;
  -o-object-fit: cover;
  object-fit: cover;
}

.messages-list__footer-btn.send-message__btn {
  background-color: #4987FF;
  padding-right: 4px;
}

.messages-list__footer-btn.send-message__btn svg {
  width: 19px;
  height: 19px;
  -o-object-fit: cover;
  object-fit: cover;
}

.messages-list__footer-input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.messages-list__footer-input input::-webkit-input-placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input:-ms-input-placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input::-ms-input-placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input,
.messages-list__footer-input input::-webkit-input-placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input,
.messages-list__footer-input input:-ms-input-placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input,
.messages-list__footer-input input::-ms-input-placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input,
.messages-list__footer-input input::placeholder {
  display: block;
  width: 100%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #050425;
}

.messages-list__footer-input input {
  padding: 0 18px;
  border: none;
  outline: none;
}

.messages-list__footer-info {
  position: absolute;
  top: 5px;
  font-size: 10px;
}

.divider {
  margin: 6px 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.divider::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: white;
}

.divider__text {
  padding: 0 28px;
  position: relative;
  z-index: 1;
  display: inline-block;
  background-color: white;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 26px;
  color: #A0B0CD;
}

.divider.new .divider__text {
  color: #4987FF;
}

.messages-list__body.files-list__body {
  margin-bottom: 20px;
  display: block;
}

.files-list__item + .files-list__item {
  margin-top: 55px;
}

.files-list__head {
  margin-bottom: 24px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #0A0A0B;
}

@media (max-width: 1799px) {
  .files-list__head {
    margin-bottom: 10px;
  }
}

@media (max-width: 1430px) {
  .files-list__head {
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media (max-width: 1023px) {
  .files-list__head {
    font-size: 15px;
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .files-list__head {
    font-size: 16px;
    line-height: 1;
    color: #8995AA;
    margin-bottom: 22px;
  }
}

.file-item {
  padding: 14px 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  color: #A0B0CD;
  border-bottom: 2px solid #EFF3F7;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  width: 100%;
}

@media (max-width: 1430px) {
  .file-item {
    padding: 12px 0;
  }
}

@media (max-width: 767px) {
  .file-item {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 60px calc(100% - 60px);
    grid-template-columns: 60px calc(100% - 60px);
    grid-row-gap: 15px;
    grid-column-gap: 0;
    padding: 0px 0 14px;
    margin-bottom: 13px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-item:hover {
  opacity: 0.6;
}

.file-item__logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4987FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.file-item__logo svg {
  width: 30%;
}

.file-item__logo svg path {
  stroke: white;
}

@media (max-width: 1799px) {
  .file-item__logo {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1430px) {
  .file-item__logo {
    width: 44px;
    height: 44px;
  }
}

@media (max-width: 1023px) {
  .file-item__logo {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767px) {
  .file-item__logo {
    width: 60px;
    height: 60px;
  }
}

.file-item__cont {
  padding: 0 13px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 1799px) {
  .file-item__cont {
    padding: 0 25px;
  }
}

@media (max-width: 1430px) {
  .file-item__cont {
    padding: 0 14px;
  }
}

@media (max-width: 1023px) {
  .file-item__cont {
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  .file-item__cont {
    max-width: 100%;
    padding: 0 17px;
  }
}

.file-item__title {
  font-size: 16px;
  line-height: 24px;
  color: #0A0A0B;
}

@media (max-width: 1430px) {
  .file-item__title {
    font-size: 12px;
  }
}

@media (max-width: 1023px) {
  .file-item__title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .file-item__title {
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-item__text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 21px;
}

@media (max-width: 1430px) {
  .file-item__text {
    font-size: 11px;
    line-height: 1;
  }
}

@media (max-width: 1023px) {
  .file-item__text {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .file-item__text {
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-item__date {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1;
}

@media (max-width: 1430px) {
  .file-item__date {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .file-item__date {
    grid-column: 1 / -1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
  }
}

/* End messages */
/* Start user-card */
.user-card__headline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-card__headline-btn {
  margin-right: 30px;
  width: 27px;
  height: 19px;
  background-image: url("/svg/arrow-left-black.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.user-card__headline-btn:hover {
  opacity: 0.6;
}

@media (max-width: 1430px) {
  .user-card__headline-btn {
    margin-right: 35px;
    width: 24px;
    height: 18px;
  }
}

@media (max-width: 1279px) {
  .user-card__headline-btn {
    margin-right: 17px;
    width: 23px;
    height: 14px;
  }
}

@media (max-width: 767px) {
  .user-card__headline-btn {
    margin-right: 22px;
    width: 11px;
    height: 19px;
    background-image: url("/svg/arrow-left-black-small.svg");
  }
}

.user-card {
  margin-top: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 1430px) {
  .user-card {
    margin-top: 35px;
  }
}

@media (max-width: 1279px) {
  .user-card {
    margin-top: 30px;
  }
}

@media (max-width: 1023px) {
  .user-card {
    display: block;
  }
}

.user-card__aside {
  width: 330px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (max-width: 1799px) {
  .user-card__aside {
    width: 30%;
  }
}

@media (max-width: 1023px) {
  .user-card__aside {
    width: 100%;
  }
}

.user-card__aside-item {
  padding: 23px 30px 35px;
  background: white;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.user-card__aside-item.teacher-card__profile {
  position: relative;
}

.user-card__aside-item + .user-card__aside-item {
  margin-top: 17px;
}

@media (max-width: 1799px) {
  .user-card__aside-item {
    padding: 23px 25px 35px;
  }
}

@media (max-width: 1430px) {
  .user-card__aside-item {
    padding: 20px 22px 25px;
  }

  .user-card__aside-item + .user-card__aside-item {
    margin-top: 22px;
  }
}

@media (max-width: 1279px) {
  .user-card__aside-item {
    padding: 20px 15px 25px;
  }
}

@media (max-width: 1023px) {
  .user-card__aside-item {
    padding: 20px 40px;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-top {
    max-height: 100px;
    padding-right: 10px;
    width: 55%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-avatar {
    -webkit-box-ordinal-group: -1;
    -ms-flex-order: -2;
    order: -2;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-top-info {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-bottom {
    width: 45%;
    padding-left: 20px;
    border-left: 2px solid #EFF3F7;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top {
    margin-bottom: 22px;
    position: relative;
    min-height: 90px;
    padding-left: 123px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-avatar {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top-info {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top-info .user__name {
    margin-top: 0;
    text-align: left;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top-info .user__town {
    text-align: left;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-head {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .user-card__aside-item {
    padding: 23px 35px 30px;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-top {
    max-height: 100%;
    padding-right: 0;
    width: 100%;
    display: block;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-avatar {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-top-info {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .user-card__wrapper .user-card__aside-item.user-card__profile .user-card__profile-bottom {
    width: 100%;
    padding-left: 0;
    border-left: 0;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top {
    margin-bottom: 22px;
    position: relative;
    min-height: auto;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-avatar {
    margin-bottom: 14px;
    position: relative;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    width: 150px;
    height: 150px;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top-info {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top-info .user__name {
    margin-top: 0;
    text-align: center;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-top-info .user__town {
    text-align: center;
  }

  .teacher-card__wrapper .user-card__aside-item.teacher-card__profile .user-card__profile-head {
    margin-bottom: 18px;
    -webkit-box-ordinal-group: -1;
    -ms-flex-order: -2;
    order: -2;
  }
}

.user-card__profile-head {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.teacher-card__wrapper .user-card__profile-head {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 0;
}

@media (max-width: 1799px) {
  .teacher-card__wrapper .user-card__profile-head {
    margin-bottom: 18px;
  }
}

@media (max-width: 1430px) {
  .teacher-card__wrapper .user-card__profile-head {
    margin-bottom: 12px;
  }
}

@media (max-width: 1279px) {
  .teacher-card__wrapper .user-card__profile-head {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .user-card__wrapper .user-card__profile-head {
    margin-bottom: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .teacher-card__wrapper .user-card__profile-head {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .user-card__wrapper .user-card__profile-head {
    margin-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .teacher-card__wrapper .user-card__profile-head {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.user-status {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #A0B0CD;
  text-transform: lowercase;
}

.user-status.user-status--on {
  padding-left: 12px;
  position: relative;
  color: #16D9C2;
}

.user-status.user-status--on::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #16D9C2;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 1279px) {
  .user-status {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 1430px) {
  .user-status {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 1023px) {
  .user-card__wrapper .user-status {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .user-status {
    font-size: 14px;
    line-height: 17px;
  }

  .user-card__wrapper .user-status {
    margin-left: 0px;
  }
}

.user-old {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #A0B0CD;
}

@media (max-width: 1279px) {
  .user-old {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 1430px) {
  .user-old {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 767px) {
  .user-old {
    font-size: 14px;
    line-height: 17px;
  }
}

.user-card__profile-avatar {
  position: relative;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.user-card__profile-avatar .user__logo {
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 2px solid #EFF3F7;
}

.user-card__profile-avatar .user__logo img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.user-card__profile-avatar .user__message-btn {
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #4987FF;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.user-card__profile-avatar .user__message-btn:hover {
  opacity: 0.6;
}

@media (max-width: 1799px) {
  .user-card__profile-avatar .user__message-btn {
    right: -14px;
  }
}

@media (max-width: 1430px) {
  .user-card__profile-avatar {
    width: 136px;
    height: 136px;
  }

  .user-card__profile-avatar .user__message-btn {
    width: 48px;
    height: 48px;
    right: -12px;
  }

  .user-card__profile-avatar .user__message-btn svg {
    width: 23px;
  }
}

@media (max-width: 1279px) {
  .user-card__profile-avatar {
    width: 110px;
    height: 110px;
  }

  .user-card__profile-avatar .user__message-btn {
    width: 40px;
    height: 40px;
    right: -11px;
  }

  .user-card__profile-avatar .user__message-btn svg {
    width: 19px;
  }
}

@media (max-width: 1023px) {
  .user-card__profile-avatar {
    width: 80px;
    height: 80px;
  }

  .user-card__wrapper .user-card__profile-avatar {
    margin: 0 20px 0 0;
  }

  .user-card__profile-avatar .user__message-btn {
    width: 34px;
    height: 34px;
    right: -9px;
  }

  .user-card__profile-avatar .user__message-btn svg {
    width: 16px;
  }
}

@media (max-width: 767px) {
  .user-card__profile-avatar {
    width: 150px;
    height: 150px;
  }

  .user-card__wrapper .user-card__profile-avatar {
    margin: 0 auto;
  }

  .user-card__profile-avatar .user__message-btn {
    width: 54px;
    height: 54px;
    right: -13px;
  }

  .user-card__profile-avatar .user__message-btn svg {
    width: 25px;
  }
}

.user__name {
  margin-top: 14px;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #0A0A0B;
  display: block;
}

.teacher-card__wrapper .user__name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.teacher-card__wrapper .user__name .teacher-card__status {
  margin-left: 8px;
  width: 8px;
  height: 8px;
  background-color: #16D9C2;
  border-radius: 50%;
}

@media (max-width: 1799px) {
  .user__name {
    line-height: 29px;
  }
}

@media (max-width: 1430px) {
  .user__name {
    margin-top: 8px;
    font-size: 20px;
    line-height: 26px;
  }

  .teacher-card__wrapper .user__name .teacher-card__status {
    margin-left: 6px;
    width: 6px;
    height: 6px;
  }
}

@media (max-width: 1279px) {
  .user__name {
    margin-top: 14px;
    font-size: 18px;
    line-height: 24px;
  }

  .teacher-card__wrapper .user__name .teacher-card__status {
    margin-left: 10px;
  }
}

@media (max-width: 1023px) {
  .user-card__wrapper .user__name {
    margin-top: 0;
    text-align: left;
  }

  .teacher-card__wrapper .user__name {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .teacher-card__wrapper .user__name .teacher-card__status {
    margin-left: 7px;
  }
}

@media (max-width: 767px) {
  .user__name {
    margin-top: 14px;
    font-size: 20px;
    line-height: 30px;
  }

  .user-card__wrapper .user__name {
    margin-top: 14px;
    text-align: center;
  }

  .teacher-card__wrapper .user__name {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.user__town {
  margin-top: auto;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #8995AA;
  text-align: center;
}

@media (max-width: 1799px) {
  .user__town {
    margin-top: 9px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1430px) {
  .user__town {
    margin-top: 0;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1279px) {
  .user__town {
    margin-top: 3px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .user__town {
    margin-top: 4px;
    margin-bottom: 13px;
  }

  .user-card__wrapper .user__town {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .user__town {
    margin-top: 8px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 19px;
  }

  .user-card__wrapper .user__town {
    text-align: center;
  }
}

.user__time {
  padding: 15px 0;
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 2px solid #EFF3F7;
  border-bottom: 2px solid #EFF3F7;
}

.user__time-item {
  width: 50%;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
  color: #8995AA;
}

.user__time-item + .user__time-item {
  border-left: 2px solid #EFF3F7;
}

.user__time-head {
  font-size: 11px;
  line-height: 14px;
}

.user__time-body {
  margin-top: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user__time-img {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.user__time-img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.user__time-val {
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 1430px) {
  .user__time {
    margin-bottom: 20px;
  }
}

@media (max-width: 1279px) {
  .user__time {
    margin-bottom: 15px;
    padding: 14px 0;
  }

  .user__time-body {
    margin-top: 5px;
  }

  .user__time-img {
    width: 15px;
    height: 15px;
  }

  .user__time-val {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 1023px) {
  .user__time {
    margin-bottom: 8px;
  }

  .user-card__wrapper .user__time {
    border-top: 0;
    padding: 0 0 15px;
  }

  .teacher-card__wrapper .user__time {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .teacher-card__wrapper .user__time .user__time-item {
    padding-left: 40px;
    min-width: 26%;
    width: auto;
    text-align: left;
  }

  .teacher-card__wrapper .user__time .user__time-item:first-child {
    padding-left: 0;
  }

  .teacher-card__wrapper .user__time .user__time-item .user__time-body {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .user__time {
    margin-bottom: 25px;
  }

  .user-card__wrapper .user__time {
    padding: 15px 0;
    border-top: 2px solid #EFF3F7;
  }

  .teacher-card__wrapper .user__time {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .teacher-card__wrapper .user__time .user__time-item {
    padding-left: 0;
    min-width: auto;
    width: 50%;
    text-align: center;
  }

  .teacher-card__wrapper .user__time .user__time-item .user__time-body {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .user__time-img {
    width: 16px;
    height: 16px;
  }

  .user__time-val {
    font-size: 14px;
    line-height: 17px;
  }
}

.user__lessons-count {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #0A0A0B;
  text-align: center;
}

.user__lessons-count-total {
  margin-top: 14px;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #8995AA;
}

@media (max-width: 1430px) {
  .user__lessons-count {
    font-size: 14px;
    line-height: 18px;
  }

  .user__lessons-count-total {
    margin-top: 12px;
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 1023px) {
  .user-card__wrapper .user__lessons-count {
    text-align: left;
  }

  .user__lessons-count-total {
    margin-top: 5px;
  }
}

@media (max-width: 767px) {
  .user__lessons-count {
    font-size: 16px;
    line-height: 21px;
  }

  .user-card__wrapper .user__lessons-count {
    text-align: center;
  }

  .user__lessons-count-total {
    margin-top: 14px;
    font-size: 15px;
    line-height: 18px;
  }
}

.user-card__aside-item.user-card__interests {
  margin-bottom: 25px;
}

@media (max-width: 1023px) {
  .user-card__aside-item.user-card__interests {
    padding-bottom: 8px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .user-card__aside-item.user-card__interests {
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.user__interests-head {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #4987FF;
}

@media (max-width: 1430px) {
  .user__interests-head {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (max-width: 1023px) {
  .user__interests-head {
    margin-right: 13px;
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .user__interests-head {
    margin-right: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
  }
}

.user__interests-level {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  margin: 10px 0 20px;
}

.user__levels-container {
  display: block;
  margin: 40px 0;
}

.user__levels-container .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 14px;
  border-color: #EFF3F7;
}

.user__levels-container .button:hover {
  border-color: #0A0A0B;
}

.user__levels-container .button:last-child {
  margin-bottom: 0;
}

.user__levels-container .button.active {
  border-color: #0A0A0B;
}

.user__interests-items {
  margin: 16px 0 0 -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .user__interests-items {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .user__interests-items {
    margin: 16px 0 0 -5px;
  }
}

.user__interests-item {
  margin: 0 0 10px 5px;
  padding: 0 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  text-transform: lowercase;
  color: #0A0A0B;
  border: 2px solid #EFF3F7;
  border-radius: 20px;
}

@media (max-width: 1430px) {
  .user__interests-item {
    padding: 0 20px;
    font-size: 13px;
    line-height: 34px;
  }
}

@media (max-width: 1279px) {
  .user__interests-item {
    font-size: 12px;
    line-height: 31px;
  }
}

@media (max-width: 1023px) {
  .user__interests-item {
    margin: 0 0 13px 13px;
  }
}

@media (max-width: 767px) {
  .user__interests-item {
    padding: 0 25px;
    margin: 0 0 10px 5px;
    font-size: 14px;
    line-height: 38px;
  }
}

.user-card__main {
  width: 100%;
  margin-left: 30px;
}

@media (max-width: 1799px) {
  .user-card__main {
    margin-left: 20px;
  }
}

@media (max-width: 1279px) {
  .user-card__main {
    margin-left: 15px;
  }
}

@media (max-width: 1023px) {
  .user-card__main {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .user-card__main {
    margin-top: 10px;
  }
}

.user__main-item {
  position: relative;
  padding: 40px 55px;
  background: white;
  border-radius: 20px;
}

.user__main-item + .user__main-item {
  margin-top: 15px;
}

@media (max-width: 1799px) {
  .user__main-item {
    padding: 35px 30px;
  }

  .user__main-item.payments-item {
    padding: 35px 55px;
  }
}

@media (max-width: 1430px) {
  .user__main-item {
    padding: 25px 40px 33px;
  }

  .user__main-item.payments-item {
    padding: 35px 40px;
  }
}

@media (max-width: 1279px) {
  .user__main-item {
    padding: 30px 40px;
  }

  .user__main-item.payments-item {
    padding: 33px 35px;
  }
}

@media (max-width: 1023px) {
  .user__main-item {
    padding: 20px 25px 40px;
  }

  .user__main-item.payments-item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .user__main-item {
    padding: 25px 25px 35px;
  }
}

.user__main-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user__main-head-img {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #EFF3F7;
  border-radius: 50%;
}

.user__main-head-img img {
  display: block;
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
  object-fit: contain;
}

.user__main-head-title {
  margin-left: 18px;
  font-size: 24px;
  line-height: 35px;
}

@media (max-width: 1799px) {
  .user__main-head-title {
    font-size: 22px;
    line-height: 29px;
  }
}

@media (max-width: 1430px) {
  .user__main-head-img {
    width: 45px;
    height: 45px;
  }

  .user__main-head-title {
    margin-left: 16px;
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 1279px) {
  .user__main-head-img {
    width: 40px;
    height: 40px;
  }

  .user__main-head-title {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 1023px) {
  .payments-item .user__main-head {
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .user__main-head-img {
    width: 34px;
    height: 34px;
  }

  .user__main-head-title {
    margin-left: 23px;
    font-size: 20px;
    line-height: 26px;
  }

  .payments-item .user__main-head {
    padding-left: 28px;
  }
}

.user__main-body {
  margin-top: 35px;
}

@media (max-width: 1799px) {
  .user__main-body {
    margin-top: 30px;
  }
}

@media (max-width: 1430px) {
  .user__main-body {
    margin-top: 26px;
  }
}

@media (max-width: 1279px) {
  .user__main-body {
    margin-top: 27px;
  }
}

@media (max-width: 1023px) {
  .user__main-body {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .user__main-body {
    margin-top: 25px;
  }
}

.user__main-body_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.user__main-body_row .user__lang-items {
  width: 53.192%;
  border-right: 2px solid #EFF3F7;
  padding-right: 8.512%;
}

.user__main-body_row .user__lang-item {
  margin-right: 0;
  margin-bottom: calc(35px - 17px);
}

.user__main-body_row .user__lang-item .teacher-main-article__courses {
  margin-top: 20px;
}

.user__main-body_row .user__video-block .user__lang-item {
  margin-bottom: 0;
}

.user__main-body_row .user__video-block .teacher-main-article__video {
  margin-top: 20px;
}

.user__main-body_row .user__lang-cards {
  margin: 20px 0 0 -15px;
}

@media (max-width: 1799px) {
  .user__main-body_row {
    display: block;
  }

  .user__main-body_row .user__lang-items {
    width: 100%;
    border-right: none;
    padding-right: 0;
  }

  .user__main-body_row .user__lang-item {
    margin-bottom: calc(40px - 4px);
  }

  .user__main-body_row .user__lang-cards {
    margin: 15px 0 0 -4px;
  }

  .user__main-body_row .user__lang-cards .user__lang-card {
    margin-left: 4px;
    margin-bottom: 4px;
  }

  .user__main-body_row .user__video-block .teacher-main-article__video {
    margin-top: 17px;
  }
}

@media (max-width: 1279px) {
  .user__main-body_row .user__lang-item {
    margin-bottom: calc(30px - 4px);
  }

  .user__main-body_row .user__lang-cards {
    margin: 8px 0 0 -4px;
  }

  .user__main-body_row .user__video-block .teacher-main-article__video {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .user__main-body_row .user__video-block .teacher-main-article__video {
    margin-top: 10px;
  }
}

.user__lang-items:only-child {
  border-right: none;
  width: auto;
  padding-right: 0;
}

.user__video-block {
  width: calc(8.512% + 310px);
}

.user__video-block:hover .teacher-video-preview__gif {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1799px) {
  .user__video-block {
    padding-left: 0;
    width: 47.832%;
  }
}

@media (max-width: 1430px) {
  .user__video-block {
    width: 55.476%;
  }
}

@media (max-width: 1279px) {
  .user__video-block {
    width: 46.346%;
  }
}

@media (max-width: 1023px) {
  .user__video-block {
    width: 41.84%;
  }
}

@media (max-width: 767px) {
  .user__video-block {
    width: 100%;
  }
}

.user__main-body-head {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 17px;
  color: #8995AA;
}

@media (max-width: 1430px) {
  .user__main-body-head {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 1279px) {
  .user__main-body-head {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 767px) {
  .user__main-body-head {
    font-size: 14px;
    line-height: 17px;
  }
}

.user__main-body-text {
  font-weight: 500;
}

@media (max-width: 1799px) {
  .user__main-body-text.small {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 1430px) {
  .user__main-body-text.small {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: 767px) {
  .user__main-body-text.small {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .user__lang-items .user__lang-item + .user__lang-item {
    margin-top: 18px;
  }
}

@media (max-width: 1799px) {
  .user__lang-item.lang-item__main {
    margin-right: 35px;
  }
}

@media (max-width: 1430px) {
  .user__lang-item.lang-item__main {
    margin-right: 45px;
  }
}

@media (max-width: 1279px) {
  .user__lang-item.lang-item__main {
    margin-right: 55px;
  }
}

@media (max-width: 767px) {
  .user__lang-item.lang-item__main {
    margin-right: 0;
  }
}

.user__lang-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 13px 0 0 -15px;
}

@media (max-width: 1799px) {
  .user__lang-cards {
    margin: 19px 0 0 -15px;
  }
}

@media (max-width: 1430px) {
  .user__lang-cards {
    margin: 8px 0 0 -14px;
  }
}

@media (max-width: 767px) {
  .user__lang-cards {
    margin: 10px 0 0 -12px;
  }
}

.user__lang-card {
  margin-left: 15px;
  margin-bottom: 17px;
}

@media (max-width: 1799px) {
  .user__lang-card {
    margin-bottom: 22px;
  }
}

@media (max-width: 1430px) {
  .user__lang-card {
    margin-left: 14px;
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .user__lang-card {
    margin-left: 12px;
    margin-bottom: 10px;
  }
}

.lang__card {
  padding: 19px 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #EFF3F7;
  border-radius: 10px;
}

.lang__card-logo {
  overflow: hidden;
  width: 20px;
  height: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #EFF3F7;
}

.lang__card-logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.lang__card-title {
  margin-left: 13px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #0A0A0B;
}

.lang__card-remove-btn {
  margin-left: 25px;
  width: 15px;
  height: 16px;
  cursor: pointer;
}

.lang__card-remove-btn svg {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.lang__card-remove-btn svg path {
  fill: #D2DCEF;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.lang__card-remove-btn:hover svg path {
  fill: #87a1d4;
}

.lang__card.lang__card-create {
  padding: 19px 40px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.lang__card.lang__card-create .lang__card-logo {
  position: relative;
  border: none;
}

.lang__card.lang__card-create .lang__card-logo::before, .lang__card.lang__card-create .lang__card-logo::after {
  content: "";
  width: 14px;
  height: 2px;
  background-color: #4987FF;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lang__card.lang__card-create .lang__card-logo::after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.lang__card.lang__card-create .lang__card-title {
  color: #4987FF;
}

.lang__card.lang__card-create:hover {
  border-color: #4987FF;
}

@media (max-width: 1799px) {
  .lang__card {
    padding: 17px 33px;
  }

  .lang__card-remove-btn {
    margin-left: 24px;
    width: 12px;
    height: 13px;
  }

  .lang__card.lang__card-create {
    padding: 17px 25px;
  }
}

@media (max-width: 1430px) {
  .lang__card {
    padding: 19px 30px;
  }

  .lang__card-logo {
    width: 18px;
    height: 18px;
  }

  .lang__card-title {
    font-size: 14px;
    line-height: 18px;
  }

  .lang__card.lang__card-create {
    padding: 19px 25px;
  }

  .lang__card.lang__card-create::before, .lang__card.lang__card-create::after {
    width: 12px;
  }
}

@media (max-width: 1279px) {
  .lang__card {
    padding: 14px 15px;
  }

  .lang__card-logo {
    width: 17px;
    height: 17px;
  }

  .lang__card-title {
    margin-left: 11px;
    font-size: 13px;
    line-height: 17px;
  }

  .lang__card.lang__card-create {
    padding: 14px 25px;
  }
}

@media (max-width: 767px) {
  .lang__card {
    padding: 13px 20px 13px 15px;
  }

  .lang__card-logo {
    width: 16px;
    height: 16px;
  }

  .lang__card-title {
    margin-left: 10px;
    font-size: 10px;
    line-height: 14px;
  }

  .lang__card-remove-btn {
    margin-left: 16px;
    width: 11px;
    height: 12px;
  }

  .lang__card.lang__card-create {
    padding: 13px 20px;
  }

  .lang__card.lang__card-create::before, .lang__card.lang__card-create::after {
    width: 13px;
  }
}

.interests-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.interest__card {
  min-width: 110px;
  width: 110px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 15px 6px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #EFF3F7;
  border-radius: 10px;
}

.interest__card-logo {
  margin-bottom: 15px;
  max-width: 46px;
}

.interest__card-logo img {
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
}

.interest__card-title {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #0A0A0B;
  text-align: center;
  word-break: break-word;
}

.interest__card.interest__card-create {
  cursor: pointer;
  justify-content: center;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.interest__card.interest__card-create:hover {
  border-color: #4987FF;
}

.interest__card.interest__card-create .interest__card-logo {
  position: relative;
  width: 14px;
  height: 14px;
}

.interest__card.interest__card-create .interest__card-logo::before, .interest__card.interest__card-create .interest__card-logo::after {
  content: "";
  width: 14px;
  height: 2px;
  background-color: #4987FF;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.interest__card.interest__card-create .interest__card-logo::after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.interest__card.interest__card-create .interest__card-title {
  margin-top: 0;
  color: #4987FF;
  font-size: 13px;
  line-height: 17px;
  text-transform: none;
}

@media (max-width: 1023px) {
  .interest__card {
    min-width: 102px;
    padding: 18px 5px;
  }

  .interest__card-title {
    font-size: 10px;
    line-height: 12px;
  }

  .interest__card.interest__card-create .interest__card-logo {
    width: 12px;
    height: 12px;
  }

  .interest__card.interest__card-create .interest__card-title {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 767px) {
  .interest__card {
    min-width: 86px;
  }

  .interest__card-logo {
    margin-bottom: 19px;
  }

  .interest__card-title {
    font-size: 12px;
    line-height: 15px;
  }

  .interest__card.interest__card-create .interest__card-logo {
    width: 16px;
    height: 16px;
  }
}

.user__info-item + .user__info-item {
  margin-top: 40px;
}

@media (max-width: 1799px) {
  .user__info-item + .user__info-item {
    margin-top: 35px;
  }
}

@media (max-width: 1430px) {
  .user__info-item + .user__info-item {
    margin-top: 30px;
  }
}

@media (max-width: 1279px) {
  .user__info-item + .user__info-item {
    margin-top: 27px;
  }
}

@media (max-width: 1023px) {
  .user__info-item + .user__info-item {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .user__info-item + .user__info-item {
    margin-top: 40px;
  }
}

.user__main-body-text {
  margin-top: 10px;
}

/* End user-card */
/* Start Profile */
.user__main-body-item.user__personal-data-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 1023px) {
  .user__main-body-item.user__personal-data-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.user__data-fields {
  width: 57.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.user__data-fields .input-box {
  width: calc((100% - 65px) / 2);
}

@media (max-width: 1799px) {
  .user__data-fields .input-box {
    width: calc((100% - 60px) / 2);
  }
}

@media (max-width: 1430px) {
  .user__data-fields .input-box {
    width: calc((100% - 50px) / 2);
  }
}

@media (max-width: 1279px) {
  .user__data-fields .input-box {
    width: calc((100% - 23px) / 2);
  }
}

@media (max-width: 1023px) {
  .user__data-fields .input-box {
    width: calc((100% - 35px) / 2);
  }
}

@media (max-width: 767px) {
  .user__data-fields .input-box {
    width: 100%;
    margin-bottom: 30px;
  }

  .user__data-fields .input-box:last-child {
    margin-bottom: 0;
  }
}

.user__data-fields .input-box__input {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  padding-top: 10px;
}

.user__data-fields.no-edited .input-box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Montserrat";
  margin-bottom: 45px;
}

.user__data-fields.no-edited .input-box:last-child, .user__data-fields.no-edited .input-box:nth-last-child(2) {
  margin-bottom: 0;
}

.user__data-fields.no-edited .input-box .user__data-field-text-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #8995AA;
}

@media (max-width: 1430px) {
  .user__data-fields.no-edited .input-box .user__data-field-text-label {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 1279px) {
  .user__data-fields.no-edited .input-box .user__data-field-text-label {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 767px) {
  .user__data-fields.no-edited .input-box .user__data-field-text-label {
    font-size: 14px;
    line-height: 17px;
  }
}

.user__data-fields.no-edited .input-box .user__data-field-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0A0A0B;
}

@media (max-width: 1430px) {
  .user__data-fields.no-edited .input-box .user__data-field-text {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: 767px) {
  .user__data-fields.no-edited .input-box .user__data-field-text {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .user__data-fields.no-edited .input-box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media (max-width: 1799px) {
  .user__data-fields {
    width: 55.32%;
  }
}

@media (max-width: 1430px) {
  .user__data-fields {
    width: 56.69%;
  }
}

@media (max-width: 1279px) {
  .user__data-fields {
    width: 57.125%;
  }
}

@media (max-width: 1023px) {
  .user__data-fields {
    width: 81.59%;
  }
}

@media (max-width: 767px) {
  .user__data-fields {
    width: 100%;
    display: block;
  }
}

.input-with-tip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-with-tip.schedule__timezone-logo {
  width: 18px;
  height: 18px;
}

.input-box__input.react-date-picker {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.input-box__input.react-date-picker .react-date-picker__wrapper {
  border: none;
}

.input-box__input.react-date-picker .react-date-picker__inputGroup__input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-box__input.react-date-picker .react-date-picker__calendar {
  top: 130% !important;
  min-width: 360px;
  width: auto;
}

.input-box__input.react-date-picker .react-calendar {
  border: none;
  background: white;
  -webkit-box-shadow: 0px 4px 20px rgba(2, 2, 2, 0.15);
  box-shadow: 0px 4px 20px rgba(2, 2, 2, 0.15);
  border-radius: 10px;
  padding: 20px 30px;
  width: 100%;
}

.input-box__input.react-date-picker .react-calendar__navigation__label {
  font-family: "Evolventa";
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  color: #0A0A0B;
}

.input-box__input.react-date-picker .react-calendar__navigation__next-button {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.input-box__input.react-date-picker .react-calendar__navigation {
  height: auto;
}

.input-box__input.react-date-picker .react-calendar__month-view__weekdays {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #D2DCEF;
  margin-bottom: 10px;
}

.input-box__input.react-date-picker abbr[title] {
  border: none;
  text-decoration: none;
}

.input-box__input.react-date-picker .react-calendar__month-view__weekdays__weekday {
  padding: 0;
}

.input-box__input.react-date-picker .react-calendar__month-view__days__day {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #0A0A0B;
}

.input-box__input.react-date-picker .react-calendar__tile--active {
  background: #4987FF;
  color: white;
}

.input-box__input.react-date-picker .react-calendar__month-view__days__day--weekend {
  color: #FF3366;
}

.input-box__input.react-date-picker .react-calendar__tile {
  border-radius: 10px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.input-box__input.react-date-picker .react-calendar__tile--hasActive {
  background: #4987FF;
  color: white;
  border-radius: 10px;
}

.input-box__input.react-date-picker .react-date-picker__inputGroup__input:invalid {
  background: transparent;
}

.user__avatar {
  padding: 25px 35px 45px;
  width: 28.8%;
  border: 2px solid #EFF3F7;
  border-radius: 20px;
}

.user__avatar-img {
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}

.user__avatar-img img {
  border-radius: 50%;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.user__avatar-text {
  margin-top: 6px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #D2DCEF;
}

.user__avatar-text.error {
  color: #FF3366;
  font-size: 12px;
  line-height: 16px;
  color: #FF3366;
}

.user__avatar-btns {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user__avatar-btn.button.medium {
  cursor: pointer;
  width: 140px;
  height: 40px;
}

.user__avatar-btn + .user__avatar-btn {
  margin-top: 10px;
}

.user__avatar-input {
  display: none;
}

@media (max-width: 1799px) {
  .user__avatar {
    margin-top: -80px;
    width: 27.9%;
    padding: 25px 30px 40px;
  }

  .user__avatar-text {
    margin-top: 9px;
    line-height: 22px;
  }

  .user__avatar-btns {
    margin-top: 15px;
  }

  .user__avatar-btn.button.medium {
    height: 43px;
    font-size: 13px;
  }
}

@media (max-width: 1430px) {
  .user__avatar {
    margin-top: -70px;
    width: 25.49%;
    padding: 20px 15px 22px;
  }

  .user__avatar-img {
    width: 100px;
    height: 100px;
  }

  .user__avatar-text {
    margin-top: 10px;
    line-height: 18px;
  }

  .user__avatar-btns {
    margin-top: 20px;
  }

  .user__avatar-btn.button.medium {
    width: 128px;
    height: 44px;
  }
}

@media (max-width: 1279px) {
  .user__avatar {
    margin-top: -65px;
    width: 26%;
    padding: 20px 25px 20px;
  }

  .user__avatar-img {
    width: 90px;
    height: 90px;
  }

  .user__avatar-text {
    margin-top: 15px;
    font-size: 12px;
    line-height: 18px;
  }

  .user__avatar-btn.button.medium {
    width: 123px;
    height: 32px;
    font-size: 9px;
  }
}

@media (max-width: 1023px) {
  .user__avatar {
    margin-top: 0;
    margin-bottom: 33px;
    padding: 12px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
  }

  .user__avatar-img {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 auto;
    width: 150px;
    height: 150px;
  }

  .user__avatar-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 55px 0 30px;
    text-align: left;
  }

  .user__avatar-btns {
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: auto;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  .user__avatar-btn + .user__avatar-btn {
    margin-top: 0;
    margin-left: 9px;
  }
}


.input-box.password-curr {
  padding-top: 15px;
  margin-bottom: 0px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
}

.input-box.password-curr .input-box__input {
  border: none;
}

.user__change-password-triple {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 50px;
}

.user__change-password-triple .input-box {
  width: calc((100% - 60px) / 3);
  margin-bottom: 0;
  flex-direction: column;
}

.user__change-password-triple .input-box input {
  flex: 0 0 46px;
}

.user__change-password-triple .input-box__input {
  padding-top: 10px;
}

.profile__headline {
  width: 74.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile__headline-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

.profile__headline-btns li + li {
  margin-left: 40px;
  position: relative;
}

.profile__headline-btns li + li::before {
  content: "";
  width: 16px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -20px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgba(10, 10, 11, 0.3);
}

@media (max-width: 1799px) {
  .profile__headline-btns li + li {
    margin-left: 46px;
  }

  .profile__headline-btns li + li::before {
    left: -23px;
  }
}

@media (max-width: 1430px) {
  .profile__headline-btns li + li {
    margin-left: 34px;
  }

  .profile__headline-btns li + li::before {
    width: 15px;
    left: -17px;
  }
}

@media (max-width: 1279px) {
  .profile__headline-btns li + li {
    margin-left: 46px;
  }

  .profile__headline-btns li + li::before {
    width: 15px;
    left: -23px;
  }
}

@media (max-width: 1023px) {
  .profile__headline-btns {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .profile__headline-btns {
    display: none;
  }
}

.profile__headline-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0A0A0B;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.profile__headline-btn:hover {
  opacity: 0.6;
}

.profile__headline-btn.active {
  color: #4987FF;
  pointer-events: none;
}

@media (max-width: 1430px) {
  .profile__headline-btn {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1279px) {
  .profile__headline-btn {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 1799px) {
  .profile__headline {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .profile__headline {
    display: block;
  }
}

.profile-inner {
  margin-top: 25px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 1799px) {
  .profile-inner {
    margin-top: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media (max-width: 1430px) {
  .profile-inner {
    margin-top: 27px;
  }
}

@media (max-width: 1279px) {
  .profile-inner {
    margin-top: 20px;
  }
}

@media (max-width: 1023px) {
  .profile-inner {
    margin-top: 28px;
  }
}

@media (max-width: 767px) {
  .profile-inner {
    margin-top: 20px;
  }
}

.balance-box.widget-item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;
  padding: 33px 40px 40px;
  margin: 0;
  width: 23.36%;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  right: 0;
}

.widget-item .widget-buttons {
  z-index: 2;
}

.widget-item .widget-buttons a:hover {
  color: white;
}

@media (max-width: 1799px) {
  .balance-box.widget-item {
    padding: 22px 120px 22px 30px;
    margin-bottom: 22px;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    top: 0;
    right: auto;
  }

  .balance-box.widget-item .widget-label {
    margin-bottom: 0;
  }

  .balance-box.widget-item .widget-label .widget-icon {
    margin-right: 15px;
    width: 40px;
    height: 40px;
  }

  .balance-box.widget-item .widget-label .widget-name {
    font-size: 18px;
  }

  .balance-box.widget-item .widget-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .balance-box.widget-item .widget-buttons {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .balance-box.widget-item .widget-buttons .button.medium {
    height: 54px;
    min-width: 205px;
    font-size: 13px;
  }

  .balance-box.widget-item .widget-picture {
    width: 93px;
    top: -3px;
  }
}

@media (max-width: 1430px) {
  .balance-box.widget-item {
    padding: 26px 100px 26px 40px;
    margin-bottom: 20px;
  }

  .balance-box.widget-item .widget-label .widget-icon {
    width: 36px;
    height: 36px;
  }

  .balance-box.widget-item .widget-label .widget-name {
    font-size: 16px;
  }


  .balance-box.widget-item .widget-buttons .button.medium {
    height: 45px;
    min-width: 185px;
  }

  .balance-box.widget-item .widget-picture {
    width: 87px;
    top: -5px;
  }
}

@media (max-width: 1279px) {
  .balance-box.widget-item {
    padding: 24px 80px 24px 40px;
    margin-bottom: 12px;
  }

  .balance-box.widget-item .widget-label .widget-icon {
    margin-right: 12px;
    width: 30px;
    height: 30px;
  }

  .balance-box.widget-item .widget-label .widget-name {
    font-size: 14px;
  }


  .balance-box.widget-item .widget-buttons .button.medium {
    height: 32px;
    min-width: 108px;
    font-size: 9px;
  }

  .balance-box.widget-item .widget-picture {
    display: none;
  }
}

@media (max-width: 767px) {
  .balance-box.widget-item {
    flex-direction: column!important;
  }
}

.profile {
  width: 74.5%;
}

@media (max-width: 1799px) {
  .profile {
    width: 100%;
  }
}

.profile__lang-cards.user__lang-cards {
  margin-left: -9px;
}

.profile-lang__card {
  margin-left: 9px;
  margin-bottom: 9px;
}

.profile__interests-cards {
  margin-top: 19px;
  margin-left: -16px;
}

.profile__interests-cards .interest__card {
  margin-left: 16px;
}

.profile-box__btns {
  margin-top: 30px;
}

.profile-box__btn {
  cursor: pointer;
}

.profile-box__btn + .profile-box__btn {
  margin-left: 10px;
}

@media (max-width: 1430px) {
  .profile-box__btn {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .profile-box__btn {
    margin-top: 35px;
  }
}

.user__payment-item {
  padding: 25px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user__payment-item + .user__payment-item {
  border-top: 2px solid #EFF3F7;
}

.user__payment-item:last-child {
  padding-bottom: 0;
}

@media (max-width: 1799px) {
  .user__payment-item {
    padding: 28px 0;
  }
}

@media (max-width: 1279px) {
  .user__payment-item {
    padding: 23px 0;
  }
}

@media (max-width: 1023px) {
  .user__payment-item {
    display: block;
    padding: 25px 40px 27px;
  }
}

@media (max-width: 767px) {
  .user__payment-item {
    padding: 30px 28px 35px;
  }
}

.user__payment-date {
  width: 15%;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #A0B0CD;
}

@media (max-width: 1799px) {
  .user__payment-date {
    width: 17%;
  }
}

@media (max-width: 1430px) {
  .user__payment-date {
    width: 13.6%;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1279px) {
  .user__payment-date {
    width: 14.46%;
  }
}

@media (max-width: 1023px) {
  .user__payment-date {
    margin-bottom: 22px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
  }
}

.user__payment-info {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-family: "Montserrat";
}

.user__payment-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #0A0A0B;
}

@media (max-width: 1430px) {
  .user__payment-title {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 1023px) {
  .user__payment-title {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 1023px) {
  .user__payment-subtitle {
    margin-top: 4px;
  }
}

.user__payment-subtitle-item {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #A0B0CD;
}

.user__payment-subtitle-item + .user__payment-subtitle-item {
  margin-left: 5px;
}

@media (max-width: 1430px) {
  .user__payment-subtitle-item {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 1023px) {
  .user__payment-subtitle-item {
    font-size: 14px;
  }
}

.user__payment-coast {
  width: 17%;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #0A0A0B;
}

@media (max-width: 1430px) {
  .user__payment-coast {
    font-size: 22px;
    line-height: 29px;
  }
}

@media (max-width: 1023px) {
  .user__payment-coast {
    margin-top: 15px;
    width: 100%;
    font-size: 24px;
    line-height: 32px;
  }
}

.user__payment-status {
  padding: 8px;
  width: 115px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 66px;
}

.user__payment-status.status-success {
  background: rgba(73, 135, 255, 0.13);
  color: #4987FF;
}

.user__payment-status.status-error {
  background: rgba(255, 51, 102, 0.1);
  color: #FF3366;
}

@media (max-width: 1279px) {
  .user__payment-status {
    padding: 10px;
    width: 97px;
    font-size: 9px;
    line-height: 11px;
  }
}

@media (max-width: 1023px) {
  .user__payment-status {
    margin-top: 20px;
    padding: 8px;
    width: 115px;
    font-size: 12px;
    line-height: 16px;
  }
}

.pp-profile-delete__content {
  max-width: 445px;
  width: 100%;
  padding: 60px 52px 52px;
}

.pp-profile-delete__content .pp__close {
  top: 25px;
  right: 30px;
}

.pp-profile-delete__logo {
  width: 58px;
  height: 58px;
  margin: 0 auto;
}

.pp-profile-delete__logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.pp-profile-delete__title {
  margin-top: 30px;
  text-align: center;
}

.pp-profile-delete__text {
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.pp-profile-delete__btns {
  display: block;
}

.pp-profile-delete__btns .button {
  width: 100%;
}

.pp-profile-delete__btns .button + button {
  margin-top: 18px;
}

.pp-profile-delete__btns {
  display: block;
}

.pp-profile-change__body .pp-profile-delete__title {
  margin-top: 0;
}

.pp-profile-change__body .pp-profile-delete__logo {
  width: 142px;
  height: 77px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pp__content.pp-profile-interest__content,
.pp__content.pp-profile-lang__content {
  padding: 45px 60px;
  width: 810px;
}

.pp__content.pp-profile-interest__content .pp__close,
.pp__content.pp-profile-lang__content .pp__close {
  top: 22px;
  right: 22px;
}

@media (max-width: 1430px) {
  .pp__content.pp-profile-interest__content .pp__close,
  .pp__content.pp-profile-lang__content .pp__close {
    width: 25px;
    height: 25px;
    top: 25px;
    right: 25px;
  }
}

@media (max-width: 1023px) {
  .pp__content.pp-profile-interest__content,
  .pp__content.pp-profile-lang__content {
    width: 680px;
  }

  .pp__content.pp-profile-interest__content .pp__close,
  .pp__content.pp-profile-lang__content .pp__close {
    width: 18px;
    height: 18px;
    top: 20px;
    right: 22px;
  }
}

@media (max-width: 1799px) {
  .pp__content.pp-profile-lang__content {
    padding: 40px 45px 45px;
  }
}

@media (max-width: 1430px) {
  .pp__content.pp-profile-lang__content {
    padding: 40px 50px 40px;
  }
}

@media (max-width: 1279px) {
  .pp__content.pp-profile-lang__content {
    padding: 40px 135px 45px;
  }
}

@media (max-width: 1023px) {
  .pp__content.pp-profile-lang__content {
    padding: 30px 108px 35px;
  }
}

@media (max-width: 767px) {
  .pp__content.pp-profile-lang__content {
    padding: 40px 45px 40px;
  }
}

.pp__lang-cards.user__lang-cards {
  margin-top: 0;
  margin-left: -25px;
}

@media (max-width: 1279px) {
  .pp__lang-cards.user__lang-cards {
    margin-left: -14px;
  }
}

@media (max-width: 767px) {
  .pp__lang-cards.user__lang-cards {
    margin-left: 0;
    display: block;
  }
}

.lang__card.pp-lang__card {
  margin-bottom: 12px;
  margin-left: 25px;
  width: calc((100% - 75px) / 3);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 1799px) {
  .lang__card.pp-lang__card {
    margin-bottom: 14px;
  }
}

@media (max-width: 1430px) {
  .lang__card.pp-lang__card {
    margin-bottom: 12px;
  }
}

@media (max-width: 1279px) {
  .lang__card.pp-lang__card {
    margin-bottom: 13px;
    margin-left: 14px;
    width: calc((100% - 42px) / 3);
  }
}

@media (max-width: 767px) {
  .lang__card.pp-lang__card {
    margin-left: 0;
    width: 100%;
  }

  .lang__card.pp-lang__card + .lang__card.pp-lang__card {
    margin-top: 16px;
  }
}

.pp-lang__card:hover,
.pp-interest__card:hover {
  border-color: #bccdde;
}

.pp-lang__card.active,
.pp-interest__card.active {
  border-color: #0A0A0B;
}

@media (max-width: 1799px) {
  .pp__content.pp-profile-interest__content {
    padding: 40px 60px 45px;
  }
}

@media (max-width: 1430px) {
  .pp__content.pp-profile-interest__content {
    padding: 40px 70px 35px;
  }
}

@media (max-width: 1023px) {
  .pp__content.pp-profile-interest__content {
    padding: 30px 40px 35px;
  }
}

@media (max-width: 767px) {
  .pp__content.pp-profile-interest__content {
    padding: 40px 20px 40px;
  }
}

.interests-cards.pp__interests-cards {
  margin-left: -16px;
}

@media (max-width: 1430px) {
  .interests-cards.pp__interests-cards {
    margin-left: -12px;
  }
}

@media (max-width: 767px) {
  .interests-cards.pp__interests-cards {
    margin-left: -11px;
  }
}

.interest__card.pp-interest__card {
  margin-left: 16px;
  margin-bottom: 25px;
  width: calc((100% - 80px) / 5);
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 1430px) {
  .interest__card.pp-interest__card {
    margin-left: 12px;
    margin-bottom: 11px;
    width: calc((100% - 60px) / 5);
  }
}

@media (max-width: 767px) {
  .interest__card.pp-interest__card {
    margin-left: 11px;
    margin-bottom: 14px;
    width: calc((100% - 33px) / 3);
  }
}

.pp-buttons.pp-profile-interest__btns {
  margin-top: 10px;
}

@media (max-width: 1023px) {
  .pp-buttons.pp-profile-interest__btns {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .pp-buttons.pp-profile-interest__btns {
    margin-top: 20px;
  }
}

/* End Profile */
/* Start Schedule */
.schedule__wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 1279px) {
  .schedule__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.schedule-aside {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  right: 0;
  width: 29.82%;
}

@media (max-width: 1799px) {
  .schedule-aside {
    width: 29.9227799%;
  }
}

@media (max-width: 1430px) {
  .schedule-aside {
    width: 28.954%;
  }
}

@media (max-width: 1279px) {
  .schedule-aside {
    width: 100%;
    position: relative;
    top: 0;
    right: initial;
    margin-bottom: 30px;
  }
}

.schedule-aside__card .widget-teacher {
  padding-top: 22px;
  border-top: 2px solid #EFF3F7;
}

@media (max-width: 1799px) {
  .schedule-aside__card .widget-name {
    font-size: 14px;
  }

  .schedule-aside__card .widget-info__title {
    font-size: 18px;
  }

  .schedule-aside__card .widget-info__time {
    font-size: 14px;
    line-height: 1;
    margin-right: 14px;
  }

  .schedule-aside__card .widget-info__duration {
    font-size: 10px;
  }

  .schedule-aside__card .widget-info__duration::before {
    width: 14px;
    height: 14px;
  }

  .schedule-aside__card .widget-info {
    margin-bottom: 15px;
  }

  .schedule-aside__card .widget-teacher {
    padding-top: 15px;
  }

  .schedule-aside__card .widget-picture {
    width: 35%;
  }

  .schedule-aside__card .widget-teacher__photo {
    width: 56px;
    height: 56px;
    margin-right: 30px;
  }

  .schedule-aside__card .widget-teacher__envelop {
    width: 30px;
    height: 30px;
    background-size: 60%;
    right: -25%;
    bottom: 5%;
  }

  .schedule-aside__card .widget-teacher__info {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .schedule-aside__card .widget-teacher__name {
    font-size: 14px;
  }

  .schedule-aside__card .widget-teacher__country {
    font-size: 12px;
  }

  .schedule-aside__card .widget-teacher__status {
    font-size: 12px;
  }
}

.schedule-aside__card-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1279px) {
  .schedule-aside__card .schedule-aside__card-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .schedule-aside__card .widget-picture {
    width: 15%;
  }

  .schedule-aside__card .schedule-aside__card-item {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 40.4545455% 1fr;
    grid-template-columns: 40.4545455% 1fr;
    grid-column-gap: 4.77272727%;
  }

  .schedule-aside__card .widget-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .schedule-aside__card .widget-buttons .button:not(:only-child) {
    margin-right: 8px;
    margin-bottom: 0;
  }

  .schedule-aside__card .widget-buttons .button:not(:only-child):first-child {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .schedule-aside__card .widget-buttons .button:not(:only-child):last-child {
    margin-right: 0;
    margin-bottom: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }

  .schedule-aside__card .widget-teacher {
    padding: 0 0 0 35px;
    margin: 0;
    border-top: none;
    border-left: 2px solid #EFF3F7;
  }

  .schedule-aside__card .widget-teacher__photo {
    width: 60px;
    height: 60px;
    margin-right: 50px;
  }
}

@media (max-width: 1023px) {
  .schedule-aside__card .schedule-aside__card-item {
    -ms-grid-columns: 28.7539936% 1fr;
    grid-template-columns: 28.7539936% 1fr;
    grid-column-gap: 9.58466454%;
    grid-row-gap: 15px;
  }

  .schedule-aside__card .widget-buttons {
    grid-column: 2 / -1;
    padding-left: 40px;
  }

  .schedule-aside__card .widget-info {
    margin-bottom: 0;
  }

  .schedule-aside__card .widget-info__title {
    font-size: 21px;
  }
}

@media (max-width: 767px) {
  .schedule-aside__card .schedule-aside__card-item {
    display: block;
  }

  .schedule-aside__card .widget-info__title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .schedule-aside__card .widget-teacher__name {
    font-size: 16px;
  }

  .schedule-aside__card .widget-picture {
    width: 40%;
  }

  .schedule-aside__card .widget-teacher {
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
    border-top: 1px solid #EFF3F7;
    border-left: none;
  }

  .schedule-aside__card .widget-teacher__photo {
    margin-right: 30px;
  }

  .schedule-aside__card .widget-buttons {
    padding-left: 0;
    margin-top: 24px;
  }

  .schedule-aside__card .widget-buttons .button:not(:only-child) {
    width: auto;
    padding: 0;
    height: 40px;
  }
}

.schedule-aside__card.schedule-aside__card-teacher {
  overflow: hidden;
}

.schedule-aside__card.schedule-aside__card-teacher .widget-teacher {
  padding-top: 0;
  border-top: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedule-aside__card.schedule-aside__card-teacher + .schedule-aside__card.schedule-aside__card-teacher {
  margin-top: 16px;
}

.schedule-aside__card.schedule-aside__card-teacher .widget-teacher__text {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #0A0A0B;
}

.schedule-aside__card.schedule-aside__card-teacher .widget-buttons .button {
  width: 100%;
}

.schedule-aside__card.schedule-aside__card-teacher .widget-picture {
  width: 230px;
  height: 230px;
  top: auto;
  bottom: -19px;
  right: -42px;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 1799px) {
  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__text {
    font-size: 15px;
    line-height: 19px;
  }
}

@media (max-width: 1430px) {
  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__text {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 1279px) {
  .schedule-aside__card.schedule-aside__card-teacher .schedule-aside__card-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-gap: 0;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher {
    padding-left: 0;
    border-left: none;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__photo {
    margin-right: 25px;
    width: 80px;
    height: 80px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__name {
    font-size: 16px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__country {
    font-size: 14px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-buttons {
    margin-top: 0;
    margin-left: auto;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-buttons .button {
    width: 221px;
    height: 44px;
    font-size: 12px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-picture {
    width: 84px;
    height: 84px;
    right: 0;
  }
}

@media (max-width: 1023px) {
  .schedule-aside__card.schedule-aside__card-teacher .widget-buttons .button {
    width: 183px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-picture {
    width: 70px;
    height: 70px;
    right: 0;
    bottom: -10px;
  }
}

@media (max-width: 767px) {
  .schedule-aside__card.schedule-aside__card-teacher .schedule-aside__card-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher {
    margin-top: 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__photo {
    margin-right: 25px;
    width: 80px;
    height: 80px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__name {
    font-size: 16px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-teacher__country {
    font-size: 14px;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-buttons {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-buttons .button {
    width: 100%;
  }

  .schedule-aside__card.schedule-aside__card-teacher .widget-picture {
    width: 84px;
    height: 84px;
    right: 0;
  }
}

.widget-item.schedule-aside__card {
  width: 100%;
}

@media (max-width: 1430px) {
  .widget-item.schedule-aside__card {
    padding: 20px 26px;
  }
}

@media (max-width: 1279px) {
  .widget-item.schedule-aside__card {
    padding: 20px 40px;
  }
}

@media (max-width: 1023px) {
  .widget-item.schedule-aside__card {
    padding: 17px 26px 20px;
  }
}

.widget-item__empty-text {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #050425;
}

.teacher__lesson-duration {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(210, 220, 239, 0.4);
  border-radius: 20px;
}

.lesson-duration__logo {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.lesson-duration__logo img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.lesson-duration__text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8995AA;
}

.schedule-aside__footer.teacher__lesson-duration {
  margin-top: 25px;
}

@media (max-width: 1799px) {
  .schedule-aside__footer.teacher__lesson-duration {
    border-radius: 12px;
    margin-top: 15px;
  }

  .schedule-aside__footer .lesson-duration__text {
    font-size: 12px;
  }

  .schedule-aside__footer .lesson-duration__logo {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1430px) {
  .schedule-aside__footer.teacher__lesson-duration {
    display: block;
    padding: 15px 40px;
    text-align: center;
  }

  .schedule-aside__footer .lesson-duration__logo {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-bottom: -3px;
  }

  .schedule-aside__footer .lesson-duration__logo img {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .schedule-aside__footer .lesson-duration__text {
    display: inline;
    font-size: 12px;
    line-height: 1;
  }
}

@media (max-width: 1279px) {
  .schedule-aside__footer.teacher__lesson-duration {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 13px 20px;
    text-align: center;
    border-radius: 18px;
  }
}

@media (max-width: 767px) {
  .schedule-aside__footer .teacher__lesson-duration {
    padding: 14px 20px;
  }

  .schedule-aside__footer .lesson-duration__text {
    font-size: 14px;
  }

  .schedule-aside__footer .lesson-duration__logo {
    display: none;
  }
}

.schedule {
  width: 68.18%;
}

.schedule h1 {
  margin-bottom: 3px!important;
}

@media (max-width: 1799px) {
  .schedule {
    width: 68.1467181%;
  }
}

@media (max-width: 1430px) {
  .schedule {
    width: 67.138%;
  }
}

@media (max-width: 1279px) {
  .schedule {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .schedule {
    position: relative;
  }
}

.schedule__head {
  margin-bottom: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedule__head-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedule__head-val {
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  color: #0A0A0B;
}

@media (max-width: 1799px) {
  .schedule__head-val {
    font-size: 14px;
  }
}

.schedule__head-btn {
  margin-left: 30px;
}

@media (max-width: 767px) {
  .schedule__head {
    display: block;
  }
}

@media (max-width: 767px) {
  .schedule__head-btn.button.medium {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .schedule__title {
    padding-bottom: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid #e7e7e7;
  }
}

.schedule__table {
  background: white;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 1430px) {
  .schedule__table {
    border-radius: 16px;
  }
}

.schedule__table-head {
  padding: 25px 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1799px) {
  .schedule__table-head {
    padding: 15px 20px;
  }
}

@media (max-width: 1279px) {
  .schedule__table-head {
    padding: 20px 40px;
  }
}

@media (max-width: 1023px) {
  .schedule__table-head {
    padding: 15px 20px;
  }
}

@media (max-width: 767px) {
  .schedule__table-head {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 50px;
  }
}

.schedule__datepicker {
  cursor: pointer;
}

.schedule__datepicker .select-box {
  width: auto;
  height: auto;
}

.schedule__datepicker .select-box .select__placeholder {
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #8995AA;
  width: auto;
  cursor: pointer;
}

@media (max-width: 1799px) {
  .schedule__datepicker .select-box .select__placeholder {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .schedule__datepicker .select-box .select__placeholder {
    font-size: 18px;
  }
}

.schedule__datepicker .select-box .select__single-value {
  position: relative;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #8995AA;
  width: auto;
  cursor: pointer;
  max-width: 100%;
  top: initial;
  -o-text-overflow: unset;
  text-overflow: unset;
}

@media (max-width: 1799px) {
  .schedule__datepicker .select-box .select__single-value {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .schedule__datepicker .select-box .select__single-value {
    font-size: 18px;
  }
}

.schedule__datepicker .select-box .select__control {
  border-bottom: none;
  cursor: pointer;
}

.schedule__datepicker .select-box .select__dropdown-indicator svg {
  fill: #8995aa;
}

@media (max-width: 1799px) {
  .schedule__datepicker .select-box .select__dropdown-indicator svg {
    width: 15px;
    height: 9px;
  }
}

@media (max-width: 767px) {
  .schedule__datepicker .select-box .select__dropdown-indicator svg {
    width: 20px;
    height: auto;
  }
}

.schedule__datepicker .select-box .select__option {
  font-family: "Evolventa";
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid #EFF3F7;
  cursor: pointer;
}

@media (max-width: 1799px) {
  .schedule__datepicker .select-box .select__option {
    font-size: 14px;
    line-height: 1;
  }
}

.schedule__datepicker .select-box .select__option:last-child {
  border-bottom: none;
}

.schedule__datepicker .select__menu {
  width: auto;
  white-space: nowrap;
  left: -26px;
  border: none;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 3;
  -webkit-animation: show-drop .3s ease 0s;
  animation: show-drop .3s ease 0s;
}

.schedule__timezone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedule__timezone-val {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8995AA;
}

.schedule__timezone-logo {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.schedule__timezone-logo:hover {
  opacity: 0.6;
}

.schedule__timezone-logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 1799px) {
  .schedule__timezone-logo {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 1430px) {
  .schedule__timezone-val {
    font-size: 11px;
    line-height: 13px;
  }
}

@media (max-width: 1279px) {
  .schedule__timezone-val {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1023px) {
  .schedule__timezone-val {
    font-size: 12px;
    line-height: 14px;
  }

  .schedule__timezone-logo {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 767px) {
  .schedule__timezone {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 3;
  }

  .schedule__timezone-val {
    font-size: 18px;
    line-height: 23px;
  }

  .schedule__timezone-logo {
    margin-left: 8px;
    width: 28px;
    height: 28px;
  }
}

.schedule__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: initial !important;
}

.schedule__time .preloader {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
}

.schedule__time-col {
  width: calc(100% / 7);
}

.schedule__time-col.active .schedule__days-item {
  color: #4987FF;
}

.schedule__time-col.active .schedule__days-item::before {
  height: 4px;
}

.schedule__time-col.active .schedule__days-item::before {
  height: 4px;
}

.schedule__time-col.active .schedule__time-items {
  background: rgba(73, 135, 255, 0.05);
}

.cursor-p {
  cursor: pointer;
}

.schedule__time-col:not(:first-child) .schedule__days-item {
  border-left: 2px solid white;
}

.schedule__days-item {
  border-top: 2px solid #EFF3F7;
  border-bottom: 2px solid #EFF3F7;
  text-transform: uppercase;
  position: relative;
  padding: 35px 29px;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.schedule__days-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #4987FF;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 1799px) {
  .schedule__days-item {
    padding: 25px 10px;
    font-size: 16px;
  }
}

@media (max-width: 1279px) {
  .schedule__days-item {
    padding: 23px 10px;
    font-size: 18px;
    line-height: 1;
  }
}

@media (max-width: 1023px) {
  .schedule__days-item {
    padding: 15px 10px;
    font-size: 14px;
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .schedule__days-item {
    margin: auto;
    border: none;
    height: 32px;
    font-size: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.schedule__time-item {
  padding: 5px 7px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  color: rgba(137, 149, 170, 0.4);
  position: relative;
  /*pointer-events: none;*/
  cursor: default;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-animation: showCell .3s ease 0s 1;
  animation: showCell .3s ease 0s 1;
  display: flex;
}

.schedule__time__buttons-item > div:not(:last-child) {
  margin-right: 4px;
}
.schedule__time__buttons-item {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
}

.workslot .schedule__time__buttons-item {

}

.schedule__time__button-item {
  display: block;
  cursor: pointer;
}

.schedule__time__button-item img {
  height: 15px;
}

.schedule__icon_break {
  font-size: 14px;
  color: #79849e;
}

.schedule__time-item-name {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin-top: 14px;
}

@media (max-width: 1799px) {
  .schedule__time-item {
    width: auto;
    height: 46px;
    border-width: 1px !important;
  }
}

@media (max-width: 1430px) {
  .schedule__time-item {
    width: auto;
    height: 46px;
  }
}

@media (max-width: 1279px) {
  .schedule__time-item {
    width: auto;
    height: 46px;
  }
}

@media (max-width: 767px) {
  .schedule__time-item {
    width: auto;
    height: 60px!important;
    font-size: 18px;
    margin-right: 5px;
  }

  .schedule__time-item-text {
    display: flex !important;
  }

  .schedule__time-item-text > div {
    line-height: 18px;
  }

  .schedule__time-item-text > span {
    margin-right: 5px;
    line-height: 18px;
  }
}

.schedule__time-item + .schedule__time-item {
  border-top: 2px solid #EFF3F7;
}

.schedule__time-item.past {
  font-weight: 600;
  background-color: rgba(239, 243, 247, 0.6);
  color: rgba(10, 10, 11, 0.3);
}

.schedule__time-item.past-lesson {
  font-weight: 600;
  background-color: rgba(47, 128, 237, 0.4);
  color: rgba(10, 10, 11, 0.3);
}

.schedule__time-item.past-lesson-trial {
  font-weight: 600;
  background-color: #C8A3D7;
  color: rgba(10, 10, 11, 0.3);
}

.schedule__time-item.available {
  pointer-events: initial;
  cursor: pointer;
  color: #79849e;
}

.schedule__time-item.available:hover {
  background: #DBE6FF;
  color: #79849e;
}

.schedule__time-item.workslot {
  font-weight: 600;
  pointer-events: initial;
  cursor: default;
  background: #DBE6FF;
  color: #79849e;
}

.schedule__time-item.isCanceled {
  pointer-events: initial;
  cursor: default;
  font-weight: bold;
  background: #ffdbdb;
}

.schedule__time-item.finished {
  pointer-events: initial;
  cursor: default;
  font-weight: bold;
  background: #b9d1ff;
}

.schedule__time-item.workslot:hover {
  background: #EAEFFF;
}

.schedule__time-item.assigned {
  pointer-events: initial;
  cursor: pointer;
  font-weight: bold;
  background-color: rgba(73, 135, 255, 0.2);
}

.schedule__time-item.assigned:hover {
  background-color: #D2DCEF;
}

.schedule__time-item.isMine {
  pointer-events: initial;
  cursor: pointer;
  font-weight: bold;
  background-color: #4987FF;
  color: white;
}

.schedule__time-item.isMine:hover {
  background-color: #1B5DDC;
}

.schedule__time-item.isMineTrial {
  pointer-events: initial;
  cursor: pointer;
  font-weight: bold;
  background-color: #BB6BD9;
  color: white;
}

.schedule__time-item.isMineTrial:hover {
  background-color: #aa62c5;
}

.schedule__time-item.isBrake {
  pointer-events: initial;
  cursor: pointer;
  font-weight: bold;
  background-color: #43D398;
  color: white;
}

.schedule__time-item.free {
  pointer-events: initial;
  cursor: pointer;
  background-color: #DBE6FF;
  color: #8995AA;
}

.schedule__time-item.free:hover {
  background-color: #EAEFFF;
}

@-webkit-keyframes showCell {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes showCell {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.schedule__time-col + .schedule__time-col .schedule__time-item {
  border-left: 2px solid #EFF3F7;
}

.schedule__empty {
  padding: 90px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedule__empty-logo {
  width: 28.6%;
}

.schedule__empty-logo img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.schedule__empty-title {
  margin-top: 30px;
  text-align: center;
}

.schedule__empty-text {
  max-width: 422px;
  width: 100%;
  margin-top: 15px;
  text-align: center;
}

.schedule__time-nav {
  height: 77px;
  position: absolute;
  top: -100px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 767px) {
  .schedule__time-nav {
    height: 45px;
  }
}

.schedule__time-nav .swiper-button:after {
  content: none;
  font-size: 28px;
}

.schedule__time-nav {
  top: -50px;
}

.schedule__time-nav .swiper-button {
  width: 25%;
  background-size: 8px;
  background: no-repeat center;
  top: 0;
  height: 50px;
  margin-top: 0;
}

.schedule__time-nav .swiper-button-prev {
  background-image: url(/svg/schedule-left.svg);
}

.schedule__time-nav .swiper-button-next {
  background-image: url(/svg/schedule-right.svg);
}

.pp__content.pp-schedule__content {
  padding: 45px;
  width: 445px;
  min-height: 485px;
}

.pp__content.pp-schedule__content .pp__close {
  top: 25px;
  right: 32px;
}

.pp-lesson__suptitle {
  margin-top: 10px;
  margin-bottom: 17px;
  text-transform: uppercase;
  text-align: center;
}

.pp-lesson__title {
  text-align: center;
  text-transform: capitalize;
}

.pp-lesson__time {
  margin-top: 10px;
  text-align: center;
}

.pp-lesson__duration {
  font-family: "Montserrat";
  margin-top: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8995AA;
}

.pp-lesson__duration .widget-info__duration {
  margin-left: 10px;
  -webkit-box-flex: initial;
  -ms-flex: initial;
  flex: initial;
}

.pp-lesson__btns {
  margin-top: 55px;
}

.pp-lesson__btns .button {
  width: 100%;
}

.pp-lesson__footer {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #8995AA;
  text-align: center;
}

.pp-lesson__footer-link {
  margin-top: 20px;
  text-decoration: underline;
}

.pp-lesson-un-assign__headline {
  padding-bottom: 22px;
  border-bottom: 2px solid #EFF3F7;
}

.pp-lesson-un-assign__headline-text {
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #0A0A0B;
  text-align: center;
}

.pp-lesson-un-assign__cont-text {
  margin-top: 22px;
  font-weight: "Montserrat";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0A0A0B;
}

.pp-lesson-un-assign__body .pp-lesson__title {
  margin-top: 12px;
}

.pp-lesson-un-assign__body .pp-lesson__btns {
  margin-top: 27px;
}

.lesson-assign__empty-logo {
  margin-bottom: 10px;
  width: 100%;
}

.lesson-assign__empty-logo img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.lesson-assign__empty-title {
  text-align: center;
}

.lesson-assign__empty-btns {
  margin-top: 30px;
}

.lesson-assign__empty-btns .button {
  width: 100%;
}

/* End Schedule */
/* Start Stream */
.stream {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 0;
  height: 100%;
  background-color: #050425;
}

.stream__item {
  width: 60%;
}

@media (max-width: 767px) {
  .stream__item {
    width: 100%;
    height: 50%;
  }
}

.stream .messages-list__wrapper {
  background-color: white;
  width: 100%;
  height: calc(100% - 80px);
  border-radius: 20px;
}

@media (max-width: 1799px) {
  .stream .messages-list__wrapper {
    height: calc(100% - 60px);
  }
}

@media (max-width: 1430px) {
  .stream .messages-list__wrapper {
    height: calc(100% - 56px);
  }
}

@media (max-width: 1279px) {
  .stream .messages-list__wrapper {
    min-height: 350px;
    height: calc(50vh - 14px);
    width: 100%;
    -webkit-box-flex: initial;
    -ms-flex: initial;
    flex: initial;
  }
}

@media (max-width: 1023px) {
  .stream .stream__messages-head.messages-list__head {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .stream .stream__messages-head.messages-list__head {
    padding: 10px 20px;
  }
}

.stream .preloader-progress::before {
  background-color: white;
}

@media (max-width: 1279px) {
  .stream {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    top: 0;
    right: 0;
    left: 0;
  }
}

.stream__dialog {
  width: 40%;
  padding: 0px 20px 20px 0;
}

@media (max-width: 1279px) {
  .stream__dialog {
    width: 100%;
  }
}

@media (max-width: 1279px) {
  .stream__dialog {
    padding: 0 14px 14px;
  }
}

@media (max-width: 767px) {
  .stream__dialog {
    padding: 14px 14px 14px;
  }
}

.stream__dialog-head {
  height: 80px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  z-index: 9999;
}

@media (max-width: 1799px) {
  .stream__dialog-head {
    height: 60px;
  }
}

@media (max-width: 1430px) {
  .stream__dialog-head {
    height: 56px;
  }
}

@media (max-width: 1279px) {
  .stream__dialog-head {
    position: absolute;
    right: 20px;
    top: 25px;
  }
}

@media (max-width: 767px) {
  .stream__dialog-head {
    right: 20px;
    height: auto;
    top: 16px;
  }
}

.stream__pip {
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  margin-right: 27px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  position: relative;
  color: white;
}

.stream__pip:last-child {
  margin-right: 0;
}

.stream__pip::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 45px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 767px) {
  .stream__pip::before {
    display: none;
  }
}

.stream__pip > {
  position: relative;
  z-index: 1;
}

.stream__pip > span {
  opacity: 0;
  visibility: hidden;
  width: 0;
}

@media (max-width: 767px) {
  .stream__pip > span {
    display: none;
  }
}

.stream__pip > img {
  margin-left: 0;
}

@media (max-width: 1799px) {
  .stream__pip > img {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 1279px) {
  .stream__pip > img {
    width: auto;
    height: auto;
  }
}

@media (max-width: 767px) {
  .stream__pip > img {
    margin: 0;
    width: 22px;
    height: 22px;
  }
}

@media (min-width: 1024px) {
  .stream__pip:hover {
    padding: 0 23px;
  }

  .stream__pip:hover::before {
    visibility: visible;
    opacity: 1;
  }

  .stream__pip:hover img {
    margin-left: 12px;
  }

  .stream__pip:hover span {
    visibility: visible;
    opacity: 1;
    width: auto;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
  }
}

@media (max-width: 1799px) {
  .stream__pip {
    height: 40px;
    margin-right: 20px;
  }
}

@media (max-width: 1430px) {
  .stream__pip {
    height: 38px;
  }
}

@media (max-width: 1279px) {
  .stream__pip {
    height: 48px;
  }
}

@media (max-width: 767px) {
  .stream__pip {
    height: auto;
    margin-right: 15px;
  }
}

.stream__messages-close-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.stream__messages-close-btn:hover {
  opacity: 0.6;
}

.stream__messages-close-btn .close__lane {
  position: absolute;
  margin: 0%;
  height: 3px;
  background-color: white;
}

@media (max-width: 1799px) {
  .stream__messages-close-btn {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 1279px) {
  .stream__messages-close-btn {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .stream__messages-close-btn {
    width: 22px;
    height: 22px;
    z-index: 10;
  }
}

.stream__window {
  position: relative;
  overflow: hidden;
}

.stream__window .fullscreen {
  height: 100%;
  width: 100%;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.stream__window.online {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.stream__window .fullscreen.offline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stream__window.offline .stream__window-logo {
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 50px;
}

@media (max-width: 767px) {
  .stream__window.offline .stream__window-logo {
    top: 17px;
    left: 20px;
  }
}

.stream__window::before, .stream__window::after {
  content: "";
  width: 100%;
  height: 22.5%;
  position: absolute;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.stream__window::before {
  top: 0;
  background-position: bottom center;
  background-image: url("/svg/stream-offline-top-logo.png");
}

.stream__window::after {
  bottom: 0;
  background-position: top center;
  background-image: url("/svg/stream-offline-bottom-logo.png");
}

.stream__window .logo {
  width: 200px;
}

@media (max-width: 767px) {
  .stream__window .logo {
    width: 144px;
  }
}

.stream__window-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stream__window-body .stream__window-body-title {
  text-align: center;
}

.stream__window-body-logo {
  margin: 0 auto 65px;
  width: 148px;
}

.stream__window-body-logo img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 767px) {
  .stream__window-body-logo {
    width: 80px;
    margin-bottom: 30px;
  }
}

.stream__window-body-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 43px;
  color: white;
}

@media (max-width: 1799px) {
  .stream__window-body-title {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 1430px) {
  .stream__window-body-title {
    font-size: 22px;
    line-height: 29px;
  }
}

@media (max-width: 767px) {
  .stream__window-body-title {
    max-width: 70%;
    text-align: center;
    margin: 0 auto;
  }
}

.stream__window-body-time-info {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: white;
  flex-direction: column;
}

@media (max-width: 1799px) {
  .stream__window-body-time-info {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .stream__window-body-time-info {
    font-size: 16px;
    line-height: 20px;
  }
}

.stream__window-body-date {
  padding-right: 25px;
  border-right: 1px solid white;
  text-transform: capitalize;
}

.stream__window-body-time {
  margin-left: 25px;
}

.stream__window-body-btns {
  margin-top: 50px;
  text-align: center;
}

@media (max-width: 767px) {
  .stream__window-body-btns {
    margin-top: 30px;
  }
}

.stream__window-body-btn {
  background-color: white;
  color: #0A0A0B;
  font-size: 13px;
  line-height: 29px;
}

.stream__window-body-btn:hover {
  background-color: white;
  opacity: 0.6;
}

.stream__window-body-btn img {
  display: inline-block;
  margin-right: 11px;
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 1023px) {
  .stream__window-body-btn {
    padding: 0 30px;
  }
}

@media (max-width: 767px) {
  .stream__window-body {
    margin-top: 90px;
    padding-bottom: 30px;
  }
}

.stream__window-bg {
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  height: 100%;
}

.stream__window-bg > div {
  height: 100%;
}

.stream__window-bg.active {
  opacity: 1;
  visibility: visible;
}

.stream__window-bg video {
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (max-width: 1279px) {
  .stream__window-bg {
    width: 80%;
    padding-bottom: calc(80% / 16 * 9);
  }
}

@media (max-width: 767px) {
  .stream__window-bg {
    width: 100%;
    padding-bottom: calc(100% / 16 * 9);
  }
}

.stream__window:-webkit-full-screen .stream__window-bg {
  width: 100%;
  padding-bottom: calc((100% / 16 * 9) - 100px);
  bottom: initial;
}

.stream__window:-ms-fullscreen .stream__window-bg {
  width: 100%;
  padding-bottom: calc((100% / 16 * 9) - 100px);
  bottom: initial;
}

.stream__window:-moz-full-screen .stream__window-bg {
  width: 100%;
  padding-bottom: calc((100% / 16 * 9) - 100px);
  bottom: initial;
}

.stream__window:fullscreen .stream__window-bg {
  width: 100%;
  padding-bottom: calc((100% / 16 * 9) - 100px);
  bottom: initial;
}

.stream__window-head, .stream__window-body, .stream__window-footer {
  position: relative;
  z-index: 2;
}

.stream__window-head {
  padding: 42px 50px;
  height: 35%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.stream__window-head::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(10.35%, #141313), color-stop(96.68%, rgba(21, 20, 20, 0)));
  background: -webkit-linear-gradient(top, #141313 10.35%, rgba(21, 20, 20, 0) 96.68%);
  background: -o-linear-gradient(top, #141313 10.35%, rgba(21, 20, 20, 0) 96.68%);
  background: linear-gradient(180deg, #141313 10.35%, rgba(21, 20, 20, 0) 96.68%);
  opacity: 0.6;
}

.stream__window-head .stream__duration {
  margin-top: 15px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: white;
}

@media (max-width: 767px) {
  .stream__window-head .stream__duration {
    margin-top: 5px;
  }
}

.stream__window-head .stream__window-user-logo {
  width: 180px;
  height: 100px;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.stream__window-head .stream__window-user-logo:empty {
  background-image: url(/svg/cat-avatar.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.stream__window-head .stream__window-user-logo img {
  display: block;
  width: 140px;
  height: 140px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.stream__window-head .stream__window-user-logo video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
}

@media (max-width: 1439px) {
  .stream__window-head .stream__window-user-logo {
    width: 144px;
    height: 80px;
  }
}

@media (max-width: 1279px) {
  .stream__window-head .stream__window-user-logo {
    margin-top: 67px;
  }
}

@media (max-width: 1023px) {
  .stream__window-head .stream__window-user-logo {
    width: 108px;
    height: 60px;
  }

  .stream__window-head .stream__window-user-logo img {
    width: 84px;
    height: 84px;
  }
}

@media (max-width: 767px) {
  .stream__window-head .stream__window-user-logo {
    margin-top: 43px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .stream__window-head .stream__window-user-logo img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1023px) {
  .stream__window-head {
    padding: 17px 20px;
  }
}

.stream__window-logo {
  width: 200px;
}

@media (max-width: 767px) {
  .stream__window-logo {
    width: 144px;
  }
}

.stream__window-footer {
  padding: 50px;
  height: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.stream__window-footer::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(10.35%, #141313), color-stop(96.68%, rgba(21, 20, 20, 0)));
  background: -webkit-linear-gradient(bottom, #141313 10.35%, rgba(21, 20, 20, 0) 96.68%);
  background: -o-linear-gradient(bottom, #141313 10.35%, rgba(21, 20, 20, 0) 96.68%);
  background: linear-gradient(0deg, #141313 10.35%, rgba(21, 20, 20, 0) 96.68%);
  opacity: 0.8;
}

.stream__window-footer-btn {
  display: inline-block;
  width: 62px;
  height: 62px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  position: relative;
}

.stream__window-footer-btn::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-radius: 50%;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.stream__window-footer-btn:hover::before {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.stream__window-footer-btn + .stream__window-footer-btn {
  margin-left: 30px;
}

@media (max-width: 1679px) {
  .stream__window-footer-btn + .stream__window-footer-btn {
    margin-left: 15px;
  }
}

.stream__window-footer-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.stream__window-footer-btn.mute-mic::after {
  background-image: url(/svg/call-mic-mute-icon.svg);
}

/*@media only screen and (hover: none) and (pointer: coarse) {*/
/*  .stream__window-footer-btn.share-screen {*/
/*    display: none;*/
/*  }*/
/*}*/

.stream__window-footer-btn.share-screen::after {
  background-image: url(/svg/screen-share-icon.svg);
}

@media only screen and (hover: none) and (pointer: coarse) {
  .stream__window-footer-btn.mute-sound {
    margin-left: 0;
  }
}

.stream__window-footer-btn.mute-sound::after {
  background-image: url(/svg/call-sound-mute-icon.svg);
}

.stream__window-footer-btn.mute-cam::after {
  background-image: url(/svg/call-cam-mute-icon.svg);
}

.stream__window-footer-btn.muted::before {
  background-color: #3F3F49;
}

.stream__window-footer-btn.mute-mic.muted::after {
  background-image: url(/svg/call-mic-unmute-icon.svg);
}

.stream__window-footer-btn.share-screen.muted::after {
  background-image: url(/svg/screen-unshare-icon.svg);
}

.stream__window-footer-btn.mute-sound.muted::after {
  background-image: url(/svg/call-sound-unmute-icon.svg);
}

.stream__window-footer-btn.mute-cam.muted::after {
  background-image: url(/svg/call-cam-unmute-icon.svg);
}

.stream__window-footer-btn.disconnect::before {
  background-color: #8995AA;
}

.stream__window-footer-btn.disconnect::after {
  background-image: url(/svg/call-out-icon.svg);
}

.stream__window-footer-btn.end::before {
  background-color: #FF3366;
}

.stream__window-footer-btn.end::after {
  background-image: url(/svg/call-out-icon.svg);
}

.stream__window-footer-btn.note::before {
  background-color: #16D9C2;
}

.stream__window-footer-btn.note::after {
  background-image: url(/svg/note-adding-icon.svg);
}

@media (max-width: 1679px) {
  .stream__window-footer-btn {
    width: 49px;
    height: 49px;
  }
}

@media (max-width: 1439px) {
  .stream__window-footer-btn {
    width: 39px;
    height: 39px;
  }
}

@media (max-width: 1023px) {
  .stream__window-footer {
    padding: 30px;
  }
}

.stream__window:-webkit-full-screen .stream__window-footer {
  padding-bottom: 23px;
  z-index: 10;
}

.stream__window:-webkit-full-screen .stream__window-footer::before {
  opacity: 0;
}

.stream__window:-webkit-full-screen .stream__window-footer-btn {
  width: 54px;
  height: 54px;
}

@media (max-width: 1023px) {
  .stream__window:-webkit-full-screen .stream__window-footer-btn {
    width: 40px;
    height: 40px;
  }
}

.stream__window:-webkit-full-screen .stream-video-button {
  bottom: 23px;
}

.stream__window:-ms-fullscreen .stream__window-footer {
  padding-bottom: 23px;
  z-index: 10;
}

.stream__window:-ms-fullscreen .stream__window-footer::before {
  opacity: 0;
}

.stream__window:-ms-fullscreen .stream__window-footer-btn {
  width: 54px;
  height: 54px;
}

@media (max-width: 1023px) {
  .stream__window:-ms-fullscreen .stream__window-footer-btn {
    width: 40px;
    height: 40px;
  }
}

.stream__window:-ms-fullscreen .stream-video-button {
  bottom: 23px;
}

.stream__window:-webkit-full-screen .stream__window-footer {
  padding-bottom: 23px;
  z-index: 10;
}

.stream__window:-moz-full-screen .stream__window-footer {
  padding-bottom: 23px;
  z-index: 10;
}

.stream__window:-ms-fullscreen .stream__window-footer {
  padding-bottom: 23px;
  z-index: 10;
}

.stream__window:fullscreen .stream__window-footer {
  padding-bottom: 23px;
  z-index: 10;
}

.stream__window:-webkit-full-screen .stream__window-footer::before {
  opacity: 0;
}

.stream__window:-moz-full-screen .stream__window-footer::before {
  opacity: 0;
}

.stream__window:-ms-fullscreen .stream__window-footer::before {
  opacity: 0;
}

.stream__window:fullscreen .stream__window-footer::before {
  opacity: 0;
}

.stream__window:-webkit-full-screen .stream__window-footer-btn {
  width: 54px;
  height: 54px;
}

.stream__window:-moz-full-screen .stream__window-footer-btn {
  width: 54px;
  height: 54px;
}

.stream__window:-ms-fullscreen .stream__window-footer-btn {
  width: 54px;
  height: 54px;
}

.stream__window:fullscreen .stream__window-footer-btn {
  width: 54px;
  height: 54px;
}

@media (max-width: 1023px) {
  .stream__window:-webkit-full-screen .stream__window-footer-btn {
    width: 40px;
    height: 40px;
  }

  .stream__window:-moz-full-screen .stream__window-footer-btn {
    width: 40px;
    height: 40px;
  }

  .stream__window:-ms-fullscreen .stream__window-footer-btn {
    width: 40px;
    height: 40px;
  }

  .stream__window:fullscreen .stream__window-footer-btn {
    width: 40px;
    height: 40px;
  }
}

.stream__window:-webkit-full-screen .stream-video-button {
  bottom: 23px;
}

.stream__window:-moz-full-screen .stream-video-button {
  bottom: 23px;
}

.stream__window:-ms-fullscreen .stream-video-button {
  bottom: 23px;
}

.stream__window:fullscreen .stream-video-button {
  bottom: 23px;
}

@media (max-width: 1799px) {
  .stream__window {
    width: 60%;
  }
}

@media (max-width: 1430px) {
  .stream__window {
    width: 60%;
  }
}

@media (max-width: 1279px) {
  .stream__window {
    width: 100%;
    height: 50vh;
    min-height: 310px;
  }
}

@media (max-width: 767px) {
  .stream__window {
    min-height: 90vh;
  }
}

.stream__window-footer-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer-btns__left {
  margin-right: 60px;
}

@media (max-width: 767px) {
  .footer-btns__left {
    margin-right: 15px;
  }
}

.stream-video-button {
  position: absolute;
  right: 40px;
  bottom: 50px;
  background-color: #0d0f27;
  z-index: 111;
  cursor: pointer;
}

.stream-video-button::before {
  background-color: transparent;
}

.stream-video-button::after {
  background-image: url(/svg/fullscreen-icon.svg);
}

.stream__window:-webkit-full-screen .stream-video-button::after {
  background-image: url(/svg/exit-fullscreen-icon.svg);
}

.stream__window:-ms-fullscreen .stream-video-button::after {
  background-image: url(/svg/exit-fullscreen-icon.svg);
}

.stream__window:-moz-full-screen .stream-video-button::after {
  background-image: url(/svg/exit-fullscreen-icon.svg);
}

.stream__window:fullscreen .stream-video-button::after {
  background-image: url(/svg/exit-fullscreen-icon.svg);
}

@media (max-width: 1023px) {
  .stream-video-button {
    bottom: 30px;
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  .stream-video-button {
    display: none;
  }
}

.exitPip {
  position: absolute;
  right: 17px;
  top: 15px;
  width: 20px;
  height: 20px;
  display: block;
  z-index: 10;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  display: none;
}

.exitPip:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.pp-videochat {
  top: initial;
  left: initial;
}

.pp-videochat.pip-mode {
  width: 430px;
  height: 240px;
  right: 44px;
  bottom: 44px;
  top: initial;
  left: initial;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 1799px) {
  .pp-videochat.pip-mode {
    width: 340px;
    height: 190px;
  }
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode {
    width: 305px;
    height: 170px;
  }
}

@media (max-width: 767px) {
  .pp-videochat.pip-mode {
    width: 89.33%;
    height: calc((89.33vw / 16) * 9);
    right: 0;
    left: 0;
    margin: auto;
    bottom: 70px;
  }
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream {
    height: 100%;
  }
}

.pp-videochat.pip-mode .stream__dialog {
  display: none;
}

.pp-videochat.pip-mode .stream__window {
  width: 100%;
  position: relative;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
  }
}

.pp-videochat.pip-mode .stream__window-body-logo {
  width: 50px;
  margin: 0 auto 20px;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-body-logo {
    width: 30px;
    margin: 0 auto 10px;
  }
}

.pp-videochat.pip-mode .stream__window-body-btns {
  margin-top: 10px;
}

.pp-videochat.pip-mode .stream__window-logo {
  width: 70px;
  top: 20px;
  left: 20px;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-body {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.pp-videochat.pip-mode .stream__window-body-title {
  font-size: 1em;
  line-height: 120%;
  text-align: center;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-body-title {
    ont-size: 0.8em;
  }
}

.pp-videochat.pip-mode .stream__window-body-time-info {
  font-size: 0.7em;
  line-height: 1;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-body-time-info {
    margin-top: 10px;
  }
}

.pp-videochat.pip-mode .stream__window-body-btn {
  height: 40px;
  padding: 0 20px;
  min-width: initial;
  font-size: 10px;
  line-height: 1;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-body-btn {
    height: 30px;
  }
}

.pp-videochat.pip-mode .stream__window-body-btn img {
  width: 1.4em;
  height: 1.4em;
}

.pp-videochat.pip-mode .stream__window-body-btn {
  height: 40px;
  padding: 0 20px;
  min-width: initial;
  font-size: 10px;
  line-height: 1;
}

.pp-videochat.pip-mode .stream__window-body-btn img {
  width: 1.4em;
  height: 1.4em;
}

.pp-videochat.pip-mode .stream__window-head-item {
  display: none;
}

.pp-videochat.pip-mode .stream__window-footer {
  margin-top: auto;
  padding-bottom: 22px;
  padding-top: 0;
}

.pp-videochat.pip-mode .footer-btns__left {
  margin-right: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1799px) {
  .pp-videochat.pip-mode .footer-btns__left {
    margin-right: 28px;
  }
}

.pp-videochat.pip-mode .stream__window-footer-btn {
  width: 40px;
  height: 40px;
}

@media (max-width: 1799px) {
  .pp-videochat.pip-mode .stream__window-footer-btn {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-footer-btn {
    width: 28px;
    height: 28px;
  }
}

.pp-videochat.pip-mode .__react_component_tooltip {
  font-size: 10px;
}

.pp-videochat.pip-mode .__react_component_tooltip.show {
  display: none;
}

.pp-videochat.pip-mode .stream__window-footer-btn + .stream__window-footer-btn {
  margin-left: 14px;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-footer-btn + .stream__window-footer-btn {
    margin-left: 10px;
  }
}

.pp-videochat.pip-mode .stream-video-button {
  display: none;
}

.pp-videochat.pip-mode .footer-btns__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pp-videochat.pip-mode .stream__window-bg video {
  -o-object-fit: cover;
  object-fit: cover;
}

.pp-videochat.pip-mode .exitPip {
  display: block;
}

.pp-videochat.pip-mode .exitPip,
.pp-videochat.pip-mode .stream__window-footer {
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.pp-videochat.pip-mode:hover .exitPip,
.pp-videochat.pip-mode:hover .stream__window-footer {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1279px) {
  .pp-videochat.pip-mode .stream__window-bg {
    width: 100%;
    padding-bottom: calc(100% / 16 * 9);
  }
}

.stream__messages-list-wrapper {
  background-color: white;
}

.stream__messages-head.messages-list__head {
  padding: 38px 38px 25px 60px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stream__messages-head-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stream__messages-head-label {
  padding: 9px 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #EFF3F7;
  border-radius: 45px;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.stream__messages-head-label:hover {
  opacity: 0.6;
}

.stream__messages-head-label img {
  display: block;
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
  object-fit: cover;
}

.stream__messages-head-label span {
  margin-left: 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  color: #A0B0CD;
}

@media (max-width: 767px) {
  .stream__messages-head-label span {
    font-size: 12px;
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .stream__messages-head-label {
    margin-left: auto;
    padding-left: 13px;
    padding-right: 13px;
  }
}

.stream__messages-list-body.messages-list__body {
  margin-top: 33px;
}

.stream__messages-list.messages-list {
  padding: 0 55px 0 60px;
}

.stream__messages-list-footer.messages-list__footer {
  padding: 20px 38px 20px 60px;
}

.stream__messages-list-footer.messages-list__footer .messages-list__footer-btn {
  width: 60px;
  height: 60px;
  position: relative;
}

.stream__messages-list-footer.messages-list__footer .messages-list__footer-btn svg {
  width: 26px;
  height: 26px;
}

.stream__messages-list-footer.messages-list__footer .messages-list__footer-btn input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.remote-ofline {
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #8995AA;
}

.pp__content.pp-stream-problem__content {
  padding: 55px;
  width: 530px;
}

.pp__content.pp-stream-problem__content .pp__close {
  top: 22px;
  right: 30px;
}

@media (max-width: 1799px) {
  .pp__content.pp-stream-problem__content {
    top: 60px;
  }
}

@media (max-width: 767px) {
  .pp__content.pp-stream-problem__content {
    top: 0px;
  }
}

.pp-stream-problem__title {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #0A0A0B;
}

.pp-stream-problem__errors {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #0A0A0B;
  margin-top: 1em;
}

.pp-stream-problem__errors span {
  color: #FF3366;
  font-weight: bold;
}

.pp-stream-problem__tabs {
  margin-top: 28px;
}

.pp-stream-problem__tabs-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pp-stream-problem__tab-btn {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 17px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.41);
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.pp-stream-problem__tab-btn + .pp-stream-problem__tab-btn {
  margin-left: 40px;
}

.pp-stream-problem__tab-btn:hover {
  opacity: 0.6;
}

.pp-stream-problem__tab-btn.active {
  pointer-events: none;
  color: #0A0A0B;
}

.pp-stream-problem__tabs-content {
  margin-top: 28px;
}

.pp-stream-problem__banner {
  padding: 22px 50px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #0A0A0B;
  border: 2px solid #0A0A0B;
  border-radius: 20px;
}

.pp-stream-problem__banner-link {
  font-weight: 800;
  text-decoration: underline;
}

.pp-stream-problem__text {
  margin-top: 40px;
}

.pp-stream-problem__text p {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #0A0A0B;
}

.pp-stream-problem__text p + p {
  margin-top: 40px;
}

.pp-stream-problem__footer {
  margin-top: 65px;
  padding: 15px 40px 30px;
  border: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  text-align: center;
  font-family: "Montserrat";
}

.pp-stream-problem__footer-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #0A0A0B;
}

.pp-stream-problem__footer-link {
  display: inline-block;
  margin-top: 15px;
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  color: #0A0A0B;
}

.pp__content.pp-stream-note-adding__content {
  width: 800px;
  padding: 45px 80px;
}

.pp__content.pp-stream-note-adding__content .pp__close {
  top: 50px;
  right: 65px;
}

.pp-stream-note-adding__title {
  font-weight: bold;
  font-size: 32px;
  line-height: 43px;
  color: #0A0A0B;
}

.pp-stream-note-adding__form {
  margin-top: 20px;
}

.pp-stream-note-adding__form .input-box {
  height: auto;
}

.pp-stream-note-adding__form .input-box__label {
  top: 20px;
}

.input-box__input.pp-stream-note-adding__textarea {
  padding: 20px 0;
  display: block;
  border: none;
  resize: none;
  min-height: 588px;
}

/* End Stream */
/* Start Students */
.students__inner {
  margin-top: 43px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 30px;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@media (max-width: 1799px) {
  .students__inner {
    margin-top: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1430px) {
  .students__inner {
    margin-top: 40px;
    grid-gap: 20px;
  }
}

@media (max-width: 1279px) {
  .students__inner {
    margin-top: 15px;
  }
}

@media (max-width: 1023px) {
  .students__inner {
    margin-top: 25px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 20px;
  }
}

@media (max-width: 767px) {
  .students__inner {
    margin-top: 20px;
    display: block;
  }
}

.students__inner-card {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.students__inner-card .user-card__aside-item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.students__inner-card .user-card__profile-head {
  margin-bottom: 8px;
}

.students__inner-card .user-card__profile-avatar {
  width: 118px;
  height: 118px;
}

.students__inner-card .user__name {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 26px;
}

.students__inner-card .user-card__profile-bottom {
  margin-top: auto;
}

.students__inner-card .user__time {
  margin-bottom: 18px;
}

@media (max-width: 1799px) {
  .students__inner-card .user-card__profile-head {
    margin-bottom: 25px;
  }

  .students__inner-card .user-card__profile-avatar {
    width: 96px;
    height: 96px;
  }

  .students__inner-card .user-card__profile-avatar .user__message-btn {
    width: 43px;
    height: 43px;
  }

  .students__inner-card .user-card__profile-avatar .user__message-btn svg {
    width: 20px;
    height: 16px;
  }

  .students__inner-card .user__name {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 24px;
  }

  .students__inner-card .user__town {
    margin-bottom: 20px;
  }

  .students__inner-card .user__time {
    margin-bottom: 14px;
  }
}

@media (max-width: 1430px) {
  .students__inner-card .user-card__profile-head {
    margin-bottom: 15px;
  }

  .students__inner-card .user-card__profile-avatar {
    width: 87px;
    height: 87px;
  }

  .students__inner-card .user-card__profile-avatar .user__message-btn {
    width: 40px;
    height: 40px;
  }

  .students__inner-card .user-card__profile-avatar .user__message-btn svg {
    width: 19px;
    height: 14px;
  }

  .students__inner-card .user__town {
    margin-top: 10px;
    margin-bottom: 18px;
  }

  .students__inner-card .user__time {
    padding: 11px 0;
    margin-bottom: 15px;
  }

  .students__inner-card .user__lessons-count {
    font-size: 15px;
    line-height: 19px;
  }

  .students__inner-card .user__lessons-count-total {
    margin-top: 4px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 1279px) {
  .students__inner-card .user-card__profile-head {
    margin-bottom: 13px;
  }

  .students__inner-card .user-card__profile-avatar {
    width: 82px;
    height: 82px;
  }

  .students__inner-card .user-card__profile-avatar .user__message-btn {
    width: 37px;
    height: 37px;
  }

  .students__inner-card .user-card__profile-avatar .user__message-btn svg {
    width: 17px;
    height: 13px;
  }

  .students__inner-card .user__name {
    font-size: 16px;
    line-height: 20px;
  }

  .students__inner-card .user__town {
    margin-top: 2px;
    margin-bottom: 24px;
  }

  .students__inner-card .user__time {
    padding: 10px 0;
  }

  .students__inner-card .user__lessons-count {
    font-size: 14px;
    line-height: 18px;
  }

  .students__inner-card .user__lessons-count-total {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 767px) {
  .students__inner-card + .students__inner-card {
    margin-top: 10px;
  }

  .students__inner-card .user-card__profile-head {
    margin-bottom: 3px;
  }

  .students__inner-card .user__town {
    margin-top: 5px;
  }
}

/* End Students */
/* Start TeacherMain */
.teacherMain__wrapper {
  width: 100%;
  max-width: 100%;
}

.teacherMain__headline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

@media (max-width: 767px) {
  .teacherMain__headline .teacherMain__headline-title {
    margin-bottom: 20px;
    width: 100%;
  }
}

.teacherMain__headline-title {
  text-transform: capitalize;
  width: 50%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slider-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider-btn {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #0A0A0B;
  cursor: pointer;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.slider-btn:hover {
  opacity: 0.6;
}

.slider-btn img {
  width: 10px;
  height: 18px;
  -o-object-fit: contain;
  object-fit: contain;
}

.slider-btn + .slider-btn {
  margin-left: 13px;
}

@media (max-width: 1799px) {
  .slider-btn {
    width: 45px;
    height: 45px;
  }

  .slider-btn img {
    width: 9px;
    height: 16px;
  }

  .slider-btn + .slider-btn {
    margin-left: 12px;
  }
}

@media (max-width: 1430px) {
  .slider-btn {
    width: 40px;
    height: 40px;
  }

  .slider-btn + .slider-btn {
    margin-left: 10px;
  }
}

@media (max-width: 1279px) {
  .slider-btn {
    width: 36px;
    height: 36px;
  }

  .slider-btn img {
    width: 8px;
    height: 13px;
  }
}

.slider-btn-prev img {
  margin-right: 3px;
}

.slider-btn-next img {
  margin-left: 3px;
}

.teacherMain__headline-btns {
  margin-left: 43px;
}

@media (max-width: 1799px) {
  .teacherMain__headline-btns {
    margin-left: 25px;
  }
}

@media (max-width: 1430px) {
  .teacherMain__headline-btns {
    margin-left: 20px;
  }
}

@media (max-width: 1279px) {
  .teacherMain__headline-btns {
    margin-left: 23px;
  }
}

@media (max-width: 1023px) {
  .teacherMain__headline-btns {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .teacherMain__headline-btns {
    margin-left: 0;
  }
}

.teacherMain__headline-timezone {
  margin-left: auto;
}

@media (max-width: 767px) {
  .teacherMain__headline-timezone .schedule__timezone-val {
    font-size: 12px;
    line-height: 24px;
  }

  .teacherMain__headline-timezone .schedule__timezone-logo {
    margin-left: 12px;
    width: 20px;
    height: 20px;
  }
}

.teacherMain__inner {
  margin-top: 40px;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 1799px) {
  .teacherMain__inner {
    margin-top: 32px;
  }
}

@media (max-width: 1430px) {
  .teacherMain__inner {
    margin-top: 30px;
  }
}

@media (max-width: 1023px) {
  .teacherMain__inner {
    margin-top: 18px;
  }
}

.teacherMain__slide {
  max-width: 100%;
}

.teacherMain__card {
  position: relative;
  padding: 30px 90px 30px 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  border-radius: 20px;
  overflow: hidden;
}

.teacherMain__card.disabled {
  opacity: 0.3;
}

.teacherMain__card + .teacherMain__card {
  margin-top: 12px;
}

.teacherMain__card::after {
  content: "";
  width: 230px;
  height: 230px;
  position: absolute;
  top: -55px;
  right: -43px;
  background-image: url("/svg/widget-picture-1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.teacherMain__card-head {
  width: 14.25%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  color: #050425;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.teacherMain__card-head::before {
  content: "";
  width: 25px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #050425;
}

.teacherMain__card-body {
  margin-left: 90px;
  margin-right: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.teacherMain__card-body .user-card__profile-avatar {
  width: 80px;
  height: 80px;
}

.teacherMain__card-body .user-card__profile-avatar .user__message-btn {
  width: 36px;
  height: 36px;
}

.teacherMain__card-body .user-card__profile-avatar .user__message-btn svg {
  width: 17px;
  height: 13px;
}

.teacherMain__card-body .teacherMain__card-user-info {
  margin-left: 35px;
}

.teacherMain__card-body .teacherMain__card-user-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #050425;
}

.teacherMain__card-body .teacherMain__card-user-location {
  margin-top: 4px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8995AA;
}

.teacherMain__card-body .user-status {
  margin-top: 9px;
  line-height: 18px;
}

.teacherMain__card-footer {
  margin-left: auto;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 1;
}

@media (max-width: 1799px) {
  .teacherMain__card {
    padding: 30px 45px 30px 55px;
  }

  .teacherMain__card::after {
    top: -53px;
    right: -35px;
  }

  .teacherMain__card-head {
    width: 19.23%;
  }

  .teacherMain__card-body {
    margin-left: 70px;
    margin-right: 70px;
  }

  .teacherMain__card-footer .button.medium {
    height: 50px;
    font-size: 13px;
  }
}

@media (max-width: 1430px) {
  .teacherMain__card {
    padding: 26px 40px 26px 37px;
  }

  .teacherMain__card::after {
    width: 208px;
    height: 208px;
    top: -48px;
    right: -32px;
  }

  .teacherMain__card-head {
    width: 17.93%;
    font-size: 22px;
    line-height: 29px;
  }

  .teacherMain__card-head::before {
    width: 22px;
  }

  .teacherMain__card-body {
    margin-left: 90px;
    margin-right: 90px;
  }

  .teacherMain__card-body .user-card__profile-avatar {
    width: 73px;
    height: 73px;
  }

  .teacherMain__card-body .user-card__profile-avatar .user__message-btn {
    width: 32px;
    height: 32px;
  }

  .teacherMain__card-body .user-card__profile-avatar .user__message-btn svg {
    width: 15px;
    height: 12px;
  }

  .teacherMain__card-body .teacherMain__card-user-name {
    font-size: 18px;
    line-height: 24px;
  }

  .teacherMain__card-body .teacherMain__card-user-location {
    margin-top: 3px;
    font-size: 15px;
    line-height: 18px;
  }

  .teacherMain__card-body .user-status {
    margin-top: 8px;
    font-size: 13px;
    line-height: 16px;
  }

  .teacherMain__card-footer .button.medium {
    padding: 0 22px;
    height: 45px;
    font-size: 12px;
  }
}

@media (max-width: 1279px) {
  .teacherMain__card {
    padding: 25px 35px;
  }

  .teacherMain__card + .teacherMain__card {
    margin-top: 10px;
  }

  .teacherMain__card::after {
    width: 196px;
    height: 196px;
    top: -45px;
    right: -30px;
  }

  .teacherMain__card-head {
    width: 16.64%;
    font-size: 20px;
    line-height: 26px;
  }

  .teacherMain__card-head::before {
    width: 20px;
  }

  .teacherMain__card-body {
    margin-left: 88px;
    margin-right: 88px;
  }

  .teacherMain__card-body .user-card__profile-avatar {
    width: 68px;
    height: 68px;
  }

  .teacherMain__card-body .user-card__profile-avatar .user__message-btn {
    width: 30px;
    height: 30px;
  }

  .teacherMain__card-body .user-card__profile-avatar .user__message-btn svg {
    width: 14px;
    height: 11px;
  }

  .teacherMain__card-body .teacherMain__card-user-info {
    margin-left: 30px;
  }

  .teacherMain__card-body .teacherMain__card-user-location {
    margin-top: 2px;
  }

  .teacherMain__card-body .user-status {
    margin-top: 6px;
  }

  .teacherMain__card-footer .button.medium {
    padding: 0 19px;
    height: 42px;
  }
}

@media (max-width: 1023px) {
  .teacherMain__card {
    padding: 19px 30px 19px 19px;
  }

  .teacherMain__card::after {
    width: 140px;
    height: 140px;
    top: -32px;
    right: -25px;
  }

  .teacherMain__card-head {
    width: 18.30%;
    font-size: 16px;
    line-height: 22px;
  }

  .teacherMain__card-head::before {
    width: 16px;
  }

  .teacherMain__card-body {
    margin-left: 32px;
    margin-right: 20px;
  }

  .teacherMain__card-body .user-card__profile-avatar {
    margin-right: 0;
    width: 62px;
    height: 62px;
  }

  .teacherMain__card-body .user-card__profile-avatar .user__message-btn {
    width: 27px;
    height: 27px;
  }

  .teacherMain__card-body .user-card__profile-avatar .user__message-btn svg {
    width: 13px;
    height: 10px;
  }

  .teacherMain__card-body .teacherMain__card-user-info {
    margin-left: 23px;
  }

  .teacherMain__card-body .teacherMain__card-user-name {
    font-size: 16px;
    line-height: 21px;
  }

  .teacherMain__card-body .teacherMain__card-user-location {
    font-size: 12px;
    line-height: 14px;
  }

  .teacherMain__card-body .user-status {
    margin-left: 0;
    margin-top: 8px;
    font-size: 12px;
    line-height: 15px;
  }

  .teacherMain__card-footer .button.medium {
    padding: 0 18px;
    height: 32px;
    font-size: 9px;
  }
}

@media (max-width: 767px) {
  .teacherMain__card {
    padding: 20px 25px 25px;
    display: block;
  }

  .teacherMain__card-head {
    width: 52.8%;
    font-size: 22px;
    line-height: 29px;
  }

  .teacherMain__card-head::before {
    width: 22px;
  }

  .teacherMain__card-body {
    margin: 17px 0 30px;
  }

  .teacherMain__card-body .user-card__profile-avatar {
    margin: 0;
  }

  .teacherMain__card-footer .button.medium {
    padding: 0 25px;
    height: 50px;
    font-size: 13px;
  }
}

/* End TeacherMain */
/* Start Teacher-Schedule */
.teacher-shedule .schedule__time-item.available,
.teacher-shedule .schedule__time-item.blocked {
  padding-left: 25px;
  padding-right: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
}

@media (max-width: 1799px) {
  .teacher-shedule .schedule__time-item.available,
  .teacher-shedule .schedule__time-item.blocked {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.teacher-shedule .schedule__time-item.available .schedule__time-item-text,
.teacher-shedule .schedule__time-item.blocked .schedule__time-item-text {
  font-weight: 600;
  color: #0A0A0B;
}

.schedule__time-item-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 2px;
  position: absolute;
}

.teacher-shedule .schedule__time-item.available:hover {
  background: none;
}

.teacher-shedule .schedule__time-item.blocked .schedule__time-item-text {
  color: rgba(137, 149, 170, 0.4);
  font-weight: bold;
}

.teacher-shedule .schedule__time-item.assigned {
  pointer-events: initial;
  cursor: pointer;
  font-weight: bold;
  background-color: #4987FF;
  color: white;
}

.teacher-shedule .schedule__time-item.assigned:hover {
  background-color: #1B5DDC;
}

.teacher-shedule .pp-lesson__footer {
  padding-top: 25px;
  margin-top: 22px;
  border-top: 2px solid #EFF3F7;
}

.teacher-shedule .pp-lesson__footer-text {
  width: 95%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A0A0B;
}

.teacher-shedule .schedule-aside__card .widget-teacher {
  padding-top: 0;
  border-top: 0;
}

.teacher-shedule .schedule-aside__card .widget-teacher .widget-teacher__envelop {
  background-color: #4987FF;
}

.schedule-aside__card-item + .schedule-aside__card-item {
  padding-top: 18px;
  margin-top: 35px;
  border-top: 2px solid #EFF3F7;
}

.schedule__time-item-remove-btn,
.schedule__time-item-renew-btn {
  display: block;
  width: 15px;
  height: 16px;
}

.schedule__time-item-remove-btn svg,
.schedule__time-item-renew-btn svg {
  display: inline-block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.schedule__time-item-remove-btn svg path,
.schedule__time-item-renew-btn svg path {
  fill: #D2DCEF;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.schedule__time-item-remove-btn:hover svg path,
.schedule__time-item-renew-btn:hover svg path {
  fill: #0A0A0B;
}

.schedule__time-item-renew-btn {
  width: 17px;
  height: 17px;
}

/* End Teacher-Schedule */
.input-range {
  height: 30px;
  position: relative;
  width: 100%;
}

.input-range__label {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #0A0A0B;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -25px;
  position: absolute;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--min {
  left: 0;
}

.input-range__track {
  background: #D2DCEF;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
  -o-transition: left 0.3s ease-out, width 0.3s ease-out;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #4987FF;
}

.input-range__slider-container {
  -webkit-transition: left 0.3s ease-out;
  -o-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.input-range__label--value {
  position: absolute;
  top: -40px;
}

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  height: 30px;
  width: 30px;
  margin-left: -15px;
  margin-top: -15px;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transition: -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
  -o-transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
  background-image: url(/svg/range-ico.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.input-range__slider:hover {
  -webkit-box-shadow: -6px 9px 28px rgba(0, 0, 0, 0.122);
  box-shadow: -6px 9px 28px rgba(0, 0, 0, 0.122);
}

.input-range__label--max {
  right: 0;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.teachers-list {
  margin-bottom: 52px;
}

.teacher {
  margin-bottom: 24px;
  border-radius: 20px;
  display: block;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  will-change: box-shadow;
}

.teacher:hover {
  opacity: 1;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, 0.03);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, 0.03);
}

.teacher:hover .teacher-video-preview__gif {
  opacity: 1;
  visibility: visible;
}

.teacher:hover .teacher-video-ico:before {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.teacher:hover .teacher-video-preview:before {
  opacity: 0;
}

@media (max-width: 1430px) {
  .teacher {
    margin-bottom: 16px;
    border-radius: 15px;
  }
}

@media (max-width: 1279px) {
  .teacher {
    margin-bottom: 12px;
    border-radius: 14px;
  }
}

@media (max-width: 1023px) {
  .teacher {
    padding: 35px 40px 20px;
  }
}

@media (max-width: 767px) {
  .teacher {
    padding: 20px 23px 18px;
    margin-bottom: 15px;
    border-radius: 20px;
  }
}

.teacher.aos-animate {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.teacher.aos-animate:hover {
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, 0.14);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, 0.14);
}

.teacher-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 1023px) {
  .teacher-cont {
    display: block;
  }
}

.teacher__vis {
  position: relative;
  width: 24.61%;
  padding: 37px 45px;
}

@media (max-width: 1799px) {
  .teacher__vis {
    width: 25.4%;
    padding: 27px 25px;
  }
}

@media (max-width: 1430px) {
  .teacher__vis {
    width: 25.3%;
    padding: 25px 22px;
  }
}

@media (max-width: 1279px) {
  .teacher__vis {
    width: 27%;
    padding: 25px 17px;
  }
}

@media (max-width: 1023px) {
  .teacher__vis {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .teacher__vis {
    padding-bottom: 27px;
  }
}

.teacher-vis {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 1023px) {
  .teacher-vis {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: left;
  }
}

.teacher-vis__img {
  width: 116px;
  height: 116px;
  margin-bottom: 16px;
}

@media (max-width: 1799px) {
  .teacher-vis__img {
    width: 86px;
    height: 86px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1430px) {
  .teacher-vis__img {
    width: 78px;
    height: 78px;
    margin-bottom: 8px;
  }
}

@media (max-width: 1023px) {
  .teacher-vis__img {
    margin-bottom: 0;
    margin-right: 18px;
  }
}

.teacher-vis__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #EFF3F7;
}

.teacher-vis-info__name {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

@media (max-width: 1799px) {
  .teacher-vis-info__name {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (max-width: 1430px) {
  .teacher-vis-info__name {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 1279px) {
  .teacher-vis-info__name {
    margin-bottom: 3px;
  }
}

.teacher-vis-info__place {
  margin-bottom: 20px;
}

@media (max-width: 1799px) {
  .teacher-vis-info__place {
    margin-bottom: 13px;
  }
}

@media (max-width: 1430px) {
  .teacher-vis-info__place {
    margin-bottom: 10px;
  }
}

@media (max-width: 1023px) {
  .teacher-vis-info__place {
    margin-bottom: 15px;
  }
}

.teacher-vis-info__place-city {
  color: #8995AA;
  font-size: 14px;
}

@media (max-width: 1799px) {
  .teacher-vis-info__place-city {
    font-size: 12px;
  }
}

.teacher-vis-info__held,
p.teacher-vis-info__held {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  font-family: "Evolventa";
}

@media (max-width: 1799px) {
  .teacher-vis-info__held,
  p.teacher-vis-info__held {
    font-size: 12px;
  }
}

.teacher-vis__buttons {
  margin-top: 25px;
  width: 100%;
}

.teacher-vis__buttons .button {
  width: 100%;
  margin-bottom: 12px;
  padding: 0 20px;
  cursor: pointer;
}

.teacher-vis__buttons .button:last-child {
  margin-bottom: 0;
}

@media (max-width: 1799px) {
  .teacher-vis__buttons {
    margin-top: 17px;
  }

  .teacher-vis__buttons .button {
    height: 36px;
    margin-bottom: 9px;
    padding: 0 17px;
    font-size: 12px;
  }
}

@media (max-width: 1430px) {
  .teacher-vis__buttons {
    margin-top: 14px;
  }

  .teacher-vis__buttons .button {
    padding: 0 15px;
    font-size: 10px;
  }
}

@media (max-width: 1023px) {
  .teacher-vis__buttons {
    margin-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .teacher-vis__buttons .button {
    min-width: 104px;
    margin-bottom: 0;
    margin-right: 7px;
    width: auto;
  }

  .teacher-vis__buttons .button:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .teacher-card__wrapper .teacher-vis__buttons {
    display: block;
  }

  .teacher-card__wrapper .teacher-vis__buttons .button {
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
  }

  .teacher-card__wrapper .teacher-vis__buttons .button:last-child {
    margin-bottom: 0;
  }
}

.button.teacher-chosen {
  position: relative;
  pointer-events: none;
}

.button.teacher-chosen::before {
  content: "";
  position: relative;
  display: block;
  background-image: url(/svg/teacher-chosen.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 17px;
  height: 14px;
  margin-right: 10px;
  z-index: 1;
}

@media (max-width: 1799px) {
  .button.teacher-chosen::before {
    width: 12px;
    height: 10px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
}

@media (max-width: 1430px) {
  .button.teacher-chosen::before {
    width: 11px;
    height: 9px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
}

.teacher__main {
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 37px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (max-width: 1799px) {
  .teacher__main {
    padding: 27px 0;
  }
}

@media (max-width: 1430px) {
  .teacher__main {
    padding: 25px 0;
  }
}

@media (max-width: 1023px) {
  .teacher__main {
    padding: 0;
  }
}

.teacher-main-cont {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-left: 2px solid #EFF3F7;
}

@media (max-width: 1023px) {
  .teacher-main-cont {
    padding-bottom: 50px;
    display: block;
    border-left: none;
    border-top: 2px solid #EFF3F7;
  }
}

@media (max-width: 1023px) {
  .teacher-main-cont {
    padding-bottom: 60px;
  }
}

.teacher-main-part {
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 2px solid #EFF3F7;
  padding: 0px 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1799px) {
  .teacher-main-part {
    padding: 0 25px;
  }
}

@media (max-width: 1430px) {
  .teacher-main-part {
    padding: 0 29px;
  }
}

@media (max-width: 1023px) {
  .teacher-main-part {
    padding: 14px 0 0;
    border-right: none;
    display: block;
  }
}

.teacher-main-part_r {
  width: 42.413%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 42.413%;
  flex: 0 0 42.413%;
  border-right: none;
}

@media (max-width: 1799px) {
  .teacher-main-part_r {
    width: 42.8756%;
  }
}

@media (max-width: 1430px) {
  .teacher-main-part_r {
    width: 42.796%;
  }
}

@media (max-width: 1279px) {
  .teacher-main-part_r {
    width: 43.925%;
  }
}

@media (max-width: 1023px) {
  .teacher-main-part_r {
    width: 100%;
  }
}

.first-teacher-element {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 20px;
}

.first-teacher-element:last-child {
  margin-bottom: 0;
}

@media (max-width: 1799px) {
  .first-teacher-element {
    margin-bottom: 15px;
  }
}

@media (max-width: 1430px) {
  .first-teacher-element {
    margin-bottom: 5px;
  }
}

@media (max-width: 1279px) {
  .first-teacher-element {
    margin-bottom: 0;
  }
}

@media (max-width: 1023px) {
  .first-teacher-element {
    display: block;
  }
}

.teacher-main-article {
  margin-bottom: 33px;
}

.teacher-main-article.fade-out_bottom {
  overflow: hidden;
  margin-bottom: 30px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, black), to(transparent));
  background: -webkit-linear-gradient(top, black 70%, transparent 100%);
  background: -o-linear-gradient(top, black 70%, transparent 100%);
  background: linear-gradient(180deg, black 70%, transparent 100%);
  -webkit-background-clip: text;
  /*-webkit-text-fill-color: transparent;*/
  /*background-clip: text;*/
}

.teacher-main-article.fade-out_bottom .teacher-main-article__text {
  max-height: 140px;
}

.teacher-main-article:nth-last-child(1) {
  margin-bottom: 0;
}

@media (max-width: 1799px) {
  .teacher-main-article {
    margin-bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .teacher-main-article-video-wrap {
    margin-bottom: 0;
    margin-right: 5.86%;
    width: 36.63%;
  }
}

@media (max-width: 767px) {
  .teacher-main-article {
    margin-bottom: 23px;
  }

  .teacher-main-article-video-wrap {
    margin-bottom: 23px;
    margin-right: 0;
    width: 100%;
  }
}

.teacher-main-article__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 15px;
}

@media (max-width: 1799px) {
  .teacher-main-article__title {
    margin-bottom: 11px;
    font-size: 14px;
  }
}

@media (max-width: 1279px) {
  .teacher-main-article__title {
    margin-bottom: 10px;
  }
}

.teacher-main-article__text p {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 24px;
}

.teacher-main-article__text p:last-child {
  margin-bottom: 0;
}

@media (max-width: 1799px) {
  .teacher-main-article__text p {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 17px;
  }
}

@media (max-width: 1430px) {
  .teacher-main-article__text p {
    margin-bottom: 7px;
    line-height: 20px;
  }
}

@media (max-width: 1023px) {
  .teacher-main-article__text p {
    margin-bottom: 12px;
  }
}

.teacher-main-article__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.teacher-main-tag {
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #EFF3F7;
  border-radius: 8px;
  padding: 15px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.teacher-main-tag:last-child {
  margin-bottom: 5px;
}

.teacher-main-tag img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 16px;
  width: 16px;
}

.teacher-main-tag span {
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  width: auto;
  padding-left: 10px;
  font-size: 12px;
}

@media (max-width: 1799px) {
  .teacher-main-tag {
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 11px 14px;
    border-radius: 5px;
  }

  .teacher-main-tag:last-child {
    margin-bottom: 4px;
  }

  .teacher-main-tag img {
    height: 11px;
    width: 11px;
  }

  .teacher-main-tag span {
    padding-left: 7px;
  }
}

@media (max-width: 1430px) {
  .teacher-main-tag {
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 10px 12px;
  }

  .teacher-main-tag:last-child {
    margin-bottom: 3px;
  }

  .teacher-main-tag img {
    height: 10px;
    width: 10px;
  }

  .teacher-main-tag span {
    padding-left: 6px;
    font-size: 10px;
  }
}

.teacher__ico {
  width: 10%;
  padding-top: 42px;
}

.teacher-ico {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

.teacher-ico span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #4987FF;
}

.teacher-ico span:nth-child(1) {
  height: 3px;
  width: 100%;
}

.teacher-ico span:nth-child(2) {
  height: 100%;
  width: 3px;
}

.teacher-video {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 0;
}

.teacher-video-preview {
  position: relative;
  padding-bottom: 57.92%;
  overflow: hidden;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.teacher-video-preview:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.29)), to(rgba(0, 0, 0, 0.29)));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29));
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29));
  opacity: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 4;
}

.teacher-video-ico {
  position: absolute;
  margin: auto;
  width: 48px;
  height: 48px;
  z-index: 2;
}

.teacher-video-ico:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 0;
  margin: auto;


  border: 6px solid transparent;
  border-left: 10px solid #FFF;
  width: 0;
  height: 0;
  z-index: 2;
}

.teacher-video-ico:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #4987FF;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 1799px) {
  .teacher-video-ico {
    width: 34px;
    height: 34px;
  }

  .teacher-video-ico:after {
    border: 5px solid transparent;
    border-left: 8px solid #FFF;
  }
}

@media (max-width: 1430px) {
  .teacher-video-ico {
    width: 30px;
    height: 30px;
  }

  .teacher-video-ico:after {
    left: 5px;
    border: 4px solid transparent;
    border-left: 7px solid #FFF;
  }
}

@media (max-width: 767px) {
  .teacher-video-ico {
    width: 42px;
    height: 42px;
  }

  .teacher-video-ico:after {
    left: 8px;
    border: 6px solid transparent;
    border-left: 10px solid #FFF;
  }
}

.teacher-video-preview__jpg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

.teacher-video-preview__gif {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.teacher-main-article__courses {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.teacher-course {
  margin-bottom: 8px;
  margin-right: 8px;
  background: #EFF3F7;
  border-radius: 8px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #0A0A0B;
  padding: 12px 24px;
}

@media (max-width: 1799px) {
  .teacher-course {
    padding: 11px 16px;
    border-radius: 6px;
    margin-bottom: 6px;
    margin-right: 6px;
  }
}

@media (max-width: 1430px) {
  .teacher-course {
    padding: 10px 16px;
    font-size: 10px;
    border-radius: 5px;
  }
}

.teacher-main-more {
  position: relative;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
  line-height: 1;
  color: #4987FF;
  text-transform: capitalize;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.teacher-main-more::after {
  content: "";
  background-image: url(/svg/teacher-more.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 21px;
  height: 12px;
  display: block;
  margin-left: 10px;
}

@media (max-width: 1799px) {
  .teacher-main-more {
    height: 46px;
    font-size: 12px;
  }

  .teacher-main-more:after {
    margin-left: 6px;
    width: 15px;
    height: 8px;
  }
}

@media (max-width: 1430px) {
  .teacher-main-more {
    height: 26px;
  }

  .teacher-main-more:after {
    margin-left: 5px;
    width: 13px;
    height: 7px;
  }
}

@media (max-width: 1279px) {
  .teacher-main-more {
    height: 32px;
  }
}

@media (max-width: 1023px) {
  .teacher-main-more {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.teaches-filters__mobile-btn {
  margin-top: 10px;
  position: relative;
  display: none;
  padding: 0 11px 0 14px;
  height: 34px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #0A0A0B;
  border: 1px solid rgba(160, 176, 205, 0.25);
  border-radius: 6px;
}

.teaches-filters__mobile-btn::after {
  content: "";
  position: relative;
  margin-left: 15px;
  width: 13px;
  height: 14px;
  background: url("/svg/teachers-filters-icon.svg") no-repeat center;
  background-size: contain;
}

@media (max-width: 767px) {
  .teaches-filters__mobile-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.teaches-filters__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767px) {
  .teaches-filters__inner {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 55px;
    left: 0;
  }

  .teaches-filters__inner.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .teaches-filters__inner::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #0A0A0B;
    opacity: 0.6;
  }
}

.teaches-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767px) {
  .teaches-filters {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 12px 0 0;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
  }
}

.teaches-select {
  margin-right: 15px;
}

.teaches-select:last-child {
  margin-right: 0;
}

.teaches-select .select__control {
  background-color: transparent;
  border-color: #D2DCEF;
  border-width: 2px;
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
  width: 260px;
  min-height: 44px;
}

.teaches-select .select__control:hover {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.teaches-select .select__value-container {
  padding: 6px 30px;
}

.teaches-select .select__placeholder,
.teaches-select .select__single-value {
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #0A0A0B;
}

.teaches-select .select__input input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.teaches-select .select__indicator-separator {
  display: none;
}

.teaches-select .select__indicator.select__dropdown-indicator path {
  fill: #0A0A0B;
}

.teaches-select .select__menu {
  -webkit-animation: show-drop .3s ease 0s;
  animation: show-drop .3s ease 0s;
  margin-bottom: 0;
  margin-top: 10px;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.teaches-select .select__menu-list {
  padding: 0 19px;
  max-height: 200px;
}

.teaches-select .select__menu-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.teaches-select .select__menu-list::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.teaches-select .select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #050425;
}

.teaches-select .select__option {
  background-color: white;
  color: black;
  padding: 15px 13px;
  line-height: 1;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid #EFF3F7;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  cursor: pointer;
}

.teaches-select .select__option:last-child {
  border-bottom: none;
}

.teaches-select .select__option:hover {
  background-color: #EFF3F7;
}

.teaches-select .select__option.select__option--is-selected {
  color: #4987FF;
}

@media (max-width: 1799px) {
  .teaches-select {
    margin-right: 14px;
  }

  .teaches-select .select__control {
    border-radius: 6px;
  }
}

@media (max-width: 1430px) {
  .teaches-select .select__control {
    min-height: 40px;
  }

  .teaches-select .select__value-container {
    padding: 5px 30px;
  }

  .teaches-select .select__placeholder,
  .teaches-select .select__single-value {
    font-size: 12px;
  }

  .teaches-select .select__dropdown-indicator {
    padding: 9px;
  }

  .teaches-select .select__dropdown-indicator svg {
    width: 18px;
    height: 18px;
  }

  .teaches-select .select__option {
    padding: 14px 13px;
    font-size: 12px;
  }
}

@media (max-width: 1279px) {
  .teaches-select .select__control {
    min-height: 34px;
  }

  .teaches-select .select__value-container {
    padding: 4px 30px;
  }

  .teaches-select .select__dropdown-indicator {
    padding: 5px 8px;
  }

  .teaches-select .select__option {
    padding: 10px 13px;
  }
}

@media (max-width: 1023px) {
  .teaches-select {
    margin-right: 9px;
  }

  .teaches-select .select__control {
    width: 168px;
  }

  .teaches-select .select__value-container {
    padding: 2px 15px;
  }

  .teaches-select .select__menu-list {
    padding: 0 17px;
  }

  .teaches-select .select__option {
    padding: 11px 0;
  }
}

@media (max-width: 767px) {
  .teaches-select {
    margin-right: 0;
    width: 100%;
  }

  .teaches-select:first-child .select__control::after {
    display: none;
  }

  .teaches-select .select__menu {
    width: 100%;
    margin-top: 0;
    position: relative;
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }

  .teaches-select .select__control {
    position: relative;
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 27px 45px;
  }

  .teaches-select .select__control::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 35px;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), color-stop(0.01%, #231F20), to(rgba(35, 31, 32, 0)));
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #231F20 0.01%, rgba(35, 31, 32, 0) 100%);
    background: -o-linear-gradient(top, #FFFFFF 0%, #231F20 0.01%, rgba(35, 31, 32, 0) 100%);
    background: linear-gradient(180deg, #FFFFFF 0%, #231F20 0.01%, rgba(35, 31, 32, 0) 100%);
    opacity: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .teaches-select .select__control::after {
    content: "";
    position: absolute;
    top: 0;
    left: 45px;
    width: calc(100% - 90px);
    height: 1px;
    background: #EFF3F7;
  }

  .teaches-select .select__control.select__control--menu-is-open::before {
    opacity: 0.05;
  }

  .teaches-select .select__control.select__control--menu-is-open::after {
    display: none;
  }

  .teaches-select .select__value-container {
    padding: 0;
    padding-right: 15px;
  }

  .teaches-select .select__placeholder,
  .teaches-select .select__single-value {
    margin: 0;
    font-size: 16px;
  }

  .teaches-select .select__dropdown-indicator {
    padding: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }

  .teaches-select .select__control--menu-is-open .select__dropdown-indicator {
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
  }

  .teaches-select .select__dropdown-indicator svg {
    width: 26px;
    height: 26px;
  }

  .teaches-select .select__menu-list {
    position: relative;
    padding: 0 45px;
    max-height: 35vh;
  }

  .teaches-select .select__option {
    padding: 17px 0;
    font-weight: 400;
    font-size: 16px;
  }
}

.pp-teacher-video .pp__content {
  padding: 0;
  border-radius: 0;
  overflow-y: visible;
  overflow-x: visible;
  height: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-clip-path: none;
  clip-path: none;
  padding-bottom: calc((900px / 16) * 9);
  background-color: transparent;
}

@media (max-width: 1023px) {
  .pp-teacher-video .pp__content {
    padding-bottom: calc((85% / 16) * 9);
    width: 85%;
  }
}

@media (max-width: 1023px) {
  .pp-teacher-video .pp__content {
    padding-bottom: calc((100% / 16) * 9);
  }
}

@media (max-width: 767px) {
  .pp-teacher-video .pp__content {
    padding-bottom: calc((90% / 16) * 9);
    width: 90% !important;
    height: auto !important;
    top: calc(90% - calc((90% / 16) * 9));
    left: 0;
    right: 0;
  }
}

.pp-teacher-video .pp__close {
  top: -35px;
  right: -35px;
}

@media (max-width: 767px) {
  .pp-teacher-video .pp__close {
    right: 0;
  }
}

.pp-teacher-video .close__lane {
  background-color: white;
}

.pp__video {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 250ms ease-in;
  -o-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
}

.premier__label {
  background-image: url(/svg/premium-ico.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.premier__label_ru {
  background-image: url(/svg/premium-ico_ru.svg);
}

.headig-premier__label.premier__label {
  width: 155px;
  height: 45px;
  margin-left: 20px;
}

@media (max-width: 1799px) {
  .headig-premier__label.premier__label {
    margin-left: 17px;
    width: 137px;
    height: 40px;
  }
}

@media (max-width: 1430px) {
  .headig-premier__label.premier__label {
    margin-left: 12px;
    width: 107px;
    height: 31px;
  }
}

@media (max-width: 1279px) {
  .headig-premier__label.premier__label {
    margin-left: 15px;
    width: 91px;
    height: 26px;
  }
}

@media (max-width: 1023px) {
  .headig-premier__label.premier__label {
    margin-left: 13px;
  }
}

@media (max-width: 767px) {
  .headig-premier__label.premier__label {
    width: 91px;
  }
}

.teacher__vis-premier__label.premier__label {
  position: absolute;
  top: 22px;
  left: 22px;
  width: 93px;
  height: 27px;
}

@media (max-width: 1799px) {
  .teacher__vis-premier__label.premier__label {
    width: 75px;
    height: 21px;
    top: 16px;
    left: 16px;
  }
}

@media (max-width: 1430px) {
  .teacher__vis-premier__label.premier__label {
    width: 70px;
    height: 20px;
    top: 10px;
    left: 15px;
  }
}

@media (max-width: 1023px) {
  .teacher__vis-premier__label.premier__label {
    width: 83px;
    height: 23px;
    top: 0;
    left: auto;
    right: 0;
  }
}

@media (max-width: 767px) {
  .teacher__vis-premier__label.premier__label {
    width: 62px;
    height: 18px;
  }
}

.teacher-card__profile-premier-label.premier__label {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 93px;
  height: 27px;
}

@media (max-width: 1799px) {
  .teacher-card__profile-premier-label.premier__label {
    top: 20px;
    left: 20px;
  }
}

@media (max-width: 1430px) {
  .teacher-card__profile-premier-label.premier__label {
    width: 80px;
    height: 23px;
    top: 15px;
    left: 16px;
  }
}

@media (max-width: 1279px) {
  .teacher-card__profile-premier-label.premier__label {
    top: 15px;
    left: 20px;
  }
}

@media (max-width: 1023px) {
  .teacher-card__profile-premier-label.premier__label {
    top: 16;
    left: auto;
    right: 40px;
  }
}

@media (max-width: 767px) {
  .teacher-card__profile-premier-label.premier__label {
    width: 96px;
    height: 27px;
    top: 19px;
    left: 21px;
    right: auto;
  }
}

.pp-teacher-choice .pp-profile-delete__logo {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  overflow: hidden;
}

/* pp convertation start */
.pp-convert-tarif__content {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: auto;
  max-width: 95%;
}

@media (max-width: 767px) {
  .pp-convert-tarif__content {
    display: block;
    max-width: 100%;
  }
}

.pp-convert-tarif__content .pp__close {
  top: 30px;
  right: 36px;
  width: 18px;
  height: 18px;
}

@media (max-width: 767px) {
  .pp-convert-tarif__content .pp__close {
    top: 20px;
    right: 20px;
  }
}

.pp-convert-part {
  padding: 32px 54px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .pp-convert-part {
    padding: 50px 44px 40px;
    display: block;
  }
}

.pp-convert-part_left {
  background-color: #EFF3F7;
  max-width: 395px;
}

.pp-convert-part_right {
  padding-right: 36px;
  max-width: 470px;
  background-image: url(/svg/widget-picture-3.svg);
  background-repeat: no-repeat;
  background-position: right top;
}

.pp-convert-part__title {
  margin-bottom: 18px;
}

.pp-convert-tarif__descr {
  color: #8995AA;
  border-top: 1px solid #D2DCEF;
  border-bottom: 1px solid #D2DCEF;
  padding: 15px 0;
  margin: 15px 0;
}

.pp-convert-tarif__name {
  margin-bottom: 15px;
}

.pp-convert-tarif__count {
  color: #0A0A0B;
}

.pp-convert-price {
  font-size: 48px;
  line-height: 1;
  margin: 12px 0 20px;
  display: block;
}

.pp-convert-price span {
  display: block;
}

.pp-convert-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .pp-convert-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.pp-convert-footer__politic {
  margin-bottom: 15px;
  position: relative;
  color: black;
}

.pp-convert-footer__politic input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.pp-convert-footer__politic a {
  text-decoration: underline;
  margin-left: 5px;
}

.pp-convert-footer__politic label {
  position: relative;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: rgba(10, 10, 11, 0.67);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.pp-convert-footer__politic label::before,
.pp-convert-footer__politic label::after {
  content: "";
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin-right: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.pp-convert-footer__politic label::after {
  position: absolute;
  left: -1px;
  top: -5px;
  width: 20px;
  height: 15px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxOSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNi44OTQ3NEMxLjk5NTI3IDcuOTA1MjYgNi4yNTI0MiAxMS4zODYgOC4yNTY0NiAxM0wxOCAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
  background-size: contain;
  border: none;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.pp-convert-footer__politic input:checked ~ label::after {
  opacity: 1;
  visibility: visible;
}

.pp-convert-footer__button {
  padding: 0 60px;
}

.pp-convert-footer__right {
  margin-left: 105px;
}

@media (max-width: 767px) {
  .pp-convert-footer__right {
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;
  }
}

.pp-convert-footer__label {
  width: 90px;
  height: 30px;
  border: 2px solid #0A0A0B;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 15px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

@media (max-width: 767px) {
  .pp-convert-footer__label {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.pp-convert-footer__label:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .pp-convert-footer__label:last-child {
    margin-right: 0px;
  }
}

.pp-convert-footer__label.active {
  border-color: #16D9C2;
  color: #16D9C2;
}

.pp-convert-price_general {
  color: #16D9C2;
}

.pp-convert-price_secondary {
  font-size: 32px;
  line-height: 1;
  color: #0A0A0B;
  text-decoration: line-through;
  margin-top: 10px;
}

.pp-convert-price_label {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #16D9C2;
  margin-top: 10px;
}

.promocode {
  display: block;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}

.promocode-label {
  color: #16D9C2;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
}

.promocode-form {
  display: block;
  position: relative;
}

.promocode-form__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 290px;
  text-transform: uppercase;
  height: 44px;
}

.promocode-form__input {
  text-transform: inherit;
  border: none;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  color: #8995AA;
  background-color: #EFF3F7;
  border-radius: 8px 0 0 8px;
  padding: 0 19px;
  height: inherit;
  -webkit-box-shadow: inset 0 0 0 100px #EFF3F7;
  box-shadow: inset 0 0 0 100px #EFF3F7;
  min-width: 0;
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}

.promocode-form__input::-webkit-input-placeholder {
  color: #BCC4D1;
}

.promocode-form__input:-ms-input-placeholder {
  color: #BCC4D1;
}

.promocode-form__input::-ms-input-placeholder {
  color: #BCC4D1;
}

.promocode-form__input::placeholder {
  color: #BCC4D1;
}

.promocode-form__input.error {
  color: #FF3366;
}

.promocode-form__button {
  height: inherit;
  border-radius: 0 8px 8px 0;
  padding: 0 17px;
  width: auto;
  min-width: initial;
  font-weight: bold;
  font-size: 13px;
  text-transform: inherit;
}

.promocode-form__errors {
  font-family: "Montserrat";
  font-weight: 500;
  color: #FF3366;
  font-size: 10px;
  line-height: 1;
  margin-top: 5px;
}

.promocode-active {
  background-color: rgba(5, 222, 197, 0.2);
  border-radius: 8px;
  height: 44px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 19px;
  color: #16D9C2;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  cursor: pointer;
}

.promocode-active__reset {
  margin-left: 28px;
  width: 15px;
  height: 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.promocode-active__reset::before, .promocode-active__reset::after {
  content: "";
  background-color: #16D9C2;
  position: absolute;
  width: 100%;
  height: 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.promocode-active__reset::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.promocode-active:hover .promocode-active__reset {
  opacity: .3;
}

/* pp convertation end */
/* Start radio-btns */
.radio__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .radio__btns {
    display: block;
  }
}

.radio__btn {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .radio__btn + .radio__btn {
    margin-top: 18px;
  }
}

.radio__btn-input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.radio__btn-input:checked ~ .radio__btn-label .radio__btn-label-check::before {
  opacity: 1;
}

.radio__btn-label {
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 32px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-align: left;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

@media (max-width: 1799px) {
  .radio__btn-label {
    padding-left: 26px;
  }
}

@media (max-width: 1430px) {
  .radio__btn-label {
    padding-left: 24px;
    font-size: 13px;
  }
}

@media (max-width: 1279px) {
  .radio__btn-label {
    padding-left: 26px;
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 767px) {
  .radio__btn-label {
    padding-left: 38px;
    font-size: 14px;
    line-height: 18px;
  }
}

.radio__btn-label-check {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.radio__btn-label-check::before {
  content: "";
  opacity: 0;
  width: 27px;
  height: 21px;
  position: absolute;
  bottom: 4px;
  left: -5px;
  background: url("/svg/radio-check-icon.svg") no-repeat center;
  background-size: contain;
}

.projects__controls-btn.active .radio__btn-label-check::before {
  opacity: 1;
}

/* End radio-btns */
/*# sourceMappingURL=styles.css.map */


.courses-tarif-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.courses-tarif {
  position: relative;
  overflow: hidden;
  width: 49%;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  padding: 68px 54px
}

.courses-tarif__img {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 28px
}

.courses-tarif__img img {
  width: 100%;
  height: 100%;
  width: 280px;
  height: 280px;
  object-fit: cover;
  clip-path: circle(48% at 50% 50%);
  transform: scale(1);
  transition: .33s
}

.courses-tarif:hover .courses-tarif__img img {
  clip-path: circle(50% at 50% 50%);
  transform: scale(1.1)
}

.courses-tarif__title {
  margin-bottom: 12px
}

.courses-tarif__title span {
  font-size: 38px;
  font-family: Evolventa;
  font-weight: 600
}

.courses-tarif__label {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 174px;
  margin: 0 auto;
  margin-bottom: 32px
}

.courses-tarif__label img {
  width: 100%
}

.courses-tarif__text {
  margin-bottom: 38px
}

.custom-weight {
  width: 100% !important;
}

.button-translate-edit {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.button-translate-edit:hover {
  color: #03a9f4;
}

.list-ms_edit-cont {
  background: #4987ff;
  position: absolute;
  top: -40px;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  border: 1px solid;
  left: 8px;
  cursor: pointer;
}

.list-ms_edit-cont-not-top {
  top: unset !important;
}

.tableKeyItem:hover {
  cursor: pointer;
  color: #03a9f4;
}

.training__study-modal-subtitle {
  font-size: 18px;
}

.training__study-modal-text {
  font-size: 16px;
}

.training__study-modal-select-wrapper {
  margin-bottom: 15px;
  align-items: center;
  display: flex;
}

.training__study-modal-select-wrapper > p {
  margin-right: 10px;
}

.training__study-modal-select-wrapper > div {
  margin-right: 10px;
}

.eapp-whatsapp-chat-root-layout-component > div {
  z-index: 10!important;
}

.main-blue-banner__container {
  display: block;
  margin-bottom: 70px;
}

.main-blue-banner {
  position: relative;
  height: 210px;
  background: #050425;
  border-radius: 20px;
  overflow: hidden;

  background-image: url(/img/dark-bunner-bg-right.png);
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: right center;
}

.main-blue-banner img {
  margin: 23px 0 23px 0;
}

.main-blue-banner .h2 {
  font-family: "Evolventa", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #fff;
  font-size: 26px;
  line-height: 110%;
  font-weight: 700;
  margin-bottom: 16px;
}

.main-blue-banner .green-text {
  font-family: "Evolventa", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #16D9C2;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.main-blue-banner .main-blue-banner__content {
  width: 50%;
  margin: 0 24px;
  text-align: center;
}

@media (max-width: 767px) {
  .main-blue-banner {
    height: auto;
    flex-direction: column;
  }

  .main-blue-banner img {
    display: none;
  }

  .main-blue-banner .main-blue-banner__content {
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
  }
}

.registration-buttons {
  margin-left: -40px;
  margin-right: -40px;
}

.registration-buttons .button {
  min-width: auto;
  font-size: 12px;
}

@media (max-width: 1023px) {
  .registration-buttons {
    margin-left: 0;
    margin-right: 0;
  }
}