:root {
  --colorWhite: #ffffff;
  --colorBlack: #0A0A0B;
  --colorBlue: #4987FF;
  --gridWidth: 1300px
}

/** {*/
/*  -webkit-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*  outline: 0 !important*/
/*}*/

/*body,*/
/*html {*/
/*  width: 100%*/
/*}*/

/*body {*/
/*  font-family: Montserrat, sans-serif;*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*  color: var(--colorBlack);*/
/*  position: relative;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  font-smoothing: antialiased;*/
/*  font-display: swap;*/
/*  margin: 0;*/
/*  scroll-behavior: smooth*/
/*}*/

.landing footer,
.landing header,
.landing main {
  width: 100%;
  min-width: 375px;
  max-width: 100vw
}

::-moz-selection {
  background: rgba(73, 135, 255, .6)
}

::selection {
  background: rgba(73, 135, 255, .6)
}

::-moz-selection {
  background: rgba(73, 135, 255, .6)
}

.btn_no-wrap {
  white-space: nowrap;
}

.trial-lesson-price-label {
  position: absolute;
  right: 75px;
  bottom: 25px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
  background-color: #f36;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.purchase-wizzard-price {
  right: -20px;
  bottom: 20px;
  font-size: 14px;
  margin-left: 15px;
  padding: 0 10px;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.landing input {
  width: 100%;
  padding: 18px 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #8995aa;
  color: #0a0a0b;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0 !important;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing input:focus,
.landing input:hover,
.landing textarea:focus,
.landing textarea:hover {
  border-bottom: 1px solid #0a0a0b !important
}

.landing input::-webkit-input-placeholder {
  font-family: Montserrat, sans-serif;
  color: #8995aa
}

textarea::-webkit-input-placeholder {
  font-family: Montserrat, sans-serif;
  color: #8995aa
}

.landing input:-moz-placeholder {
  font-family: Montserrat, sans-serif;
  color: #8995aa
}

.landing textarea:-moz-placeholder {
  font-family: Montserrat, sans-serif;
  color: #8995aa
}

.landing input::-moz-placeholder {
  font-family: Graphik, sans-serif;
  color: #8995aa
}

.landing textarea::-moz-placeholder {
  font-family: Graphik, sans-serif;
  color: #8995aa
}

.landing input:-ms-input-placeholder {
  font-family: Graphik, sans-serif;
  color: #8995aa
}

.landing input:-ms-input-placeholder {
  font-family: Graphik, sans-serif;
  color: #8995aa
}

.landing textarea:-ms-input-placeholder {
  font-family: Graphik, sans-serif;
  color: #8995aa
}

.landing input.error {
  border-bottom: 1px solid #f36 !important
}

.landing img,
.landing picture,
.landing video {
  max-width: 100%;
  max-height: 100%
}

.landing ol,
.landing ul {
  padding: 0;
  margin: 0
}

.landing h1,
.landing h2,
.landing h3,
.landing h4,
.landing h5,
.landing h6 {
  font-family: Evolventa, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.1;
  font-weight: 600;
  color: #0a0a0b
}

.landing a {
  text-decoration: none;
  outline: 0 !important;
  color: inherit;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  will-change: opacity
}

.landing a:hover {
  opacity: .5
}

.landing a:active {
  text-decoration: none;
  color: inherit
}

.landing button {
  outline: 0 !important;
  border: none;
  background-color: unset;
  padding: 0;
  margin: 0
}

.landing h1 {
  font-size: 64px;
  line-height: 1.2
}

.landing h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 130%
}

.landing p {
  font-size: 18px;
  line-height: 1.9;
  color: rgba(10, 10, 11, .85);
  margin: 0
}

.landing p.small {
  font-size: 15px;
  line-height: 160%
}

.landing .noscroll {
  overflow: hidden !important
}

.landing .overflow-x_hidden {
  overflow-x: hidden;
}

.landing .sections {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(14.01%, #fff), color-stop(86.75%, rgba(255, 255, 255, 0))), #e0ebf0;
  background: -o-linear-gradient(top, #fff 14.01%, rgba(255, 255, 255, 0) 86.75%), #e0ebf0;
  background: linear-gradient(180deg, #fff 14.01%, rgba(255, 255, 255, 0) 86.75%), #e0ebf0;
  padding-top: 80px;
  z-index: 2
}

.landing .header {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px 46px;
  border-bottom: 2px solid #e4ebef;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background: #fff
}

.landing .header__logo {
  display: block;
  width: 200px;
  height: 40px;
  margin-right: 62px
}

.landing .header__logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.landing .header__nav {
  margin-right: auto
}

.landing .header__nav > a {
  margin-right: 40px;
  opacity: 1;
  position: relative;
  display: inline-block
}

.landing .header__nav .header-float__nav,
.landing .header__nav a span {
  transition: .3s;
  font-weight: 600;
  font-size: 16px;
  color: #0a0a0b
}

.landing .wrapper_dark .header__nav .header-float__nav,
.landing .wrapper_dark .header__nav a span {
  color: #FFFFFF
}

.landing .header__nav a:hover span {
  opacity: .6
}

.landing .header__lang .language-selector__button > span {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.landing .header__lang {
  cursor: pointer;
  margin-right: 32px
}

.landing .header__lang:hover .language-selector__button > span {
  color: #797979;
}

.landing .header-lang {
  display: inline-block;
  position: relative;
  cursor: pointer;
  opacity: 1 !important;
}

.landing .header-lang__lable {
  position: relative
}

.landing .header-black__language .language-selector__item span {
  color: #000000 !important;
}

.landing .header-black__language .language-selector__item span {
  color: #000000 !important;
}

.landing .header-lang__lable span {
  font-weight: 600;
  font-size: 16px
}

.landing .header__buttons {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .btn {
  display: inline-block;
  position: relative;
  padding: 16px 34px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  opacity: 1
}

.landing a.btn.btn_header,
.landing a.wrapper_btn {
  margin-left: 14px;
}

.landing .btn.btn_header {
  padding: 12px 24px;
  height: 48px
}

.landing .btn.btn_blue-transp {
  border: 2px solid #4987ff
}

.landing .btn.btn_blue {
  background: #4987ff;
  border-color: transparent
}

.landing .btn.btn_blue span {
  color: #fff
}

.landing .wrapper {
  margin-bottom: 493px;
  min-width: 375px;
}

.landing .section.section_main {
  overflow: hidden
}

.landing .section {
  position: relative;
  padding: 114px 0 62px;
  z-index: 1
}

.landing .btn.btn_blue-transp span {
  color: #4987ff
}

.landing .btn span {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Evolventa;
  z-index: 2
}

.landing .btn.btn_black {
  background: #0a0a0b;
  border: transparent
}

.landing .btn.btn_black span {
  color: #fff
}

.landing .grid {
  width: 1300px;
  margin: 0 auto
}

.grid_min-height {
  min-height: 500px;
}

.landing .grid.grid_l-r {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  position: relative;
  z-index: 2
}

.landing .grid__main-l {
  width: 65%
}

.landing .grid__main-r {
  width: 35%
}

.landing .section.section_main.section_main-page .grid__main-r {
  height: 735px;
}

.landing .title-block-main {
  margin-bottom: 38px
}

.landing .title__row-animated {
  overflow: hidden
}

.landing .title__row-animated span {
  display: block;
  font-size: 75px;
  font-weight: 700;
  line-height: 1;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%)
}

.landing .title__row-animated.title__row-animated_start span {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.landing .color-blue {
  color: #4987ff
}

.landing .color-gray {
  color: #b9b9b9
}

.landing .text-block {
  font-size: 22px;
  line-height: 1.5
}

.landing .text-block p {
  font-size: 18px;
  line-height: 1.9
}

.landing .buttons-block {
  z-index: 3;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  position: relative;
  margin-top: 52px
}

.landing .lable {
  position: relative;
  width: 300px;
  height: 200px;
  top: -40px;
  left: 12px
}

.landing .mouse-block {
  margin-top: 88px
}

.landing .mouse {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer
}

.landing .mouse img {
  width: 28px;
  height: 44px;
  margin-right: 22px
}

.landing .mouse span {
  display: block;
  color: #bebebe;
  font-size: 19px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-bottom: 1px solid transparent
}

.landing .mouse:hover span {
  border-bottom: 1px solid
}

.landing .title-block {
  margin-bottom: 58px
}

.landing .title-block__row-animated {
  display: block;
  overflow: hidden
}

.landing .title-block__row-animated span {
  display: block;
  font-size: 54px;
  font-family: Evolventa;
  line-height: 120%;
  font-weight: 600
}

.landing .title-block__row-animated.title-block__row-animated_main span {
  font-size: 75px;
  line-height: 107%
}

.landing .grid__half-l {
  width: 50%
}

.landing .grid__half-r {
  width: 50%
}

.landing .section.section_blue {
  background: #4987ff
}

.landing .color-white {
  color: var(--colorWhite)
}

.landing .btn.btn_capitalize span {
  text-transform: initial
}

.landing .section.section_untop {
  background: 0 0;
  padding: 0;
}

.landing .section.section_padbot-big {
  /*background: url("public/landing/svg/bg-padbot-big.svg") no-repeat center;*/
  background-size: cover;
  padding: 144px 0 444px
}

.landing .untop-bg {
  /*background: url("public/landing/svg/bg-padbot-big.svg") no-repeat center;*/
  background-size: cover
}

.landing .card-img-text {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 62px 43px;
  height: 100%;
  background: #fff;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .14);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .14);
  border-radius: 20px
}

.landing .card-img-text__img {
  width: 160px
}

.landing .card-img-text__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .card-img-text__txt {
  width: calc(100% - 160px);
  line-height: 1.5;
  max-width: 320px;
  padding-left: 50px
}

.landing .card-img-text__txt span {
  font-weight: 600;
  font-size: 22px
}

.landing .sticky-wrap {
  position: relative;
  z-index: 2
}

.landing .custom .scrollmagic-pin-spacer {
  overflow-x: hidden;
}

.landing .custom .title-block {
  margin-bottom: 100px;
}

.landing .custom .sticky-container {
  margin-bottom: 230px;
}

.landing .sticky-container {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
  padding-top: 204px;
  z-index: 2;
}

.landing .custom-scrollbar {
  position: absolute;
  right: 100px;
  bottom: 0;
  left: -100px;
}

.landing .hor-slider-cont {
  width: max-content !important;
  padding: 0 2.5% 0 5% !important
}

.landing .hor-container {
  position: static !important
}

.landing .sticky-container:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  margin: auto;
  height: 400px;
  background: #4987ff;
  z-index: 1
}

.landing .wide-container-slider {
  position: relative;
  margin-top: -295px;
  z-index: 2;
}

.landing .wide-container {
  position: relative;
  z-index: 1;
  overflow: hidden
}

.landing .sticky-slider > .scrollmagic-pin-spacer {
  padding-bottom: 0;
}

.landing .sticky-slider-slide {
  width: 585px;
  margin-right: 25px;
  height: 100%;
}

.landing .grid.grid_wide {
  width: 100%;
  position: relative
}

.landing .grid.grid_rel {
  position: relative
}

.landing .sticky-slider-scrollbar,
.landing .swiper-scrollbar {
  opacity: 1 !important;
  background: #dce4e9
}

.landing .sticky-slider-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding-bottom: 50px;
}

.landing .sticky-slider_show-mobile {
  background: #F5F8F9;
  display: none;
}

.landing .swiper-scrollbar-drag {
  background: #4987ff
}

.landing .sticky-slider-scrollbar {
  z-index: 50;
  height: 5px;
  background: #dce4e9;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.landing .sticky-slider-scrollbar__drag {
  height: 100%;
  position: relative;
  background: #4987ff;
  border-radius: 10px;
  left: 0;
  top: 0;
}

.landing .title-block.title-block_centered {
  text-align: center;
  margin-bottom: 12px
}

.landing .text-block.text-block_centered {
  text-align: center
}


.landing .swiper-container.swiper-container_people {
  margin-top: 114px
}

.landing .slider-person {
  width: 100%;
  cursor: pointer
}

.landing .slider-person__img {
  width: 288px;
  height: 288px;
  overflow: hidden;
  border-radius: 50%
}

.landing .slider-person__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .slider-personuuuuuuu {
  width: 200px;
  height: 100px
}

.landing .buttons-block.buttons-block_centered {
  text-align: center
}

.landing .buttons-block.buttons-block_centered .btn {
  margin: 0 auto
}

.landing .popcards {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: 3000px;
  margin-bottom: -190px
}

.landing .popcards__item {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-bottom: 400px;
  margin-top: 123px
}

.landing .popcard {
  padding: 58px 72px;
  border-radius: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1)
}

.landing .popcards__item {
  transition: transform .3s;
}

.landing .popcards__item_scale:nth-child(1) > .popcard {
  transform: scale(0.8);
  transition: transform .3s;
}

.landing .popcards__item_scale:nth-child(2) > .popcard {
  transform: scale(0.9);
  transition: transform .3s;
}

.landing .popcards .popcards__item_assembly {
  /*transform: translateY(-62px);*/
  /*position: relative !important;*/
  /*inset: initial !important;*/
}

.landing .popcard.popcard_blue {
  background: #2264e4
}

.landing .popcard.popcard_green {
  background: #05dec5
}

.landing .popcard.popcard_lightblue {
  background: #4987ff
}

.landing .popcard-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.landing .popcard__info {
  width: calc(100% - 448px);
  padding-right: 42px
}

.landing .popcard-info__title {
  font-weight: 700;
  font-size: 38px;
  color: #fff;
  margin-bottom: 18px
}

.landing .popcard-info__text {
  margin-bottom: 42px
}

.landing .btn.btn_white {
  background: #fff
}

.landing .popcard__img {
  width: 372px
}

.landing .popcard__nums {
  color: rgba(255, 255, 255, .45);
  font-size: 31px;
  margin-bottom: auto;
  white-space: nowrap
}

.landing .popcard__img img {
  width: 100%
}

.landing .popcard-info__text p {
  color: #fff
}

.landing .popcards__item:nth-child(1) {
  top: 300px
}

.landing .popcards__item:nth-child(2) {
  top: 350px
}

.landing .popcards__item:nth-child(3) {
  top: 400px
}

.landing .swiper-container_reviews .swiper-container {
  overflow: visible;
  max-width: 1300px;
  width: 92%;
  margin-bottom: 50px;
}

.landing .swiper-container_reviews .swiper-scrollbar {
  background: rgba(255, 255, 255, .15)
}

.landing .swiper-container_reviews .swiper-pagination .swiper-pagination-progressbar-fill {
  background: #FFFFFF;
}

.landing .swiper-container_reviews .swiper-pagination {
  top: auto !important;
  bottom: -50px;
  height: 5px !important;
  background: rgba(255, 255, 255, .15);
}

.landing .function {
  margin-bottom: 72px;
  padding-bottom: 72px;
  border-bottom: 3px solid #dce4e9
}

.landing .function:nth-last-child(1) {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0
}

.landing .function-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  position: relative;
  z-index: 2
}

.landing .function__img {
  position: relative;
  width: calc(50% + 10%);
  padding-right: 42px;
  margin-left: -10%;
  z-index: 2
}

.landing .function__img img {
  width: 100%
}

.landing .function__info {
  width: 50%;
  padding-top: 54px;
  padding-right: 42px
}

.landing .function-info__title {
  margin-bottom: 32px
}

.landing h3 {
  font-size: 54px
}

.landing .function__info + .function__img {
  padding-right: 0;
  margin-left: 0;
  margin-right: -5%
}

.landing .function__img + .function__info {
  padding-right: 0;
  z-index: 2
}

.landing .section.section_reviews {
  margin-top: 50px;
  background: #4987ff;
  padding: 124px 0 106px
}

.landing .swiper-slide:not(:last-child) .review-card {
  margin-right: 20px;
}

.landing .review-card {
  position: relative;
  height: 100%;
  background: #fff;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .14);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .14);
  border-radius: 20px;
  padding: 25px 30px;
  width: 290px;
}

.landing .review-card__text {
  padding-bottom: 52px;
  margin-bottom: 118px
}

.landing .review-card__text p {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.landing .review-card__user {
  padding-top: 22px;
  border-top: 1.5px solid rgba(137, 149, 170, .15)
}

.landing .review-card-user {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  align-items: center
}

.landing .review-card-user__img {
  width: 60px;
  height: 60px
}

.landing .review-card-user__img img {
  width: 100%;
  height: 100%
}

.landing .review-card-user__info {
  width: calc(100% - 90px);
  padding-left: 22px
}

.landing .review-card-user__name {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px
}

.landing .review-card-user__dscrp {
  color: rgba(5, 4, 37, .35);
  font-size: 14px;
  padding-top: 22px
}

.landing .swiper-container_reviews .swiper-scrollbar-drag {
  background: #fff
}

.landing .section.section_regist {
  padding: 118px 0 138px
}

.landing .regist {
  border-radius: 20px;
  padding: 82px 100px;
  background: url("/landing/svg/regist-bg.svg") no-repeat center;
  background-size: cover;
  background-color: #fff
}

.landing .regist-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch
}

.landing .regist__info {
  width: 55%
}

.landing .regist-info__title {
  margin-bottom: 28px
}

.landing .regist-info__text {
  margin-bottom: 52px
}

.landing .regist__img {
  pointer-events: none;
  position: relative;
  width: 45%
}

.landing .regist-img__scrreen {
  width: 700px;
  margin-top: -138px;
  margin-left: -88px;
  margin-bottom: -124px
}

.landing .footer-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #0a0a0b;
  overflow: hidden
}

.landing .footer-top {
  padding: 52px 0 45px;
  position: relative
}

.landing .footer-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  position: relative
}

.cost-swiper {
  overflow-x: hidden;
}

.cost-swiper .swiper-wrapper {
  display: flex;
}

.cost-swiper .swiper-slide {
  width: 415px;
  min-width: 415px;
  height: auto;
}

.cost-swiper .slidercost {
  margin-right: 22px;
  margin-bottom: 20px;
}

.landing .footer-cont__col.footer-cont__col_1 {
  width: 28%
}

.landing .footer-logo {
  width: 214px
}

.landing .footer-logo img {
  width: 100%
}

.landing .footer-cont__col.footer-cont__col_2 {
  width: 56%
}

.landing .footer-cont__col.footer-cont__col_3 {
  width: 16%;
  white-space: nowrap
}

.landing .footer-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 38px;
  margin-top: 6px
}

.landing .footer-contacts {
  margin-bottom: 38px
}

.landing .footer-contacts__item {
  margin-bottom: 38px
}

.landing .footer-contacts__item:nth-last-child(1) {
  margin-bottom: 0
}

.landing .footer-contacts-link {
  margin-bottom: 8px
}

.landing .footer-contacts-link a {
  font-size: 18px;
  color: #fff
}

.landing .footer-contacts-linkdscrp {
  font-size: 14px;
  color: rgba(255, 255, 255, .45)
}

.landing .footer-socs__item {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 44px;
  height: 44px;
  margin-right: 12px;
}

.landing .footer-socs__item:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #171719;
  border-radius: 50%
}

.landing .footer-socs__item img {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.landing .footer-socs__item.yandex-dzen img {
  width: 30px;
  height: 30px
}

.landing .footer-socs__item:nth-last-child(1) {
  margin-right: 0
}

.landing .page-up {
  position: absolute;
  bottom: 72px;
  right: -12%;
  width: 44px;
  height: 44px;
  cursor: pointer
}

.landing .page-up:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  border-radius: 50%;
  transition: .3s;
  transform: scale(1)
}

.landing .page-up:hover:before {
  transform: scale(1.1)
}

.landing .page-up img {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto
}

.landing .footer-pays:after,
.landing .footer-top:after {
  content: '';
  position: absolute;
  width: 250%;
  left: -75%;
  right: -75%;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #151414
}

.landing .footer-bot {
  padding: 22px 0
}

.landing .footer-dev {
  display: block
}

.landing .footer-text a,
.landing .footer-text span {
  font-size: 14px;
  color: #6d6d6d
}

.landing .img-top-bg-temporarily {
  height: 100%;
  width: 160%
}

.landing .function {
  position: relative
}

.landing p a {
  border-bottom: 2px solid
}

.landing p a:hover {
  border-bottom: 2px solid transparent
}

.landing .function.function_bordnone {
  border-bottom: 0;
  padding-bottom: 0
}

.landing .grid__main-l_short {
  padding-right: 178px
}

.landing .contacts {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  margin-top: 92px
}

.landing .contacts__item {
  margin-right: 42px;
  padding-right: 42px;
  border-right: 2px solid rgba(0, 0, 0, .06)
}

.landing .contacts__link {
  margin-bottom: 8px
}

.landing .contacts__link a {
  font-weight: 600;
  font-size: 36px
}

.landing .contacts__link-dsrcp span {
  color: #4987ff;
  font-weight: 600;
  font-size: 16px
}

.landing .contacts-socs {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch
}

.landing .contacts-socs__item {
  display: inline-block;
  position: relative;
  width: 66px;
  height: 66px;
  margin-right: 26px;
  cursor: pointer
}

.landing .contacts__item:nth-last-child(1) {
  margin-right: 0;
  border-right: 0
}

.landing .contacts-socs__item:nth-last-child(1) {
  margin-right: 0
}

.landing .contacts-socs__item:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #303030;
  border-radius: 50%
}

.landing .contacts-socs__item.contacts-socs__item_sk:before {
  background: #03a9f4
}

.landing .contacts-socs__item.contacts-socs__item_tg:before {
  background: #039be5
}

.landing .contacts-socs__item.contacts-socs__item_vi:before {
  background: #1bd741
}

.landing .contacts-socs__item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 34px;
  height: 34px
}

.landing .cost-info {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 52px
}

.landing .cost-info-part {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .cost-lable {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  background: #ecf2f6;
  border-radius: 7px;
  padding: 10px 18px
}

.landing .cost-info__title {
  font-weight: 600;
  font-size: 22px;
  margin-right: 32px
}

.landing .cost-info__logos {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .cost-info-logo {
  margin-right: 32px
}

.landing .cost-info-logo:nth-last-child(1) {
  margin-right: 0
}

.landing .cost-lable img {
  margin-right: 10px
}

.landing .cost-lable span {
  font-size: 14px;
  color: #8995aa;
  white-space: pre
}

.landing .cost-cards-wrap {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch
}

.landing .cost-card {
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  width: calc(100% / 3);
  border-radius: 20px;
  margin-right: 24px;
  position: relative;
  padding: 62px 48px;
  flex-flow: column;
  background: #fff;
  display: flex;
}

.landing .cost-card:nth-child(1) {
  z-index: 3
}

.landing .cost-card:nth-child(2) {
  z-index: 2
}

.landing .cost-card:nth-child(3) {
  z-index: 1
}

.landing .cost-card:nth-last-child(1) {
  margin-right: 0
}

.landing .cost-card__title {
  position: relative;
  margin-bottom: 32px
}

.landing .cost-card-title__num {
  display: inline-block;
  font-weight: 600;
  font-size: 48px;
  margin-right: 12px
}

.landing .cost-card-title__lesson {
  display: inline-block;
  font-weight: 600;
  font-size: 38px
}

.landing .cost-card-lable {
  position: absolute;
  top: -22px;
  right: -111px;
  width: 200px
}

.landing .cost-card-lable img {
  width: 100%
}

.landing .cost-card__text {
  margin-bottom: 38px
}

.landing .cost-card__text p {
  color: rgba(10, 10, 11, .67);
  font-size: 15px
}

.landing .cost-card__vals {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-line-pack: stretch;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-direction: row;
  -webkit-box-pack: start;
  align-content: stretch;
  -ms-flex-align: center;
  -ms-flex-wrap: nowrap;
  -ms-flex-pack: start;
  display: -ms-flexbox;
  display: -webkit-box;
  flex-direction: row;
  margin-bottom: 28px;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: auto;
  display: flex;
}

.landing .cost-card-val span {
  font-weight: 600;
  font-size: 38px;
  color: #0a0a0b
}

.landing .cost-card-val img {
  width: 28px;
  height: 26px;
  -o-object-fit: contain;
  object-fit: contain;
}

.landing .cost-card-slash {
  width: 20px;
  margin-left: 4px;
  margin-right: 14px
}

.landing .cost-card-slash img {
  height: 100%
}

.landing .centered-slider-wrap {
  padding: 224px 0
}

.landing .swiper-container.swiper-container_centered {
  text-align: center
}

.landing .centered-slider-item {
  width: 622px;
  margin: 0 auto
}

.landing .centered-slider-item__title {
  margin-bottom: 58px
}

.landing .centered-slider-item__text p {
  font-size: 25px
}

.landing .swiper-pagination_centered {
  margin-top: 42px
}

.landing .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 47px;
  height: 3px;
  border-radius: 0;
  margin: 0 15px;
  opacity: .3;
  background: #fff
}

.landing .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1
}

.landing .btn.btn_main {
  padding: 22px 48px
}

.landing .section.section_padbot-big .text-block p {
  font-size: 22px
}

.landing section.container_padding-b {
  padding-bottom: 158px;
}

.landing .section.section_people {
  padding: 158px 0 158px
}

.landing .title-block__row-animated .title-big {
  font-size: 64px;
  line-height: 1.3
}

.landing .text-block p.text-big {
  font-size: 22px;
  font-weight: 600
}

.landing .section.section_main.section_main-page {
  padding: 60px 0 92px;
}

.landing .section.section_main.section_main-teaching {
  padding: 156px 0 82px
}

.landing .section_main-teaching .title-block__row-animated:nth-last-child(1) {
  margin-top: 18px
}

.landing .teachers-list {
  margin-bottom: 52px
}

.landing .teacher {
  background: #fff;
  margin-bottom: 24px;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  border-radius: 20px;
  cursor: pointer
}

.landing .teacher.aos-animate {
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .teacher.aos-animate:hover {
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .14);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .14)
}

.landing .teacher-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch
}

.landing .teacher__vis {
  width: 30%;
  padding: 42px 38px
}

.landing .teacher-vis {
  height: 100%
}

.landing .teacher-vis__img {
  width: 116px;
  height: 116px;
  margin-bottom: 16px
}

.landing .teacher-vis__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%
}

.landing .teacher-vis-info__name {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 6px
}

.landing .teacher-vis-info__place {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 12px
}

.landing .teacher-vis-info__place-city {
  color: #8995aa;
  font-size: 14px;
  margin-right: 12px
}

.landing .teacher-vis-info__place-gmt {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .teacher-vis-gmt__ico {
  width: 14px;
  height: 14px;
  margin-right: 6px
}

.landing .teacher-vis-gmt__text {
  font-size: 14px;
  color: #8995aa
}

.landing .teacher-vis-info__age {
  font-weight: 600;
  font-size: 16px;
  color: #4987ff
}

.landing .teacher__main {
  width: 60%;
  padding: 24px 0
}

.landing .teacher-main-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  border-left: 2px solid #eff3f7
}

.landing .teacher-main-part {
  width: 50%;
  border-right: 2px solid #eff3f7;
  padding: 0 38px;
  padding-top: 18px
}

.landing .teacher-main-article {
  margin-bottom: 38px
}

.landing .teacher-main-article:nth-last-child(1) {
  margin-bottom: 0
}

.landing .teacher-main-article__title {
  font-weight: 600;
  font-family: Evolventa;
  font-size: 18px;
  margin-bottom: 18px
}

.landing .teacher-main-article__text p {
  font-size: 15px
}

.landing .teacher-main-tag {
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgba(73, 135, 255, .4);
  border-radius: 8px;
  padding: 10px 14px;
  margin-right: 8px;
  margin-bottom: 8px
}

.landing .teacher-main-tag:nth-last-child(1) {
  margin-right: 0
}

.landing .teacher-main-tag img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 16px;
  width: 16px
}

.landing .teacher-main-tag span {
  line-height: 16px;
  display: inline-block;
  font-weight: 600;
  width: calc(100% - 16px);
  padding-left: 12px
}

.landing .teacher__ico {
  width: 10%;
  padding-top: 42px
}

.landing .teacher-ico {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto
}

.landing .teacher-ico span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #4987ff
}

.landing .teacher-ico span:nth-child(1) {
  height: 3px;
  width: 100%
}

.landing .teacher-ico span:nth-child(2) {
  height: 100%;
  width: 3px
}

.landing .doubleforms {
  position: relative;
  margin-top: 72px
}

.landing .doubleforms-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch
}

.landing .doubleforms__item {
  width: 49%;
  background: #fff;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  border-radius: 20px
}

.landing .doubleforms-item {
  text-align: center;
  padding: 60px 99px
}

.landing .doubleforms-item__title {
  margin-bottom: 32px
}

.landing .doubleforms-item__title span {
  font-weight: 600;
  font-size: 32px
}

.landing .doubleforms-item__text {
  margin-bottom: 42px
}

.landing .doubleforms-item__text span {
  font-size: 15px
}

.landing .doubleforms-item__fields {
  margin-bottom: 38px
}

.landing fieldset {
  border: 0 !important;
  margin: 0;
  padding: 0
}

.landing .doubleforms-item-fields {
  margin-bottom: 48px
}

.landing .doubleforms-item-field {
  margin-bottom: 18px
}

.landing .doubleforms-item__rememb a {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid
}

.landing .doubleforms-item__dscrp span {
  font-weight: 500;
  font-size: 12px
}

.landing .btn.btn_pink {
  background: #f36;
  border-color: #f36
}

.landing .btn.btn_pink span {
  color: #fff
}

.landing .btn.btn_rad {
  border-radius: 40px
}

.landing .doubleforms-or {
  position: absolute;
  top: calc(50% - 44px);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  padding: 23px 0;
  width: 88px;
  height: 88px;
  font-weight: 600;
  font-size: 26px;
  color: #4987ff;
  border-radius: 50%;
  -webkit-box-shadow: 0 3.325px 43.225px rgba(0, 0, 0, .12), -16.625px 14.1312px 42.3937px rgba(0, 0, 0, .14);
  box-shadow: 0 3.325px 43.225px rgba(0, 0, 0, .12), -16.625px 14.1312px 42.3937px rgba(0, 0, 0, .14);
  border: 4.15625px solid #4987ff;
  background: #fff
}

.landing .section.section_doubleforms {
  padding: 87px 0 102px;
  background: url("/landing/svg/bg-blue-doubleforms.svg") no-repeat center;
  background-size: cover;
  background-color: #4987ff
}

.landing .section.section_couch {
  min-height: 714px;
  padding: 200px 0 162px
}

.landing .section.section_teaches {
  background-color: #ecf2f6;
  text-align: center;
  padding: 162px 0 162px
}

.landing .img-top-bg-temporarily_onright {
  position: relative;
  left: 100px;
  width: calc(160% - 220px)
}

.landing .section_nearvideo .swiper-pagination {
  position: relative;
  margin-top: 42px;
  text-align: left
}

.landing .section_nearvideo .swiper-pagination .swiper-pagination-bullet:nth-child(1) {
  margin-left: 0
}

.landing .section_nearvideo .swiper-pagination .swiper-pagination-bullet {
  background: #4987ff;
  margin: 0 18px;
  margin-left: 0
}

.landing .videocircle {
  position: relative;
  width: 536px;
  height: 536px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto
}

.landing .videocircle__frame {
  width: 100%;
  height: 100%
}

.landing .videocircle__ico {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 118px;
  height: 118px
}

.landing .videocircle__ico img {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 4px;
  right: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  z-index: 2
}

.landing .videocircle__ico:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  z-index: 1
}

.landing .video-frame {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-clip-path: circle(100% at 50% 50%);
  clip-path: circle(100% at 50% 50%)
}

.landing .video-frame video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .text-block.text-block_teaches {
  text-align: center;
  width: 666px;
  margin: 0 auto;
  margin-top: 42px
}

.landing .title-block.title-block_teaches {
  text-align: center;
  margin-bottom: 52px
}

.landing .text-block.text-block_short {
  width: 740px;
  margin: 0 auto
}

.landing .accordion {
  background: #fff;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .05);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .05);
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 24px
}

.landing .section.section_faq {
  margin-top: -70px;
  padding-top: 0
}

.landing .accordion__cont {
  padding: 32px 0
}

.landing .accordion-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start
}

.landing .accordion-cont__title {
  width: calc(100% - 120px);
  border-right: 2px solid #eff3f7;
  padding: 0 82px;
  font-weight: 600;
  font-size: 22px
}

.landing .accordion-cont__plus {
  width: 120px;
  padding-top: 2px
}

.landing .accordion-plus {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto
}

.landing .accordion-plus span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #0a0a0b
}

.landing .accordion-plus span:nth-child(1) {
  height: 3px;
  width: 100%
}

.landing .accordion-plus span:nth-child(2) {
  height: 100%;
  width: 3px
}

.landing .accordion__panel {
  padding: 0 82px 38px
}

.landing .accordion.open .accordion-plus span:nth-child(2) {
  height: 0%
}

.landing .accordion__panel p {
  font-size: 18px
}

.landing .accordion__panel p a {
  color: #4987ff;
  border-bottom: 1px solid
}

.landing .teacher.open .teacher-ico span:nth-child(2) {
  height: 0%
}

.landing .askform-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch
}

.landing .askform-cont__part.askform-cont__part_l {
  width: calc(100% - 716px);
  padding-right: 80px
}

.landing .askform-cont__part.askform-cont__part_r {
  width: 716px
}

.landing .askform-panel {
  background: #fff;
  -webkit-box-shadow: 0 4px 67px rgba(0, 0, 0, .04);
  box-shadow: 0 4px 67px rgba(0, 0, 0, .04);
  border-radius: 10px;
  overflow: hidden;
  padding: 48px 58px
}

.landing .askform-panel__fields {
  margin-bottom: 28px
}

.landing .askform-panel__fields-row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 12px;
  position: relative
}

.landing .askform-panel__field-half {
  position: relative;
  width: 48%
}

.landing .askform-panel textarea {
  width: 100%;
  padding: 16px 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #8995aa;
  color: #0a0a0b;
  font-weight: 500;
  font-size: 15px;
  height: 52px;
  resize: none;
  border-radius: 0 !important;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .askform-panel__button {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .askform-panel-button {
  margin-right: 44px
}

.landing .askform-panel-buttext span {
  color: rgba(5, 4, 37, .35);
  font-weight: 500;
  font-size: 12px
}

.landing .video-frame img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .title-block.title-block_centered.title-block_short {
  margin-bottom: 62px
}

/*.header__nav a.active:before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -31px;*/
/*  left: -10%;*/
/*  right: 0;*/
/*  width: 120%;*/
/*  margin: 0 auto;*/
/*  height: 3px;*/
/*  background: #0a0a0b*/
/*}*/

.landing .section.section_404 {
  padding: 12px 0 118px
}

.landing .page404 {
  text-align: center
}

.landing .page404__img {
  width: 818px;
  margin: 0 auto;
  margin-bottom: -38px
}

.landing .page404__img img {
  width: 100%
}

.landing .page404__title {
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 18px
}

.landing .page404__text {
  margin: 0 auto;
  margin-bottom: 38px
}

.landing .wide-right-block {
  position: relative;
  width: 167%;
  height: 100%
}

.landing .small-circle.wide-right-block {
  position: relative;
  width: 145%;
  height: 100%
}

.landing .mask-pic {
  position: absolute;
  z-index: 2
}

.landing .mask-pic-cont {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-clip-path: circle(100% at 50% 50%);
  clip-path: circle(100% at 50% 50%);
  -webkit-animation: clip 1.5s ease-out;
  animation: clip 1.5s ease-out
}

@-webkit-keyframes clip {
  0% {
    -webkit-clip-path: circle(0 at 50% 50%);
    clip-path: circle(0 at 50% 50%)
  }

  100% {
    -webkit-clip-path: center(100% at 50% 50%);
    clip-path: center(100% at 50% 50%)
  }
}

@keyframes clip {
  0% {
    -webkit-clip-path: circle(0 at 50% 50%);
    clip-path: circle(0 at 50% 50%)
  }

  100% {
    -webkit-clip-path: center(100% at 50% 50%);
    clip-path: center(100% at 50% 50%)
  }
}


.landing .mask-pic-cont img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .circle-pic {
  position: absolute;
  z-index: 1
}

.landing .aos-animate .circle-pic-cont {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-animation: circle-clip .5s cubic-bezier(.67, .24, .58, .9);
  animation: circle-clip .5s cubic-bezier(.67, .24, .58, .9);
  height: 100%
}

@-webkit-keyframes circle-clip {
  0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@keyframes circle-clip {
  0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

.landing .circle-pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.landing .mask-text-cont {
  position: absolute
}

.landing .loader-head-wrap {
  width: 200%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-left: -100%
}

.landing .loader-head {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 68%;
  border-radius: 75% 75% 0 0
}

.landing .circle-radius {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0 50% 50% 0;
  overflow: hidden
}

.landing .loader-pacman {
  position: absolute;
  width: 200%;
  height: 100%;
  right: 0;
  border-radius: 50%;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.landing .loader-pacman_small {
  transform: translateX(25%);
  border-radius: 0;
}

.landing .loader-pacman_small .loader-pacman__wrapper {
  width: 100%;
  transform: translateX(-50%);
  height: 100%;
  border-radius: 100%;
}

.landing .loader-pacman_small .loader-pacman {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  border-radius: 50%;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.landing .loader-pacman_small .loader-head-wrap {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-left: 0;
  position: relative;
  overflow: hidden;
}

.landing .loader-pacman:after,
.landing .loader-pacman:before {
  content: "";
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  top: 28%;
  right: 26%
}

.landing .loader-pacman:after,
.landing .loader-pacman:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.landing .aos-animate .loader-pacman:after {
  -webkit-animation: pacman-lower 1s ease-in-out 3;
  animation: pacman-lower 1s ease-in-out 3;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s
}

.landing .aos-animate .loader-pacman:before {
  -webkit-animation: pacman-under 1.6s ease-in-out 1;
  animation: pacman-under 1.6s ease-in-out 1
}

.landing .aos-animate .circle-radius_delay .loader-pacman:after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s
}

.landing .aos-animate .circle-radius_delay .loader-pacman:before {
  -webkit-animation: pacman-under 2s ease-in-out 1;
  animation: pacman-under 2s ease-in-out 1
}

.landing .aos-animate .circle-radius_delay-2x .loader-pacman:after {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s
}

.landing .aos-animate .circle-radius_delay-2x .loader-pacman:before {
  -webkit-animation: pacman-under 2.4s ease-in-out 1;
  animation: pacman-under 2.4s ease-in-out 1
}

@-webkit-keyframes pacman-lower {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }

  50% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }
}

@keyframes pacman-lower {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }

  50% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }
}

@-webkit-keyframes pacman-under {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }

  75% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }

  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
}

@keyframes pacman-under {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }

  75% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }

  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
}

.landing .circle-radius.circle-radius_mirror {
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.landing .circle-radius.circle-radius_mirror_small {
  -webkit-transform: scale(-1, 1) translateX(25%);
  -ms-transform: scale(-1, 1) translateX(25%);
  transform: scale(-1, 1) translateX(25%);
}

.text-pic_aboutpage_bottom {
  bottom: 0;
  transform: translate(-10%, -20%);
}

.landing .circle-pic-cont_green .loader-head,
.landing .circle-pic-cont_green .loader-pacman:after,
.landing .circle-pic-cont_green .loader-pacman:before {
  background: #05dec5
}

.landing .circle-pic-cont_grey .loader-head,
.landing .circle-pic-cont_grey .loader-pacman:after,
.landing .circle-pic-cont_grey .loader-pacman:before {
  background: #ecf2f6
}

.landing .circle-pic-cont_blue .loader-head,
.landing .circle-pic-cont_blue .loader-pacman:after,
.landing .circle-pic-cont_blue .loader-pacman:before {
  background: #4987ff
}

.landing .circle-pic-cont_blue-gr .loader-head {
  background: linear-gradient(180deg, #16275f 14.01%, rgba(255, 255, 255, 0) 86.75%), #4987ff;
}

.landing .circle-pic-cont_blue-gr .loader-pacman:before {
  background: #4987ff
}

.landing .circle-pic-cont_green-gr .loader-head {
  background: linear-gradient(180deg, #07565f 14.01%, rgba(255, 255, 255, 0) 86.75%), #05dec5;
}

.landing .circle-pic-cont_green-gr .loader-pacman:before {
  background: #05dec5
}

.landing .circle-pic-cont_violet .loader-head,
.landing .circle-pic-cont_violet .loader-pacman:after,
.landing .circle-pic-cont_violet .loader-pacman:before {
  background: #332e65
}

.landing .mask-pic_main-1 {
  top: 0;
  left: 0;
  width: 306px;
  height: 306px
}

.header__show-mobile > a > button {
  width: -webkit-fill-available;
}

.header__show-mobile > .wrapper_btn {
  padding: 0 !important;
}

.btn_ml-0 {
  margin-left: 0 !important;
}

.landing .mask-pic_main-1 .mask-pic-cont {
  -webkit-animation-delay: .5s;
  animation-delay: .5s
}

.landing .mask-pic_main-2 {
  top: 0;
  left: 580px;
  width: 306px;
  height: 306px
}

.landing .mask-pic_main-2 .mask-pic-cont {
  -webkit-animation-delay: .5s;
  animation-delay: .5s
}

.landing .mask-pic_main-3 {
  top: 320px;
  left: 180px;
  width: 414px;
  height: 414px
}

.landing .mask-pic_main-3 .mask-pic-cont {
  -webkit-animation-delay: .5s;
  animation-delay: .5s
}

.landing .circle-pic_main-1 {
  top: 0;
  left: 360px;
  width: 158px;
  height: 306px
}

.landing .circle-pic_main-1 .mask-pic-cont {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.landing .circle-pic_main-2 {
  top: 320px;
  left: -42px;
  width: 214px;
  height: 414px
}

.landing .circle-pic_main-2 .mask-pic-cont {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.landing .circle-pic_main-3 {
  top: 320px;
  left: 608px;
  width: 214px;
  height: 414px
}

.landing .circle-pic_main-3 .mask-pic-cont {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms
}

.landing .mask-pic_main-2 .mask-text-cont {
  top: 108px;
  left: -158px
}

.landing .mask-pic_main-3 .mask-text-cont {
  top: 122px;
  left: -138px
}

.landing .sticky-container .grid.title-block {
  position: relative;
  z-index: 2
}

.landing .sticky-container .wide-container {
  position: relative;
  z-index: 2
}

.landing .section-untop-first {
  position: relative;
  background-color: #4987ff;
  padding: 103px 0 100px;
  z-index: 1
}

.landing .title-block__row-animated span span {
  display: inline
}

.landing .circle-pit {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  overflow: hidden
}

.landing .circle-pit__elem {
  position: absolute
}

.landing .circle-pit__elem_main-1 {
  width: 685px;
  height: 685px;
  top: 0;
  right: -154px
}

.landing .circle-pit__elem_main-2 {
  width: 184px;
  height: 356px;
  top: 450px;
  right: -30px
}

.landing .circle-pit__elem_main-3 {
  width: 132px;
  height: 132px;
  top: 495px;
  right: 810px
}

.landing .circle-pit__elem_main-4 {
  width: 853px;
  height: 853px;
  top: 47px;
  right: -245px
}

.landing .section-untop-second {
  position: relative;
  z-index: 0
}

.landing .sticky-container .circle-pit {
  z-index: 2
}

.landing .slider-semicircle {
  width: 150px;
  height: 288px
}

.landing .slider-semicircle img {
  height: 100%
}

.landing .slider-person__info {
  position: absolute;
  top: 8px;
  left: -100px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  pointer-events: none;
  opacity: 0
}

.landing .slider-person:hover .slider-person__info {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  pointer-events: initial;
  opacity: 1
}

.landing .row-info {
  position: relative;
  margin-bottom: -8px
}

.landing .row-info-text {
  display: inline-block;
  padding: 10px 17px;
  background: #fff;
  border-radius: 6px
}

.landing .row-info-text span {
  font-size: 16px;
  white-space: nowrap;
}

.landing .row-info-text p {
  font-size: 16px;
  white-space: nowrap;
}

.landing .function-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1
}

.landing .function-bg__item {
  position: absolute
}

.landing .function-bg__item_main-1 {
  top: 160px;
  left: -100px;
  width: 130px;
  height: 130px
}

.landing .function-bg__item_main-2 {
  top: 290px;
  left: -140px;
  width: 356px;
  height: 182px
}

.landing .function-bg__item_main-3 {
  top: 90px;
  right: -180px;
  width: 202px;
  height: 388px
}

.landing .function-bg__item_main-4 {
  top: 90px;
  right: -410px;
  width: 202px;
  height: 388px
}

.landing .function-bg__item img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.landing .section.section_funtions {
  overflow: hidden
}

.landing .review-card-cont {
  position: relative;
  height: 100%
}

.landing .mask-pic.mask-pic_teach-1 {
  width: 70px;
  height: 70px;
  top: 6%;
  left: 16%
}

.landing .mask-pic.mask-pic_teach-2 {
  width: 160px;
  height: 160px;
  top: 38%;
  left: 6%
}

.landing .mask-pic.mask-pic_teach-3 {
  width: 98px;
  height: 98px;
  top: 79%;
  left: -2%
}

.landing .mask-pic.mask-pic_teach-4 {
  width: 122px;
  height: 122px;
  top: 72%;
  left: 16%
}

.landing .circle-pic.circle-pic_teach-2 {
  top: 32%;
  left: 18%
}

.landing .circle-pic.circle-pic_teach-1 {
  top: 6%;
  left: 0
}

.landing .mask-pic.mask-pic_teach-5 {
  width: 86px;
  height: 86px;
  top: 6%;
  right: 13%
}

.landing .mask-pic.mask-pic_teach-6 {
  width: 98px;
  height: 98px;
  top: 27%;
  right: 22%
}

.landing .mask-pic.mask-pic_teach-7 {
  width: 160px;
  height: 160px;
  top: 37%;
  right: 5%
}

.landing .mask-pic.mask-pic_teach-8 {
  width: 115px;
  height: 115px;
  top: 72%;
  right: 13%
}

.landing .circle-pic.circle-pic_teach-4 {
  width: 46px;
  height: 88px;
  top: 37%;
  right: 16%
}

.landing .circle-pic.circle-pic_teach-3 {
  width: 82px;
  height: 160px;
  top: 6%;
  right: 0
}

.landing .section.section_teaches .circle-pit .mask-pic {
  width: 172px;
  height: 172px
}

.landing .circle-pic_teachersmany-1,
.landing .circle-pic_teachersmany-4,
.landing .mask-pic_teachersmany-1,
.landing .mask-pic_teachersmany-2,
.landing .mask-pic_teachersmany-6,
.landing .mask-pic_teachersmany-7 {
  top: 6%
}

.landing .mask-pic_teachersmany-1,
.landing .mask-pic_teachersmany-4 {
  left: -2%
}

.landing .mask-pic_teachersmany-2,
.landing .mask-pic_teachersmany-5 {
  left: 18%
}

.landing .circle-pic_teachersmany-2,
.landing .circle-pic_teachersmany-5,
.landing .circle-pic_teachersmany-6,
.landing .mask-pic_teachersmany-3,
.landing .mask-pic_teachersmany-8 {
  top: 36%
}

.landing .mask-pic_teachersmany-3 {
  left: 9%
}

.landing .circle-pic_teachersmany-3,
.landing .circle-pic_teachersmany-6,
.landing .mask-pic_teachersmany-10,
.landing .mask-pic_teachersmany-4,
.landing .mask-pic_teachersmany-5,
.landing .mask-pic_teachersmany-9 {
  top: 68%
}

.landing .mask-pic_teachersmany-6,
.landing .mask-pic_teachersmany-9 {
  right: 19%
}

.landing .mask-pic_teachersmany-10,
.landing .mask-pic_teachersmany-7 {
  right: -2%
}

.landing .mask-pic_teachersmany-8 {
  right: 9%
}

.landing .section.section_teaches .circle-pic {
  width: 88px;
  height: 172px
}

.landing .circle-pic_teachersmany-1,
.landing .circle-pic_teachersmany-3 {
  left: 10%
}

.landing .circle-pic_teachersmany-2 {
  left: 0
}

.landing .circle-pic_teachersmany-4,
.landing .circle-pic_teachersmany-6 {
  right: 10%
}

.landing .circle-pic_teachersmany-5 {
  right: 0
}

.landing .circle-pic.circle-pic_carriers-1 {
  left: 60%
}

.landing .circle-pic.circle-pic_carriers-2 {
  left: 80%
}

.landing .circle-pic_carriers {
  position: absolute;
  z-index: 2
}

.landing .circle-pic_carriers.circle-pic_carriers-1 {
  top: 100px;
  right: -37px
}

.landing .circle-pic_carriers.circle-pic_carriers-2 {
  right: -120px;
  top: 181px
}

.landing .section.section_carriers {
  padding: 200px 0 200px
}

.landing .section.section_carriers .grid__half-r .circle-pit {
  overflow: initial
}

.landing .circle-pic.circle-pic_carriers-1,
.landing .circle-pic.circle-pic_carriers-2 {
  top: -36px;
  bottom: 0;
  margin: auto;
  width: 214px;
  height: 414px
}

.landing .svg-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1
}

.landing .svg-bg svg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .plyr__video-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto
}

.landing .plyr__controls {
  display: none
}

.landing .plyr__captions {
  display: none
}

button.plyr__control {
  display: none
}

.landing .video-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2px;
  right: -2px;
  margin: auto;
  z-index: 4;
  cursor: pointer
}

.landing .video-cont.show .video-bg {
  opacity: 0;
  pointer-events: none
}

.landing .wide-right-block.wide-right-block_proc {
  height: 120%;
  top: -10%;
  left: -10%;
  width: 177%
}

.landing .wide-right-block_proc .mask-pic {
  width: 186px;
  height: 186px
}

.landing .wide-right-block_proc .circle-pic {
  width: 96px;
  height: 186px
}

.landing .circle-pic_proc-1,
.landing .circle-pic_proc-2,
.landing .mask-pic_proc-1 {
  top: 0
}

.landing .mask-pic_proc-2,
.landing .mask-pic_proc-3 {
  top: 32%
}

.landing .mask-pic_proc-2 {
  left: -10%
}

.landing .mask-pic_proc-4 {
  top: 422px;
  right: 18%
}

.landing .text-pic_proc {
  position: absolute;
  z-index: 23
}

.landing .text-pic_proc.text-pic_proc-1 {
  top: 52px;
  left: -85px
}

.landing .mask-pic.mask-pic_proc-1 {
  top: 0;
  right: 20%
}

.landing .text-pic_proc.text-pic_proc-2 {
  top: 52px;
  right: -121px
}

.landing .mask-pic_proc-3 {
  right: 3%
}

.landing .text-pic_proc.text-pic_proc-3 {
  top: 62px;
  left: -118px
}

.landing .circle-pic_proc-3,
.landing .circle-pic_proc-4,
.landing .mask-pic_proc-4 {
  top: 66%
}

.landing .circle-pic.circle-pic_proc-3 {
  top: 73%
}

.landing .circle-pic.circle-pic_proc-1 {
  right: 54%
}

.landing .circle-pic.circle-pic_proc-2 {
  right: 0
}

.landing .circle-pic.circle-pic_proc-4 {
  right: 0
}

.landing .proc-tablet-main {
  position: absolute;
  top: 13%;
  left: 13%;
  z-index: 66
}

.landing .mask-pic.mask-pic_proc-2,
.landing .mask-pic.mask-pic_proc-3 {
  z-index: 88
}

.landing .function__img.function__img_sm {
  padding-left: 90px;
  padding-right: 90px
}

.landing .section.section_about-main .mask-pic {
  position: absolute;
  z-index: 1
}

.landing .circle-pic_about-1,
.landing .circle-pic_about-2 {
  top: 0;
  width: 214px;
  height: 414px
}

.landing .circle-pic_about-2 {
  left: 19%;
  z-index: 2
}

.landing .circle-pic_about-1 {
  left: -15%
}

.landing .text-pic_about.text-pic_about-1 {
  top: 100px;
  right: -37px
}

.landing .text-pic_about {
  position: absolute;
  z-index: 2
}

.landing .text-pic_about.text-pic_about-2 {
  right: -120px;
  top: 181px
}

.landing .mask-pic.mask-pic_about-1 {
  top: 0;
  right: -9%;
  z-index: -1
}

.landing .circle-pic.circle-pic_faq-1 {
  width: 194px;
  height: 378px;
  top: 20%;
  left: 0
}

.landing .section.section_faq-main .title-block {
  margin-top: 62px
}

.landing .circle-pic.circle-pic_faq-2 {
  width: 160px;
  height: 160px;
  top: 0;
  left: 10%
}

.landing .circle-pic.circle-pic_faq-3 {
  width: 90px;
  height: 90px;
  top: 20%;
  right: 40%
}

.landing .faq-pic-main {
  position: relative;
  width: 380px;
  height: 620px;
  right: -65px;
  z-index: 99
}

.landing .text-pic_faq.text-pic_faq-1 {
  position: absolute;
  top: 318px;
  left: -112px
}

.landing .faq-pic-main > img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.landing .section.section_main.section_faq-main {
  padding-top: 36px
}

.landing .ui-slider-range {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 5px;
  background: #4987ff;
  border-radius: 8px;
  cursor: pointer
}

.landing .ui-slider {
  position: relative;
  height: 5px;
  border-radius: 5px;
  background: rgba(5, 4, 37, .2);
  cursor: pointer
}

.landing .ui-slider-handle {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  background: url(/landing/svg/range-ico.svg) no-repeat center;
  background-size: 50%;
  background-color: #fff;
  -webkit-box-shadow: -6px 9px 28px rgba(0, 0, 0, .12);
  box-shadow: -6px 9px 28px rgba(0, 0, 0, .12);
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .ui-slider-handle.ui-state-focus {
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s
}

/*.landing .ui-slider-handle:hover {*/
/*  box-shadow: -6px 9px 28px rgb(0 0 0 / 30%)*/
/*}*/

.landing .filters {
  position: relative;
  z-index: 2;
  margin-bottom: 62px
}

.landing .filters-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .filters__item {
  margin-right: 42px
}

.landing .filters__item:nth-last-child(1) {
  margin-right: 0
}

.landing .filters__item:nth-last-child(2) {
  width: 100%;
}

.landing .filters__item:nth-child(2) .filters-item__title {
  margin-right: 20px !important;
}

.landing .select_langs {
  width: auto !important;
}

.landing .filters-item {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-line-pack: stretch;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-align: center;
  align-content: stretch;
  -ms-flex-wrap: nowrap;
  display: -ms-flexbox;
  display: -webkit-box;
  -ms-flex-pack: start;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
}

.landing .filters-item__title {
  margin-right: 15px
}

.landing .filters-item__title span {
  font-weight: 600;
  font-size: 14px
}

.landing .filters-item__content {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  -webkit-box-align: center;
  -ms-flex-direction: row;
  -webkit-box-pack: start;
  align-content: stretch;
  -ms-flex-align: center;
  -ms-flex-wrap: nowrap;
  -ms-flex-pack: start;
  display: -ms-flexbox;
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
}

.landing .input-range__slider {
  border: 1px solid #c5c5c5;
  -ms-touch-action: none;
  background: #f6f6f6;
  font-weight: normal;
  position: absolute;
  touch-action: none;
  cursor: default;
  color: #454545;
  height: 19px;
  width: 19px;
  z-index: 2;
  transform: translateY(3px);
}

.landing .filters-tag {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-line-pack: stretch;
  -webkit-box-align: center;
  border: 2px solid #d7e0e6;
  -webkit-box-pack: center;
  -ms-flex-direction: row;
  justify-content: center;
  -ms-flex-align: center;
  align-content: stretch;
  -ms-flex-wrap: nowrap;
  -ms-flex-pack: center;
  display: -ms-flexbox;
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  border-radius: 8px;
  flex-wrap: nowrap;
  cursor: pointer;
  display: flex;
  height: 44px;
  width: auto;
}

.landing .filters-tag.filters-tag_circle > input {
  display: none;
}

.landing .filters-tag.filters-tag_circle {
  border-radius: 50%;
  width: 44px;
  padding: 0;
}

.landing .filters-tag span {
  color: rgba(5, 4, 37, .35);
  font-weight: 600;
  font-size: 14px;
}

.landing .filters-input {
  background: rgba(73, 135, 255, .6);
  margin-right: 38px;
  position: relative;
  width: 548px;
  height: 5px;
}

.landing .filters-input__vals {
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-line-pack: stretch;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  -ms-flex-direction: row;
  -ms-flex-align: center;
  align-content: stretch;
  -ms-flex-pack: justify;
  -ms-flex-wrap: nowrap;
  display: -ms-flexbox;
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  position: absolute;
  flex-wrap: nowrap;
  display: flex;
  top: -32px;
  width: 100%
}

.landing .filters-input-val {
  font-weight: 600;
  font-size: 14px
}

.landing .filters-tag.active {
  border-color: #4987ff
}

.landing .filters-tag.active span {
  color: #4987ff
}

.landing .section.section_main.section_main-page .wide-right-block {
  height: 110%
}

.landing .custom-select-trigger {
  justify-content: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  padding-right: 42px;
  padding-left: 30px;
}

.landing .custom-option {
  border-bottom: 2px solid #eff3f7;
  -webkit-transition: .3s;
  -o-transition: .3s;
  padding: 16px 16px;
  transition: .3s;
  display: block;
  opacity: 1
}

.landing .custom-option,
.landing .custom-select-trigger {
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  margin: 0;
}

.landing .custom-options {
  -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, .12);
  box-shadow: 0 4px 15px rgba(0, 0, 0, .12);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: .3s;
  top: calc(100% + 7px);
  pointer-events: none;
  -o-transition: .3s;
  border-radius: 8px;
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  background: #fff;
  transition: .3s;
  padding: 0 16px;
  width: 100%;
  opacity: 0;
}

.landing .custom-select-wrapper {
  position: relative;
  width: 258px
}

.landing .custom-select {
  position: relative;
  appearance: none;
}

.landing .filters-item__content_langs:before {
  content: '';
  width: 12px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  background: url('/landing/svg/sel-ar.svg') no-repeat;
  margin-right: 10px;

}

.landing .custom-select {
  position: relative;
  background: transparent;
  border: 2px solid #d7e0e6;
  border-radius: 8px;
  padding: 0 38px;
  height: 44px;
  font-size: 12px;
  font-weight: 600;
}

.landing .custom-option:hover {
  opacity: .6
}

.landing .custom-option:nth-last-child(1) {
  border-bottom: 0
}

.landing .custom-select.opened .custom-options {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.landing .custom-select-trigger:after {
  background: url(/landing/svg/sel-ar.svg) no-repeat center;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transition: .3s;
  background-size: 50%;
  transform: rotate(0);
  -o-transition: .3s;
  position: absolute;
  transition: .3s;
  display: block;
  margin: auto;
  content: '';
  height: 6px;
  right: 16px;
  width: 12px;
  bottom: 0;
  top: 0;
}

.landing .custom-select.opened .custom-select-trigger:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg)
}

.landing .filters-mob-button {
  display: none
}

.landing .sliding-accordion-element {
  padding-top: 32px
}

.landing .header__nav .btn.btn_starts {
  display: none
}

.landing .tablet-v-show {
  display: none
}

.landing .section.section_centered .circle-pit .mask-pic {
  width: 166px;
  height: 166px
}

.landing .mask-pic.mask-pic_aboutpage-1 {
  top: 9%
}

.landing .mask-pic.mask-pic_aboutpage-1 {
  left: 18%
}

.landing .mask-pic.mask-pic_aboutpage-6 {
  top: 13%;
  right: 17%
}

.landing .mask-pic.mask-pic_aboutpage-2 {
  top: 20%
}

.landing .mask-pic.mask-pic_aboutpage-2 {
  left: -2%
}

.landing .mask-pic.mask-pic_aboutpage-7 {
  right: 1%;
  top: 25%
}

.landing .mask-pic.mask-pic_aboutpage-3 {
  top: 35%;
  left: 11%
}

.landing .mask-pic.mask-pic_aboutpage-4 {
  top: 58%;
  left: -2%
}

.landing .mask-pic.mask-pic_aboutpage-5 {
  top: 71%;
  left: 14%
}

.landing .mask-pic.mask-pic_aboutpage-8 {
  top: 51%;
  right: 11%
}

.landing .mask-pic.mask-pic_aboutpage-9 {
  top: 72%;
  right: 21%
}

.landing .mask-pic.mask-pic_aboutpage-10 {
  top: 73%;
  right: -2%
}

.landing .text-pic_aboutpage {
  position: absolute;
  top: 38px
}

.landing .text-pic_aboutpage.text-pic_aboutpage-7 {
  left: -205px
}

.landing .text-pic_aboutpage.text-pic_aboutpage-2 {
  right: -115px
}

.landing .text-pic_aboutpage.text-pic_aboutpage-6 {
  top: 0;
}

.landing .text-pic_aboutpage.text-pic_aboutpage-5 {
  right: -172px
}

.landing .function-bg__item.function-bg__item_funct-1 {
  top: 160px;
  right: -138px;
  width: 116px;
  height: 116px
}

.landing .function-bg__item.function-bg__item_funct-2 {
  top: 277px;
  right: -188px;
  width: 326px;
  height: 168px
}

.landing .function-bg__item.function-bg__item_funct-3 {
  top: -58px;
  left: -34%;
  width: 764px;
  height: 764px
}

.landing .function-bg__item.function-bg__item_funct-4 {
  top: 21px;
  left: -26%;
  width: 164px;
  height: 322px
}

.landing .function-bg__item.function-bg__item_funct-5 {
  top: -10px;
  right: -96px;
  width: 150px;
  height: 150px
}

.landing .function-bg__item.function-bg__item_funct-6 {
  top: 94px;
  right: -184px;
  width: 194px;
  height: 378px
}

.landing .function-bg__item.function-bg__item_funct-square {
  width: 172px;
  height: 172px
}

.landing .function-bg__item.function-bg__item_funct-square > div {
  height: 100%
}

.landing .function-bg__item.function-bg__item_funct-square-1,
.landing .function-bg__item.function-bg__item_funct-square-2,
.landing .function-bg__item.function-bg__item_funct-square-3 {
  top: 0
}

.landing .function-bg__item.function-bg__item_funct-square-4,
.landing .function-bg__item.function-bg__item_funct-square-5,
.landing .function-bg__item.function-bg__item_funct-square-6 {
  top: 190px
}

.landing .function-bg__item.function-bg__item_funct-square-7,
.landing .function-bg__item.function-bg__item_funct-square-8,
.landing .function-bg__item.function-bg__item_funct-square-9 {
  top: 382px
}

.landing .function-bg__item.function-bg__item_funct-square-1,
.landing .function-bg__item.function-bg__item_funct-square-4,
.landing .function-bg__item.function-bg__item_funct-square-7 {
  left: 0
}

.landing .function-bg__item.function-bg__item_funct-square-2,
.landing .function-bg__item.function-bg__item_funct-square-5,
.landing .function-bg__item.function-bg__item_funct-square-8 {
  left: 186px
}

.landing .function-bg__item.function-bg__item_funct-square-3,
.landing .function-bg__item.function-bg__item_funct-square-6,
.landing .function-bg__item.function-bg__item_funct-square-9 {
  left: 396px
}

.landing .function__img.function__img_square {
  min-height: 560px;
  left: 118px
}

.landing .section_main.section_main-process {
  padding-bottom: 132px
}

.landing .filters-pp {
  display: none
}

.landing .cookie {
  width: 532px;
  visibility: hidden;
  position: fixed;
  top: auto;
  left: auto;
  bottom: 38px;
  right: 38px;
  background: #fff;
  -webkit-box-shadow: 0 4px 55px rgba(0, 0, 0, .25);
  box-shadow: 0 4px 55px rgba(0, 0, 0, .25);
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  z-index: 100
}

.landing .cookie.show {
  pointer-events: all;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.landing .cookie-close {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 22px;
  right: 22px;
  margin: auto;
  background: url(/landing/svg/filters-pp-cross.svg) no-repeat center;
  background-size: 90%;
  opacity: 1;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  z-index: 2;
  cursor: pointer
}

.landing .cookie-close:hover {
  opacity: .6
}

.landing .cookie-cont {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding: 24px 22px 18px
}

.landing .cookie-cont__img {
  width: 156px;
  height: 156px
}

.landing .cookie-cont__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.landing .cookie-cont__info {
  width: calc(100% - 156px);
  padding-left: 32px
}

.landing .cookie-title {
  font-family: Evolventa;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 6px
}

.landing .cookie-text {
  font-size: 15px;
  margin-bottom: 16px
}

.landing .btn.btn_cookie span {
  font-size: 15px
}

.landing .btn.btn_cookie {
  padding: 14px 34px
}

.landing .btn.btn_cookie.btn_cookie-alltransp {
  background: 0 0;
  padding: 14px 14px
}

.landing .btn.btn_cookie.btn_cookie-alltransp span {
  color: rgba(0, 0, 0, .9)
}

.landing .btn:after {
  content: '';
  display: block;
  position: absolute;
  left: var(--x);
  top: var(--y);
  right: auto;
  bottom: auto;
  margin: auto;
  width: calc(var(--w) * 1.5);
  height: calc(var(--w) * 1.5);
  border-radius: 50%;
  -webkit-transition: .5s -webkit-transform;
  transition: .5s -webkit-transform;
  -o-transition: .5s transform;
  transition: .5s transform;
  transition: .5s transform, .5s -webkit-transform;
  margin-right: -100%;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0)
}

.landing .btn:hover:after {
  -webkit-transform: translate(-50%, -50%) scale(2);
  -ms-transform: translate(-50%, -50%) scale(2);
  transform: translate(-50%, -50%) scale(2)
}

.landing .btn.btn_black:after,
.landing .btn.btn_black:hover {
  background: #4987ff;
}

.landing .btn.btn_black.btn_black-to-blue:after,
.landing .btn.btn_black.btn_black-to-blue:hover {
  background: #4987ff !important;
}

.landing .btn.btn_blue:after,
.landing .btn.btn_blue:hover {
  background: #2264e4
}

.landing .btn.btn_blue-transp:after,
.landing .btn.btn_blue-transp:hover {
  background: #4987ff
}

.landing .btn span {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s
}

.landing .btn.btn_blue-transp:hover span {
  color: #fff
}

.landing .btn.btn_cookie-alltransp:after {
  display: none
}

.landing .btn.btn_cookie-alltransp:hover {
  opacity: .6
}

.landing .btn.btn_white:after {
  background: #0a0a0b
}

.landing .btn.btn_white:hover {
  background: #4987ff;
}

.landing .btn.btn_white:hover span {
  color: #fff
}

.landing .section-untop-first .btn.btn_black:hover,
.landing .section-untop-first .btn.btn_black:after {
  background: #fff
}

.landing .section-untop-first .btn.btn_black:hover span {
  color: #0a0a0b
}

.landing .teacher-video {
  overflow: hidden;
  position: relative;
  height: 180px;
  border-radius: 10px
}

.landing .teacher-video-preview {
  position: relative;
  height: 100%;
  z-index: 1
}

.header__show-mobile {
  display: none;
}

.landing .teacher-video-ico {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -24px;
  width: 48px;
  height: 48px;
  z-index: 2;
  opacity: 1;
  transition: .3s
}

.landing .rewvid-img-item__ico-conor .teacher-video-ico {
  bottom: 5px;
  left: 5px;
  top: initial;
  right: initial;
  margin: initial;
}

.landing .rewvid-img-item:hover .teacher-video-ico {
  opacity: 0
}

.landing .teacher-video-preview:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .29)), to(rgba(0, 0, 0, .29)));
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, .29), rgba(0, 0, 0, .29));
  background: linear-gradient(0deg, rgba(0, 0, 0, .29), rgba(0, 0, 0, .29));
  opacity: 1;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  z-index: 4
}

.landing .teacher-video-ico:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 0;
  margin: auto;
  border: 6px solid transparent;
  border-left: 10px solid #fff;
  width: 0;
  height: 0;
  z-index: 2
}

.landing .teacher-video-ico:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: #4987ff;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.landing .teacher-video:hover .teacher-video-ico:before {
  -webkit-transform: scale(.9);
  -ms-transform: scale(.9);
  transform: scale(.9)
}

.landing .teacher-video:hover .teacher-video-preview:before {
  opacity: 0
}

.landing .teacher-video-preview img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1
}

.landing img.teacher-video-preview__gif {
  z-index: 2;
  opacity: 0
}

.landing .teacher-video:hover img.teacher-video-preview__gif {
  opacity: 1
}

.landing img.teacher-video-preview__jpg {
  z-index: 1
}

.landing .pp {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: auto;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .pp.show {
  opacity: 1;
  visibility: visible;
  pointer-events: visible
}

.landing .pp-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(25, 24, 29, .65);
  z-index: 0;
  cursor: pointer
}

.landing .pp-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 1276px;
  height: 720px;
  z-index: 3;
  background: #fff
}

.landing .pp-cross {
  position: absolute;
  top: -22px;
  right: -66px;
  width: 38px;
  height: 38px;
  background: url(/landing/svg/pp-cross.svg) no-repeat center;
  background-size: contain;
  z-index: 22;
  cursor: pointer;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .pp-content {
  position: relative;
  height: 100%
}

.landing .first-teacher-element {
  position: relative
}

.landing .first-teacher-element:before {
  content: '';
  display: block;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 62px;
  background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(255, 255, 255, 0)));
  background: -o-linear-gradient(bottom, #fff 0, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #fff 0, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s;
  opacity: 1
}

.landing .teacher.open .first-teacher-element:before {
  opacity: 0
}

.landing .sliding-accordion-element > div {
  opacity: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .teacher.open .sliding-accordion-element > div {
  opacity: 1
}

.landing a.btn:hover {
  opacity: 1
}

.landing .pp-container.pp-container_thanx {
  width: 716px;
  height: auto;
  padding: 42px 38px;
  border-radius: 10px
}

.landing .thanx {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center
}

.landing .thanx__info {
  width: calc(100% - 300px)
}

.landing .thanx-title {
  margin-bottom: 14px
}

.landing .thanx-title span {
  display: block;
  font-family: Evolventa, sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.14
}

.landing .thanx__img {
  width: 300px;
  height: 190px
}

.landing .thanx__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.landing .no-content {
  background: #fff;
  -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
  border-radius: 20px;
  padding: 42px 0 68px
}

.landing .no-content__img {
  width: 374px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: -28px
}

.landing .no-content__img img {
  width: 100%
}

.landing .no-content__text {
  font-family: Evolventa;
  font-weight: 600;
  font-size: 28px;
  text-align: center
}

label.checkbox__text.checkbox__text_two {
  display: block;
  position: relative;
  color: rgba(5, 4, 37, .35);
  font-weight: 500;
  font-size: 12px;
  padding-left: 36px;
  cursor: pointer
}

.landing .checkbox-box__item {
  position: relative
}

.landing .checkbox-box__item > input {
  opacity: 0;
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 0;
  right: -9000px;
  pointer-events: none
}

input.error ~ label.checkbox__text:before {
  border: 1px solid #f36 !important
}

.landing .checkbox-box__item > input ~ label.checkbox__text:before {
  content: '';
  display: block;
  position: absolute;
  display: block;
  left: 0;
  top: -1px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: 0 0;
  border: 1px solid rgba(5, 4, 37, .35);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .checkbox-box__item > input ~ label.checkbox__text:hover:before {
  border: 1px solid rgba(5, 4, 37, .65)
}

.landing .info__item {
  margin-bottom: 28px
}

.landing .checkbox-box__item > input ~ label.checkbox__text:after {
  content: "";
  position: absolute;
  top: 0;
  left: 8px;
  width: 7px;
  height: 18px;
  border-right: 3px solid #4987ff;
  border-bottom: 3px solid #4987ff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.landing .checkbox-box__item > input:checked ~ label.checkbox__text:after {
  opacity: 1;
  top: -6px
}

.landing .checkbox-box__item > input:focus + label:before {
  border: 1px solid rgba(5, 4, 37, .75) !important
}

.landing label.error {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-size: 10px;
  color: #f36;
  z-index: 23
}

.landing input[aria-invalid=true] + label.error + label:before {
  border: 1px solid #f36 !important
}

.landing .circle-pic-cont {
  position: relative;
  height: 100%
}

.landing .btn.btn_pink:after {
  background: #4987ff
}

.landing .header-float {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  padding-right: 20px;
  cursor: pointer
}

.landing .header-float__nav {
  opacity: 1;
  transition: .3s
}

.header-float__nav > a {
  margin-right: 0!important;
}

.landing .header-float__nav span {
  font-weight: 600;
  font-size: 16px;
  color: #0a0a0b
}

.landing .header-black .header-float:after {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(344deg) brightness(118%) contrast(101%) !important;
}

.header__popover_styles a {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
  opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  color: #0A0A0B;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 0;
}

.header__popover_styles a:hover {
  opacity: .5;
  color: #0A0A0B;
}

.landing .header-float__items {
  position: absolute;
  top: 37px;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 260px;
  background: #0a0a0b;
  border-radius: 9px;
  padding: 14px 0;
  margin-left: -85%;
  transform: translateY(10px);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none
}

.landing .header-float-item {
  text-align: center;
  margin-bottom: 12px
}

.landing .header-float-item a,
.landing .header-float-item a > span {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto
}

.landing .header-float-item a span {
  font-weight: 600;
  font-size: 16px;
  color: #fff
}

.landing .header-float-item:nth-last-child(1) {
  margin-bottom: 0
}

.landing .header-float:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 10px;
  height: 6px;
  background: url(/landing/svg/header-float-ar.svg) no-repeat center;
  background-size: cover
}

.landing .header-float:hover .header-float__nav {
  opacity: 1
}

.landing .header-float__submenu.header-float__items,
.landing .header-float:hover .header-float__items {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  pointer-events: all
}

.landing .header-float__items:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  height: 30px;
  width: 100%
}

.landing .header-float:hover .header-float__nav {
  opacity: .6
}

.landing .choice {
  margin-top: 62px
}

.landing .choice-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 56px;
  margin-left: -122px
}

.landing .choice-cont__part {
  padding: 48px 108px;
  border-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.landing .choice-img {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 96px
}

.teacher_premiera {
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
}

.landing .choice-img-item {
  position: relative;
  width: 240px;
  height: 240px;
  margin: 0 12px;
  cursor: pointer
}

.landing .choice-img-item__pic {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: 50%
}

.landing .choice-img-item__pic img {
  height: 100%;
  width: 100%;
  object-fit: cover
}

.landing .choice-img-item__vid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: .3s;
  z-index: 2;
  overflow: hidden;
  border-radius: 50%
}

.landing .choice-img-item__vid video {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.landing .choice-img-item__ico {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  opacity: 1;
  transition: .3s
}

.landing .choice-img-item:hover .choice-img-item__ico {
  opacity: 0
}

.landing .choice-img-item__ico .teacher-video-ico:before {
  display: none
}

.landing .choice-img-item__ico .teacher-video-ico:after {
  border: 14px solid transparent;
  border-left: 24px solid #fff
}

.landing .choice-img-item__label {
  position: absolute;
  top: 212px;
  right: 0;
  left: 0;
  width: 182px;
  margin: 0 auto;
  z-index: 5
}

.landing .choice-img-item__float {
  position: absolute;
  left: -145px;
  top: 100px;
  z-index: 5;
  transform: translateX(30px);
  opacity: 0;
  visibility: hidden;
  transition: .3s
}

.landing .choice-img-item:hover .choice-img-item__float {
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.landing .choice-text {
  text-align: center;
  font-size: 16px;
  line-height: 1.9;
  margin-bottom: 28px
}

.landing .choice-button {
  text-align: center;
  margin-top: auto
}

.landing .choice-img-item:hover .choice-img-item__vid {
  opacity: 1
}

.landing .section.section_people.section_halfpink {
  z-index: 0
}

.landing .func-step {
  margin-top: 38px
}

.landing .func-step-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 0;
  background: #fff;
  box-shadow: 0 4px 67px rgba(0, 0, 0, .05);
  border-radius: 12px;
  margin-bottom: 14px
}

.landing .func-step-item__title {
  width: 120px;
  color: #4987ff;
  font-weight: 600;
  font-size: 24px;
  padding-left: 28px
}

.landing .func-step-item__text {
  width: calc(100% - 120px);
  color: rgba(10, 10, 11, .67);
  font-size: 18px;
  border-left: 2px solid #edf3f9;
  padding: 10px 18px
}

.landing .rewvid-img-item {
  position: relative;
  width: 100%;
  height: 250px;
  margin-bottom: 22px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer
}

.landing .rewvid-img-item__pic {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: 12px
}

.landing .rewvid-img-item__pic img {
  height: 100%;
  width: 100%;
  object-fit: cover
}

.landing .rewvid-img-item__vid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: .3s;
  z-index: 2
}

.landing .rewvid-img-item__vid video {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.landing .rewvid-img-item__ico .teacher-video-ico {
  width: 48px;
  height: 48px;
}

.landing .rewvid-img-item__ico .teacher-video-ico:after {
  border: 10px solid transparent;
  border-left: 18px solid #fff;
  left: 18px
}

.landing .rewvid-img-item:hover .rewvid-img-item__vid {
  opacity: 1
}

.landing .footer-menu {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-right: 100px;
  padding-top: 8px
}

.landing .footer-menu-items {
  width: 50%
}

.landing .footer-menu__item {
  margin-bottom: 28px
}

.landing .footer-menu__item a {
  font-weight: 600;
  font-size: 18px;
  color: #fff
}

.landing .section_courses-main .title-block__row-animated span {
  font-size: 64px
}

.landing .section_blue .courses-bg-text p {
  color: #fff;
  font-size: 18px
}

.landing .courses-bg-block {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 110%
}

.landing .courses-bg-block__text {
  width: 50%
}

.landing .courses-bg-block__img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  max-width: 800px
}

.landing .section_funtions-courses .function__info {
  padding-right: 0;
  text-align: right;
  padding-top: 22px
}

.landing .section_funtions-courses .function__img + .function__info {
  text-align: left
}

.landing .section_funtions-courses .function__img {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start
}

.landing .function-img-rad {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  overflow: hidden
}

.landing .function-img-rad img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.landing .section_funtions-courses .function-info__title h3 {
  font-size: 38px
}

.landing .section_funtions-courses .buttons-block {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end
}

.landing .section_funtions-courses .function__img + .function__info .buttons-block {
  -ms-flex-pack: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start
}

.landing .circle-pic_courses-1,
.landing .mask-pic_courses-1,
.landing .mask-pic_courses-2,
.landing circle-pic_courses-2 {
  top: 0
}

.z-index-2 {
  z-index: 2;
}

.landing .circle-pic_courses-3,
.landing .mask-pic_courses-3,
.landing .mask-pic_courses-4,
.landing .mask-pic_courses-5 {
  top: 244px
}

.landing .circle-pic_courses-1,
.landing .circle-pic_courses-2,
.landing .circle-pic_courses-3,
.landing .mask-pic_courses-1,
.landing .mask-pic_courses-2,
.landing .mask-pic_courses-3,
.landing .mask-pic_courses-4,
.landing .mask-pic_courses-5 {
  width: 172px;
  height: 172px
}

.landing .mask-pic_courses-1 {
  left: 34%
}

.landing .mask-pic_courses-2 {
  left: 68%
}

.landing .mask-pic_courses-3 {
  left: 12%
}

.landing .section_courses-main .grid__main-l,
.landing .section_courses-main .grid__main-r {
  width: 50%
}

.landing .mask-pic_courses-4 {
  left: 47%
}

.landing .mask-pic_courses-5 {
  left: 68%
}

.landing .circle-pic_courses-1 {
  left: 16%
}

.landing .circle-pic_courses-2 {
  left: 52%
}

.landing .circle-pic_courses-3 {
  left: 30%
}

.landing .section.section_pink {
  background: #ffe0e8
}

.landing .courses-tarif-wrap {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.landing .courses-tarif {
  position: relative;
  overflow: hidden;
  width: 49%;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  padding: 68px 54px
}

.landing .courses-tarif__img {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 28px
}

.landing .courses-tarif__img img {
  width: 100%;
  height: 100%;
  width: 280px;
  height: 280px;
  object-fit: cover;
  clip-path: circle(48% at 50% 50%);
  transform: scale(1);
  transition: .33s
}

.landing .courses-tarif:hover .courses-tarif__img img {
  clip-path: circle(50% at 50% 50%);
  transform: scale(1.1)
}

.landing .courses-tarif__title {
  margin-bottom: 12px
}

.landing .courses-tarif__title span {
  font-size: 38px;
  font-family: Evolventa;
  font-weight: 600
}

.landing .courses-tarif__label {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 174px;
  margin: 0 auto;
  margin-bottom: 32px
}

.landing .courses-tarif__label img {
  width: 100%
}

.landing .courses-tarif__text {
  margin-bottom: 38px
}

.landing .coursesinfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px
}

.landing .coursesinfo-item {
  background: #fff;
  padding: 42px 58px;
  -webkit-box-shadow: -20px 17px 51px rgb(0 0 0 / 5%);
  box-shadow: -20px 17px 51px rgb(0 0 0 / 5%);
  border-radius: 20px
}

.landing .coursesinfo-item__title {
  margin-bottom: 28px
}

.landing .coursesinfo-item__title span {
  font-weight: 700;
  font-size: 28px;
  color: #4987ff
}

.landing .coursesinfo-item__content strong {
  display: block;
}

.landing .coursesinfo-item__content p {
  margin-bottom: 20px;
}

.landing .coursesinfo-item__content ul {
  font-family: Montserrat, sans-serif;
  margin-left: 20px;
  padding-left: 0
}

.landing .coursesinfo-item__content li {
  font-size: 18px;
  margin-bottom: 18px;
  padding-left: 12px
}

.landing .coursesinfo-item__content li:nth-last-child(1) {
  margin-bottom: 0
}

.landing .coursesinfo-item__content li::marker {
  color: #dadada
}

.landing .coursesinfo-item-list__item {
  margin-bottom: 18px
}

.landing .coursesinfo-item-listitem {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative
}

.landing .coursesinfo-item-listitem__title {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  background: #fff;
  z-index: 2;
  padding-right: 8px
}

.landing .coursesinfo-item-listitem__border {
  position: absolute;
  bottom: 6px;
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #bfcbd1
}

.landing .coursesinfo-item-listitem__dscrp {
  position: relative;
  z-index: 2;
  font-size: 18px;
  background: #fff;
  padding-left: 7px
}

.landing .label-translate {
  position: relative;
  transform-origin: left;
  transform: rotate(22deg);
}

.landing .label-translate > img {
  max-width: 265px;
}

.landing .label-translate > p {
  position: absolute;
  height: 74px;
  left: 134px;
  font-weight: 600;
  width: 90px;
  line-height: 140%;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.landing .section.section_main.section_courses-main .buttons-lable {
  right: -128px
}

.landing .section.section_main.section_courses-main {
  /* padding-bottom: 98px; */
  padding-bottom: 0;
}

.landing .mask-pic.mask-pic_courses-big-1 {
  width: 442px
}

.landing .circle-pic.circle-pic_courses-big-1,
.landing .mask-pic.mask-pic_courses-big-1 {
  top: -20px;
  height: 442px;
  left: 20%
}

.landing .circle-pic.circle-pic_courses-big-1 {
  width: 228px;
  left: 68%
}

.landing .section.section_textonly {
  padding-bottom: 112px
}

.landing .textonly-block {
  background: #fff;
  padding: 42px 54px;
  -webkit-box-shadow: -20px 17px 51px rgb(0 0 0 / 5%);
  box-shadow: -20px 17px 51px rgb(0 0 0 / 5%);
  border-radius: 20px
}

.landing .textonly-block ul {
  padding-left: 40px;
  margin-top: 8px;
  font-size: 18px;
}

.landing .textonly-block ul li {
  margin-bottom: 10px;
}

.landing .section.section_coursesinfo {
  padding-top: 0
}

.landing .price-container {
  position: relative;
}

.landing .price-container .tarif-list__navigation {
  position: absolute;
  right: 20px;
  top: -25px;
}

.landing .slider-cost-block .swiper-button-next,
.landing .slider-cost-block .swiper-button-prev {
  margin: 0;
  top: 6px;
}

.landing .slider-cost-block .swiper-button-prev {
  right: 88px;
  left: auto;
}

.landing .slider-cost-block > .swiper {
  overflow: visible;
  position: initial;
}

.landing .slider-cost-block {
  /*position: relative;*/
  margin-bottom: 48px;
  z-index: 3
}

.overflow-swiper {
  position: relative;
  overflow: hidden;
  min-height: 300px;
}

.landing .slidercost {
  padding: 32px 28px;
  background: #fff;
  border-radius: 20px
}

.landing .slidercost__title {
  margin-bottom: 22px
}

.landing .slidercost__title span {
  position: relative;
  color: #4987ff;
  font-weight: 700;
  font-size: 28px;
  font-family: Evolventa
}

.landing .slidercost__text {
  margin-bottom: 26px
}

.landing .slidercost__text p {
  font-size: 15px;
  color: rgba(10, 10, 11, .67)
}

.landing .slidercost__checks {
  margin-bottom: 28px;
  width: 100%
}

.landing .slidercost-checks {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 18px 0;
  border-bottom: 2px solid rgba(0, 0, 0, .04);
  width: 100%
}

.landing .slidercost-checks__checkbox {
  position: relative;
  width: 35px
}

.landing .slidercost-checks__checkbox input {
  position: absolute;
  left: -9999px;
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0
}

.landing .slidercost-checks__checkbox input:checked + label:after {
  opacity: 1
}

.landing .slidercost-checks__checkbox input + label:after {
  content: '';
  display: block;
  background: url(/landing/svg/check-ico.svg) no-repeat center;
  background-size: contain;
  width: 30px;
  height: 22px;
  position: absolute;
  top: -12px;
  bottom: 0;
  left: -2px;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: .3s
}

.landing .slidercost-checks__checkbox label {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  cursor: pointer
}

.landing .slidercost-checks__title {
  width: 130px;
  font-weight: 700;
  font-size: 18px
}

.landing .slidercost-checks__cost {
  flex: 1 0 0;
  padding-left: 2%
}

.landing .slidercost-checks__cost span {
  font-weight: 600;
  font-size: 24px
}

.landing .slidercost-checks__cost img {
  height: 17px
}

.landing .slidercost-checks__lesson-price img {
  height: 9px;
}

.landing .slidercost-checks__label {
  position: absolute;
  width: 100px;
  top: 22px;
  right: -28px
}

.landing .slidercost-checks__checkbox label:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, .1)
}

.landing .slidercost-checks:nth-last-child(1) {
  border-bottom: 0
}

.landing .slider-cost-dcrp {
  position: relative;
  line-height: 1.66;
  padding-left: 16px
}

.landing .slider-cost-dcrp:after {
  content: '*';
  display: block;
  position: absolute;
  top: 2px;
  left: 0
}

.landing .slidercost__title span:after {
  content: '*';
  display: block;
  position: absolute;
  bottom: 12px;
  right: -21px;
  font-size: 22px
}

.landing .swiper-navigation {
  position: absolute;
  top: -90px;
  right: 0;
  width: 150px;
  z-index: 12
}

.landing .swiper-container.swiper-container_slidercost .swiper-slide {
  width: 415px;
  margin-right: 22px
}

.landing .swiper-button-next,
.landing .swiper-button-prev {
  width: 58px;
  height: 58px;
  background: 0 0
}

.landing .swiper-button-next:before,
.landing .swiper-button-prev:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  border-radius: 50%;
  transition: .3s;
  transform: scale(1)
}

.landing .swiper-button-next:hover:before,
.landing .swiper-button-prev:hover:before {
  transform: scale(1.1)
}

.landing .swiper-button-next:after,
.landing .swiper-button-prev:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 24px;
  height: 14px
}

.landing .swiper-button-next:after {
  background: url(/landing/svg/slidercost-ar-r.svg) no-repeat center;
  background-size: contain
}

.landing .swiper-button-prev:after {
  background: url(/landing/svg/slidercost-ar-l.svg) no-repeat center;
  background-size: contain
}

.landing .sections.sections_pink {
  background: linear-gradient(180.3deg, #fff .26%, rgba(255, 255, 255, 0) 68.48%), #ffe8ee
}

.landing .coursesinfo-item__title.coursesinfo-item__title_pink span {
  color: #eb1451
}

.landing .article-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px
}

.landing .article-block {
  display: block;
  position: relative;
  border-radius: 18px;
  padding: 32px 28px;
  overflow: hidden;
  opacity: 1 !important;
  height: 100%;
}

.landing .article-block__edit {
  position: absolute;
  right: 0;
  top: 0;
}

.landing .article-block__edit-button {
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.landing .article-block__edit-img {
  width: 18px;
}

.landing .article-block__edit-button:hover {
  background: rgba(255, 255, 255, 1);
}

.landing .article-block__edit-button:not(:last-child) {
  border-radius: 0 18px 0 0;
}

.landing .article-block__edit-button:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.landing .article-block__edit-button:last-child {
  border-radius: 0 0 0 18px;
}

.landing .article-block__date {
  font-size: 15px;
  margin-bottom: 28px
}

.landing .swiper-about {
  padding-bottom: 30px !important;
}

.landing .swiper__custom-pagination .swiper-pagination-bullet {
  width: 47px;
  height: 2px;
  border-radius: 0;
  margin: 0 15px;
  opacity: .3;
  background: #fff;
}

.landing .menu-to-image > div {
  display: flex;
  align-items: center;
}

.landing .menu-to-image img {
  height: 30px;
  margin-right: 10px;
}

.swiper__custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.form-textarea.indent,
.form-input.indent {
  margin-bottom: 10px;
}

.form-input,
.form-textarea {
  border: 1px solid #8995AA;
  border-radius: 20px;
  padding: 0 20px;
}

.form-input {
  width: 100%;
  height: 50px;
}

.form-textarea {

}

.landing .article-block__title {
  font-size: 22px;
  font-weight: 600;
  max-height: 112px;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  opacity: 1;
  transition: .3s;
  margin-bottom: 112px
}

.landing  .article-block-with-image .article-block__title {
  margin-bottom: 0;
}

.article-block__image {
  margin: 16px 0;
  display: block;
}

.article-block__image img {
  border-radius: 16px;
  max-height: 156px;
  width: 100%;
  object-fit: cover;
}

.landing .article-block__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1
}

.landing .article-block__bg img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.landing .article-list-more {
  text-align: center;
  margin-top: 58px
}

.landing .btn.btn_border-black {
  padding: 14px 34px;
  border: 2px solid #0a0a0b
}

.landing .mask-pic.mask-pic_courses-2 .mask-text-cont {
  top: 24px;
  left: -98px
}

.landing .section.section_main.section_courses-main .title-block {
  margin-bottom: 26px
}

.landing .section.section_main.section_courses-main .text-block p strong {
  font-size: 20px
}

.landing .section.section_main.section_courses-main .text-block p {
  line-height: 1.7;
  margin-bottom: 36px
}

.landing .section.section_main.section_courses-main .text-block p:nth-last-child(1) {
  /*margin-bottom: 0*/
}

.landing .section.section_courses-bg {
  padding: 82px 0 82px;
  overflow: hidden
}

.landing .title-lable {
  margin-bottom: 28px
}

.landing .article-top {
  padding: 48px 156px;
  border-radius: 20px;
  background: url(/landing/svg/article-top-bg.svg) no-repeat center;
  background-size: cover;
  margin-bottom: 18px
}

.landing .article-top__all {
  margin-bottom: 28px
}

.landing .article-top__all a {
  position: relative;
  padding-left: 38px;
  font-size: 14px;
  color: #4987ff
}

.landing .article-top__all a:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 28px;
  height: 16px;
  background: url(/landing/svg/allarticles.svg) no-repeat center;
  background-size: contain
}

.landing .article-top__title {
  margin-bottom: 72px
}

.landing .article-top__title h1 {
  font-size: 48px
}

.landing .article-top__info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.landing .article-top-info {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 28px
}

.landing .article-top-info img {
  width: 20px;
  height: 20px;
  margin-right: 9px
}

.landing .article-top-info span {
  font-weight: 600;
  font-size: 16px;
  color: rgba(111, 126, 155, .51)
}

.landing .article-top-info:nth-last-child(1) {
  margin-right: 0
}

.landing .article-content {
  position: relative;
  background: #fff;
  border-radius: 20px;
  padding: 42px 156px
}

.landing .article-content-socs {
  position: absolute;
  top: 40px;
  left: 36px;
  height: calc(100% - 300px)
}

.landing .article-content-stik {
  position: sticky;
  top: 120px
}

.landing a.article-soc {
  display: block;
  position: relative;
  width: 56px;
  height: 56px;
  margin-bottom: 18px
}

.landing .share-ico {
  text-align: center;
  line-height: 57px;
  padding-right: 4px;
  font-size: 19px;
}

.landing a.article-soc:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 2px solid rgba(0, 0, 0, .08);
  border-radius: 50%;
  transform: scale(1);
  transition: .3s
}

.landing a.article-soc img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 16px;
  height: 16px
}

.landing a.article-soc:hover:before {
  transform: scale(1.1)
}

.landing .article-content-box__block h2,
.landing .article-content-box__block h3 {
  margin-bottom: 24px
}

.landing .article-content-box__block h2 {
  font-size: 28px
}

.landing .article-content-box__block h3 {
  font-size: 18px
}

.landing .article-content-box__block p {
  font-size: 18px;
  line-height: 160%;
  margin-bottom: 42px
}

.landing .article-content-box__block img {
  width: 100%;
  border-radius: 18px;
  margin-bottom: 52px;
  object-fit: contain
}

.landing .article-content-box__block p a {
  color: #4987ff;
  border-bottom: 0
}

.landing .article-content-box__block ul {
  margin-bottom: 48px
}

.landing .article-content-box__block li {
  font-size: 18px;
  line-height: 160%;
  margin-left: 20px;
  padding-left: 15px;
  margin-bottom: 14px
}

.landing .article-content-box__block li:nth-last-child(1) {
  margin-bottom: 0
}

.landing .article-content-box__block li::marker {
  color: #4987ff
}

.landing .article-content-box__block blockquote {
  position: relative;
  font-family: Evolventa;
  line-height: 147.5%;
  font-weight: 600;
  border-radius: 20px;
  background: #f5f8fa;
  padding: 28px 98px;
  margin: 0;
  margin-bottom: 42px
}

.landing .article-content-box__block blockquote:after {
  display: block;
  content: '';
  position: absolute;
  top: 12px;
  left: 26px;
  width: 52px;
  height: 52px;
  background: url(/landing/svg/blockquote.svg) no-repeat center;
  background-size: contain
}

.landing .article-content-box__block iframe {
  width: 100%;
  height: 508px;
  border-radius: 20px;
  margin-bottom: 28px
}

.landing .article-content-box__socs {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 72px;
  padding-top: 42px;
  border-top: 2px solid rgba(163, 185, 224, .25)
}

.landing .article-socs.article-socs_horizontal {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.landing .article-info-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.landing a.article-soc.article-soc_horizontal {
  border-right: 2px solid #f3f3f3
}

.landing a.article-soc.article-soc_horizontal:before {
  display: none
}

.landing .articlesall {
  position: absolute;
  top: 0;
  right: 0
}

.landing .section.section_articles .grid {
  position: relative
}

.landing span.color-grey {
  color: #bac2c6
}

.landing .footer-under {
  padding: 12px 0
}

.landing .footer-bot .footer-text {
  margin-right: 42px
}

.landing .btn.btn_border-black:after,
.landing .btn.btn_border-black:hover {
  background: #4987ff
}

.landing .sections_pink .btn.btn_border-black:after {
  background: #eb1451
}

.landing .fuctions .btn.btn_black {
  border: 2px solid
}

.landing .btn.btn_border-black:hover {
  border: 2px solid #4987ff
}

.landing .btn.btn_border-black:hover span {
  color: #fff
}

/*.landing .function .btn.btn_black {*/
/*  border: 2px solid #0a0a0b*/
/*}*/

.landing .courses-tarif__buttons .btn.btn_black {
  border: 2px solid #0a0a0b
}

.landing .btn.btn_black:hover {
  border-color: #4987ff
}

.landing .function .btn {
  margin-right: 18px
}

.landing .function .btn:nth-last-child(1) {
  margin-right: 0
}

.landing .courses-tarif__buttons .btn {
  margin-right: 18px
}

.landing .courses-tarif__buttons .btn:nth-last-child(1) {
  margin-right: 0
}

.landing .sections_pink .btn.btn_border-black:hover {
  border: 2px solid #eb1451
}

.landing .article-block__button:hover {
  opacity: 1 !important;
}

.landing .article-block__button:hover .btn.btn_black {
  background: #4987ff;
}

.landing .article-block .btn.btn_black:after {
  opacity: 0
}

.landing .section.section_courses-slider {
  padding-bottom: 0
}

.landing .mob-show {
  display: none
}

.landing .sections.sections_article {
  background: #f5f8fa
}

.landing .slidercost__button .btn span {
  font-size: 14px
}

.landing .choice-cont__part:nth-child(1) {
  background: linear-gradient(151.56deg, #f1f3f4 8.96%, rgba(255, 255, 255, 0) 82.43%), #e0ebf0
}

.landing .choice-cont__part:nth-child(2) {
  background: linear-gradient(180.3deg, #050425 .26%, #121132 68.48%)
}

.landing .choice-cont__part:nth-child(2) .choice-text {
  color: #fff
}

.landing .choice-cont__part:nth-child(2) .btn.btn_black {
  background: #fff
}

.landing .choice-cont__part:nth-child(2) .btn.btn_black span {
  color: var(--colorBlack)
}

.landing .choice-cont__part:nth-child(2) .btn.btn_black:hover span {
  color: #fff
}

.landing .wrapper_dark .sections {
  background: linear-gradient(180.3deg, #050425 .26%, #121132 68.48%)
}

.landing .wrapper_dark .section_main .text-block p,
.landing .wrapper_dark .section_main .title-block span {
  color: #fff
}

.landing .courses-tarif:nth-child(2) .courses-tarif__buttons .btn:nth-last-child(1):after,
.landing .courses-tarif:nth-child(2) .courses-tarif__buttons .btn:nth-last-child(1):hover {
  background: #05dec5
}

.landing .courses-tarif:nth-child(2) .courses-tarif__buttons .btn:nth-last-child(1):hover {
  border: 2px solid #05dec5
}

.landing .courses-tarif__img:after,
.landing .courses-tarif__img:before {
  content: '';
  display: block;
  position: absolute;
  top: 68px;
  width: 144px;
  height: 280px
}

.landing .courses-tarif__img:after {
  right: 0
}

.landing .courses-tarif__img:before {
  left: 0
}

.landing .btn_border-green {
  background: #05dec5
}

.landing .btn_green:after {
  background: #4987ff
}

.landing .wrapper_dark .section_coursesinfo .title-block {
  color: #fff;
  margin-bottom: 12px
}

.landing .wrapper_dark .section_coursesinfo .subtext-block p {
  color: #fff
}

.landing .subtext-block p {
  font-size: 22px
}

.landing .subtext-block {
  margin-bottom: 38px
}

.landing .coursesinfo.coursesinfo_green .coursesinfo-item__title span {
  color: #05dec5
}

.landing .coursesinfo.coursesinfo_blue .coursesinfo-item__title span {
  color: #4987ff
}

.landing .regist-info__text p {
  margin-bottom: 22px
}

.landing .regist-info__text p:nth-last-child(1) {
  margin-bottom: 0
}

.landing .regist-info__text p a {
  font-size: 17px;
  border-bottom: 0;
  margin-right: 28px;
  font-weight: 600
}

.landing .btn.btn_green {
  background: #05dec5
}

.landing .btn.btn_green span {
  color: #fff
}

.landing .wrapper_dark .header {
  background: #050425;
  border: 2px solid rgba(228, 235, 239, .07)
}

.landing .wrapper_dark .header-float__nav span {
  color: #fff
}

.landing .wrapper_dark .header__nav a span {
  color: #fff
}

.landing .wrapper_dark .btn_header.btn_enter:after {
  background: #4987ff
}

.landing .wrapper_dark .header-lang__lable span {
  color: #fff
}

.landing .wrapper_dark .footer-wrap {
  background: #fff
}

.landing .wrapper_dark .footer-menu__item a {
  color: #0a0a0b;
  font-weight: 600
}

.landing .wrapper_dark .footer-contacts-link a {
  color: #0a0a0b;
  font-weight: 600
}

.landing .wrapper_dark .footer-contacts-linkdscrp {
  color: #000
}

.landing .wrapper_dark .footer-pays:after,
.landing .wrapper_dark .footer-top:after {
  background: rgba(48, 48, 48, .07)
}

.landing .wrapper_dark .page-up:before {
  background: #e9e9e9
}

.landing .section_reviews .grid.title-block {
  padding-right: 172px
}

.landing .section.section_main.section_main-page .grid.grid_l-r {
  margin-left: 142px;
  align-items: center;
}

.landing .section.section_main.section_main-page .grid__main-l {
  width: calc(100% + 142px)
}

.landing .function-cont {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.landing .function-cont .function__info {
  padding-top: 0
}

.landing .row-info-wrap {
  filter: drop-shadow(-20px 17px 51px rgba(0, 0, 0, .14))
}

.landing .function .buttons-block .btn_capitalize span {
  text-transform: uppercase
}

.landing .section.section_main.section_faq-main .text-block {
  width: 84%
}

.landing .askform-cont .title-block {
  margin-bottom: 24px
}

.landing .swiper-container.swiper-container_people .swiper-slide {
  width: auto;
}

.landing .textonly-block p a {
  font-weight: 500;
  color: #4987ff;
  border-bottom: 2px solid transparent
}

.landing .textonly-block p a:hover {
  opacity: 1;
  border-bottom: 2px solid
}

.landing .slidercost {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  height: 100%;
  -webkit-box-shadow: -10px 17px 51px rgb(0 0 0 / 5%);
  box-shadow: 0 0 20px rgb(0 0 0 / 5%)
}

.landing .slidercost__button {
  margin-top: auto
}

.landing .swiper-container.swiper-container_slidercost .swiper-slide {
  height: auto;
  margin-bottom: 20px
}

.landing .article-top-info:nth-last-child(1) img {
  width: 27px
}

.landing .mouse img {
  -webkit-animation: scroll 1.5s linear 0s infinite;
  animation: scroll 1.5s linear 0s infinite
}

@media (min-width: 2500px) {
  .landing .section.section_main.section_main-page .grid.grid_l-r {
    margin-left: auto;
  }
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0
  }
}

.landing .footer-pays {
  padding: 28px 0;
  position: relative
}

.landing .footer-pay {
  max-height: 28px;
  margin-right: 42px
}

.landing .footer-pay img {
  height: 100%
}

.landing .footer-pay:nth-last-child(1) {
  margin-right: 0
}

.landing .slidercost__button .btn.btn_black {
  background: #4987ff
}

.landing .slidercost__button .btn.btn_black:after {
  background: #2264e4
}

.landing .section_blue .btn.btn_black:after,
.landing .section_blue .btn.btn_black:hover {
  background: #fff
}

.landing .section_blue .btn.btn_black:hover span {
  color: #0a0a0b
}

.landing #gtx-trans {
  display: none
}

.landing .courses-tarif__buttons .btn span {
  text-transform: uppercase
}

.landing .section.section_main.section_main-page .mouse-block {
  margin-top: 0
}

.landing .section.section_main.section_contacts {
  padding: 114px 0 114px
}

.landing .contacts-socs__item.contacts-socs__item_tg img {
  width: 32px;
  height: 32px;
  left: -3px
}

.landing .section.section_teachers-page {
  padding: 88px 0 88px
}

.landing .section.section_teachers-page h2.title-block .title-big {
  line-height: 1.1
}

.landing .section.section_funtions.section_funtions-onabout {
  padding-top: 52px
}

.landing .askform-panel__field-half:before,
.landing .askform-panel__fields-row:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #1d1916;
  transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -webkit-transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -moz-transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -o-transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -ms-transition: all .5s cubic-bezier(.19, 1, .22, 1)
}

.landing .askform-panel__fields-row:before {
  bottom: 0
}

.landing .askform-panel__field-half.val:before,
.landing .askform-panel__fields-row.val:before {
  width: 100%
}

.landing .input-wrap.error:before,
.landing .textarea-wrap.error:before {
  background: #f95151
}

.landing span.error {
  display: block;
  text-align: left;
  font-family: 'Graphik LCG', Arial, Helvetica;
  font-weight: 500;
  color: #f95151;
  font-size: 12px;
  margin-bottom: -10px;
  margin-top: 10px;
  transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -webkit-transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -moz-transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -o-transition: all .5s cubic-bezier(.19, 1, .22, 1);
  -ms-transition: all .5s cubic-bezier(.19, 1, .22, 1)
}

.landing .coursesinfo-item__content p a {
  border-bottom: 1px solid
}

.landing .coursesinfo-item__content p a:hover {
  border-bottom: 2px solid transparent
}

.landing .function-img-rad a {
  display: block;
  height: 100%;
  clip-path: circle(46% at 50% 50%)
}

.landing .function-img-rad a:hover {
  clip-path: circle(50% at 50% 50%);
  opacity: 1
}

.landing .section_textonly-toppadnone {
  padding-top: 0
}

.landing .element-menu-mob {
  display: none !important
}

.landing .error ~ .input-line {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d40202;
  width: 100%;
  height: 2px;
  z-index: 22
}

.landing .slider-hide {
  display: none
}

/* new cost styles */
.landing .slidercost__label {
  position: absolute;
  right: -20px;
  top: 14px;
  width: 133px;
  height: 75px;
}

.landing .slidercost__title.title-w-label {
  max-width: 80%;
}

.landing .slidercost-checks__cost {
  text-align: right;
}

.landing .slidercost-checks__double {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.landing .slidercost-checks__old {
  margin-right: 10px;
  position: relative;
}

.landing .slidercost-checks__old::before {
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #0A0A0B;
  margin: auto;
}

.landing .slidercost-checks__new {
  color: #FF3366;
}

.landing .slidercost-checks__lesson-price {
  margin-top: 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  color: #FF3366;
}

.landing .slidercost-checks__lesson-price img {
  height: 9px;
}

.main__steps {
  max-width: 600px;
  margin-left: auto;
}

.main__steps-item {
  display: flex;
  padding: 20px;
  border-radius: 20px;
  background: #FFFFFF;
  align-items: center;
  margin-bottom: 10px;
}

.main__steps-item > p:first-child {
  white-space: nowrap;
  font-weight: bold;
  color: #4987ff;
  height: 100%;
}

.main__steps-item > p:last-child {
  border-left: 2px solid rgba(0, 0, 0, .2);
  padding-left: 10px;
  margin-left: 10px;
}

.color-w > span {
  color: #FFFFFF !important;
}

@media only screen and (max-width: 767px) {
  .landing .sticky-slider_show-mobile {
    display: block;
  }

  .landing .function-cont .function__info .buttons-block .wrapper_btn > .btn,
  .landing .function-cont .function__info .buttons-block .btn {
    margin: 0 auto !important;
  }

  .landing .function-cont .function__info .buttons-block .wrapper_btn {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .text-pic_aboutpage_bottom {
    transform: translate(-16%, -235%);
  }

  .landing .sticky-slider_show-desktop {
    display: none;
  }

  .landing .slidercost__label {
    width: 100px;
    height: auto;
    right: -5px;
  }
}

/* new cost styles end */

@media only screen and (max-width: 1700px) {
  .landing .title-block__row-animated.title-block__row-animated_main span {
    font-size: 70px
  }

  .grid_min-height {
    min-height: 450px;
  }

  .landing .mask-pic_main-1 {
    height: 265px;
    width: 265px;
    left: -50px
  }

  .landing .circle-pic_main-1 {
    left: 248px;
    width: 136px;
    height: 264px
  }

  .landing .mask-pic_main-2 {
    height: 265px;
    width: 265px;
    left: 53%
  }

  .landing .circle-pic_main-2 {
    width: 155px;
    height: 285px;
  }

  .landing .mask-pic_main-3 {
    top: 320px;
    left: 180px;
    width: 385px;
    height: 385px
  }

  .landing .header__nav .header-float__nav,
  .landing .header__nav a span {
    font-size: 15px
  }

  .landing .header__logo {
    width: 194px;
    height: 38px;
    margin-right: 49px
  }

  .landing .header-lang__lable span {
    font-size: 15px
  }

  .landing .btn_header span {
    font-size: 15px
  }

  .landing .section-untop-second + .circle-pit .circle-pit__elem_main-2 {
    display: none
  }

  .landing .section-untop-first > .circle-pit .circle-pit__elem_main-2 {
    display: none
  }

  .landing .section-untop-first {
    padding-bottom: 0
  }

  .landing .page-up {
    display: none
  }

  .landing .popcards__item {
    margin-top: 86px
  }

  .landing .mask-pic.mask-pic_about-1 {
    width: 360px;
    height: 360px;
    right: 18%
  }

  .landing .circle-pic_about-1,
  .landing .circle-pic_about-2 {
    width: 186px;
    height: 360px
  }

  .landing .circle-pic_about-2 {
    left: 7%
  }

  .landing .circle-pic_about-1 {
    left: -21%
  }

  .landing .centered-slider-wrap {
    padding: 168px 0
  }

  .landing .function__info + .function__img {
    margin-right: 0
  }

  .landing .function__img {
    padding: 36px 52px
  }

  .landing .wide-right-block_proc .mask-pic {
    width: 136px;
    height: 136px
  }

  .landing .text-pic_proc.text-pic_proc-2 {
    top: 20px;
    right: -162px
  }

  .landing .proc-tablet-main {
    width: 412px;
    left: 2%
  }

  .landing .mask-pic_proc-2 {
    left: -19%
  }

  .landing .circle-pic.circle-pic_proc-3 {
    top: 66%;
    left: -10%
  }

  .landing .mask-pic.mask-pic_proc-1 {
    right: 41%
  }

  .landing .mask-pic.mask-pic_proc-3 {
    left: 58%;
    right: auto
  }

  .landing .mask-pic.mask-pic_proc-4 {
    right: auto;
    left: 40%
  }

  .landing .circle-pic.circle-pic_proc-1 {
    right: auto;
    left: 22%
  }

  .landing .circle-pic.circle-pic_proc-2 {
    right: auto;
    left: 63%
  }

  .landing .wide-right-block_proc .circle-pic {
    width: 84px;
    height: 162px
  }

  .landing .circle-pic.circle-pic_proc-4 {
    right: auto;
    left: 63%
  }

  .landing .mask-pic.mask-pic_proc-2,
  .landing .mask-pic.mask-pic_proc-3 {
    top: 35%
  }

  .landing .text-pic_proc.text-pic_proc-1 {
    top: 26px;
    left: -124px
  }

  .landing .text-pic_proc.text-pic_proc-3 {
    top: 28px;
    left: -124px
  }

  .landing .function-bg__item.function-bg__item_funct-1 {
    top: 178px;
    right: -80px
  }

  .landing .function-bg__item.function-bg__item_funct-2 {
    top: 295px;
    right: -175px
  }

  .landing .function-bg__item.function-bg__item_funct-3 {
    top: -100px;
    left: -19%
  }

  .landing .function-bg__item.function-bg__item_funct-4 {
    top: -56px;
    left: -8%
  }

  .landing .grid__main-l_short {
    padding-right: 208px
  }

  .landing .function-bg__item.function-bg__item_funct-5 {
    top: 8px;
    right: -44px
  }

  .landing .function-bg__item.function-bg__item_funct-6 {
    top: 107px;
    right: -122px
  }

  .landing .circle-pic.circle-pic_teach-1 {
    width: 56px;
    top: 11%
  }

  .landing .circle-pic.circle-pic_teach-3 {
    display: none
  }

  .landing .mask-pic.mask-pic_teach-2 {
    width: 120px;
    height: 120px;
    top: 36%;
    left: 5%
  }

  .landing .section.section_main.section_main-teaching {
    padding: 122px 0 82px
  }

  .landing .mask-pic.mask-pic_teach-7 {
    width: 134px;
    height: 134px;
    top: 34%;
    right: -2%
  }

  .landing .mask-pic.mask-pic_teach-8 {
    width: 96px;
    height: 96px;
    top: 70%;
    right: 7%
  }

  .landing .circle-pic.circle-pic_teach-4 {
    top: 40%;
    right: 10%
  }

  .landing .mask-pic.mask-pic_teach-5 {
    top: 7%;
    right: 7%
  }

  .landing .mask-pic.mask-pic_teach-6 {
    top: 24%;
    right: 16%;
    width: 82px;
    height: 82px
  }

  .landing .mask-pic.mask-pic_teach-4 {
    width: 92px;
    height: 92px;
    top: 65%;
    left: 13%
  }

  .landing .mask-pic.mask-pic_teach-1 {
    width: 52px;
    height: 52px;
    top: 13%;
    left: 13%
  }

  .landing .circle-pic.circle-pic_teach-2 {
    width: 52px;
    top: 32%;
    left: 17%
  }

  .landing .mask-pic.mask-pic_teach-3 {
    width: 72px;
    height: 72px;
    top: 66%;
    left: -2%
  }

  .landing .section_main-teaching .title-block__row-animated:nth-last-child(1) {
    margin-top: 12px
  }

  .landing .circle-pic.circle-pic_carriers-1 {
    left: 55%
  }

  .landing .circle-pic.circle-pic_carriers-2 {
    left: 75%
  }

  .landing .section.section_blue.section_couch .title-block__row-animated span {
    font-size: 64px
  }

  .landing .section.section_couch {
    min-height: 590px;
    padding: 158px 0 162px;
    overflow: hidden
  }

  .landing .text-block.text-block_short {
    width: 572px
  }

  .landing .svg-bg {
    left: -10%;
    width: 120%
  }

  .landing .title-block.title-block_centered.title-block_short {
    margin-bottom: 44px
  }

  .landing .videocircle {
    width: 438px;
    height: 438px
  }

  .landing .section.section_teaches .circle-pit .mask-pic {
    width: 128px;
    height: 128px
  }

  .landing .section.section_teaches .circle-pic {
    width: 68px;
    height: 128px
  }

  .landing .mask-pic.mask-pic_courses-2 .mask-text-cont {
    display: none
  }

  .landing .courses-bg-block__text .buttons-lable {
    right: 113px
  }

  .landing .section.section_courses-bg {
    padding: 82px 0 64px
  }

  .landing .courses-bg-block__img {
    top: 136px;
    width: 586px
  }

  .landing .section_courses-main .title-block__row-animated span {
    font-size: 56px
  }

  .landing .page-up:hover:before {
    transform: scale(1.1)
  }

  .landing .footer-menu__item a {
    font-size: 16px
  }

  .landing .footer-cont__col.footer-cont__col_1 {
    width: 30%
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 20%
  }

  .landing .footer-menu {
    padding-right: 58px
  }

  .landing .choice-cont__part {
    padding: 48px 68px
  }

  .landing .choice-cont {
    margin-left: -42px
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    white-space: nowrap
  }

  .landing .section.section_main.section_main-page .grid.grid_l-r {
    margin-left: auto
  }

  .landing .section.section_main.section_main-page .grid__main-l {
    width: 65%
  }

  .landing .popcards__item:nth-child(1) {
    top: 150px
  }

  .landing .popcards__item:nth-child(2) {
    top: 200px
  }

  .landing .popcards__item:nth-child(3) {
    top: 250px
  }

  .landing .mask-pic_courses-1 {
    left: 23.7%
  }

  .landing .circle-pic_courses-2 {
    left: 38.5%
  }

  .landing .mask-pic_courses-2 {
    left: 53.3%
  }

  .landing .circle-pic_courses-1 {
    left: 8.5%
  }

  .landing .mask-pic_courses-3 {
    left: 4%
  }

  .landing .circle-pic_courses-3 {
    left: 19%
  }

  .landing .mask-pic_courses-4 {
    left: 34%
  }

  .landing .mask-pic_courses-5 {
    left: 53.4%
  }

  .landing .section.section_main.section_main-page .grid__main-r {
    height: 605px;
  }

  .landing .title-block.title-block_main {
    margin-bottom: 32px
  }

  .landing .mask-pic_main-3 {
    width: 285px;
    height: 285px
  }

  .landing .sticky-container {
    padding-top: 152px
  }

  .landing .section.section_about-main {
    padding: 84px 0
  }

  .landing .section.section_funtions.section_funtions-onabout {
    padding-top: 0
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    left: 10%
  }

  .landing .circle-pic.circle-pic_courses-big-1 {
    left: 54%
  }

  .landing .section.section_courses-slider {
    padding-top: 52px
  }

  .landing .section.section_main.section_contacts {
    padding-top: 72px
  }

  .landing .section.section_articles {
    padding: 68px 0 62px
  }

  .landing .section.section_centered .circle-pit .mask-pic {
    width: 130px;
    height: 130px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-2 {
    right: -152px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-6 {
    right: -170px;
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-6 .row-info-text {
    white-space: nowrap;
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-5 {
    right: -192px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-7 {
    left: -212px
  }

  /*.landing .popcards {*/
  /*  height: 2500px*/
  /*}*/
  .landing .swiper-container-horizontal > .swiper-scrollbar {
    left: 0
  }

  .landing .section.section_article {
    padding: 68px 0 62px
  }

  .landing .section.section_main.section_courses-mainitem {
    padding-bottom: 0;
    margin-bottom: -58px
  }

  .landing .choice-img-item__float {
    left: -86px;
    top: 167px
  }

  .landing .mask-pic_main-2 .mask-text-cont {
    top: 108px;
    left: -138px
  }

  .landing .mask-pic_main-3 .mask-text-cont {
    top: 122px;
    left: -188px
  }
}

@media only screen and (max-width: 1439px) {
  .landing .cost-swiper .swiper-slide {
    width: 393px;
    min-width: 393px;
  }

  .landing .label-translate > p {
    height: 64px;
    left: 125px;
    width: 74px;
    font-size: 13px;
  }

  .grid_min-height {
    min-height: 420px;
  }

  .landing .review-card {
    padding: 15px 15px 30px 15px
  }

  .landing .review-card__user {
    padding-top: 20px
  }

  .landing .review-card-user__dscrp {
    padding-top: 20px
  }

  .landing .review-card-user__name {
    font-size: 16px;
    margin-bottom: 0
  }

  .landing .grid {
    width: 1222px
  }

  .landing .section.section_main.section_main-page {
    padding: 58px 0 92px;
  }

  .landing .section.section_main.section_main-page .grid__main-r {
    height: 540px;
  }

  .landing .title-block__row-animated.title-block__row-animated_main span {
    font-size: 60px
  }

  .landing .title-block {
    margin-bottom: 38px
  }

  .landing .buttons-block {
    margin-top: 48px
  }

  .landing .mask-pic_main-1 {
    height: 216px;
    width: 216px;
    left: -7%
  }

  .landing .header__nav a {
    margin-right: 14px
  }

  .landing .header__logo {
    margin-right: 38px
  }

  .landing .btn_header span {
    font-size: 13px
  }

  .landing a.btn.btn_header,
  .landing a.wrapper_btn {
    margin-left: 8px;
    line-height: 18px;
  }

  .landing .btn.btn_header {
    height: 46px
  }

  .landing .btn_main span {
    font-size: 17px
  }

  .landing .btn.btn_main {
    padding: 20px 42px
  }

  .landing .lable {
    top: -32px;
    left: 12px;
    width: 240px;
    height: 164px
  }

  .landing .circle-pic_main-1 {
    left: 26%;
    width: 110px;
    height: 214px
  }

  .landing .mask-pic_main-2 {
    height: 216px;
    width: 216px;
    left: 44%
  }

  .landing .circle-pic_main-2 {
    width: 150px;
    height: 290px
  }

  .landing .mask-pic_main-3 {
    top: 250px;
    left: 8%;
    width: 290px;
    height: 290px
  }

  .landing .circle-pic_main-2 {
    width: 150px;
    height: 290px;
    top: 250px;
    left: -16%
  }

  .landing .circle-pic_main-3 {
    width: 150px;
    height: 290px;
    top: 250px;
    left: 52%
  }

  .landing .mouse span {
    font-weight: 600;
    font-size: 13px
  }

  .landing .mouse img {
    width: 22px;
    height: 34px;
    margin-right: 18px
  }

  .landing .section-untop-first {
    margin-bottom: -1px
  }

  .landing .circle-pit__elem_main-3 {
    width: 132px;
    height: 132px;
    top: 437px;
    right: 37%
  }

  .landing .circle-pit__elem_main-4 {
    width: 853px;
    height: 853px;
    top: 47px;
    right: -34%
  }

  .landing .sticky-container {
    padding-top: 122px
  }

  .landing .title-block__row-animated span {
    font-size: 46px
  }

  .landing .sticky-container .grid.title-block {
    margin-left: 30px
  }

  .landing .hor-slider-cont {
    padding: 0 2.5% 0 2.5% !important
  }

  .landing .card-img-text__img {
    width: 165px;
    height: 175px
  }

  .landing .card-img-text__txt {
    width: 100%;
    line-height: 1.5;
    max-width: 300px
  }

  .landing .title-block__row-animated .title-big {
    font-size: 56px
  }

  .landing .slider-person__img {
    width: 236px;
    height: 236px
  }

  .landing .slider-semicircle {
    width: 122px;
    height: 236px
  }

  .landing .row-info-text span {
    font-size: 13px
  }

  .landing .popcard-info__title {
    font-size: 36px
  }

  .landing .popcard__nums {
    font-size: 26px
  }

  .landing h3 {
    font-size: 46px
  }

  .landing .function {
    margin-bottom: 42px;
    padding-bottom: 42px
  }

  .landing .review-card__text p {
    font-size: 17px
  }

  .landing .review-card__text {
    padding-bottom: 42px
  }

  .landing .section.section_reviews {
    padding: 92px 0 92px
  }

  .landing .popcards__item {
    margin-top: 0
  }

  .landing .regist-img__scrreen {
    margin-left: -122px
  }

  .landing .askform .title-block__row-animated span {
    font-size: 37px
  }

  .landing .askform-cont__part.askform-cont__part_l {
    padding-right: 32px
  }

  .landing .regist-img__scrreen {
    margin-left: -122px
  }

  .landing .accordion-cont__title {
    font-size: 18px
  }

  .landing .accordion__cont {
    padding: 28px 0
  }

  .landing h1 {
    font-size: 56px
  }

  .landing .cost-info__title {
    font-size: 18px
  }

  .landing .cost-card-lable {
    top: -12px;
    right: -84px;
    width: 144px
  }

  .landing .mask-pic.mask-pic_about-1 {
    width: 344px;
    height: 344px;
    right: 18%
  }

  .landing .circle-pic_about-1,
  .landing .circle-pic_about-2 {
    width: 176px;
    height: 344px
  }

  .landing .text-pic_about.text-pic_about-2 {
    right: -68px;
    top: 122px
  }

  .landing .text-pic_about.text-pic_about-1 {
    top: 56px;
    right: -8px
  }

  .landing .row-info {
    margin-bottom: -10px
  }

  .landing .centered-slider-item__title {
    margin-bottom: 48px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-2 {
    right: 130px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-5 {
    right: -142px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-7 {
    left: -156px
  }

  .landing .mask-pic.mask-pic_aboutpage-7 {
    top: 30%;
    right: -2%
  }

  .landing .mask-pic.mask-pic_aboutpage-3 {
    top: 38%;
    left: 8%
  }

  .landing .section.section_centered .circle-pit .mask-pic {
    width: 152px;
    height: 152px
  }

  .landing .mask-pic.mask-pic_aboutpage-1 {
    left: 14%;
    top: 7%
  }

  .landing .centered-slider-wrap {
    padding: 208px 0
  }

  .landing .section.section_blue.section_centered {
    padding: 0
  }

  .landing .mask-pic.mask-pic_aboutpage-2 {
    left: -4%
  }

  .landing .mask-pic.mask-pic_aboutpage-6 {
    top: 8%;
    right: 12%
  }

  .landing .mask-pic.mask-pic_aboutpage-9 {
    top: 76%;
    right: 18%
  }

  .landing .wide-right-block_proc .mask-pic {
    width: 120px;
    height: 120px
  }

  .landing .row-info-text span {
    font-size: 12px
  }

  .landing .text-pic_proc.text-pic_proc-2 {
    top: 8px;
    right: -126px
  }

  .landing .proc-tablet-main {
    width: 374px;
    left: -1%
  }

  .landing .circle-pic.circle-pic_proc-4 {
    left: 56%
  }

  .landing .circle-pic.circle-pic_proc-3 {
    top: 64%;
    left: -13%
  }

  .landing .circle-pic.circle-pic_proc-2 {
    left: 57%
  }

  .landing .circle-pic.circle-pic_proc-1 {
    left: 19%
  }

  .landing .mask-pic.mask-pic_proc-4 {
    left: 35%;
    top: 62%
  }

  .landing .mask-pic.mask-pic_proc-3 {
    left: 51%
  }

  .landing .text-pic_proc.text-pic_proc-3 {
    top: 18px;
    left: -94px
  }

  .landing .mask-pic.mask-pic_proc-1 {
    right: auto;
    left: 37%
  }

  .landing .text-pic_proc.text-pic_proc-1 {
    top: 26px;
    left: -98px
  }

  .landing .function-bg__item.function-bg__item_funct-square {
    width: 138px;
    height: 138px
  }

  .landing .function-bg__item.function-bg__item_funct-square-2,
  .landing .function-bg__item.function-bg__item_funct-square-5,
  .landing .function-bg__item.function-bg__item_funct-square-8 {
    left: 154px
  }

  .landing .function-bg__item.function-bg__item_funct-square-3,
  .landing .function-bg__item.function-bg__item_funct-square-6,
  .landing .function-bg__item.function-bg__item_funct-square-9 {
    left: 308px
  }

  .landing .function-bg__item.function-bg__item_funct-square-4,
  .landing .function-bg__item.function-bg__item_funct-square-5,
  .landing .function-bg__item.function-bg__item_funct-square-6 {
    top: 168px
  }

  .landing .function-bg__item.function-bg__item_funct-square-7,
  .landing .function-bg__item.function-bg__item_funct-square-8,
  .landing .function-bg__item.function-bg__item_funct-square-9 {
    top: 338px
  }

  .landing .function__img.function__img_square {
    min-height: 510px;
    left: 148px
  }

  .landing .doubleforms-item__title span {
    font-size: 29px
  }

  .landing .doubleforms-item__text span {
    font-size: 14px
  }

  .landing .doubleforms .btn_main span {
    font-size: 15px
  }

  .landing .doubleforms .btn_main {
    padding: 16px 48px
  }

  .landing .doubleforms-item__rememb a {
    font-size: 13px
  }

  .landing .doubleforms-item__fields {
    margin-bottom: 32px
  }

  .landing .doubleforms-item-fields {
    margin-bottom: 38px
  }

  .landing .doubleforms-item__title {
    margin-bottom: 18px
  }

  .landing .doubleforms-item__text {
    margin-bottom: 32px
  }

  .landing .doubleforms-or {
    font-size: 22px;
    width: 82px;
    height: 82px
  }

  .landing .doubleforms-item__dscrp span {
    font-size: 11px
  }

  .landing .doubleforms-item {
    padding: 52px 108px
  }

  .landing .circle-pic.circle-pic_carriers-1,
  .landing .circle-pic.circle-pic_carriers-2 {
    width: 196px;
    height: 368px
  }

  .landing .section.section_blue.section_couch .title-block__row-animated span {
    font-size: 56px
  }

  .landing .section.section_couch {
    min-height: 558px;
    padding: 126px 0 16px
  }

  .landing .section.section_teaches .circle-pit .mask-pic {
    width: 114px;
    height: 114px
  }

  .landing .section.section_teaches .circle-pic {
    width: 57px;
    height: 114px
  }

  .landing .circle-pic_teachersmany-1,
  .landing .circle-pic_teachersmany-3 {
    left: 9%
  }

  .landing .circle-pic_teachersmany-4,
  .landing .circle-pic_teachersmany-6 {
    right: 9%
  }

  .landing .mask-pic_teachersmany-2,
  .landing .mask-pic_teachersmany-5 {
    left: 16%
  }

  .landing .mask-pic_teachersmany-6,
  .landing .mask-pic_teachersmany-9 {
    right: 16%
  }

  .landing .text-block.text-block_teaches {
    width: 594px
  }

  .landing .choice-text {
    font-size: 14px
  }

  .landing .choice-img-item {
    width: 220px;
    height: 220px
  }

  .landing .choice-img-item__label {
    top: 194px;
    width: 172px
  }

  .landing .func-step-item__title {
    font-size: 20px
  }

  .landing .func-step-item__text {
    font-size: 16px
  }

  .landing .func-step {
    width: 89%
  }

  .landing .section.section_main.section_courses-main .text-block p strong {
    font-size: 18px
  }

  .landing .section.section_courses-bg .courses-bg-text p {
    font-size: 18px
  }

  .landing .courses-bg-block__img {
    top: 83px;
    width: 544px
  }

  .landing .function-img-rad {
    width: 440px;
    height: 440px
  }

  .landing .courses-tarif__img img {
    width: 340px;
    height: 340px
  }

  .landing .big-circle-right.circle-pic_courses-big-1 > .circle-pic-cont {
    transform: scale(0.794) !important;
    transform-origin: top;
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    height: 350px;
    width: 350px
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    left: 11%
  }

  .landing .big-circle-right {
    right: 27%;
    left: initial;
  }

  .landing .swiper-container.swiper-container_slidercost .swiper-slide {
    width: 393px
  }

  .landing .slidercost-checks__title {
    width: 110px
  }

  .landing .slidercost__title span {
    font-size: 32px
  }

  .landing .slidercost__text p {
    font-size: 14px
  }

  .landing .article-block__title {
    font-size: 22px
  }

  .landing .article-top__title h1 {
    font-size: 36px
  }

  .landing .article-top__title {
    margin-bottom: 42px
  }

  .landing .article-content-box__block h2 {
    font-size: 22px
  }

  .landing .article-content-box__block p {
    font-size: 16px;
    margin-bottom: 36px
  }

  .landing .article-content-box__block h3 {
    font-size: 19px
  }

  .landing .article-content-box__block blockquote {
    font-size: 18px
  }

  .landing .footer-cont__col.footer-cont__col_2 {
    width: 45%
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 25%
  }

  .landing .footer-menu {
    padding-right: 0
  }

  .landing .article-content-box__block iframe {
    height: 432px
  }

  .landing .slidercost__button .btn span {
    font-size: 12px
  }

  .landing .choice-cont__part {
    padding: 48px 48px
  }

  .landing .choice-cont {
    grid-gap: 28px;
    margin-left: 0
  }

  .landing .popcard__info {
    width: calc(100% - 370px)
  }

  .landing .popcard__img {
    width: 370px
  }

  .landing .slidercost__title span:after {
    bottom: 5px;
    right: -17px
  }

  .landing .faq-pic-main {
    height: 550px
  }

  .landing .text-pic_faq.text-pic_faq-1 {
    top: 306px;
    left: -92px
  }

  .landing .mask-pic_main-2 .mask-text-cont {
    left: -108px
  }

  .landing .mask-pic_main-3 .mask-text-cont {
    left: -138px
  }

  .landing .rewvid-img-item {
    margin-bottom: 20px
  }

  .landing .review-card__user {
    padding-top: 25px
  }
}

@media only screen and (max-width: 1439px) {
  .small-circle.wide-right-block {
    transform: scale(0.8);
    transform-origin: left;
  }
}

@media only screen and (max-width: 1279px) {
  .main__steps-item > p {
    font-size: 14px !important;
  }

  .grid_min-height {
    min-height: 350px;
  }

  .main__steps {
    padding-left: 20px;
  }

  .landing .cost-swiper .swiper-slide {
    width: 306px;
    min-width: 306px;
  }

  .landing .rewvid-img-item {
    height: 200px
  }

  .landing .review-card__user {
    padding-top: 15px
  }

  .landing .review-card-user__dscrp {
    padding-top: 15px
  }

  .landing .rewvid-img-item__ico .teacher-video-ico {
    width: 60px;
    height: 60px
  }

  .landing .sticky-slider-slide {
    width: 485px;
    margin-right: 20px
  }

  .landing .card-img-text__img {
    width: 135px;
    height: 145px
  }

  .landing .cart-img-text__item {
    padding-left: 40px
  }

  .landing .card-img-text__img img {
    -o-object-fit: cover;
    object-fit: cover
  }

  .landing .card-img-text__txt span {
    font-size: 18px
  }

  .landing .card-img-text {
    padding: 50px 35px
  }

  .landing .title-block__row-animated.title-block__row-animated_main span {
    font-size: 46px
  }

  .landing .grid {
    width: 964px
  }

  .landing .header__logo {
    width: 138px;
    height: 28px;
    margin-right: 28px
  }

  .landing .header__nav .header-float__nav,
  .landing .header__nav a span {
    font-size: 12px
  }

  .landing .header {
    padding: 12px 32px
  }

  .landing .btn_main span {
    font-size: 13px
  }

  .landing .btn.btn_main {
    padding: 18px 38px
  }

  .landing .circle-pic_main-1 {
    left: 22%
  }

  .landing .mask-pic_main-1 {
    left: -19%
  }

  .landing .mask-pic_main-2 .mask-text-cont {
    top: 68px;
    left: -112px
  }

  .landing .mask-pic_main-3 .mask-text-cont {
    left: -58px
  }

  .landing .mask-pic_main-3 {
    left: -2%
  }

  .landing .section.section_main.section_main-page .text-block.text-block_main {
    width: 60%
  }

  .landing .circle-pic_main-2 {
    left: -31%
  }

  .landing .section.section_main.section_main-page .grid__main-l {
    z-index: 2
  }

  .landing .header-lang__lable span {
    font-size: 12px
  }

  .landing .header__lang {
    margin-right: 12px
  }

  .landing .title-block__row-animated span {
    font-size: 42px
  }

  .landing .text-block p {
    font-size: 16px
  }

  .landing .section-untop-first {
    padding-bottom: 84px
  }

  .landing .title-block__row-animated .title-big {
    font-size: 44px
  }

  .landing .text-block p.text-big {
    font-size: 20px
  }

  .landing .slider-person__img {
    width: 200px;
    height: 200px
  }

  .landing .row-info-text span {
    font-size: 11px
  }

  .landing .row-info-text {
    padding: 8px 16px
  }

  .landing .slider-semicircle {
    width: 105px;
    height: 200px
  }

  .landing .popcard-info__title {
    font-size: 36px
  }

  .landing .popcard-info__text p {
    font-size: 16px
  }

  .landing .popcard__img {
    width: 208px
  }

  .landing .popcard__info {
    width: calc(100% - 208px)
  }

  .landing .popcard-cont {
    position: relative
  }

  .landing .popcard__nums {
    position: absolute;
    right: 0;
    top: 0
  }

  .landing .popcard-info__button .btn span {
    font-size: 13px
  }

  .landing .section.section_popcards {
    padding: 68px 0 62px
  }

  .landing .popcards__item {
    margin-top: 52px
  }

  .landing .popcards__item:nth-child(1) {
    top: 200px
  }

  .landing .popcards__item:nth-child(2) {
    top: 250px
  }

  .landing .popcards__item:nth-child(3) {
    top: 300px
  }

  .landing h3 {
    font-size: 42px
  }

  .landing p {
    font-size: 18px
  }

  .landing .btn.btn_capitalize span {
    font-size: 13px
  }

  .landing .regist-img__scrreen {
    width: 494px;
    margin-top: -106px;
    margin-left: -106px;
    margin-bottom: -134px
  }

  .landing .regist-img__scrreen span,
  .landing .regist-img__scrreen img {
    width: 100% !important;
    height: auto;
  }

  .landing .regist__info {
    width: 60%
  }

  .landing .regist__img {
    width: 40%
  }

  .landing .regist-info__button .btn span {
    font-size: 12px
  }

  .landing .regist-info__text p {
    font-size: 14px
  }

  .landing .regist-info__title {
    margin-bottom: 16px
  }

  .landing .regist-info__text {
    margin-bottom: 32px;
    width: 86%
  }

  .landing .regist {
    padding: 62px 72px 38px
  }

  .landing .footer-contacts-linkdscrp {
    font-size: 13px
  }

  .landing .footer-contacts-link a {
    font-size: 16px
  }

  .landing .footer-logo {
    width: 166px
  }

  .landing .footer-cont__col.footer-cont__col_2 {
    width: 55%
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 20%
  }

  .landing .footer-socs__item {
    width: 40px;
    height: 40px;
    margin-right: 13px
  }

  .landing .footer-socs__item img {
    width: 15px;
    height: 15px
  }

  .landing .footer-socs__item.yandex-dzen img {
    width: 27px;
    height: 27px
  }

  .landing .footer-cont__col.footer-cont__col_1 {
    width: 25%
  }

  .landing .footer-text a,
  .landing .footer-text span {
    font-size: 13px
  }

  .landing .btn_header span {
    font-size: 12px
  }

  .landing .btn.btn_header {
    padding: 8px 16px;
    height: 40px
  }

  .landing h1 {
    font-size: 56px
  }

  .landing .faq-pic-main {
    width: 300px;
    height: 540px;
    right: 0
  }

  .landing h1 {
    font-size: 44px
  }

  .landing .section.section_faq-main .grid__main-l {
    padding-right: 105px
  }

  .landing .section.section_faq-main .title-block {
    margin-top: 92px
  }

  .landing .section.section_main.section_faq-main {
    padding-top: 0
  }

  .landing .header__nav a.active:before {
    bottom: -24px;
    height: 2px
  }

  .landing .accordion-cont__title {
    width: calc(100% - 82px);
    font-size: 16px;
    padding: 0 58px
  }

  .landing .accordion-plus {
    width: 18px;
    height: 18px
  }

  .landing .accordion-cont__plus {
    width: 82px;
    padding-top: 0
  }

  .landing .accordion__panel {
    padding: 0 58px 32px
  }

  .landing .askform-cont__part.askform-cont__part_r {
    width: 545px
  }

  .landing .askform-cont__part.askform-cont__part_l {
    width: calc(100% - 545px)
  }

  .landing .askform-panel {
    padding: 32px 42px
  }

  .landing .cost-info__title {
    font-size: 18px
  }

  .landing .cost-card-lable {
    top: -12px;
    right: -84px;
    width: 144px
  }

  .landing .cost-info__title {
    font-size: 16px
  }

  .landing .cost-info-logo {
    max-width: 140px
  }

  .landing .cost-lable span {
    font-size: 12px
  }

  .landing .cost-card-title__lesson {
    font-size: 33px
  }

  .landing .cost-card-title__num {
    font-size: 37px
  }

  .landing .cost-card__text p {
    font-size: 14px
  }

  .landing .cost-card {
    padding: 32px 38px
  }

  .landing .cost-card-val span {
    font-size: 33px
  }

  .landing .cost-card-val img {
    position: relative;
    top: 0;
    width: 22px;
    height: 22px
  }

  .landing .cost-card__text {
    min-height: 210px;
    margin-bottom: 28px
  }

  .landing .cost-card-lable {
    top: -6px;
    right: -76px;
    width: 112px
  }

  .landing .cost-card__title {
    margin-bottom: 24px
  }

  .landing .section.section_main.section_contacts {
    padding: 82px 0 82px
  }

  .landing .section.section_main.section_contacts .grid__main-l.grid__main-l_short {
    padding: 0
  }

  .landing .contacts__link a {
    font-size: 30px
  }

  .landing .contacts__link-dsrcp span {
    font-size: 12px
  }

  .landing a.contacts-socs__item {
    margin-right: 18px
  }

  .landing .contacts__item {
    padding-right: 28px;
    margin-right: 28px
  }

  .landing .contacts {
    margin-top: 58px
  }

  .landing .circle-pic.circle-pic_faq-1 {
    left: -16%;
    width: 170px;
    height: 330px
  }

  .landing .circle-pic.circle-pic_faq-2 {
    width: 140px;
    height: 140px;
    top: 7%;
    left: -2%
  }

  .landing .text-pic_faq.text-pic_faq-1 {
    top: 296px;
    left: -102px
  }

  .landing .section.section_askform {
    padding: 0 0 0
  }

  .landing .section.section_centered .circle-pit .mask-pic {
    width: 122px;
    height: 122px
  }

  .landing .centered-slider-item__text p {
    font-size: 20px
  }

  .landing .centered-slider-item {
    width: 502px
  }

  .landing .centered-slider-item__title {
    margin-bottom: 32px
  }

  .landing .mask-pic.mask-pic_aboutpage-8 {
    right: 8%
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-2 {
    right: -112px
  }

  .landing .text-pic_aboutpage {
    top: 26px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-5 {
    right: -136px
  }

  .landing .mask-pic.mask-pic_about-1 {
    width: 266px;
    height: 266px;
    right: 22%
  }

  .landing .circle-pic_about-1,
  .landing .circle-pic_about-2 {
    width: 138px;
    height: 266px
  }

  .landing .circle-pic_about-2 {
    left: 4%
  }

  .landing .circle-pic_about-1 {
    left: -23%
  }

  .landing .mouse-block {
    margin-top: 62px
  }

  .landing .section.section_main.section_about-main {
    padding-top: 82px
  }

  .landing .grid__main-l.grid__main-l_short {
    padding-right: 150px
  }

  .landing .mask-pic_proc-2 {
    left: -18%
  }

  .landing .proc-tablet-main {
    width: 298px;
    left: 5%
  }

  .landing .wide-right-block_proc .circle-pic {
    width: 62px;
    height: 120px
  }

  .landing .circle-pic.circle-pic_proc-3 {
    top: 56%;
    left: -10%
  }

  .landing .mask-pic.mask-pic_proc-2,
  .landing .mask-pic.mask-pic_proc-3 {
    top: 29%
  }

  .landing .mask-pic.mask-pic_proc-4 {
    left: 37%;
    top: 54%
  }

  .landing .mask-pic.mask-pic_proc-1 {
    left: 34%
  }

  .landing .circle-pic.circle-pic_proc-4 {
    left: 58%
  }

  .landing .text-pic_proc.text-pic_proc-3 {
    left: -76px
  }

  .landing .text-pic_proc.text-pic_proc-2 {
    right: -114px
  }

  .landing .text-pic_proc.text-pic_proc-1 {
    left: -83px
  }

  .landing .section_main.section_main-process {
    padding-bottom: 62px
  }

  .landing .function-info__text p {
    font-size: 16px
  }

  .landing .function-info__title {
    margin-bottom: 18px
  }

  .landing .function__info {
    width: 60%
  }

  .landing .function__info + .function__img {
    width: calc(40% + 10%);
    margin-left: -10%
  }

  .landing .function__img.function__img_square {
    min-height: 507px;
    top: 44px;
    left: 92px
  }

  .landing .function-bg__item.function-bg__item_funct-square {
    width: 116px;
    height: 116px
  }

  .landing .function-bg__item.function-bg__item_funct-square-2,
  .landing .function-bg__item.function-bg__item_funct-square-5,
  .landing .function-bg__item.function-bg__item_funct-square-8 {
    left: 128px
  }

  .landing .function-bg__item.function-bg__item_funct-square-3,
  .landing .function-bg__item.function-bg__item_funct-square-6,
  .landing .function-bg__item.function-bg__item_funct-square-9 {
    left: 256px
  }

  .landing .function-bg__item.function-bg__item_funct-square-4,
  .landing .function-bg__item.function-bg__item_funct-square-5,
  .landing .function-bg__item.function-bg__item_funct-square-6 {
    top: 132px
  }

  .landing .function-bg__item.function-bg__item_funct-square-7,
  .landing .function-bg__item.function-bg__item_funct-square-8,
  .landing .function-bg__item.function-bg__item_funct-square-9 {
    top: 268px
  }

  .landing .function-bg__item.function-bg__item_funct-3 {
    width: 460px;
    height: 460px;
    top: 0;
    left: -8%
  }

  .landing .function-bg__item.function-bg__item_funct-4 {
    width: 104px;
    height: 198px;
    left: -6%;
    top: 12%
  }

  .landing .function-bg__item.function-bg__item_funct-1 {
    width: 84px;
    height: 84px;
    top: 122px;
    right: -53px
  }

  .landing .function-bg__item.function-bg__item_funct-2 {
    width: 238px;
    height: 122px;
    top: 206px;
    right: -114px
  }

  .landing .filters-input {
    width: 346px
  }

  .landing .filters-item__title {
    margin-right: 18px
  }

  .landing .teacher-main-tag span {
    font-size: 12px
  }

  .landing .teacher-main-article__title {
    font-size: 16px
  }

  .landing .teacher-main-article__text p {
    font-size: 14px
  }

  .landing .teacher-vis__img {
    width: 94px;
    height: 94px
  }

  .landing .teacher__vis {
    padding: 26px 26px;
    width: 35%
  }

  .landing .teacher-main-part {
    padding: 0 24px;
    padding-top: 18px
  }

  .landing .teacher-main-part.teacher-main-part_l {
    width: 55%
  }

  .landing .teacher-main-part.teacher-main-part_r {
    width: 45%
  }

  .landing .teacher-main-tag {
    padding: 8px 8px;
    margin-right: 4px;
    margin-bottom: 4px
  }

  .landing .teacher-vis-info__place-city {
    font-size: 12px
  }

  .landing .teacher-vis-gmt__text {
    font-size: 12px
  }

  .landing .teacher-vis-gmt__ico {
    width: 12px;
    height: 12px;
    margin-right: 4px
  }

  .landing .teacher-vis-gmt__ico img {
    position: relative;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    top: -3px
  }

  .landing .teacher-vis-info__age {
    font-size: 12px
  }

  .landing .teacher__main {
    width: 55%
  }

  .landing .doubleforms-item__title span {
    font-size: 25px
  }

  .landing .doubleforms-item {
    padding: 46px 72px
  }

  .landing .doubleforms .btn_main span {
    font-size: 13px
  }

  .landing .doubleforms .btn_main {
    padding: 14px 42px
  }

  .landing .doubleforms-item-field input {
    font-size: 12px
  }

  .landing .doubleforms-item__text span {
    font-size: 13px
  }

  .landing .doubleforms-or {
    font-size: 20px;
    width: 78px;
    height: 78px
  }

  .landing .circle-pic.circle-pic_carriers-1,
  .landing .circle-pic.circle-pic_carriers-2 {
    width: 154px;
    height: 298px
  }

  .landing .circle-pic_carriers.circle-pic_carriers-2 {
    right: -22px;
    top: 106px
  }

  .landing .circle-pic_carriers.circle-pic_carriers-1 {
    top: 42px;
    right: 12px
  }

  .landing .section.section_blue.section_couch .title-block__row-animated span {
    font-size: 44px
  }

  .landing .section.section_couch {
    min-height: 472px;
    padding: 104px 0 16px
  }

  .landing .videocircle {
    width: 378px;
    height: 378px
  }

  .landing .mask-pic.mask-pic_teachersmany-1 {
    display: none
  }

  .landing .circle-pic.circle-pic_teachersmany-2 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-4 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-7 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-10 {
    display: none
  }

  .landing .circle-pic.circle-pic_teachersmany-5 {
    display: none
  }

  .landing .section.section_teaches {
    padding: 122px 0 158px
  }

  .landing .circle-pic.circle-pic_teachersmany-1 {
    left: 0
  }

  .landing .mask-pic.mask-pic_teachersmany-2 {
    left: 9%
  }

  .landing .mask-pic.mask-pic_teachersmany-3 {
    left: -2%;
    top: 33%
  }

  .landing .mask-pic.mask-pic_teachersmany-5 {
    left: 9%;
    top: 65%
  }

  .landing .circle-pic.circle-pic_teachersmany-3 {
    left: -6px;
    top: 65%
  }

  .landing .circle-pic.circle-pic_teachersmany-4 {
    right: -7px
  }

  .landing .mask-pic.mask-pic_teachersmany-6 {
    right: 10%
  }

  .landing .mask-pic.mask-pic_teachersmany-8 {
    right: -22px
  }

  .landing .circle-pic.circle-pic_teachersmany-6 {
    right: -2px;
    top: 66%
  }

  .landing .mask-pic.mask-pic_teachersmany-9 {
    right: 11%
  }

  .landing .circle-pic.circle-pic_teach-1 {
    display: none
  }

  .landing .mask-pic.mask-pic_teach-3 {
    display: none
  }

  .landing .mask-pic.mask-pic_teach-7 {
    display: none
  }

  .landing .mask-pic.mask-pic_teach-1 {
    top: 10%;
    left: 9%
  }

  .landing .mask-pic.mask-pic_teach-2 {
    top: 35%;
    left: -3%
  }

  .landing .mask-pic.mask-pic_teach-4 {
    top: 73%;
    left: 11%
  }

  .landing .circle-pic.circle-pic_teach-2 {
    top: 37%;
    left: 14%;
    width: 42px
  }

  .landing .mask-pic.mask-pic_teach-5 {
    top: 7%;
    right: -3%
  }

  .landing .mask-pic.mask-pic_teach-6 {
    top: 31%;
    right: 10%
  }

  .landing .circle-pic.circle-pic_teach-4 {
    top: 41%;
    right: 1%
  }

  .landing .mask-pic.mask-pic_teach-8 {
    top: 74%;
    right: -2%
  }

  .landing .filters__item {
    margin-right: 18px
  }

  .landing .custom-select-trigger {
    padding-left: 18px
  }

  .landing .custom-option {
    padding: 16px 4px
  }

  .landing .cookie {
    bottom: 12px;
    right: 12px
  }

  .landing .pp-container {
    width: 768px;
    height: 462px
  }

  .landing .teacher-video.pp_ {
    height: 106px
  }

  .landing .choice-img-item {
    width: 180px;
    height: 180px
  }

  .landing .choice-img-item__ico .teacher-video-ico:after {
    border: 12px solid transparent;
    border-left: 22px solid #fff;
    left: 16px;
    top: 4px
  }

  .landing .choice-img {
    margin-bottom: 82px
  }

  .landing .choice-img-item__label {
    top: 152px;
    width: 136px
  }

  .landing section.container_padding-b {
    padding-bottom: 106px;
  }

  .landing .section.section_people {
    padding: 106px 0 88px
  }

  .landing .text-block p.text-big {
    font-size: 18px
  }

  .landing .func-step-item__title {
    width: 100px;
    font-size: 15px;
    padding-left: 24px
  }

  .landing .func-step-item__text {
    font-size: 12px;
    width: calc(100% - 100px)
  }

  .landing .courses-bg-block__text {
    width: 60%
  }

  .landing .courses-bg-block__text .buttons-lable {
    right: 248px
  }

  .landing .courses-bg-block__img {
    width: 335px
  }

  .landing .section_courses-main .title-block__row-animated span {
    font-size: 44px
  }

  .landing .circle-pic_courses-1,
  .landing .circle-pic_courses-2,
  .landing .circle-pic_courses-3,
  .landing .mask-pic_courses-1,
  .landing .mask-pic_courses-2,
  .landing .mask-pic_courses-3,
  .landing .mask-pic_courses-4,
  .landing .mask-pic_courses-5 {
    width: 150px;
    height: 150px
  }

  .landing
  .landing .function-img-rad {
    width: 370px;
    height: 370px
  }

  .landing .section_funtions-courses .function__info {
    padding-right: 78px
  }

  .landing .section_funtions-courses .function__img + .function__info {
    padding-right: 0
  }

  .landing .title-lable img {
    width: 170px
  }

  .landing span.color-grey {
    color: #bac2c6
  }

  .landing .courses-tarif__img img {
    width: 280px;
    height: 280px
  }

  .landing .courses-tarif__title span {
    font-size: 32px
  }

  .landing .courses-tarif__text p {
    font-size: 16px
  }

  .landing .title-lable {
    margin-bottom: 19px
  }

  .landing .big-circle-right.circle-pic_courses-big-1 > .circle-pic-cont {
    transform: scale(0.6109) !important;
  }

  .landing .big-circle-right {
    right: 15%;
    left: initial !important;
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    left: 11%
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    height: 270px;
    width: 270px
  }

  .landing .coursesinfo-item__title span {
    font-size: 24px
  }

  .landing .coursesinfo-item__content li {
    font-size: 16px;
    margin-bottom: 16px
  }

  .landing .slidercost__title span {
    font-size: 26px
  }

  .landing .slidercost-checks__title {
    font-size: 12px
  }

  .landing .slidercost-checks__cost span {
    font-size: 18px
  }

  .landing .slidercost-checks__cost img {
    height: 13px
  }

  .landing .slidercost-checks__lesson-price img {
    height: 9px;
  }

  .landing .slidercost-checks {
    padding: 16px 0
  }

  .landing .slidercost__text {
    margin-bottom: 18px
  }

  .landing .slidercost__title {
    margin-bottom: 18px
  }

  .landing .slider-cost-dcrp {
    padding-left: 14px
  }

  .landing .swiper-container.swiper-container_slidercost .swiper-slide {
    width: 306px
  }

  .landing .slidercost-checks__title {
    width: 72px
  }

  .landing .slidercost-checks__label {
    width: 67px;
    top: 14px;
    right: -13px
  }

  .landing .article-block__title {
    font-size: 22px;
    margin-bottom: 98px
  }

  .landing .section.section_articles {
    padding-top: 62px
  }

  .landing .article-list {
    grid-template-columns: 1fr 1fr 1fr
  }

  .landing .section.section_article {
    padding-top: 42px
  }

  .landing .header-float__nav span {
    font-size: 12px
  }

  .landing .footer-menu__item a {
    font-size: 14px
  }

  .landing .footer-menu__item {
    margin-bottom: 24px
  }

  .landing .footer-contacts-linkdscrp {
    font-size: 12px
  }

  .landing .footer-cont__col.footer-cont__col_2 {
    width: 48%
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 27%
  }

  .landing .courses-tarif {
    padding: 42px 42px
  }

  .landing .section.section_main.section_courses-main {
    padding-top: 62px
  }

  .landing .section.section_main.section_courses-main .buttons-lable {
    right: -78px
  }

  .landing .article-content {
    padding: 42px 132px
  }

  .landing .article-top {
    padding: 48px 132px
  }

  .landing .article-content-box__block iframe {
    height: 328px
  }

  .landing .slidercost__button .btn {
    padding: 16px 22px
  }

  .landing .swiper-navigation_title {
    top: -68px
  }

  .landing .choice-cont {
    grid-gap: 22px;
    margin-left: 0
  }

  .landing .choice-cont__part {
    padding: 38px 22px
  }

  .landing .slidercost__title span:after {
    bottom: 4px;
    right: -16px
  }

  .landing .footer-pay {
    max-height: 24px;
    margin-right: 32px
  }

  .landing .circle-pic_courses-1,
  .landing .circle-pic_courses-2,
  .landing .circle-pic_courses-3,
  .landing .mask-pic_courses-1,
  .landing .mask-pic_courses-2,
  .landing .mask-pic_courses-3,
  .landing .mask-pic_courses-4,
  .landing .mask-pic_courses-5 {
    width: 130px;
    height: 130px
  }

  .landing .section.section_teachers-page {
    padding: 58px 0 58px
  }

  .landing .circle-pic.circle-pic_courses-big-1 {
    left: 51%
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    left: 20%
  }

  /*.landing .popcards {*/
  /*  height: 2300px*/
  /*}*/
  .landing .section.section_article {
    padding: 42px 0
  }

  .landing .choice-img-item__float {
    left: -62px;
    top: 116px
  }
}

@media only screen and (max-width: 1024px) {
  .landing .wrapper {
    margin-bottom: 0;
  }

  .landing .footer-wrap {
    position: relative
  }

  .landing .mask-pic-cont {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    -webkit-clip-path: circle(100% at 50% 50%);
    clip-path: circle(100% at 50% 50%)
  }

  .landing .no-content__img {
    width: 328px
  }

  .landing .no-content__text {
    font-size: 26px
  }

  .landing .page404__img {
    width: 518px
  }

  .landing .btn:after {
    display: none !important
  }

  .landing .title-block.title-block_main {
    width: 90%;
  }
}

@media only screen and (max-width: 1023px) {
  .premium-badge_mobile > span {
    font-size: 18px !important;
  }

  .premium-badge_mobile > img {
    height: 15px !important;
    width: 15px !important;
  }

  .header__show-mobile {
    display: block;
  }

  .main__steps-item > p {
    font-size: 13px !important;
    line-height: 120% !important;
  }

  .landing .mask-pic_courses-2 {
    left: 68%
  }

  .landing .slider-cost-block .swiper-button-prev {
    right: 68px;
  }

  .landing .slider-cost-block .swiper-button-next,
  .landing .slider-cost-block .swiper-button-prev {
    width: 40px;
    height: 40px;
  }

  .main__steps {
    padding-left: 40px;
  }

  .landing .title-block.title-block_main {
    width: 100%;
  }

  .landing .circle-pit__elem_main-2 {
    width: 80px
  }

  .landing .section {
    padding: 62px 0 62px
  }

  .landing .section.section_main {
    padding-top: 72px
  }

  .landing .header__nav {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 53px;
    padding: 22px 0;
    background: #fff;
    -webkit-box-shadow: 0 40px 52px 0 rgba(0, 0, 0, .13);
    box-shadow: 0 40px 52px 0 rgba(0, 0, 0, .13);
    -webkit-transform: translateX(200%);
    -ms-transform: translateX(200%);
    transform: translateX(200%);
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
  }

  .landing .header__nav a {
    margin: 0;
    display: block;
    padding: 12px 38px
  }

  .landing .header__nav .header-float__nav,
  .landing .header__nav a span {
    font-size: 24px
  }

  .landing .header__nav.show {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
    pointer-events: auto
  }

  .landing .header__lang {
    margin-right: 12px;
    margin-left: auto
  }

  .landing .title-block__row-animated.title-block__row-animated_main span {
    font-size: 42px
  }

  .landing .mask-pic_main-1 {
    height: 158px;
    width: 158px;
    left: 5%
  }

  .landing .mask-pic_main-2 {
    display: none
  }

  .landing .mask-pic_main-3 {
    top: 188px;
    left: 24%;
    width: 214px;
    height: 214px
  }

  .landing .circle-pic_main-1 {
    left: 49%;
    width: 80px;
    height: 158px
  }

  .landing .circle-pic_main-2 {
    width: 110px;
    height: 214px;
    top: 188px;
    left: -7%
  }

  .landing .circle-pic_main-3 {
    display: none
  }

  .landing .row-info-text {
    padding: 5px 11px;
    border-radius: 4px
  }

  .landing .row-info {
    margin-bottom: -6px
  }

  .landing .btn_main span {
    font-size: 12px
  }

  .landing .btn {
    padding: 14px 28px
  }

  .landing .title-block {
    margin-bottom: 24px
  }

  .landing .buttons-block {
    margin-top: 38px
  }

  .landing .grid {
    width: 92%
  }

  .landing h3 {
    font-size: 32px
  }

  .landing .text-block p,
  .landing .textonly-block ul li,
  .landing p {
    font-size: 14px;
    line-height: 1.7
  }

  .landing .textonly-block ul {
    padding-left: 20px;
  }

  .landing .regist {
    padding: 36px 42px 36px
  }

  .landing .regist-img__scrreen {
    width: 392px;
    margin-top: -66px;
    margin-left: -84px;
    margin-bottom: -50px
  }

  .landing .mouse-block {
    margin-top: 54px
  }

  .landing .header {
    padding: 10px 32px
  }

  .landing .sections {
    padding-top: 60px
  }

  .landing .section.section_main.section_main-page {
    padding: 52px 0 38px;
  }

  .landing .section.section_main.section_main-page .grid__main-r {
    height: 400px;
  }

  .landing .title-block__row-animated span {
    font-size: 32px;
    font-weight: 600
  }

  .landing .section.section_main .text-block p {
    font-size: 14px
  }

  .landing img.half-img-big {
    position: relative;
    top: -22px;
    width: 123%;
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content
  }

  .landing .section-untop-first {
    overflow-x: hidden;
    padding-bottom: 0;
    margin-bottom: -42px;
    padding-top: 72px
  }

  .landing .circle-pit__elem_main-1 {
    top: -30px;
    right: -420px
  }

  .landing .circle-pit__elem_main-3 {
    top: 55%;
    right: 29%
  }

  .landing .sticky-container {
    padding-top: 154px
  }

  .landing .title-block__row-animated .title-big {
    font-size: 40px
  }

  .landing .text-block p.text-big {
    font-size: 18px
  }

  .landing .title-block.title-block_centered {
    margin-bottom: 8px
  }

  .landing .swiper-container.swiper-container_people {
    margin-top: 48px
  }

  .landing section.container_padding-b {
    padding-bottom: 102px;
  }

  .landing .section.section_people {
    padding: 102px 0 96px
  }

  .landing .popcard {
    padding: 42px 62px 38px;
    border-radius: 12px
  }

  .landing .popcard__img {
    width: 114px
  }

  .landing .popcard__info {
    width: calc(100% - 114px);
    padding-right: 24px
  }

  .landing .popcard-info__title {
    font-size: 27px
  }

  .landing .popcards {
    width: 100%
  }

  .landing .btn.btn_capitalize span {
    font-size: 12px
  }

  .landing .btn.btn_main {
    padding: 14px 32px
  }

  .landing .popcard-info__button .btn span {
    font-size: 12px
  }

  .landing .popcard__nums {
    font-size: 19px;
    right: -12px
  }

  .landing .popcards__item {
    margin-top: 92px;
    margin-bottom: 300px
  }

  .landing .function-bg__item_main-1 {
    top: 82px;
    left: -64px;
    width: 72px;
    height: 72px
  }

  .landing .function-bg__item_main-2 {
    top: 154px;
    left: -56px;
    width: 149px;
    height: 71px
  }

  .landing .function-bg__item_main-4 {
    display: none
  }

  .landing .function-bg__item_main-3 {
    top: 36px;
    right: -58px;
    width: 108px;
    height: 208px
  }

  .landing .function__info + .function__img {
    margin-right: 0
  }

  .landing .card-img-text__txt span {
    font-size: 14px
  }

  .landing .sticky-slider-slide {
    width: 430px
  }

  .landing .card-img-text__img {
    width: 128px;
    height: 128px
  }

  .landing .card-img-text__txt {
    max-width: 230px;
    padding-left: 30px
  }

  .landing .card-img-text {
    padding: 24px 24px;
    border-radius: 12px
  }

  .landing .section.section_main.section_main-page .text-block.text-block_main {
    width: 70%
  }

  .landing .function__info {
    padding-top: 18px;
    padding-right: 18px
  }

  .landing .function-info__title {
    margin-bottom: 22px
  }

  .landing .buttons-block {
    margin-top: 32px
  }

  .landing .section.section_reviews .title-block {
    margin-bottom: 42px
  }

  .landing .section.section_reviews {
    padding: 52px 0 52px
  }

  .landing .review-card-user__img {
    width: 35px;
    height: 35px
  }

  .landing .review-card__user {
    padding-top: 10px
  }

  .landing .rewvid-img-item {
    margin-bottom: 15px
  }

  .landing .review-card-user__dscrp {
    padding-top: 10px
  }

  .landing .review-card {
    padding: 12px 12px 20px 12px
  }

  .landing .review-card-user__info {
    width: calc(100% - 35px);
    padding-left: 15px
  }

  .landing .review-card__text {
    padding-bottom: 36px
  }

  .landing .review-card-user__name {
    font-size: 12px;
    margin-bottom: 0
  }

  .landing .review-card-user__dscrp {
    font-size: 11px
  }

  .landing .rewvid-img-item {
    height: 142px
  }

  .landing .rewvid-img-item__ico .teacher-video-ico {
    width: 50px;
    height: 50px
  }

  .landing h1,
  .landing h2,
  .landing h3,
  .landing h4,
  .landing h5,
  .landing h6 {
    line-height: 1.1
  }

  .landing .footer-top .footer-cont__col.footer-cont__col_1 {
    position: absolute;
    top: 0
  }

  .landing .footer-top .footer-cont {
    padding-top: 76px
  }

  .landing .footer-top {
    padding: 32px 0 22px
  }

  .landing .footer-cont__col.footer-cont__col_2 {
    width: 75%
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 25%
  }

  .landing .footer-title {
    margin-bottom: 22px;
    margin-top: 0
  }

  .landing .footer-contacts-linkdscrp {
    font-size: 12px
  }

  .landing .footer-contacts-link {
    margin-bottom: 4px
  }

  .landing .footer-bot .footer-cont__col.footer-cont__col_1 {
    width: auto;
    display: inline-block;
    margin-right: 32px
  }

  .landing .footer-bot .footer-cont__col.footer-cont__col_2 {
    width: auto;
    display: inline-block
  }

  .landing .footer-bot .footer-cont {
    display: block
  }

  .landing .footer-bot .footer-cont__col.footer-cont__col_3 {
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
  }

  .landing .slider-person__img {
    width: 152px;
    height: 152px
  }

  .landing .slider-semicircle {
    width: 82px;
    height: 152px
  }

  .landing .section.section_regist {
    padding: 68px 0 68px
  }

  .landing .mobmenu-btn {
    position: relative;
    width: 26px;
    height: 18px;
    margin-left: 18px
  }

  .landing .burger {
    display: block;
    position: absolute;
    background: #010102;
    width: 100%;
    height: 2px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    opacity: 1
  }

  .small-circle.wide-right-block {
    transform: scale(0.7);
    transform-origin: left;
    min-width: 580px;
  }

  .landing .burger::after,
  .landing .burger::before {
    content: "";
    display: block;
    position: absolute;
    background: #010102;
    width: 100%;
    height: 2px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
  }

  .landing .burger::before {
    top: 5px
  }

  .landing .burger::after {
    bottom: 5px
  }

  .landing .burger,
  .landing .burger::after,
  .landing .burger::before {
    transition: all .5s cubic-bezier(.19, 1, .22, 1);
    -webkit-transition: all .5s cubic-bezier(.19, 1, .22, 1);
    -moz-transition: all .5s cubic-bezier(.19, 1, .22, 1);
    -o-transition: all .5s cubic-bezier(.19, 1, .22, 1);
    -ms-transition: all .5s cubic-bezier(.19, 1, .22, 1)
  }

  .landing .mobmenu-btn_burger.active .burger::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: 0
  }

  .landing .mobmenu-btn_burger.active .burger::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0
  }

  .landing .mobmenu-btn_burger.active .burger {
    background: rgba(29, 25, 22, 0)
  }

  .landing .section.section_main.section_main-page .text-block p {
    font-size: 16px
  }

  .landing .tablet-v-show {
    display: block
  }

  .landing .askform .title-block__row-animated span {
    font-size: 30px
  }

  .landing .askform-cont {
    display: block
  }

  .landing .askform-cont__part.askform-cont__part_l {
    width: 100%;
    padding: 0;
    text-align: center;
    margin-bottom: 32px
  }

  .landing .askform-cont__part.askform-cont__part_r {
    width: 100%
  }

  .landing .askform .title-block__row-animated {
    display: inline-block
  }

  .landing .askform .title-block {
    margin-bottom: 18px
  }

  .landing .askform-panel {
    padding: 32px 132px
  }

  .landing .circle-pic.circle-pic_faq-1 {
    top: 30%;
    left: -6%;
    width: 96px;
    height: 255px
  }

  .landing .circle-pic.circle-pic_faq-2 {
    top: 17%;
    left: 3%;
    width: 108px;
    height: 108px
  }

  .landing .section.section_faq-main .title-block {
    margin-top: 72px
  }

  .landing .faq-pic-main {
    width: 250px;
    height: 100%
  }

  .landing .faq-pic-main > img {
    position: absolute;
    bottom: -50px;
    height: auto
  }

  .landing .accordion {
    margin-bottom: 12px
  }

  .landing .section.section_main.section_faq-main {
    padding-bottom: 114px
  }

  .landing .section.section_faq {
    margin-top: -70px
  }

  .landing .circle-pic.circle-pic_faq-3 {
    width: 60px;
    height: 60px;
    top: 42%;
    right: 28%
  }

  .landing .grid__main-l_short {
    padding-right: 116px
  }

  .landing h1 {
    font-size: 40px
  }

  .landing .mask-pic.mask-pic_about-1 {
    width: 192px;
    height: 192px;
    right: 22%
  }

  .landing .circle-pic.circle-pic_about-1,
  .landing .circle-pic.circle-pic_about-2,
  .landing .mask-pic.mask-pic_about-1 {
    top: 66px
  }

  .landing .circle-pic_about-1,
  .landing .circle-pic_about-2 {
    width: 100px;
    height: 192px
  }

  .landing .mask-pic.mask-pic_aboutpage-1,
  .landing .mask-pic.mask-pic_aboutpage-10,
  .landing .mask-pic.mask-pic_aboutpage-5,
  .landing .mask-pic.mask-pic_aboutpage-9 {
    display: none
  }

  .landing .mask-pic.mask-pic_aboutpage-2 {
    top: 13%;
    left: 7%
  }

  .landing .centered-slider-wrap {
    padding: 136px 0
  }

  .landing .section.section_centered .circle-pit .mask-pic {
    width: 98px;
    height: 98px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-2 {
    right: -110px;
    top: 120px
  }

  .landing .text-pic_aboutpage.text-pic_aboutpage-7 {
    top: 22px;
    left: -144px
  }

  .landing .mask-pic.mask-pic_aboutpage-3 {
    top: 44%;
    left: -4%
  }

  .landing .mask-pic.mask-pic_aboutpage-4 {
    top: 75%;
    left: 9%
  }

  .landing .mask-pic.mask-pic_aboutpage-6 {
    top: 11%;
    right: 8%
  }

  .landing .mask-pic.mask-pic_aboutpage-7 {
    top: 78%;
    right: 3%
  }

  .landing .mask-pic.mask-pic_aboutpage-8 {
    top: 49%;
    right: -1%
  }

  .landing .contacts__item:nth-child(3) {
    width: 100%;
    margin-top: 46px
  }

  .landing .contacts {
    display: block;
    margin-top: 52px
  }

  .landing .contacts__item:nth-child(1),
  .landing .contacts__item:nth-child(2) {
    width: 47%;
    display: inline-block
  }

  .landing .contacts__item {
    margin: 0;
    padding: 0;
    border: 0
  }

  .landing .contacts__item_block {
    display: block;
  }

  .landing .contacts__link {
    margin-bottom: 4px
  }

  .landing
  .landing .cost-card {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
    position: relative;
    width: 100%;
    padding: 38px 44px;
    margin-right: 0;
    padding-right: 18px;
    margin-bottom: 12px
  }

  .landing .cost-cards-wrap {
    display: block
  }

  .landing .cost-card__title {
    width: 196px;
    margin-bottom: 0;
    padding-right: 28px
  }

  .landing .cost-card__text {
    width: calc(100% - 196px - 202px);
    margin-bottom: 0;
    padding-right: 32px;
    min-height: auto
  }

  .landing .cost-card__button {
    position: absolute;
    right: 100px;
    top: 94px
  }

  .landing .cost-card-title__num {
    font-size: 30px;
    margin-right: 4px
  }

  .landing .cost-card-title__lesson {
    font-size: 27px
  }

  .landing .cost-card__text p {
    font-size: 13px
  }

  .landing .cost-card-val span {
    font-size: 27px
  }

  .landing .cost-card-val img {
    top: 0;
    width: 19px;
    height: 18px;
    margin-left: -6px
  }

  .landing .cost-card-slash {
    width: 16px;
    margin-left: 2px;
    margin-right: 5px
  }

  .landing .cost-card-lable {
    top: 42px;
    right: auto;
    left: 0;
    width: 156px
  }

  .landing .cost-card__vals {
    margin-bottom: 0
  }

  .landing .cost-info__title {
    font-size: 18px;
    margin-right: 17px
  }

  .landing .cost-lable span {
    font-size: 11px
  }

  .landing .cost-lable {
    padding: 9px 14px
  }

  .landing .cost-lable img {
    margin-right: 8px
  }

  .landing .cost-info-logo {
    max-width: 114px;
    margin-right: 18px
  }

  .landing .section.section_main-cost {
    padding-bottom: 22px
  }

  .landing .grid__main-l.grid__main-l_short {
    padding-right: 64px
  }

  .landing .wide-right-block_proc .mask-pic {
    width: 88px;
    height: 88px
  }

  .landing .proc-tablet-main {
    width: 222px;
    left: 15%
  }

  .landing .mask-pic.mask-pic_proc-1 {
    left: 52%
  }

  .landing .circle-pic.circle-pic_proc-3 {
    top: 47%;
    left: 3%
  }

  .landing .wide-right-block_proc .circle-pic {
    width: 46px;
    height: 88px
  }

  .landing .circle-pic.circle-pic_proc-2 {
    display: none
  }

  .landing .circle-pic.circle-pic_proc-1 {
    left: 34%
  }

  .landing .circle-pic_proc-1,
  .landing .circle-pic_proc-2,
  .landing .mask-pic_proc-1 {
    top: 2%
  }

  .landing .mask-pic.mask-pic_proc-4 {
    left: 52%;
    top: 48%
  }

  .landing .mask-pic.mask-pic_proc-3 {
    display: none
  }

  .landing .circle-pic.circle-pic_proc-4 {
    display: none
  }

  .landing .mask-pic_proc-2 {
    left: -4%;
    top: 21% !important
  }

  .landing .function-bg__item.function-bg__item_funct-square {
    width: 108px;
    height: 108px
  }

  .landing .function-bg__item.function-bg__item_funct-square-2,
  .landing .function-bg__item.function-bg__item_funct-square-5,
  .landing .function-bg__item.function-bg__item_funct-square-8 {
    left: 112px
  }

  .landing .function-bg__item.function-bg__item_funct-square-3,
  .landing .function-bg__item.function-bg__item_funct-square-6,
  .landing .function-bg__item.function-bg__item_funct-square-9 {
    left: 225px
  }

  .landing .function__img.function__img_square {
    padding: 0;
    left: -12px;
    min-height: 362px;
    top: -12px
  }

  .landing .function-bg__item.function-bg__item_funct-3 {
    width: 388px;
    height: 388px;
    left: -15%
  }

  .landing .function-bg__item.function-bg__item_funct-4 {
    width: 68px;
    height: 134px
  }

  .landing .function-info__text p {
    font-size: 14px;
    line-height: 1.8
  }

  .landing .function-bg__item.function-bg__item_funct-square-4,
  .landing .function-bg__item.function-bg__item_funct-square-5,
  .landing .function-bg__item.function-bg__item_funct-square-6 {
    top: 120px
  }

  .landing .function-bg__item.function-bg__item_funct-square-7,
  .landing .function-bg__item.function-bg__item_funct-square-8,
  .landing .function-bg__item.function-bg__item_funct-square-9 {
    top: 240px
  }

  .landing .function-bg__item.function-bg__item_funct-1,
  .landing .function-bg__item.function-bg__item_funct-2,
  .landing .function-bg__item.function-bg__item_funct-5,
  .landing .function-bg__item.function-bg__item_funct-6 {
    display: none
  }

  .landing .filters-mob-button {
    display: inline-block;
    border: 1px solid #d7e0e6;
    padding: 14px 32px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 7.45087px
  }

  .landing .filters-mob-button span {
    font-family: Evolventa;
    font-weight: 700;
    margin-right: 14px
  }

  .landing .filters-mob-button img {
    position: relative;
    top: 1px
  }

  .landing .filters-pp {
    display: block;
    position: fixed;
    left: auto;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #fff;
    width: 375px;
    z-index: 99;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    pointer-events: all;
    visibility: visible;
    overflow-y: auto;
    overflow-x: hidden
  }

  .landing .filters-mobile.show .filters-pp {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    pointer-events: all;
    visibility: visible
  }

  .landing .filters-cont {
    display: block
  }

  .landing .filters .filters-cont {
    display: none
  }

  .landing .filters {
    text-align: center;
    margin-bottom: 18px;
    margin-top: -42px
  }

  .landing .filters__item {
    margin-right: 0;
    padding: 28px 0;
    border-bottom: 2px solid #eff3f7
  }

  .landing .filters__item:nth-last-child(1) {
    border: none
  }

  .landing .filters-pp__title,
  .landing .filters__item {
    padding-left: 38px;
    padding-right: 38px
  }

  .landing .filters-item {
    display: block
  }

  .landing .filters-item__title {
    margin-right: 0;
    margin-bottom: 14px
  }

  .landing .filters-input {
    width: 100%;
    margin-top: 44px
  }

  .landing .filters-input__vals {
    width: calc(100% + 22px)
  }

  .landing .filters-pp-title {
    font-weight: 700;
    font-family: Evolventa;
    font-size: 19px;
    padding: 28px 0
  }

  .landing .filters-pp__title {
    position: relative;
    border-bottom: 2px solid #eff3f7
  }

  .landing .filters-pp-cross {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 22px;
    margin: auto;
    background: url(/landing/svg/filters-pp-cross.svg) no-repeat center;
    background-size: 50%;
    z-index: 2
  }

  .landing .filters-pp .custom-select-trigger {
    display: none
  }

  .landing .filters-pp .custom-options {
    position: initial;
    overflow: initial;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    padding: 0;
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0
  }

  .landing .filters-pp span.custom-option {
    font-size: 13px;
    color: rgba(10, 10, 11, .67);
    font-weight: 300;
    border-bottom: 0;
    padding: 12px 0
  }

  .landing .bg-pp-fiters {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: rgba(0, 0, 0, .3);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    z-index: 98;
    opacity: 0;
    pointer-events: none;
    visibility: hidden
  }

  .landing .filters-mobile.show .bg-pp-fiters {
    opacity: 1;
    pointer-events: all;
    visibility: visible
  }

  .landing .filters-pp span.custom-option.selection {
    color: #000
  }

  .landing .custom-option:hover {
    opacity: 1
  }

  .landing .mask-pic.mask-pic_teach-2 {
    left: -7%
  }

  .landing .circle-pic.circle-pic_teach-2 {
    left: 12%
  }

  .landing .mask-pic.mask-pic_teach-4 {
    left: 7%;
    top: 71%
  }

  .landing .mask-pic.mask-pic_teach-6 {
    right: 8%;
    top: 37%
  }

  .landing .circle-pic.circle-pic_teach-4 {
    top: 39%;
    right: -3%
  }

  .landing .mask-pic.mask-pic_teach-8 {
    top: 71%;
    right: -4%
  }

  .landing .teacher-main-cont {
    display: block;
    border-right: 2px solid #eff3f7
  }

  .landing .teacher-main-part.teacher-main-part_l {
    width: 100%
  }

  .landing .teacher-main-part {
    border-right: 0
  }

  .landing .teacher-vis-info__place {
    margin-bottom: 8px
  }

  .landing .teacher-main-part.teacher-main-part_r {
    width: 100%
  }

  .landing .teacher-vis-info__name {
    font-size: 24px
  }

  .landing .teacher__vis {
    width: 42%
  }

  .landing .teacher__main {
    width: 48%
  }

  .landing .teacher-main-article:nth-last-child(1) {
    margin-bottom: 38px
  }

  .landing .sliding-accordion-element {
    padding-top: 0
  }

  .landing .doubleforms-cont {
    display: block
  }

  .landing .doubleforms__item {
    width: 466px;
    margin: 0 auto;
    margin-bottom: 14px
  }

  .landing .doubleforms-or {
    top: 236px;
    font-size: 18px;
    width: 70px;
    height: 70px;
    padding: 20px 0
  }

  .landing .circle-pic.circle-pic_carriers-1,
  .landing .circle-pic.circle-pic_carriers-2 {
    width: 119px;
    height: 222px
  }

  .landing .section.section_carriers {
    padding: 98px 0 98px
  }

  .landing .circle-pic_carriers.circle-pic_carriers-1 {
    top: 26px;
    right: -12px
  }

  .landing .circle-pic_carriers.circle-pic_carriers-2 {
    right: -40px;
    top: 60px
  }

  .landing .text-block.text-block_short {
    width: 452px
  }

  .landing .section.section_blue.section_couch .title-block__row-animated span {
    font-size: 40px
  }

  .landing .section.section_blue.section_couch {
    min-height: 414px;
    padding: 96px 0 16px
  }

  .landing .videocircle {
    width: 322px;
    height: 322px
  }

  .landing .videocircle__ico {
    width: 70px;
    height: 70px
  }

  .landing .videocircle__ico img {
    width: 16px;
    height: 18px
  }

  .landing .text-block.text-block_teaches {
    width: 364px
  }

  .landing .custom-select-wrapper {
    width: 100%
  }

  .landing .pp-container {
    width: 568px;
    height: 362px
  }

  .landing .teacher-video.pp_ {
    height: 162px
  }

  .landing .teacher-main-part.teacher-main-part_r {
    position: relative
  }

  .landing .teacher-main-part.teacher-main-part_r:before {
    content: '';
    display: block;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 78px;
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(bottom, #fff 0, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(0deg, #fff 0, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    opacity: 1
  }

  .landing .teacher.open .teacher-main-part.teacher-main-part_r:before {
    opacity: 0
  }

  .first-teacher-element:before {
    display: none
  }

  .landing .pp-container.pp-container_thanx {
    width: 562px
  }

  .landing .thanx__img {
    width: 238px;
    height: 142px
  }

  .landing .thanx__info {
    width: calc(100% - 238px)
  }

  .landing .thanx-title span {
    font-size: 28px
  }

  .landing .ui-slider-range:before {
    content: '';
    display: block;
    position: absolute;
    top: -12px;
    bottom: -12px;
    left: -7px;
    right: -7px;
    margin: auto;
    z-index: 0
  }

  .landing .ui-slider-range {
    z-index: 0
  }

  .landing .header-float {
    display: block;
    padding: 12px 38px . landing
  }

  .landing .header-float__nav span {
    font-size: 24px
  }

  .landing .header-float:after {
    left: 154px;
    right: auto;
    bottom: auto;
    top: 26px
  }

  .landing .header-float__items {
    position: relative;
    top: 12px;
    min-width: auto;
    margin-left: -38px;
    border-radius: 0
  }

  .landing .header-float-item {
    text-align: left
  }

  .landing .header-float-item a {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .landing .choice-img-item {
    width: 130px;
    height: 130px
  }

  .landing .choice-text {
    font-size: 12px
  }

  .landing .choice-img-item__label {
    top: 112px;
    width: 112px
  }

  .landing section.container_padding-b {
    padding-bottom: 75px;
  }

  .landing .section.section_people {
    padding: 74px 0 75px
  }

  .landing .func-step {
    width: 100%;
    padding-left: 22px;
    margin-top: -28px
  }

  .landing .func-step-item__title {
    font-size: 12px;
    width: 78px;
    padding-left: 20px
  }

  .landing .func-step-item__text {
    font-size: 10px;
    width: calc(100% - 78px)
  }

  .landing .func-step-item {
    margin-bottom: 10px;
    padding: 8px 0
  }

  .landing .section_courses-main .title-block__row-animated span {
    font-size: 40px
  }

  .landing .section_courses-main .grid__main-l {
    width: 60%
  }

  .landing .section_courses-main .grid__main-r {
    width: 40%
  }

  .landing .section_courses-main .small-circle.grid__main-l {
    width: 50%
  }

  .landing .section_courses-main .small-circle.grid__main-r {
    width: 50%
  }

  .landing .mask-pic_courses-1 {
    left: 27%
  }

  .landing .circle-pic_courses-3 {
    left: 23%
  }

  .landing .circle-pic_courses-2 {
    left: 48.8%;
  }

  .landing .mask-pic_courses-4 {
    left: 68%
  }

  .landing .mask-pic_courses-3 {
    left: 4%
  }

  .landing .circle-pic_courses-3,
  .landing .mask-pic_courses-3,
  .landing .mask-pic_courses-4,
  .landing .mask-pic_courses-5 {
    top: 196px
  }

  .landing .section.section_courses-bg .courses-bg-text p {
    font-size: 14px
  }

  .landing .courses-bg-block__img {
    right: -156px
  }

  .landing .courses-bg-block__text {
    width: 72%
  }

  .landing .courses-bg-block__text .buttons-lable {
    right: 0;
    left: 116px
  }

  .landing .function-img-rad {
    width: 300px;
    height: 300px
  }

  .landing .courses-tarif-wrap {
    display: block;
    padding-bottom: 48px
  }

  .landing .courses-tarif {
    width: 100%
  }

  .landing .courses-tarif {
    margin-bottom: 48px
  }

  .landing .section.section_courses-tarif {
    padding-top: 122px;
    padding-bottom: 0
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    left: 4%
  }

  .landing .circle-pic.circle-pic_courses-big-1 {
    left: 57%
  }

  .landing .coursesinfo {
    display: block
  }

  .landing .coursesinfo-item {
    padding: 32px 42px;
    margin-bottom: 12px
  }

  .landing .article-block__date {
    font-size: 12px;
    margin-bottom: 24px
  }

  .landing .article-block__title {
    font-size: 18px;
    margin-bottom: 78px
  }

  .landing .article-top__title h1 {
    font-size: 28px
  }

  .landing .article-content-socs {
    display: none
  }

  .landing .article-top {
    padding: 32px 58px
  }

  .landing .article-content {
    padding: 32px 58px
  }

  .landing .header-float__items:after {
    display: none
  }

  .landing .section.section_main.section_courses-main .buttons-lable {
    right: -80px;
    left: auto
  }

  .landing .header-float .header-float__items {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition: auto
  }

  .landing .footer-bot .footer-text {
    display: inline-block
  }

  .landing .footer-cont__col.footer-cont__col_2 {
    width: 65%
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 35%
  }

  .landing .header-float .header-float__items {
    width: calc(100% + 95px)
  }

  .landing .article-content {
    padding: 42px 58px
  }

  .landing .article-top {
    padding: 48px 58px
  }

  .landing .choice-cont {
    margin-left: -11px
  }

  .landing .choice-cont {
    margin-left: 0
  }

  .landing .choice-cont__part {
    border-radius: 12px
  }

  .landing .section.section_main.section_faq-main .text-block {
    width: 100%
  }

  .landing .function .btn {
    padding: 14px 14px
  }

  .landing .section_funtions-courses .function__info {
    padding-right: 60px
  }

  .landing .footer-bot .footer-text {
    margin-bottom: 14px
  }

  .landing section.section_main.section_courses-main .text-block p strong {
    font-size: 14px
  }

  .landing .wrapper_dark .header-float__nav span {
    color: #0a0a0b
  }

  .landing .wrapper_dark .header__nav a span {
    color: #0a0a0b
  }

  .landing .wrapper_dark .header__nav a .header-float-item span {
    color: #fff
  }

  .landing .wrapper_dark .burger,
  .landing .wrapper_dark .burger::after,
  .landing .wrapper_dark .burger::before {
    background: #fff
  }

  .landing .wrapper_dark .header__nav .header-float__items a span {
    color: #fff
  }

  .landing .mask-pic_courses-5 {
    left: 43%
  }

  .landing .section-untop-first .btn.btn_black:hover {
    background: #fff
  }

  .landing .choice-cont__part:nth-child(2) a.btn.btn_black.btn_main {
    background: #fff
  }

  .landing .regist-info__text p a {
    display: block
  }

  .landing .popcard-info__button .btn:hover {
    background: #0a0a0b
  }

  .landing .wrapper.wrapper_dark .btn.btn_blue-transp span {
    color: #4987ff
  }

  .landing .choice-cont__part:nth-child(2) .btn.btn_black span {
    color: #0a0a0b
  }

  .landing .text-pic_faq.text-pic_faq-1 {
    top: 196px;
    left: -82px
  }

  .landing .header__nav .element-menu-mob {
    display: block !important
  }

  .landing .choice-img-item__float {
    left: -54px;
    top: 98px
  }

  .landing .mask-pic_main-3 .mask-text-cont {
    left: -88px
  }

  .landing .btn.btn_border-black {
    padding: 12px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .landing .section_courses-main .small-circle.grid__main-r,
  .landing .section_courses-main .small-circle.grid__main-l {
    width: 100%;
  }

  .text-block.text-block_main {
    margin: auto;
  }

  .main__steps-item > p {
    font-size: 13px !important;
    line-height: 120% !important;
  }

  .big-circle-right {
    display: none;
  }

  .small-circle.wide-right-block {
    transform: scale(1);
    min-width: initial;
  }

  .grid_min-height {
    min-height: initial;
  }

  .main__steps {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .landing .btn.btn_enter {
    width: 36px;
    height: 36px;
    background-size: 35%;
    background: #0a0a0b url(/landing/svg/enter-mob.svg) no-repeat center;
  }

  .landing .btn.btn_header.btn_enter {
    padding: 0
  }

  .landing .btn.btn_enter span {
    display: none
  }

  .landing .btn.btn_starts {
    display: none
  }

  .landing .header__nav .header-float__nav,
  .landing .header__nav a span {
    font-size: 19px
  }

  .landing .header__nav a {
    padding: 16px 34px
  }

  .landing .title-block {
    text-align: center
  }

  .landing .title-block__row-animated.title-block__row-animated_main span {
    font-size: 28px
  }

  .landing .section.section_main .text-block p {
    font-size: 14px
  }

  .landing .text-block {
    text-align: center
  }

  .landing .grid.grid_l-r {
    display: block
  }

  .landing .buttons-block .buttons-lable {
    display: none
  }

  .landing .grid__main-l {
    width: 100%
  }

  .landing .grid__main-r {
    width: 100%
  }

  .landing
  .landing .section.section_main.section_main-page .text-block.text-block_main {
    width: 80%;
    min-width: 256px;
    margin: 0 auto
  }

  .landing .buttons-block {
    text-align: center;
    margin-top: 28px
  }

  .landing .btn {
    margin: 0 auto
  }

  .landing .regist-cont {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
  }

  .landing .regist-img__scrreen {
    width: auto;
    margin: 0 0 -26px;
  }

  .landing .regist__img {
    width: 100%
  }

  .landing .regist {
    padding: 0 16px 32px;
    border-radius: 12px;
    background: url(/landing/svg/regist-bg-mob.svg) no-repeat center;
    background-size: cover;
    background-color: #fff
  }

  .landing .regist__info {
    width: 100%;
    text-align: center
  }

  .landing .regist-info__button .btn {
    padding: 14px 0;
    width: 100%
  }

  .landing .regist-info__title h3 {
    font-size: 28px
  }

  .landing .regist-info__text {
    margin-bottom: 32px;
    width: 100%
  }

  .landing .grid {
    width: 92%
  }

  .landing .btn span {
    font-weight: 400
  }

  .landing .review-card__text p {
    font-size: 14px
  }

  .landing .review-card {
    padding: 12px 12px 22px 12px
  }

  .landing .review-card__user {
    padding-top: 12px
  }

  .landing .section.section_reviews .title-block {
    text-align: left;
    margin-bottom: 28px
  }

  .landing .section.section_reviews .title-block__row-animated span {
    font-size: 26px
  }

  .landing .section.section_reviews {
    margin-top: 0;
    padding: 52px 0 62px
  }

  .landing .section.section_funtions {
    padding: 62px 0 62px
  }

  .landing .function-cont {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
  }

  .landing .function__img {
    width: 100%;
    padding: 0
  }

  .landing .function__info {
    width: 100%;
    text-align: center;
    padding: 0;
    margin-bottom: 18px
  }

  .landing .function:nth-child(even) .function-cont {
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
  }

  .landing .function-bg__item_main-1 {
    top: auto;
    bottom: 128px
  }

  .landing .function-bg__item_main-2 {
    top: auto;
    bottom: 70px
  }

  .landing .function-bg__item_main-3 {
    width: 80px;
    height: 152px;
    top: auto;
    bottom: 88px;
    right: -32px
  }

  .landing .function {
    margin-bottom: 41px;
    padding-bottom: 18px
  }

  .landing h3 {
    font-size: 26px
  }

  .landing .function-info__title {
    margin-bottom: 18px
  }

  .landing .popcard-info__title {
    font-size: 26px
  }

  .landing .popcard-info__text p {
    font-size: 14px
  }

  .landing .popcard-cont {
    padding-top: 46px;
    padding-bottom: 66px;
    display: block
  }

  .landing .popcard__nums {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px
  }

  .landing .popcards__item {
    margin-top: 64px;
    margin-bottom: 200px
  }

  .landing .popcard__info {
    width: 100%;
    padding: 0;
    text-align: center
  }

  .landing .popcard__img {
    width: 100%;
    text-align: center
  }

  .landing .popcard__img img {
    width: 172px;
    margin: 0 auto
  }

  .landing .popcard {
    padding: 38px 28px 38px
  }

  .landing .popcards__item:nth-child(1) {
    top: 100px
  }

  .landing .popcards__item:nth-child(2) {
    top: 150px
  }

  .landing .popcards__item:nth-child(3) {
    top: 200px
  }

  .landing .popcard-info__button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0
  }

  .landing .popcard-info__text {
    margin-bottom: 18px
  }

  .landing .slider-person__img {
    width: 136px;
    height: 136px
  }

  .landing .slider-semicircle {
    width: 72px;
    height: 136px
  }

  .landing .slider-person__info {
    left: 0
  }

  .landing section.container_padding-b {
    padding-bottom: 28px;
  }

  .landing .section.section_people {
    padding: 28px 0 68px
  }

  .landing .title-block__row-animated .title-big {
    font-size: 26px
  }

  .landing .text-block p.text-big {
    font-size: 14px;
    width: 80%;
    margin: 0 auto
  }

  .landing .title-block.title-block_centered {
    margin-bottom: 18px
  }

  .landing .swiper-container.swiper-container_people {
    margin-top: 32px
  }

  .landing .sticky-slider-cont {
    display: block;
    width: 100% !important;
  }

  .landing .wide-container {
    width: 96%;
    margin: 0 auto
  }

  .landing .section_reviews .grid .wide-container {
    width: 100%
  }

  .landing .sticky-slider-slide {
    width: 100%;
    margin-bottom: 12px
  }

  .landing .card-img-text__img {
    width: 132px;
    height: 132px
  }

  .landing .card-img-text__txt {
    width: 100%;
    height: 100%;
    max-width: 100%
  }

  .landing .card-img-text {
    -webkit-box-shadow: -20px 17px 51px rgba(0, 0, 0, .03);
    box-shadow: -20px 17px 51px rgba(0, 0, 0, .03)
  }

  .landing .sticky-slider-scrollbar {
    display: none
  }

  .landing .title-block__row-animated span {
    font-size: 26px
  }

  .landing .section.section_untop .circle-pit {
    display: none
  }

  .landing .sticky-container {
    padding-top: 54px
  }

  .landing .section-untop-first {
    margin-bottom: 0;
    padding-top: 38px
  }

  .landing .grid__half-l {
    width: 100%
  }

  .landing .grid__half-r {
    width: 100%
  }

  .landing img.half-img-big {
    top: 0;
    width: 112%;
    max-width: -webkit-min-content;
    max-width: -moz-min-content;
    max-width: min-content;
    margin-left: -13%;
    margin-top: 28px
  }

  .landing .section.section_main.section_main-page .wide-right-block {
    height: 104px;
    width: 100%
  }

  .landing .mask-pic_main-1 {
    left: 0;
    right: 0;
    margin: auto
  }

  .landing .mask-pic_main-1,
  .landing .mask-pic_main-2,
  .landing .mask-pic_main-3 {
    top: 0;
    height: 104px;
    width: 104px
  }

  .landing .circle-pic_main-1,
  .landing .circle-pic_main-2,
  .landing .circle-pic_main-3 {
    top: 0;
    width: 54px;
    height: 104px
  }

  .landing .circle-pic_main-1 {
    left: -170px;
    right: 0;
    margin: auto
  }

  .landing .circle-pic_main-3 {
    display: block;
    right: -170px;
    left: 0;
    margin: auto
  }

  .landing .circle-pic_main-2 {
    display: none
  }

  .landing .section.section_main.section_main-page .mask-text-cont {
    display: none
  }

  .landing .mask-pic.mask-pic_main-3 {
    right: auto;
    left: -50px
  }

  .landing .mask-pic_main-2 {
    left: auto;
    right: -50px;
    display: block
  }

  .landing .mouse-block {
    display: none
  }

  .landing .section.section_main-page .mouse-block {
    display: block;
    position: absolute;
    bottom: -75px;
    left: 0;
    right: 0;
    text-align: center
  }

  .landing .mouse img {
    margin-right: 12px
  }

  .landing .section.section_main.section_main-page .grid__main-r {
    margin-top: 52px;
    margin-bottom: 68px
  }

  .landing .footer-top .footer-cont__col.footer-cont__col_1 {
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    text-align: center
  }

  .landing .footer-logo {
    margin: 0 auto
  }

  .landing .footer-top .footer-cont {
    padding-top: 54px;
    display: block
  }

  .landing .footer-top .footer-cont__col.footer-cont__col_2 {
    width: 100%;
    padding-top: 28px;
    border-top: 1px solid #303030
  }

  .landing .footer-title {
    display: none
  }

  .landing .footer-contacts {
    display: block
  }

  .landing .footer-contacts__item {
    width: 100%;
    margin-bottom: 24px;
    text-align: center
  }

  .landing .footer-cont__col.footer-cont__col_3 {
    width: 100%;
    text-align: center
  }

  .landing .footer-socs__item {
    margin-right: 4px
  }

  .landing .footer-bot .footer-cont__col.footer-cont__col_3 {
    position: relative;
    margin-top: 15px
  }

  .landing .footer-text a,
  .landing .footer-text span {
    font-size: 12px
  }

  .landing .footer-bot .footer-cont__col.footer-cont__col_1 {
    margin-right: 0;
    width: 100%;
    margin-bottom: 8px
  }

  .landing .footer-bot {
    padding: 16px 0
  }

  .landing .footer-dev img {
    width: 102px
  }

  .landing .header__nav .btn.btn_starts {
    display: block;
    text-align: center;
    margin: 12px 4% 0;
    height: auto;
    padding: 12px 16px
  }

  .btn_stars_mobile {

  }

  .landing .header__nav .btn.btn_starts span {
    font-weight: 600;
    font-size: 16px
  }

  .landing .section.section_main.section_main-page .text-block p {
    font-size: 14px
  }

  .landing .section.section_faq-main .title-block {
    margin-top: 0
  }

  .landing .sections .section.section_main {
    padding: 38px 0
  }

  .landing .section.section_main.section_main-page {
    min-height: initial;
  }

  .landing .section.section_main.section_main-page .grid__main-r {
    height: auto;
  }

  .landing .section.section_faq-main .grid__main-l {
    padding-right: 0
  }

  .landing h1 {
    font-size: 28px
  }

  .landing .tablet-v-show {
    display: none
  }

  .landing .section.section_main.section_faq-main .grid__main-r {
    height: 316px
  }

  .landing .section.section_faq {
    margin-top: 0;
    padding-bottom: 48px
  }

  .landing .circle-pic.circle-pic_faq-1 {
    top: 30%;
    left: -52%;
    right: 0;
    margin: 0 auto;
    width: 92px;
    height: 178px
  }

  .landing .wide-right-block {
    width: 100%
  }

  .landing .circle-pic.circle-pic_faq-2 {
    top: 20%;
    left: -26%;
    right: 0;
    margin: 0 auto;
    width: 76px;
    height: 76px
  }

  .landing .faq-pic-main {
    width: 178px;
    margin: 0 auto
  }

  .landing .circle-pic.circle-pic_faq-3 {
    width: 42px;
    height: 42px;
    top: 47%;
    right: 15%
  }

  .landing .text-pic_faq.text-pic_faq-1 {
    top: 206px;
    left: 60px;
    right: -60px
  }

  .landing .text-pic_faq.text-pic_faq-1 .row-info {
    text-align: right
  }

  .landing .row-info-text span {
    font-size: 10px
  }

  .landing .row-info-text {
    padding: 4px 12px
  }

  .landing .accordion-cont__title {
    font-size: 14px;
    padding: 0 32px;
    width: calc(100% - 42px);
    line-height: 1.6;
    border-right: 0
  }

  .landing .accordion__cont {
    padding: 22px 0
  }

  .landing .accordion-cont__plus {
    padding-top: 10px;
    width: auto;
    margin-right: 18px
  }

  .landing .accordion__panel p {
    font-size: 15px;
    line-height: 1.6
  }

  .landing .accordion__panel {
    padding: 0 32px 32px
  }

  .landing .askform .title-block__row-animated span {
    font-size: 28px;
    line-height: 1.12
  }

  .landing .askform-panel__field-half {
    width: 100%
  }

  .landing .askform-panel__fields-row {
    display: block
  }

  .landing .askform-panel {
    padding: 62px 32px
  }

  .landing .askform-panel__button {
    display: block
  }

  .landing .askform-panel-button {
    margin: 0;
    margin-bottom: 20px
  }

  .landing .askform-panel__fields {
    margin-bottom: 22px
  }

  .landing .askform-panel-buttext span {
    font-size: 13px
  }

  .landing input {
    font-size: 14px
  }

  .landing .section.section_main.section_about-main .grid__main-r {
    height: 192px;
    margin-top: 52px
  }

  .landing .circle-pic.circle-pic_about-1,
  .landing .circle-pic.circle-pic_about-2,
  .landing .mask-pic.mask-pic_about-1 {
    top: 0
  }

  .landing .mask-pic.mask-pic_about-1 {
    right: -16%
  }

  .landing .circle-pic_about-2 {
    left: auto;
    right: 44%
  }

  .landing .circle-pic_about-1 {
    left: auto;
    right: 78%
  }

  .landing .text-pic_about.text-pic_about-2 {
    top: 58px
  }

  .landing .text-pic_about.text-pic_about-1 {
    top: 14px;
    right: -26px
  }

  .landing .grid__main-l.grid__main-l_short {
    padding-right: 0
  }

  .landing .text-pic_aboutpage {
    display: none
  }

  .landing .mask-pic.mask-pic_aboutpage-1,
  .landing .mask-pic.mask-pic_aboutpage-2,
  .landing .mask-pic.mask-pic_aboutpage-3,
  .landing .mask-pic.mask-pic_aboutpage-4 {
    display: block;
    top: auto
  }

  .landing .mask-pic.mask-pic_aboutpage-10,
  .landing .mask-pic.mask-pic_aboutpage-5,
  .landing .mask-pic.mask-pic_aboutpage-6,
  .landing .mask-pic.mask-pic_aboutpage-7,
  .landing .mask-pic.mask-pic_aboutpage-8,
  .landing .mask-pic.mask-pic_aboutpage-9 {
    display: none
  }

  .landing .mask-pic.mask-pic_aboutpage-1 {
    left: -5%;
    right: auto
  }

  .landing .mask-pic.mask-pic_aboutpage-2 {
    right: auto;
    left: 23%
  }

  .landing .mask-pic.mask-pic_aboutpage-3 {
    left: auto;
    right: 23%
  }

  .landing .mask-pic.mask-pic_aboutpage-4 {
    right: -5%;
    left: auto
  }

  .landing .mask-pic.mask-pic_aboutpage-1,
  .landing .mask-pic.mask-pic_aboutpage-3 {
    bottom: 52px
  }

  .landing .mask-pic.mask-pic_aboutpage-2,
  .landing .mask-pic.mask-pic_aboutpage-4 {
    bottom: 68px
  }

  .landing .centered-slider-wrap {
    padding: 42px 0 202px
  }

  .landing .centered-slider-item__text p {
    font-size: 14px
  }

  .landing .centered-slider-item {
    width: 86%
  }

  .landing .centered-slider-item__title {
    margin-bottom: 24px
  }

  .landing .swiper-pagination_centered {
    margin-top: 32px
  }

  .landing .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 40px;
    margin: 0 10px
  }

  .landing .contacts__item:nth-child(1),
  .landing .contacts__item:nth-child(2) {
    width: 100%;
    display: block;
    text-align: center
  }

  .landing .contacts__item {
    margin-bottom: 32px
  }

  .landing .contacts {
    margin-top: 42px
  }

  .landing .contacts__link-dsrcp span {
    font-weight: 700
  }

  .landing .contacts__link {
    margin-bottom: 2px
  }

  .landing .contacts-socs {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center
  }

  .landing .contacts__item:nth-child(3) {
    margin-top: 0
  }

  .landing .section.section_main.section_contacts {
    padding-bottom: 0
  }

  .landing .cost-card {
    display: block;
    padding: 24px 30px
  }

  .landing .cost-card__title {
    width: 100%;
    margin-bottom: 14px;
    padding-right: 0
  }

  .landing .cost-card-title__num {
    font-size: 34px;
    margin-right: 8px
  }

  .landing .cost-card-title__lesson {
    font-size: 34px
  }

  .landing .cost-card__text {
    width: 100%;
    margin-bottom: 28px;
    padding-right: 0
  }

  .landing .cost-card-val span {
    font-size: 28px
  }

  .landing .cost-card-slash {
    width: 16px;
    margin-left: 4px;
    margin-right: 7px
  }

  .landing .cost-card__button {
    position: initial
  }

  .landing .cost-card__button .btn span {
    font-weight: 700;
    font-size: 17px
  }

  .landing .cost-card__vals {
    margin-bottom: 22px
  }

  .landing .cost-info {
    margin-bottom: 42px;
    display: block
  }

  .landing .cost-info-part {
    display: block
  }

  .landing .cost-info__title {
    font-size: 16px;
    margin-right: 0;
    text-align: center;
    margin-bottom: 3px
  }

  .landing .cost-info__logos {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 22px
  }

  .landing .cost-lable span {
    font-size: 12px
  }

  .landing .cost-lable {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center
  }

  .landing .cost-card-lable {
    top: -38px;
    right: -56px;
    left: auto;
    width: 134px
  }

  .landing .section.section_blue.section_funtions .function-cont {
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
  }

  .landing .section.section_blue.section_funtions .function:nth-child(even) .function-cont {
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse
  }

  .landing .function__info + .function__img {
    width: 100%;
    margin-left: 0
  }

  .landing .section_main.section_main-process .grid__main-r {
    display: none
  }

  .landing .function-bg__item.function-bg__item_funct-4 {
    display: none
  }

  .landing .function-bg__item.function-bg__item_funct-3 {
    left: -24px;
    right: 0;
    top: auto;
    bottom: -24px;
    margin: 0 auto
  }

  .landing .function__img.function__img_square {
    left: 6px;
    right: 0;
    margin: auto;
    margin-top: 38px
  }

  .landing .function__img {
    margin-top: 22px
  }

  .landing .section.section_main.section_main-teaching .circle-pit {
    display: none
  }

  .landing .teacher-cont {
    display: block;
    padding: 0 22px
  }

  .landing .teacher__vis {
    width: 100%;
    padding: 22px 0;
    border-bottom: 2px solid #eff3f7
  }

  .landing .teacher__main {
    width: 100%;
    padding: 0
  }

  .landing .teacher__ico {
    width: 100%;
    height: 58px;
    padding: 18px
  }

  .landing .teacher-vis {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    border: 0
  }

  .landing .teacher-main-part {
    border-right: 0;
    border-bottom: 2px solid #eff3f7;
    padding-left: 0;
    padding-right: 0
  }

  .landing .teacher-main-cont {
    border: 0
  }

  .landing .doubleforms__item {
    width: 100%
  }

  .landing .svg-bg {
    display: none
  }

  .landing .section.section_blue.section_couch {
    min-height: auto;
    padding: 52px 0
  }

  .landing .section.section_blue.section_couch .title-block__row-animated span {
    font-size: 28px
  }

  .landing .text-block.text-block_short {
    width: 100%
  }

  .landing .title-block.title-block_centered.title-block_short {
    margin-bottom: 22px
  }

  .landing .section_nearvideo .swiper-pagination {
    text-align: center
  }

  .landing .circle-pic.circle-pic_teachersmany-3 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-6 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-8 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-8 {
    display: none
  }

  .landing .mask-pic.mask-pic_teachersmany-9 {
    display: none
  }

  .landing .circle-pic.circle-pic_teachersmany-6 {
    display: none
  }

  .landing .section.section_teaches .circle-pit .mask-pic {
    width: 92px;
    height: 92px;
    top: auto;
    bottom: 62px
  }

  .landing .section.section_teaches .circle-pic {
    width: 48px;
    height: 92px;
    top: auto;
    bottom: 62px
  }

  .landing .mask-pic.mask-pic_teachersmany-2 {
    left: 0;
    right: 0;
    margin: auto
  }

  .landing .mask-pic.mask-pic_teachersmany-3 {
    right: auto;
    left: -24px
  }

  .landing .mask-pic.mask-pic_teachersmany-5 {
    left: auto;
    right: -24px
  }

  .landing .circle-pic.circle-pic_teachersmany-1 {
    left: 80px;
    right: auto
  }

  .landing .circle-pic.circle-pic_teachersmany-4 {
    right: 80px
  }

  .landing .section.section_teaches {
    padding: 72px 0 208px
  }

  .landing .section.section_carriers .grid__half-r {
    display: none
  }

  .landing .section.section_carriers {
    padding: 52px 0
  }

  .landing .teacher-main-article {
    margin-bottom: 28px !important
  }

  .landing .filters {
    margin-top: -82px
  }

  .landing .section.section_doubleforms {
    padding: 52px 0
  }

  .landing .doubleforms {
    margin-top: 32px
  }

  .landing .doubleforms-item__fields {
    margin-bottom: 22px
  }

  .landing .doubleforms-item-field {
    margin-bottom: 8px
  }

  .landing .doubleforms-or {
    top: 232px;
    font-size: 17px;
    width: 62px;
    height: 62px;
    padding: 17px 0
  }

  .landing .doubleforms__item:nth-child(2) {
    padding-top: 16px
  }

  .landing .doubleforms__item:nth-child(1) {
    padding-bottom: 22px
  }

  .landing .doubleforms .btn.btn_main {
    padding: 12px 40px
  }

  .landing .filters-pp {
    width: 100%
  }

  .landing .doubleforms-item {
    padding: 34px 12px
  }

  .landing .cookie {
    bottom: 12px;
    right: 12px
  }

  .landing .cookie {
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0
  }

  .landing .cookie-cont__img {
    display: none
  }

  .landing .cookie-cont__info {
    width: 100%;
    padding: 0
  }

  .landing .cookie-cont {
    display: block;
    padding: 22px 34px 18px
  }

  .landing .cookie {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%)
  }

  .landing .cookie.show {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }

  .landing .pp-container {
    width: 90%;
    height: 202px
  }

  .landing .teacher-video.pp_ {
    height: 162px
  }

  .landing .teacher-vis__img {
    margin-bottom: 0
  }

  .landing .teacher-vis__info {
    width: calc(100% - 94px);
    padding-left: 16px
  }

  .landing .pp-cross {
    top: -52px;
    right: -10px
  }

  .landing .pp-container.pp-container_thanx {
    width: 92%
  }

  .landing .thanx__img {
    width: 180px;
    height: 112px;
    margin: 0 auto
  }

  .landing .thanx__info {
    width: 100%;
    margin-bottom: 24px
  }

  .landing .thanx-title span {
    font-size: 28px
  }

  .landing .thanx {
    display: block
  }

  .landing .thanx-title {
    text-align: center
  }

  .landing .thanx-text {
    text-align: center
  }

  .landing .no-content__img {
    width: 262px;
    margin-bottom: -20px
  }

  .landing .no-content__text {
    font-size: 18px
  }

  .landing .no-content {
    padding: 18px 0 42px
  }

  .landing .page404__img {
    width: 100%;
    margin: 0 auto;
    margin-top: 62px;
    margin-bottom: -12px
  }

  .landing .page404__title {
    font-size: 24px;
    margin-bottom: 14px
  }

  .landing .page404 {
    width: 90%;
    margin: 0 auto
  }

  .landing .footer-cont {
    text-align: center
  }

  .landing .swiper-container.swiper-container_nearvideo {
    margin-bottom: 42px
  }

  .landing .doubleforms-item__title span {
    font-size: 22px
  }

  .landing p a {
    border-bottom: 1px solid
  }

  .landing .header {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
  }

  .landing .header.header_outtop {
    top: -100%
  }

  .landing label.error {
    position: relative;
    bottom: 0
  }

  .landing input[type=checkbox] ~ label.error {
    bottom: 8px
  }

  .landing .section.section_halfpink:after {
    display: none
  }

  .landing .choice {
    margin-top: 0
  }

  .landing .choice-cont {
    display: block
  }

  .landing .choice-cont__part {
    padding: 42px 0
  }

  .landing .choice-cont__part:nth-child(1) {
    margin-bottom: 18px
  }

  .landing .choice-text {
    margin-bottom: 32px
  }

  .landing .choice-img-item__label {
    top: 109px;
    width: 95px
  }

  .landing .choice-img {
    margin-bottom: 52px
  }

  .landing .section.section_people.section_halfpink {
    padding-bottom: 0
  }

  .landing .func-step {
    padding-left: 0;
    margin-top: -6px
  }

  .landing .rewvid-img-item {
    height: 172px;
    margin-bottom: 15px
  }

  .landing .review-card-user__dscrp {
    font-size: 14px
  }

  .landing .review-card-user__img {
    width: 43px;
    height: 43px
  }

  .landing .review-card-user__info {
    width: calc(100% - 43px)
  }

  .landing .section_courses-main .grid__main-l {
    width: 100%
  }

  .landing .section_courses-main .grid__main-r {
    width: 100%;
    height: 190px
  }

  .landing .circle-pic_courses-1,
  .landing .circle-pic_courses-2,
  .landing .circle-pic_courses-3,
  .landing .mask-pic_courses-1,
  .landing .mask-pic_courses-2,
  .landing .mask-pic_courses-3,
  .landing .mask-pic_courses-4,
  .landing .mask-pic_courses-5 {
    width: 82px;
    height: 82px
  }

  .landing .circle-pic_courses-1,
  .landing .mask-pic_courses-1,
  .landing .mask-pic_courses-2,
  .landing circle-pic_courses-2 {
    top: 28px
  }

  .landing .circle-pic_courses-3,
  .landing .mask-pic_courses-3,
  .landing .mask-pic_courses-4,
  .landing .mask-pic_courses-5 {
    top: 130px
  }

  .landing .circle-pic_courses-1 {
    left: 5px
  }

  .landing .mask-pic_courses-1 {
    left: 88px
  }

  .landing .mask-pic_courses-2 {
    right: 12px;
    left: auto
  }

  .landing .mask-pic_courses-2 {
    left: 71.9%;
  }

  .landing .mask-pic_courses-4 {
    left: 71.9%;
  }

  .landing .mask-pic_courses-3 {
    left: 4px
  }

  .landing .mask-pic_courses-5 {
    right: 108px;
    left: auto
  }

  .landing .circle-pic_courses-2 {
    top: 28px;
    left: 171px
  }

  .landing .circle-pic_courses-3 {
    left: 79px
  }

  .landing .courses-bg-block__img {
    display: none
  }

  .landing .courses-bg-block__text {
    width: 100%
  }

  .landing .courses-bg-block {
    width: 100%
  }

  .landing .section.section_courses-bg {
    padding: 42px 0 58px
  }

  .landing .section.section_funtions.section_funtions-courses .function-cont {
    -ms-flex-direction: column;
    -webkit-box-orient: column;
    -webkit-box-direction: column;
    flex-direction: column
  }

  .landing .section_funtions-courses .function-info__title h3 {
    font-size: 28px
  }

  .landing .section_funtions-courses .function__img {
    margin-top: 0;
    margin: 0 auto
  }

  .landing .section.section_funtions.section_funtions-courses .function:nth-child(even) .function-cont {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .landing .section_funtions-courses .function__info {
    padding-right: 0;
    text-align: left
  }

  .landing .section_funtions-courses .function__info {
    padding-top: 28px !important
  }

  .landing .section.section_funtions.section_funtions-courses {
    padding-bottom: 0
  }

  .landing .courses-tarif-wrap {
    display: block
  }

  .landing .courses-tarif {
    width: 100%
  }

  .landing .courses-tarif:nth-child(1) {
    margin-bottom: 38px
  }

  .landing .section.section_courses-tarif {
    padding-top: 68px;
    padding-bottom: 0
  }

  .landing .section_courses-main .title-block__row-animated span {
    font-size: 28px
  }

  .landing .section.section_main.section_courses-main .text-block p strong {
    font-size: 16px
  }

  .landing .section.section_main.section_courses-main .text-block p {
    margin-bottom: 24px
  }

  .landing .section.section_main.section_courses-main .title-block {
    margin-bottom: 18px
  }

  .landing .title-lable img {
    width: 120px
  }

  .landing .title-lable {
    margin-bottom: 16px
  }

  .landing .mob-align-left .btn {
    margin-left: 0
  }

  .landing .courses-tarif__img img {
    width: 240px;
    height: 240px
  }

  .landing .courses-tarif__title span {
    font-size: 28px
  }

  .landing .courses-tarif {
    padding: 32px 28px
  }

  .landing .courses-tarif__text p {
    font-size: 14px
  }

  .landing .courses-tarif__buttons .btn {
    width: 100%;
    margin-bottom: 10px
  }

  .landing .circle-pic.circle-pic_courses-big-1,
  .landing .mask-pic.mask-pic_courses-big-1 {
    height: 148px;
    width: 148px
  }

  .landing .circle-pic.circle-pic_courses-big-1,
  .landing .mask-pic.mask-pic_courses-big-1 {
    top: 45px
  }

  .landing .section.section_textonly {
    padding-top: 0
  }

  .landing .mask-pic.mask-pic_courses-big-1 {
    left: 0;
    right: 0;
    margin: 0 auto
  }

  .landing .circle-pic.circle-pic_courses-big-1 {
    left: auto;
    right: -36px
  }

  .landing .textonly-block {
    padding: 32px 28px
  }

  .landing .section.section_coursesinfo .title-block {
    text-align: left
  }

  .landing .coursesinfo-item__title span {
    font-size: 20px
  }

  .landing .coursesinfo-item__content li {
    font-size: 14px;
    margin-bottom: 14px
  }

  .landing .coursesinfo-item-listitem__title {
    font-size: 14px
  }

  .landing .coursesinfo-item {
    padding: 28px 24px
  }

  .landing .coursesinfo-item-listitem__dscrp {
    font-size: 14px
  }

  .landing .section.section_courses-slider .title-block {
    text-align: left
  }

  .landing .swiper-button-next,
  .landing .swiper-button-prev {
    width: 42px;
    height: 42px;
    background: 0 0
  }

  .landing .swiper-navigation {
    width: 116px;
    top: -42px
  }

  .landing .swiper-button-next:after,
  .landing .swiper-button-prev:after {
    width: 16px;
    height: 12px
  }

  .landing .slider-cost-dcrp {
    font-size: 14px
  }

  .landing .article-list {
    display: block
  }

  .landing .article-block {
    margin-bottom: 18px
  }

  .landing .article-top__title h1 {
    font-size: 22px
  }

  .landing .article-top {
    padding: 28px 32px
  }

  .landing .article-top__title {
    margin-bottom: 108px
  }

  .landing .article-content {
    padding: 28px 32px
  }

  .landing .article-content-box__block h2 {
    font-size: 18px
  }

  .landing .article-content-box__block p {
    font-size: 14px
  }

  .landing .article-content-box__block h3 {
    font-size: 16px
  }

  .landing .article-content-box__block h2,
  .landing .article-content-box__block h3 {
    margin-bottom: 18px
  }

  .landing .article-content-box__socs {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 28px
  }

  .landing a.article-soc.article-soc_horizontal:nth-child(1) {
    border-left: 2px solid #f3f3f3
  }

  .landing .section.section_article {
    padding-bottom: 0
  }

  .landing .section.section_articles .grid {
    position: initial
  }

  .landing .section.section_articles {
    padding-bottom: 100px
  }

  .landing .articlesall {
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: auto;
    bottom: 0
  }

  .landing .header-float__nav span {
    font-size: 19px
  }

  .landing .header-float:after {
    top: 24px;
    left: 120px
  }

  .landing .header-float {
    display: block;
    padding: 16px 34px
  }

  .landing .header-float .header-float__items {
    width: calc(100% + 92px)
  }

  .landing .slidercost {
    border-radius: 12px
  }

  .landing .swiper-container.swiper-container_slidercost .swiper-slide {
    margin-right: 12px
  }

  .landing .swiper-container.swiper-container_slidercost {
    margin-left: -15px;
    width: calc(100% + 30px)
  }

  .landing .swiper-container.swiper-container_slidercost .swiper-slide:nth-child(1) {
    margin-left: 15px
  }

  .landing .footer-top .footer-cont__col.footer-cont__col_2 {
    display: none
  }

  .landing .footer-top .footer-cont__col.footer-cont__col_3 {
    border-top: 1px solid #242424;
    padding-top: 18px
  }

  .landing .wrapper_dark .footer-top .footer-cont__col.footer-cont__col_3 {
    border-color: #e4e4e4
  }

  .landing .footer-pays:after,
  .landing .footer-top:after {
    display: none
  }

  .landing .footer-bot .footer-text {
    display: block;
    margin: 0;
    margin-bottom: 8px
  }

  .landing .footer-bot {
    border-top: 1px solid #242424;
    padding-top: 18px
  }

  .landing .wrapper_dark .footer-bot {
    border-color: #e4e4e4
  }

  .landing .footer-contacts {
    margin-bottom: 22px
  }

  .landing .footer-cont {
    display: block
  }

  .landing .footer-cont__col.footer-cont__col_1 {
    width: 100%
  }

  .landing .footer-under .footer-cont__col.footer-cont__col_1 {
    margin-bottom: 18px
  }

  .landing .footer-bot .footer-text:nth-last-child(1) {
    margin-bottom: 0
  }

  .landing .section.section_courses-bg .buttons-block {
    text-align: left
  }

  .landing .section.section_courses-bg .buttons-block .btn {
    margin-left: 0
  }

  .landing .mob-show {
    display: block
  }

  .landing .circle-pic.circle-pic_courses-big-2 {
    top: 45px;
    left: -36px;
    height: 148px;
    width: 148px
  }

  .landing .section.section_articles {
    padding-bottom: 0
  }

  .landing .article-list-more {
    margin-top: 38px
  }

  .landing .article-list-more .btn {
    font-size: 14px
  }

  .landing .section.section_halfpink .title-block__row-animated .title-big br {
    display: none
  }

  .landing .article-content {
    padding: 42px 26px
  }

  .landing .article-top {
    padding: 48px 26px
  }

  .landing .article-content-box__block li {
    font-size: 14px;
    padding-left: 8px
  }

  .landing .article-content-box__block blockquote:after {
    display: none
  }

  .landing .article-content-box__block blockquote {
    padding: 28px 32px;
    border-radius: 12px;
    font-size: 16px;
    line-height: 160%
  }

  .landing .article-content-box__block iframe {
    border-radius: 12px
  }

  .landing .article-content-box__block img {
    border-radius: 12px
  }

  .landing .section_articles-shortlist .title-block {
    text-align: left
  }

  .landing .article-top {
    background: url(/landing/svg/article-top-bg-mob.svg) no-repeat center;
    background-size: cover
  }

  .landing .article-content-box__block iframe {
    height: 188px
  }

  .landing .section_reviews .grid.title-block {
    padding-right: 128px
  }

  .landing .section.section_main.section_main-page .grid__main-l {
    width: 100%
  }

  .landing .section.section_funtions.section_funtions-main .function__info .buttons-block {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .landing .footer-pays .footer-cont {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .landing .footer-pays {
    border-top: 1px solid #242424
  }

  .landing .wrapper_dark .footer-pays {
    boder-color: #e4e4e4
  }

  .landing .section.section_main.section_courses-main .wide-right-block {
    width: 345px;
    margin: 0 auto
  }

  .landing .function .btn {
    margin-left: 0 !important
  }

  .landing .function__info .buttons-block {
    -ms-flex-pack: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -ms-flex-direction: column;
    -webkit-box-orient: column;
    -webkit-box-direction: normal;
    flex-direction: column
  }

  .landing .subtext-block p {
    font-size: 16px
  }

  .landing .section.section_coursesinfo {
    padding-bottom: 0
  }

  .landing .section.section_textonly {
    padding-bottom: 72px
  }

  .landing .choice-text {
    font-size: 14px
  }

  .landing .sections .mob-align-left .text-block {
    text-align: left
  }

  .landing .sections .mob-align-left .title-block__row-animated {
    text-align: left
  }

  .landing .section.section_main.section_main-page .mouse-block {
    margin-top: 0
  }

  .landing .sections .section.section_main-page .title-block__row-animated {
    text-align: center
  }

  .landing .article-block__title {
    min-height: auto
  }

  .landing .function__info .buttons-block .btn {
    margin-bottom: 8px
  }

  .landing .function__info .buttons-block .btn:nth-last-child(1) {
    margin-bottom: 0
  }

  .landing .btn.btn_enter {
    border: 1px solid #0A0A0B !important;
  }

  .landing .btn:hover.btn_enter {
    background: #4987ff url(/landing/svg/enter-mob.svg) no-repeat center !important;
    border: 1px solid #4987ff !important;
  }

  /*.landing .btn_header.btn_enter.btn_white {*/
  /*  background: url(/landing/svg/enter-mob-ondark.svg) no-repeat center !important;*/
  /*  background-color: #00B49F;*/
  /*  background-size: 35%;*/
  /*}*/
  .landing .section.section_article {
    padding: 28px 0
  }

  .landing .section.section_main.section_courses-mainmainitem {
    padding-bottom: 42px;
    margin-bottom: 0
  }

  .landing .section.section_funtions.section_funtions-onabout .function-info__text {
    text-align: left
  }

  .landing .section.section_funtions.section_funtions-onabout .function-info__title {
    text-align: left
  }

  .landing .section.section_funtions.section_funtions-onabout .function__img {
    margin-top: 0
  }

  .landing .choice-img-item__float {
    left: -33px;
    top: 98px
  }

  .landing .slidercost__title span {
    font-size: 22px
  }

  .function-cont__mobile-columns {
    flex-direction: column !important;
  }
}


@media only screen and (max-width: 680px) {
  .main__steps-item {
    min-width: initial;
  }
}

/*.swiper-slide-divider {*/
/*  width: 150px !important;*/
/*}*/

/*.scrollmagic-pin-spacer{*/
/*  padding: 0;*/
/*}*/
